<div class="actions flex-1 margin-y-2">
    <button
        class="omedom-button-primary flex-1 w-100"
        (click)="refreshEstimation.emit()"
    >
        <i class="uil uil-chart-line"></i>
        <span>
            {{
                hasValorisation
                    ? 'Rafraîchir la valorisation'
                    : 'Commencer la valorisation'
            }}
        </span>
    </button>
</div>
