import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'omedom-refresh-estimation',
    templateUrl: './refresh-estimation.component.html',
    styleUrls: ['./refresh-estimation.component.scss'],
})
export class RefreshEstimationComponent {
    @Input() hasValorisation = false;
    @Output() refreshEstimation = new EventEmitter<void>();
    constructor() {}
}
