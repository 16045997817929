@if (invoiceDetails !== null) {
    <div class="container margin-y-1" id="document-info">
        <div id="header">
            <h2 id="title" class="margin-bottom-1">
                Informations sur la facture
            </h2>
            @if (invoiceDetails.invoiceType) {
                <div id="subtitle">{{ invoiceDetails.invoiceType }}</div>
            }
            @if (invoiceDetails.constructionWorkDetail) {
                <div id="detail">
                    {{ invoiceDetails.constructionWorkDetail }}
                </div>
            }
        </div>
        @if (invoiceDetails.invoiceNumber || hasSupplier) {
            <hr class="omedom-divider" />
            <div id="content">
                @if (invoiceDetails.invoiceNumber) {
                    <div id="invoice-number">
                        <span class="label margin-right-1">
                            Numéro&nbsp;de&nbsp;facture
                        </span>
                        <span class="value">
                            {{ invoiceDetails.invoiceNumber }}
                        </span>
                    </div>
                }
                @if (hasSupplier) {
                    @if (invoiceDetails.supplier?.supplierName) {
                        <div id="supplier">
                            <span class="label margin-right-1">Fournisseur</span
                            ><span id="supplier-name">
                                {{ invoiceDetails.supplier?.supplierName }}
                            </span>
                        </div>
                    }
                    @if (hasSupplierAddress) {
                        <div id="supplier-address">
                            <span class="label">Adresse</span>
                            <div class="value">
                                @if (
                                    invoiceDetails.supplier?.address
                                        ?.streetNumber ||
                                    invoiceDetails.supplier?.address
                                        ?.suffixNumber ||
                                    invoiceDetails.supplier?.address?.street
                                ) {
                                    <div id="address-number-and-street">
                                        {{
                                            invoiceDetails.supplier?.address
                                                ?.streetNumber || ''
                                        }}
                                        {{
                                            invoiceDetails.supplier?.address
                                                ?.suffixNumber ?? ''
                                        }}{{
                                            invoiceDetails.supplier?.address
                                                ?.streetNumber
                                                ? ', '
                                                : ''
                                        }}
                                        {{
                                            invoiceDetails.supplier?.address
                                                ?.street || ''
                                        }}
                                    </div>
                                }
                                @if (
                                    invoiceDetails.supplier?.address
                                        ?.addressLine2
                                ) {
                                    <div id="address-line-2">
                                        {{
                                            invoiceDetails.supplier?.address
                                                ?.addressLine2 || ''
                                        }}
                                    </div>
                                }
                                @if (
                                    invoiceDetails.supplier?.address
                                        ?.postalCode ||
                                    invoiceDetails.supplier?.address?.city
                                ) {
                                    <div id="address-postal-code-and-city">
                                        {{
                                            invoiceDetails.supplier?.address
                                                ?.postalCode || ''
                                        }}
                                        {{
                                            invoiceDetails.supplier?.address
                                                ?.city || ''
                                        }}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                }
            </div>
        }
        @if (
            invoiceDetails.dueDate ||
            invoiceDetails.amount ||
            invoiceDetails.totalAmount
        ) {
            <hr class="omedom-divider" />
            <div id="footer">
                @if (invoiceDetails.dueDate) {
                    <div id="due-date">
                        <span class="label margin-right-1"
                            >Paiement&nbsp;le</span
                        >
                        <span class="value"
                            >{{ dueDate | date: 'dd/MM/yyyy' }}
                        </span>
                    </div>
                }

                @if (invoiceDetails.amount || invoiceDetails.totalAmount) {
                    <div id="amounts">
                        @if (invoiceDetails.amount) {
                            <div id="amount" class="w-100">
                                <span class="label margin-right-1"
                                    >Montant&nbsp;HT</span
                                >
                                <span id="amount" class="value"
                                    >{{
                                        invoiceDetails.amount | currency: 'EUR'
                                    }}
                                </span>
                            </div>
                        }
                        @if (invoiceDetails.totalAmount) {
                            <div id="total-amount" class="w-100">
                                <span class="label margin-right-1"
                                    >Montant&nbsp;TTC</span
                                >
                                <span id="total-amount" class="value"
                                    >{{
                                        invoiceDetails.totalAmount
                                            | currency: 'EUR'
                                    }}
                                </span>
                            </div>
                        }
                    </div>
                }
            </div>
        }
    </div>
}
