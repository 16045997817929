import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ReferralBalance, ReferralEntity } from '@omedom/data';
import { lastValueFrom, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReferralService {
    /**
     * @description Entity builder for the service
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @protected
     * @memberof ReferralService
     */
    protected builder = ReferralEntity;

    /**
     * Creates an instance of ReferralService.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @param {AngularFirestore} firestore
     * @memberof ReferralService
     */
    constructor(
        protected readonly firestore: AngularFirestore,
        protected functions: AngularFireFunctions,
    ) {}

    /**
     * @description Get the referral of a user (Observable version)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @param {string} userUID
     * @returns {*}  {Observable<ReferralEntity>}
     * @memberof ReferralService
     */
    public _getReferral(userUID: string): Observable<ReferralEntity> {
        return this.firestore
            .collectionGroup<ReferralEntity>('referrals', (ref) => ref.where('uid', '==', userUID))
            .valueChanges()
            .pipe(
                map((snap) =>
                    snap.map((data) => {
                        return this.builder
                            ? new this.builder(data as ReferralEntity)
                            : (data as ReferralEntity);
                    }),
                ),
                map((referrals) => referrals[0]),
            );
    }

    /**
     * @description Get the referral of a user (Promise version)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @deprecated Use _getReferral instead
     * @param {string} userUID
     * @returns {*}  {Promise<ReferralEntity>}
     * @memberof ReferralService
     */
    public getReferral(userUID: string): Promise<ReferralEntity> {
        return lastValueFrom(
            this.firestore
                .collectionGroup<ReferralEntity>('referrals', (ref) =>
                    ref.where('uid', '==', userUID),
                )
                .get()
                .pipe(
                    map(
                        (snap) =>
                            snap.docs.map((doc) => {
                                return this.builder
                                    ? new this.builder(doc.data() as ReferralEntity)
                                    : (doc.data() as ReferralEntity);
                            }) as ReferralEntity[],
                    ),
                    map((referrals) => referrals[0]),
                ),
        );
    }

    /**
     * @description Get the referrals of a user (Observable version) (deprecated)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @param {string} referralUserUID
     * @returns {*}  {Observable<ReferralEntity>}
     * @memberof ReferralService
     */
    public _getReferrals(referralUserUID: string): Observable<ReferralEntity[]> {
        return this.firestore
            .doc(`users/${referralUserUID}`)
            .collection<ReferralEntity>('referrals')
            .valueChanges()
            .pipe(
                map((snap) =>
                    snap.map((data) => {
                        return this.builder
                            ? new this.builder(data as ReferralEntity)
                            : (data as ReferralEntity);
                    }),
                ),
            );
    }

    /**
     * @description Get the referrals of a user (Promise version) (deprecated)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @param {string} referralUserUID
     * @deprecated Use _getReferrals instead
     * @returns {*}  {Promise<ReferralEntity>}
     * @memberof ReferralService
     */
    public getReferrals(referralUserUID: string): Promise<ReferralEntity[]> {
        return lastValueFrom(
            this.firestore
                .doc(`users/${referralUserUID}`)
                .collection<ReferralEntity>('referrals')
                .get()
                .pipe(
                    map(
                        (snap) =>
                            snap.docs.map((doc) => {
                                return this.builder
                                    ? new this.builder(doc.data() as ReferralEntity)
                                    : (doc.data() as ReferralEntity);
                            }) as ReferralEntity[],
                    ),
                ),
        );
    }

    public sendReferralInvitationEmail(
        email: string,
        firstname: string,
        name: string,
    ): Promise<void> {
        return lastValueFrom(
            this.functions.httpsCallable<{ firstname: string; name: string; email: string }, void>(
                'sendReferralInvitationEmail',
            )({ email, firstname, name }),
        );
    }

    public withdrawReferralBalance(): Promise<void> {
        return lastValueFrom(this.functions.httpsCallable<void, void>('withdrawReferralBalance')());
    }

    public _getBalance(userUID: string): Observable<ReferralBalance> {
        return this.firestore
            .doc(`users/${userUID}`)
            .collection<ReferralBalance>('referrals')
            .doc('_balance_')
            .valueChanges()
            .pipe(
                map((snap) => {
                    if (!snap) {
                        return new ReferralBalance({});
                    }
                    return new ReferralBalance(snap);
                }),
            );
    }
}
