<span class="omedom-radio-label">{{ label }}</span>
<sup>
    @if (required && label) {
        *
    }
</sup>

<div class="margin-top-1 omedom-radio-option-container-{{ type }}">
    @for (option of options; track option.id; let index = $index) {
        <div
            class="omedom-radio-option"
            [class.margin-top-2]="type === 'column'"
        >
            <input
                [id]="(name ?? '') + index"
                type="radio"
                [value]="option.id"
                [name]="name"
                [checked]="value === option.id"
                [disabled]="isDisabled || disabled"
                (change)="valueChange(option)"
            />
            <label [for]="(name ?? '') + index">{{ option.label }}</label>
        </div>
    }
</div>

@if (popover) {
    <omedom-popover [content]="popover"></omedom-popover>
}
