@switch (state$ | async) {
    @case ('pending') {
        <ng-container *ngTemplateOutlet="pending"></ng-container>
    }
    @default {
        <ng-container *ngTemplateOutlet="content"></ng-container>
    }
}

<ng-template #content>
    @if (bank) {
        <div class="card-header" (click)="toggle()">
            <div class="bank-header">
                <img
                    class="bank-logo"
                    [src]="bank.logoUrl"
                    [alt]="'Logo de la banque ' + bank.name"
                    [title]="bank.name"
                />
                <h3 class="bank-name">{{ bank.name }}</h3>
            </div>
            <div class="bank-balance">
                <h3 class="bank-balance-title">
                    @if (item.status !== statusCode.OK) {
                        <i
                            class="uil uil-exclamation-triangle color-yellow"
                        ></i>
                    }
                    @if (balance && showGlobalBalance) {
                        <span
                            >{{
                                showBalance
                                    ? (balance | currency: 'EUR')
                                    : '--,-- €'
                            }}
                        </span>
                    }
                </h3>
                @if (canSeeMore) {
                    <i
                        class="uil uil-angle-down bank-balance-icon"
                        [class.bank-balance-rotate]="seeMore"
                    ></i>
                }
            </div>
        </div>
        @if (seeMore) {
            <div class="card-content margin-top-1" [@list]="accounts.length">
                @if (item.status === statusCode.OK) {
                    @if (layout !== bankCardLayouts.COMPACT) {
                        <div class="card-actions margin-bottom-1">
                            @if (showSynthesisButton) {
                                <button
                                    class="omedom-button-primary card-action-extend"
                                    id="synthesis"
                                    (click)="goToSynthesis()"
                                >
                                    <i class="uil uil-eye"></i>
                                    Mouvements bancaires
                                </button>
                            }

                            @if (showEditButton) {
                                <ng-container
                                    [ngTemplateOutlet]="edit"
                                ></ng-container>
                            }

                            @if (showDeleteButton) {
                                <ng-container
                                    [ngTemplateOutlet]="delete"
                                ></ng-container>
                            }
                        </div>
                    }
                    @for (type of accountsByType; track type.type) {
                        @if (type.accounts.length > 0) {
                            <div class="account-type">
                                <h4 class="account-type-title">
                                    {{ getTypeLabel(type.type) }}
                                </h4>
                                <span class="account-type-title">
                                    {{
                                        showBalance
                                            ? (type.balance | currency: 'EUR')
                                            : '--,-- €'
                                    }}</span
                                >
                            </div>
                            @if (layout !== bankCardLayouts.COMPACT) {
                                @for (
                                    account of type.accounts;
                                    track account.bridgeID
                                ) {
                                    <div class="account-line margin-bottom-1">
                                        <span class="account-name">{{
                                            account.name
                                        }}</span>
                                        <span>
                                            {{
                                                showBalance
                                                    ? (account.balance
                                                      | currency: 'EUR')
                                                    : '--,-- €'
                                            }}
                                        </span>
                                    </div>
                                }
                            }
                        }
                    }
                    @if (layout === bankCardLayouts.COMPACT) {
                        <div class="card-actions margin-bottom-1">
                            @if (showSynthesisButton) {
                                <button
                                    class="omedom-button-primary card-action-extend"
                                    id="synthesis"
                                    (click)="goToSynthesis()"
                                >
                                    <i class="uil uil-eye"></i>
                                    Mouvements bancaires
                                </button>
                            }

                            @if (showEditButton) {
                                <ng-container
                                    [ngTemplateOutlet]="edit"
                                ></ng-container>
                            }

                            @if (showDeleteButton) {
                                <ng-container
                                    [ngTemplateOutlet]="delete"
                                ></ng-container>
                            }
                        </div>
                    }
                } @else {
                    @if (errorMessage$ | async; as errorMessage) {
                        <omedom-info [information]="errorMessage"></omedom-info>
                    }
                    <div class="card-actions margin-bottom-1">
                        @if (actionEnabled$ | async) {
                            <button
                                class="omedom-button-primary card-action-extend"
                                [disabled]="
                                    (actionState$ | async) === 'pending'
                                "
                                (click)="action()"
                            >
                                {{ actionMessage$ | async }}
                                @if ((actionState$ | async) === 'pending') {
                                    <ion-spinner></ion-spinner>
                                }
                            </button>
                        }
                        <ng-container
                            [ngTemplateOutlet]="delete"
                        ></ng-container>
                    </div>
                }
            </div>
        }
    } @else {
        <i class="uil uil-exclamation-triangle"></i>
        <span>Impossible de récupérer les données bancaires</span>
        <div class="card-actions margin-bottom-1">
            <ng-container [ngTemplateOutlet]="delete"></ng-container>
        </div>
    }
</ng-template>

<ng-template #pending>
    <div class="pending-container">
        <span>Chargement des données bancaires...</span>
        <ion-spinner></ion-spinner>
    </div>
</ng-template>

<ng-template #edit>
    @if (showEditButton) {
        <button class="omedom-button-secondary" (click)="onEdit.emit()">
            <i class="uil uil-pen"></i>
        </button>
    }
</ng-template>

<ng-template #delete>
    @if (showDeleteButton) {
        <button class="omedom-button-warn-secondary" (click)="deleteItem()">
            <i class="uil uil-trash-alt"></i>
        </button>
    }
</ng-template>
