import { Component, Input } from '@angular/core';

@Component({
    selector: 'omedom-renovation-amelioration-card',
    templateUrl: './renovation-amelioration-card.component.html',
    styleUrls: ['./renovation-amelioration-card.component.scss'],
})
export class RenovationAmeliorationCardComponent {
    @Input({ required: true }) value!: number;
    @Input({ required: true }) icon!: string;
    @Input({ required: true }) title!: string;
    @Input({ required: true }) percentage!: number;
    @Input() isProfit = false;
    @Input() unit?: string;

    constructor() {}
}
