@if (!hideHeader) {
    <div
        class="subscription-header"
        [style.background-color]="subscriptionColor"
        [style.color]="subscriptionFontColor"
    >
        <div style="display: flex; flex-direction: column">
            <div style="display: flex; align-items: center">
                @if (
                    subscriptionDocName !== subscriptionRank.ESSENTIAL &&
                    subscriptionDocName !== subscriptionRank.FAMILY &&
                    subscriptionDocName !== subscriptionRank.NOEL2024
                ) {
                    <i class="omedom-icon-crown margin-right-1"></i>
                }
                @if (subscriptionDocName === subscriptionRank.ESSENTIAL) {
                    <i class="uil uil-star margin-right-1"></i>
                }
                @if (subscriptionDocName === subscriptionRank.FAMILY) {
                    <i class="uil uil-users-alt margin-right-1"></i>
                }
                @if (subscriptionDocName === subscriptionRank.NOEL2024) {
                    <i class="uil uil-snowflake margin-right-1"></i>
                }
                <h3 style="display: inline-block">
                    {{ subscriptionName | capitalizeFirstLetter }}
                </h3>
            </div>
            @if (subscriptionDocName === subscriptionRank.BLACKFRIDAY2024) {
                <span style="font-size: 16px"
                    >Offre valable du 25/11/2024 au 02/12/2024</span
                >
            }
            @if (subscriptionDocName === subscriptionRank.NOEL2024) {
                <span style="font-size: 16px"
                    >Offre valable du 06/12/2024 au 06/01/2025</span
                >
            }
        </div>
        @if (displayPrice) {
            <div class="subscription-price">
                <div>
                    @if (
                        subscriptionDocName !==
                            subscriptionRank.BLACKFRIDAY2024 &&
                        subscriptionDocName !== subscriptionRank.NOEL2024 &&
                        subscriptionPrice &&
                        subscriptionPrice.ANNUALLY
                    ) {
                        <span>
                            A partir de
                            {{
                                subscriptionPrice.ANNUALLY / 12 / 100
                                    | currency: 'EUR'
                            }}&nbsp;TTC&nbsp;/&nbsp;mois</span
                        >
                    } @else if (
                        (subscriptionDocName ===
                            subscriptionRank.BLACKFRIDAY2024 ||
                            subscriptionDocName ===
                                subscriptionRank.NOEL2024) &&
                        subscriptionPrice &&
                        subscriptionPrice.ANNUALLY
                    ) {
                        {{
                            subscriptionPrice.ANNUALLY / 100 | currency: 'EUR'
                        }}&nbsp;TTC&nbsp;pour&nbsp;12&nbsp; &nbsp;mois
                    }
                    @if (
                        subscriptionPrice &&
                        !subscriptionPrice.ANNUALLY &&
                        subscriptionPrice.MONTHLY
                    ) {
                        <span
                            >{{
                                subscriptionPrice.MONTHLY / 100
                                    | currency: 'EUR'
                            }}&nbsp;TTC&nbsp;/&nbsp;mois</span
                        >
                    }
                </div>
                <div class="subscription-detail">sans limite de biens</div>
            </div>
        }
        @if (subscriptionDocName === subscriptionRank.ESSENTIAL) {
            <div class="subscription-price">
                <div>
                    <span> Gratuit</span>
                </div>
                <div class="subscription-detail">Limité à <b>un bien </b></div>
            </div>
        }
    </div>
}
<omedom-dropdown
    iconClass="uil uil-angle-down"
    [rotationAnimate]="true"
    [isButtonBackgroundTransparent]="true"
>
    <p
        label
        class="subscription-info"
        style="margin-bottom: 0; display: flex; flex-direction: column"
    >
        <span>{{ subscriptionSubTitle }}</span>
        @if (subscriptionDocName === subscriptionRank.FAMILY) {
            <span>
                <u>5 comptes "Premium" dont 4 à partager</u>
            </span>
        }
    </p>
    @if (
        subscriptionDocName === subscriptionRank.FAMILY ||
        subscriptionDocName === subscriptionRank.PREMIUM ||
        subscriptionDocName === subscriptionRank.BLACKFRIDAY2024 ||
        subscriptionDocName === subscriptionRank.NOEL2024
    ) {
        <div content class="subscription-info">
            <p>- Patrimoine immobilier : <b>Illimité</b></p>
            <p>- Partage entre tiers : <b>Illimité</b></p>
            <p>
                - Pilotage des Sociétés immobilières et partage entre membres
                (gérant et associés)
            </p>
            <p>- Patrimoine financier (Épargnes - Crédits)</p>
            <p>- Prévisionnel de trésorerie</p>
            <p>- Ventilation des mouvements</p>
            <p>- Estimation et valorisation des biens (- DPE projeté)</p>
            <p>- Suivi des locataires (état des loyers, quittance/relance)</p>
            <p>- Connexion bancaire : <b>Illimité</b></p>
            <p>- Centralisation des documents</p>
        </div>
    }
    @if (subscriptionDocName === subscriptionRank.ESSENTIAL) {
        <div content class="subscription-info">
            <p>Patrimoine immobilier : <b>1 bien max</b></p>
            <p>Partage entre tiers : <b>Illimité</b></p>
            <p>Prévisionnel de trésorerie : <b>1 mois</b></p>
            <p>Ventilation des charges / revenus</p>
        </div>
    }
</omedom-dropdown>

@if (displayButton) {
    <div>
        <hr class="omedom-divider" />
        @if (subscriptionDocName === subscriptionRank.ESSENTIAL) {
            <omedom-info
                information="
        Pour passer en version gratuite OMEDOM, veuillez résilier votre
        abonnement en cours."
            ></omedom-info>
        }
        @if (
            subscriptionDocName !== subscriptionRank.ESSENTIAL &&
            subscriptionDocName !== currentSubscription?.rank
        ) {
            <button
                class="omedom-button-primary w-100 margin-top-2"
                (click)="chooseEvent.emit(subscriptionDocName)"
            >
                Choisir cet abonnement
            </button>
        }
        @if (
            currentSubscription?.isSubscribed &&
            subscriptionDocName === currentSubscription?.rank
        ) {
            <div class="omedom-button-container">
                <button
                    class="omedom-button-warn-secondary"
                    (click)="cancelEvent.emit(subscriptionDocName)"
                >
                    Résilier
                </button>
                <button
                    class="omedom-button-primary"
                    (click)="chooseEvent.emit(subscriptionDocName)"
                >
                    Modifier mon abonnement
                </button>
            </div>
        }
    </div>
}
