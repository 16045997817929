import { Component, Input, OnInit } from '@angular/core';
import { IonModal, ModalController, ToastController } from '@ionic/angular';
import { SocietyEntity, SocietyMember, SocietyStatusMember } from '@omedom/data';
import { SocietyService } from '@omedom/services';

import { SocietyMemberFormComponent } from '../../containers/society/society-member-form/society-member-form.component';

@Component({
    selector: 'omedom-member-card',
    templateUrl: './member-card.component.html',
    styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent implements OnInit {
    /**
     * @description  Member data
     * @author Jérémie Lopez
     * @type {SocietyMember}
     * @memberof MemberCardComponent
     */
    @Input({ required: true })
    public member!: SocietyMember;

    /**
     * @description Get the member index in the list
     * @author Jérémie Lopez
     * @type {number}
     * @memberof MemberCardComponent
     */
    @Input()
    public index = -1;

    /**
     * @description If true, the user can edit the member
     * @author Jérémie Lopez
     * @memberof MemberCardComponent
     */
    @Input()
    public canEditMember = false;

    /**
     * @description Societ Entity data
     * @author Jérémie Lopez
     * @private
     * @type {SocietyEntity}
     * @memberof MemberCardComponent
     */
    @Input()
    public society?: SocietyEntity;

    /**
     * @description If true, the user can edit the member
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 12/08/2024
     * @memberof MemberCardComponent
     */
    @Input()
    public isEdtition = true;

    /**
     * @description If true, the member is the creator of the society
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 10/03/2025
     * @protected
     * @memberof MemberCardComponent
     */
    protected isCreator = false;

    /**
     * @description Determine if this member can be deleted
     * @author ANDRE Felix
     * @memberof MemberCardComponent
     */
    canMemberBeDelete = false;

    constructor(
        private readonly societyService: SocietyService,
        private readonly toastController: ToastController,
        private readonly modalController: ModalController,
    ) { }

    ngOnInit(): void {
        console.log('this.member', this.member);
        console.log(this.member.email + ' is' + this.member.isCreator ? ' creator' : '\'nt creator');
        this.isCreator = !!this.member.isCreator
        this.canMemberBeDelete = !this.isCreator && this.member.status !== SocietyStatusMember.manager;
    }

    async openDeleteModal(modal: IonModal): Promise<void> {
        await modal.present();
    }

    /**
     * @description Delete the member from the society
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof MemberCardComponent
     */
    protected async delete(modal?: IonModal): Promise<void> {
        if (!this.society || this.index < 0) {
            return;
        }

        this.society.members.splice(this.index, 1);
        this.removeSocietySharings();
        await this.societyService.update(this.society);

        const toast = await this.toastController.create({
            position: 'top',
            message: 'Le membre a bien été supprimé',
            duration: 2000,
            color: 'primary',
        });

        await toast.present();
        modal?.dismiss();
    }

    private removeSocietySharings() {
        if (!this.society) {
            return;
        }
        const sharingIndexToDelete = this.society.sharing.findIndex((share) => {
            return share.email === this.member?.email;
        });

        const memberIndexToDelete = this.society.members.findIndex((member) => {
            return member.email === this.member?.email;
        });

        this.society.sharing.splice(sharingIndexToDelete, 1);
        this.society.members.splice(memberIndexToDelete, 1);
    }

    /**
     * @description Open the modal to edit the member
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof MemberCardComponent
     */
    public async edit(): Promise<void> {
        const modal = await this.modalController.create({
            component: SocietyMemberFormComponent,
            canDismiss: true,
            initialBreakpoint: 1,
            backdropDismiss: true,
            breakpoints: [0, 1],
            componentProps: {
                member: this.member,
                society: this.society,
                sharingIndex: this.index,
                isCreator: this.member?.isCreator,
            },
        });
        await modal.present();
    }
}
