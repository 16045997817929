@if (user$ | async; as user) {
    <div class="referral-container w-100">
        <img
            class="referral-banner"
            src="/assets/referral-banner.jpg"
            alt="Banière de parrainage"
            @element
        />

        @if (
            ('accessManageReferral' | isAuthorised | async) === false &&
            (canAccess$ | async) === false
        ) {
            <ng-container [ngTemplateOutlet]="referralExplain"></ng-container>
        }

        <omedom-lock
            [isLocked]="
                ('accessManageReferral' | isAuthorised | async) === false &&
                (canAccess$ | async) === false
            "
        >
            <ng-container
                omedom-lock-content
                [ngTemplateOutlet]="referralContent"
            >
            </ng-container>
            <ng-container
                omedom-lock-fallback
                [ngTemplateOutlet]="subscriptionFallback"
            >
            </ng-container>
        </omedom-lock>

        <ng-template #subscriptionFallback>
            @if (referringUser$ | async; as referringUser) {
                <div class="referral-pending w-100">
                    <h2>10 € vous attendent déjà !</h2>

                    <span>
                        Vous avez été parrainé par
                        {{ referringUser.firstname }} {{ referringUser.name }},
                        et vous pouvez déjà cumuler 10 € de bon d’achat dans
                        votre cagnotte ! Pour cela, souscrivez un abonnement
                        premium annuel.
                    </span>

                    <button
                        class="omedom-button-primary w-100 margin-top-1"
                        @element
                        (click)="changeSubscription.emit()"
                    >
                        Je m'abonne annuellement
                        <i class="omedom-icon-crown"></i>
                    </button>
                </div>
            }
            <omedom-action
                class="w-100 margin-top-1 margin-bottom-1"
                text="Fonctionnalité disponible dans votre abonnement Premium, changez d’abonnement pour en profiter."
                actionIcon="omedom-icon-crown"
                (actionClicked)="changeSubscription.emit()"
            ></omedom-action>
            <button
                class="omedom-button-primary w-100 margin-top-1"
                @element
                (click)="changeSubscription.emit()"
            >
                Changer mon abonnement.
            </button>
        </ng-template>

        <ng-template #referralContent>
            <div class="referral-home w-100">
                <div @element class="referral-header">
                    <div class="invited-by">
                        <h2>
                            Offrez 10€, et recevez 10€ en retour<sup>**</sup>
                        </h2>

                        <p>
                            Invitez vos proches à s'inscrire avec votre code de
                            parrainage, et cagnottez 10€ dès la souscription de
                            leur abonnement annuel<sup>**</sup>.
                        </p>
                        <span class="referral-code">
                            <span class="referral-code-label"
                                >Votre code parrain :</span
                            >
                            @if (
                                'accessManageReferral' | isAuthorised | async
                            ) {
                                <strong class="referral-code-value">{{
                                    user.referralCode
                                }}</strong>
                                <span
                                    class="copy-icon"
                                    (click)="
                                        copyReferralCode(user.referralCode)
                                    "
                                >
                                    <i class="uil uil-copy-alt"></i>
                                </span>
                            } @else {
                                <button
                                    class="omedom-button-primary"
                                    (click)="changeSubscription.emit()"
                                >
                                    Je m'abonne
                                    <i class="omedom-icon-crown"></i>
                                </button>
                            }
                        </span>
                    </div>
                </div>

                <div @element class="referral-content w-100">
                    <div class="referral-section">
                        <div class="invite-section">
                            <span class="section-title">Invitez un proche</span>
                            <form
                                [formGroup]="referralInviteForm"
                                (submit)="sendInvitation()"
                                class="referral-invite-form"
                            >
                                <div class="referral-invite-form-item">
                                    <omedom-input
                                        label="Prénom"
                                        type="text"
                                        [boldLabel]="true"
                                        [required]="true"
                                        formControlName="firstname"
                                    ></omedom-input>
                                    <omedom-input
                                        label="Nom"
                                        type="text"
                                        [boldLabel]="true"
                                        [required]="true"
                                        formControlName="name"
                                    ></omedom-input>
                                </div>
                                <div class="referral-invite-form-item">
                                    <omedom-input
                                        label="Mail"
                                        type="email"
                                        [boldLabel]="true"
                                        [required]="true"
                                        [pattern]="emailPattern"
                                        formControlName="email"
                                    ></omedom-input>
                                </div>

                                <button
                                    class="omedom-button-primary"
                                    [disabled]="
                                        referralInviteForm.invalid ||
                                        (pending$ | async) ||
                                        ('accessManageReferral'
                                            | isAuthorised
                                            | async) === false
                                    "
                                    type="submit"
                                >
                                    Envoyer l'invitation
                                    @if (pending$ | async) {
                                        <ion-spinner></ion-spinner>
                                    }
                                    @if (
                                        ('accessManageReferral'
                                            | isAuthorised
                                            | async) === false
                                    ) {
                                        <i class="omedom-icon-crown"></i>
                                    }
                                </button>
                            </form>
                        </div>
                    </div>
                    <div class="referral-balance">
                        <div class="referral-balance-header">
                            <span class="section-title">Ma cagnotte</span>
                            <button
                                class="omedom-button-primary"
                                (click)="scrollToElement('referral-treasury')"
                            >
                                Visualiser
                                <i class="uil uil-eye"></i>
                            </button>
                        </div>
                        <omedom-widget-progress-bar
                            [progressBarData]="(progressBarData$ | async) || []"
                            class="clickable"
                            [layout]="progressBarCardLayout"
                        ></omedom-widget-progress-bar>
                    </div>
                    @if (
                        ('accessManageReferral' | isAuthorised | async) ||
                        (canAccess$ | async)
                    ) {
                        <ng-container
                            [ngTemplateOutlet]="referralExplain"
                        ></ng-container>
                    }
                    @if (referrals$ | async; as referrals) {
                        <div class="referrals-list" @element>
                            <span class="section-title">Parrainages</span>
                            <div class="referral-table-container">
                                <table class="referral-table">
                                    <thead>
                                        <tr>
                                            <th>E-mail</th>

                                            <th>Prénom</th>

                                            <th>Nom</th>
                                            <th
                                                class="referral-table-account-created"
                                            >
                                                Compte créé
                                            </th>
                                            <th
                                                class="referral-table-subscription"
                                            >
                                                Abonnement annuel
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @for (
                                            referral of referrals;
                                            track $index
                                        ) {
                                            <tr>
                                                <td>
                                                    {{ referral.email }}
                                                </td>

                                                <td>
                                                    {{ referral.firstname }}
                                                </td>
                                                <td>{{ referral.name }}</td>
                                                <td
                                                    class="referral-table-account-created"
                                                >
                                                    @if (
                                                        referral.userCreatedDate
                                                    ) {
                                                        {{
                                                            referral.userCreatedDate
                                                                | date
                                                        }}
                                                    } @else {
                                                        <i
                                                            [class]="
                                                                'uil uil-stopwatch'
                                                            "
                                                        ></i>
                                                    }
                                                </td>
                                                <td
                                                    class="referral-table-subscription"
                                                >
                                                    @if (
                                                        referral.hasSubscription
                                                    ) {
                                                        <i
                                                            [class]="
                                                                'uil uil-check-circle'
                                                            "
                                                        ></i>
                                                    } @else {
                                                        <i
                                                            [class]="
                                                                'uil uil-stopwatch'
                                                            "
                                                        ></i>
                                                    }
                                                </td>
                                            </tr>
                                        } @empty {
                                            <tr>
                                                <td colspan="5">
                                                    Aucun parrainage
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }

                    <div class="graph-section">
                        <omedom-circular-graph
                            id="referral-treasury"
                            label="Montant Cagnotte"
                            [data]="(treasuryChartData$ | async) || []"
                            [gap]="10"
                            [showLegend]="
                                !!(
                                    'accessManageReferral'
                                    | isAuthorised
                                    | async
                                ) || !!(canAccess$ | async)
                            "
                            [showTotal]="
                                !!(
                                    'accessManageReferral'
                                    | isAuthorised
                                    | async
                                ) || !!(canAccess$ | async)
                            "
                        ></omedom-circular-graph>

                        <div class="referral-table-container">
                            @if (vouchers$ | async; as vouchers) {
                                <table class="referral-table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Montant</th>
                                            <th>Statut</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @for (
                                            voucher of vouchers;
                                            track $index
                                        ) {
                                            <tr>
                                                <td>
                                                    {{
                                                        voucher.date
                                                            | date: 'dd/MM/yyyy'
                                                    }}
                                                </td>
                                                <td>
                                                     {{
                                                        voucher.amount
                                                            | currency: 'EUR'
                                                    }}
                                                </td>
                                                <td>
                                                    @switch (voucher.status) {
                                                        @case ('confirmed') {
                                                            Validé
                                                        }
                                                        @case ('withdrawn') {
                                                            Récupéré, en attente
                                                            de validation
                                                        }
                                                        @default {
                                                            {{ voucher.status }}
                                                        }
                                                    }
                                                </td>
                                                <td>
                                                    @if (
                                                        voucher.status ===
                                                            'confirmed' &&
                                                        voucher.link
                                                    ) {
                                                        <a
                                                            href="{{
                                                                voucher.link
                                                            }}"
                                                            target="_blank"
                                                            ><i
                                                                class="uil uil-download-alt"
                                                            ></i>
                                                        </a>
                                                    }
                                                </td>
                                            </tr>
                                        } @empty {
                                            <tr>
                                                <td colspan="3">
                                                    Aucun bon d'achat
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                        @if (
                            (canWithdraw$ | async) === false ||
                            (canWithdraw$ | async) === null
                        ) {
                            <span class="referral-warning">
                                Vous devez attendre que votre cagnotte atteigne
                                20€ pour pouvoir la récupérer.
                            </span>
                        }

                        <button
                            class="omedom-button-green"
                            (click)="withdrawReferralBalance()"
                            [disabled]="
                                (canWithdraw$ | async) === false ||
                                (canWithdraw$ | async) === null ||
                                (pendingWithdraw$ | async)
                            "
                        >
                            Je récupère ma cagnotte
                            @if (pendingWithdraw$ | async) {
                                <ion-spinner></ion-spinner>
                            }
                        </button>
                    </div>
                </div>
                <div class="referral-footer">
                    <span> Conditions :</span
                    ><span> <sup>*</sup>Champ obligatoire. </span
                    ><span>
                        <sup>**</sup>Dès l’abonnement PREMIUM annuel du filleul,
                        le parrain crédite un bon d’achat/carte cadeau d’un
                        montant de 10 euros sur sa cagnotte OMEDOM, accessible
                        depuis la rubrique "Parrainage". La cagnotte OMEDOM est
                        convertible en bons d’achat/cartes cadeaux dès 20 euros
                        cumulés. Les bons d’achat/cartes cadeaux pourront être
                        commandés directement via le compte OMEDOM de
                        l’Utilisateur dans la rubrique “Parrainage”, en cliquant
                        sur “Je récupère ma cagnotte”. Les conditions
                        d’utilisation des bons d’achat/cartes cadeaux seront
                        consultables sur le site de notre partenaire (cartes
                        cadeaux multi-enseignes).
                    </span>
                </div>
            </div>
        </ng-template>
    </div>
}

<ng-template #referralExplain>
    <div class="referral-explain">
        <div class="referral-explain-header">
            <h2>Comment ça marche ?</h2>
        </div>
        <div class="referral-explain-content">
            <div class="referral-explain-item">
                <div class="referral-explain-item-icon">
                    <i class="uil uil-envelope-share"></i>
                </div>
                <div class="referral-explain-item-content">
                    <h3>Invitez vos proches</h3>
                    <span>
                        Invitez vos proches à s'inscrire sur OMEDOM en leur
                        partageant votre code parrain.
                    </span>
                </div>
            </div>

            <div class="referral-explain-item">
                <div class="referral-explain-item-icon">
                    <i class="uil uil-user-check"></i>
                </div>
                <div class="referral-explain-item-content">
                    <h3>Votre proche s'inscrit</h3>
                    <span>
                        Votre proche s'inscrit sur OMEDOM avec votre code
                        parrain et reçoit 15 jours d'essai gratuit.
                    </span>
                </div>
            </div>

            <div class="referral-explain-item">
                <div class="referral-explain-item-icon">
                    <i class="uil uil-gift"></i>
                </div>
                <div class="referral-explain-item-content">
                    <h3>Recevez votre bon d'achat</h3>
                    <span>
                        Chacun cagnotte 10 € lorsque votre filleul souscrit à un
                        abonnement annuel.
                    </span>
                    <em
                        >(Débloquez vos bons d’achat dès 20€ cumulés dans votre
                        cagnotte)</em
                    >
                </div>
            </div>
        </div>
    </div>
</ng-template>
