@if ((isMaintenance$ | async) === false) {
    <div
        class="take-a-meet"
        [ngClass]="{
            'take-a-meet-open': isOpen,
            'take-a-meet-closed': !isOpen,
        }"
        (click)="!isOpen && toggle()"
    >
        @if (isOpen) {
            <div>
                <div class="take-a-meet-header">
                    <h2>15' pour faire le point</h2>
                    <i
                        class="uil uil-times"
                        (click)="$event.stopPropagation(); toggle()"
                    ></i>
                </div>
                <div class="padding-2 take-a-meet-content">
                    <div class="take-a-meet-content-text">
                        <span>
                            Amélia vous accompagne dans l'utilisation de votre
                            solution OMEDOM. Vous avez besoin d'aide dans le
                            remplissage de l'outil ou pour optimiser votre
                            utilisation d'OMEDOM ? Vous avez besoin de conseils
                            d'experts de confiance ?
                        </span>

                        <span>
                            Amélia prend 15' avec vous pour échanger sur vos
                            besoins et vous proposer des solutions
                            d'accompagnement !
                        </span>
                    </div>
                    <button
                        class="omedom-button-primary"
                        (click)="goToCalendar()"
                    >
                        Prendre un rendez-vous
                    </button>
                </div>
            </div>
        } @else {
            <div class="take-a-meet-icon">
                <span>{{ text }}</span>
                <i class="uil uil-calendar-alt"></i>
            </div>
        }
    </div>
}
