import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import {
    ConfidenceFr,
    DPEScore,
    fakeValorisation,
    PropertyEntity,
    Renovation,
    ValuationInfo,
} from '@omedom/data';
import { PricehubbleService } from '@omedom/services';
import {
    OmedomPrice,
    OmedomPricehubble,
    OmedomPricehubbleMissingData,
    OmedomProperty,
} from '@omedom/utils';

import { showLoading } from '../../functions/loader';
import { PricehubbleMissingDataModalComponent } from '../property/property-form/pricehubble-missing-data-modal/pricehubble-missing-data-modal.component';

@Component({
    selector: 'omedom-valuation',
    templateUrl: './valuation.component.html',
    styleUrls: ['./valuation.component.scss'],
})
export class ValuationComponent implements OnChanges {
    @Input({ required: true }) property!: PropertyEntity;

    @Input({ required: true }) canAccessValuation = false;

    @Input() canStartValuation = true;

    /**
     * @description Is display fake data, used to show the feature to non essential users
     * @author ANDRE Felix
     * @memberof ValuationComponent
     */
    @Input() isFakeData = false;

    /**
     * @description If missingDataInView is true, the user will be able to see the missing data in the view, not in the modal
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2024
     * @type {boolean}
     * @memberof ValuationComponent
     */
    @Input()
    public missingDataInView: boolean = false;

    /**
     * @description Event when the user click on the button to go to the missing data view
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2024
     * @memberof ValuationComponent
     */
    @Output()
    public goToMissingDataView = new EventEmitter<void>();

    /**
     * @description Used to display blur fake data for essential users
     * @author ANDRE Felix
     * @memberof ValuationComponent
     */
    fakeData = fakeValorisation;

    canUsePricehubble = false;
    dpeRating?: number;
    currentEnergyLabel?: DPEScore;
    energyLabelEstimated?: DPEScore;
    propertyValuation?: ValuationInfo;
    propertyRenovations: Renovation[] = [];
    valuationConfidence: undefined | ConfidenceFr;
    valuationConfidenceColor? = '';
    hasValorisation = false;
    lastValuationUpdate?: string;
    meterSquarePriceAvg?: number;
    meterSquarePriceLower?: number;
    meterSquarePriceUpper?: number;
    lastValorisationUpdate?: string;
    isAssimilateAppartement = false;
    isAssimilateHouse = false;

    constructor(
        private pricehubbleService: PricehubbleService,
        private modalController: ModalController,
        private loadingController: LoadingController,
    ) { }

    ionViewWillEnter() {
        this.refreshPricehubbleData();
    }

    ngOnChanges() {
        this.refreshPricehubbleData();
    }

    private refreshPricehubbleData() {
        if (this.isFakeData) {
            this.setFakeData();
            return;
        }
        this.canUsePricehubble = OmedomPricehubble.canUsePricehubble(this.property);
        this.isAssimilateAppartement = OmedomPricehubble.isPropertyAssimilateAppartement(
            this.property?.type,
        );
        this.isAssimilateHouse = OmedomPricehubble.isPropertyAssimilateHouse(this.property?.type);
        this.dpeRating =
            this.property?.dpeDetails?.estimationResult?.energyConsumption?.[0]?.measure?.value;
        this.currentEnergyLabel = this.property?.renovationCalculation?.currentEnergyLabel;
        this.energyLabelEstimated = this.property?.dpeDetails?.estimationResult?.energyLabel?.value;
        this.propertyValuation = this.property?.valuation?.valuationSale;
        this.propertyRenovations = this.property?.renovationCalculation?.renovations || [];
        this.valuationConfidence = OmedomProperty.adaptConfidence(
            this.propertyValuation?.valuationConfidence,
        );
        this.valuationConfidenceColor = OmedomProperty.adaptConfidenceTextColor(
            this.propertyValuation?.valuationConfidence,
        );
        this.hasValorisation = this.property?.valuation ? true : false;
        if (this.hasValorisation && this.propertyValuation) {
            const lastValUpdate = this.propertyValuation.valuationDate;
            const parts = lastValUpdate.split('-');
            this.lastValuationUpdate = parts[2] + '-' + parts[1] + '-' + parts[0];
            this.setMeterSquarePrice();
            this.lastValorisationUpdate = this.propertyValuation.valuationDate;
        }
    }

    async refreshPricehubble() {
        this.canUsePricehubble = OmedomPricehubble.canUsePricehubble(this.property);
        if (!this.canUsePricehubble) {
            return;
        }
        const hasValidateRefresh = await this.checkMissingData(this.property);

        if (hasValidateRefresh) {
            this.doValorisation();
        }
    }

    private async doValorisation() {
        const loadingModal = await showLoading(
            "Valorisation en cours d'estimation...",
            this.loadingController,
        );
        try {
            await this.pricehubbleService.refreshAllData(this.property);
        } catch (err) {
            console.error('error', err);
        }
        loadingModal.dismiss();
    }

    private setMeterSquarePrice() {
        if (!this.propertyValuation) {
            return;
        }
        const propertySurface = this.property.livingSpace;

        this.meterSquarePriceAvg = OmedomPrice.calcMeterSquarePrice(
            this.propertyValuation.value,
            propertySurface,
        );
        this.meterSquarePriceLower = OmedomPrice.calcMeterSquarePrice(
            this.propertyValuation.valueRange.lower,
            propertySurface,
        );
        this.meterSquarePriceUpper = OmedomPrice.calcMeterSquarePrice(
            this.propertyValuation.valueRange.upper,
            propertySurface,
        );
    }

    private async checkMissingData(property: PropertyEntity) {
        // If user don't validate the modal to refresh pricehubble
        let hasValidateRefresh = false;

        if (!this.property?.uid) {
            return hasValidateRefresh;
        }

        if (!(this.isAssimilateAppartement || this.isAssimilateHouse)) {
            return hasValidateRefresh;
        }

        const missingData = this.getAllMissingData(property);

        hasValidateRefresh = await this.displayModalMissingData(
            missingData.missingRequiredData,
            missingData.missingOptionalData,
        );

        return hasValidateRefresh;
    }

    private getAllMissingData(property: PropertyEntity) {
        if (this.isAssimilateAppartement) {
            return this.getMissingDataApartement(property);
        } else {
            return this.getMissingDataHouseGeneral(property);
        }
    }

    private getMissingDataApartement(property: PropertyEntity) {
        const missingRequiredData =
            OmedomPricehubbleMissingData.missingRequiredFieldAppartment(property);

        const missingOptionalData =
            OmedomPricehubbleMissingData.missingDataApartementOptional(property);

        return {
            missingRequiredData: missingRequiredData,
            missingOptionalData: missingOptionalData,
        };
    }

    private getMissingDataHouseGeneral(property: PropertyEntity) {
        const missingRequiredData =
            OmedomPricehubbleMissingData.missingRequiredFieldHouse(property);

        const missingOptionalData =
            OmedomPricehubbleMissingData.missingOptionalFieldHouse(property);
        return {
            missingRequiredData: missingRequiredData,
            missingOptionalData: missingOptionalData,
        };
    }

    async displayModalMissingData(
        missingRequiredData: object,
        missingOptionalData: object = {},
    ): Promise<boolean> {
        const allMissingData = {
            ...missingRequiredData,
            ...missingOptionalData,
        };

        if (!OmedomPricehubbleMissingData.hasMissingProperty(allMissingData)) {
            return true;
        }

        // Check if missing data is in the view
        if (this.missingDataInView) {
            this.goToMissingDataView.emit();
            return false;
        }

        const modal = await this.modalController.create({
            component: PricehubbleMissingDataModalComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            componentProps: {
                property: this.property,
                missingRequiredData: missingRequiredData,
                missingOptionalData: missingOptionalData,
            },
        });
        await modal.present();

        return new Promise((resolve) => {
            modal.onDidDismiss().then((res) => {
                const confirmation = res.data;
                resolve(!!confirmation);
            });
        });
    }

    private setFakeData() {
        this.canUsePricehubble = true;
        this.canUsePricehubble = OmedomPricehubble.canUsePricehubble(this.property);
        this.propertyValuation = this.fakeData.propertyValuation.valuationSale;
        this.isAssimilateAppartement = false;
        this.isAssimilateHouse = true;
        this.dpeRating = this.fakeData?.dpeRating;
        this.currentEnergyLabel = this.fakeData.currentEnergyLabel;
        this.energyLabelEstimated = this.fakeData.energyLabelEstimated;
        this.propertyValuation = this.fakeData.propertyValuation?.valuationSale;
        this.propertyRenovations = this.fakeData.propertyRenovations.renovations as Renovation[];
        this.valuationConfidence = this.fakeData.valuationConfidence;
        this.valuationConfidenceColor = this.fakeData.valuationConfidenceColor;

        const propertySurface = this.fakeData.livingSpace;
        this.meterSquarePriceAvg = OmedomPrice.calcMeterSquarePrice(
            this.propertyValuation.value,
            propertySurface,
        );
        this.meterSquarePriceLower = OmedomPrice.calcMeterSquarePrice(
            this.propertyValuation.valueRange.lower,
            propertySurface,
        );
    }
}
