import { CommonModule, CurrencyPipe, DecimalPipe, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgChartsModule } from 'ng2-charts';

import {
    AcceptEditShareConditionComponent,
    AcceptShareBankingBalanceToProComponent,
    AcceptShareConditionComponent,
    ActionComponent,
    AddItemComponent,
    AddProCardComponent,
    AddReviewComponent,
    AlertComponent,
    AssetFilterBuildingComponent,
    AssetFilterComponent,
    AssetFilterGenericComponent,
    AssetFilterSocietyComponent,
    AutocompleteComponent,
    AvatarComponent,
    ButtonChangeSubscriptionComponent,
    ButtonToggleComponent,
    CanvasHeaderComponent,
    CgvComponent,
    ChargeDeleteComponent,
    ChargeEditTypeComponent,
    ChartLegendComponent,
    ChatCardComponent,
    ChatClientCardComponent,
    ChatMessageCardComponent,
    CheckboxComponent,
    CircularGraphComponent,
    ConfidentialityComponent,
    CreateFirstItemButtonComponent,
    DataTableComponent,
    DateComponent,
    DatePickerComponent,
    DetailedOptionComponent,
    DirectoryCardComponent,
    DirectorySearchComponent,
    DismissModalComponent,
    DocumentSortComponent,
    DropdownComponent,
    EmissionConsumptionInterfaceComponent,
    EssentialVideoComponent,
    FamilyShareCardComponent,
    FormAssociateToComponent,
    FormSectionTitleComponent,
    HeaderComponent,
    IncomeDeleteComponent,
    IncomeEditTypeComponent,
    InfoComponent,
    InputComponent,
    LockComponent,
    MemberCardComponent,
    MentionsLegalComponent,
    ModalConfirmComponent,
    ModalHeaderComponent,
    ModalPdfComponent,
    ModalSuggestSubscriptionComponent,
    ModalTabComponent,
    MovementCardComponent,
    NotificationCardComponent,
    NotificationPopupComponent,
    PaymentCardComponent,
    PdfPreviewComponent,
    PopoverComponent,
    ProAlertCardComponent,
    PropertyCardComponent,
    PropertyFilterItemComponent,
    PropertyInfoComponent,
    PropertyInfoDetailsComponent,
    PropertyInfoPossessionComponent,
    PropertyInfoSettingsComponent,
    PropertySocietySortComponent,
    ProRecordDetailComponent,
    RadioComponent,
    RangeComponent,
    ReccomendProComponent,
    ReviewDisplayComponent,
    SectionHeaderComponent,
    SecurityPasswordComponent,
    SelectBottomSheetComponent,
    SelectComponent,
    SelectGridComponent,
    SelectOptionComponent,
    ShareConditionsComponent,
    ShareFormModalComponent,
    ShareToProCardComponent,
    ShowHidePasswordComponent,
    SidenavItemComponent,
    SmartPopoverComponent,
    SocietyCardComponent,
    SocietyInfoComponent,
    SocietyInfoDetailsComponent,
    SocietyInfoMembersComponent,
    StackedBarGraphComponent,
    StarRateComponent,
    StepperComponent,
    StickerClicComponent,
    StoryFooterComponent,
    SubscriptionCountdownComponent,
    SubscriptionCurrentComponent,
    SubscriptionSlideComponent,
    SubTabComponent,
    SuggestSmartComponent,
    TabComponent,
    TimelineComponent,
    ToggleComponent,
    ToggleWithIconComponent,
    TreasuryCardComponent,
    TwoFactorValidationComponent,
    ValidationModalComponent,
    WarningPopoverComponent,
    WidgetBankingAmountComponent,
    WidgetBankingOverviewComponent,
    WidgetProgressBarComponent,
    WidgetRentabilityComponent,
} from './components';
import { DPEInterfaceComponent } from './components/dpe-interface/dpe-interface.component';
import { DpeScoreComponent } from './components/dpe-score/dpe-score.component';
import {
    AssetSharingCardContainer,
    AssetSliderComponent,
    BankAccountSelectComponent,
    BankCardComponent,
    BankCardWithMouvementComponent,
    BankDeleteConfirmContainer,
    BankEmptyComponent,
    BankEmptyTutoVideoComponent,
    BankHistoryContainer,
    BankInfoComponent,
    BankSyncSuccessComponent,
    BankTransactionCategoryStepContainer,
    BankTransactionComponent,
    BankTransactionConfirmationStepContainer,
    BankTransactionDetailsStepContainer,
    BankTransactionDissociationConfirmContainer,
    BankTransactionListContainer,
    BankTransactionPatrimonyStepContainer,
    BankTransactionRecurrenceStepContainer,
    ChargeComponent,
    ChargeListComponent,
    ChatClientListComponent,
    ChatListComponent,
    ChatMessageListComponent,
    ClientFormComponent,
    ClientListComponent,
    DirectoryProListComponent,
    DirectoryTrustedProListComponent,
    DisplayRangePriceComponent,
    DocumentCardComponent,
    DocumentDeleteComponent,
    DocumentFilterComponent,
    DocumentFormComponent,
    DocumentListComponent,
    DocumentListFakeDataComponent,
    DocumentListProComponent,
    DocumentSearchComponent,
    DocumentSinglePropertyComponent,
    DocumentSingleSocietyComponent,
    DocumentViewerComponent,
    DocumentViewerInvoiceDetailsComponent,
    HelpContactComponent,
    HelpFaqComponent,
    HelpFeedbackComponent,
    HistoryListComponent,
    IncomeComponent,
    IncomeListComponent,
    LeaseComponent,
    LeaseFormComponent,
    LevelAssignmentsComponent,
    LevelHomeComponent,
    LevelMapComponent,
    LevelProgressComponent,
    LevelSuccessContainerComponent,
    LevelSuccessPopupComponent,
    LevelUpComponent,
    LicenceCancelComponent,
    LicenceExchangeComponent,
    LicenceGiftComponent,
    LicenceOrderComponent,
    LicenceResendComponent,
    LicenceSendComponent,
    LicenceStatsComponent,
    LiquidityComponent,
    LoanCardContainer,
    LoanConfirmationStepContainer,
    LoanDeleteConfirmContainer,
    LoanDetailsContainer,
    LoanDueDateStepContainer,
    LoanFormContainer,
    LoanInfosStepContainer,
    LoanProfilComponent,
    LoanSynthesisContainer,
    MenuAccountDeleteComponent,
    MenuAccountFormComponent,
    MenuSettingsComponent,
    NotificationContainerComponent,
    NotificationListComponent,
    PricehubbleMissingDataModalComponent,
    ProAlertListComponent,
    ProgressBarComponent,
    ProInfoComponent,
    PropertyCardSelectableComponent,
    PropertyContestFormContainer,
    PropertyFormComponent,
    PropertyProfilComponent,
    PropertyProfilDocumentsComponent,
    PropertyProfilInfoComponent,
    PropertyProfilTreasuryComponent,
    PropertySocietyFilterComponent,
    PropertyTenantComponent,
    QuestionsComponent,
    ReceiptRelaunchMissingFieldsComponent,
    ReceiptRelaunchMissingTenantEmailComponent,
    RegistrationFirstStepComponent,
    RegistrationSecondStepComponent,
    RegistrationThirdStepComponent,
    RentCardComponent,
    RentComponent,
    ReviewListComponent,
    SavingCardContainer,
    SavingConfirmationStepContainer,
    SavingDeleteConfirmContainer,
    SavingDetailsContainer,
    SavingExitConstraintsStepContainer,
    SavingFormContainer,
    SavingInfosStepContainer,
    SavingMovementsStepContainer,
    SavingProfilComponent,
    SavingSynthesisContainer,
    SelectPropertyCardComponent,
    ShareCardComponent,
    ShareFormComponent,
    ShareListComponent,
    ShareMultiFormContainer,
    ShareToProComponent,
    SidenavComponent,
    SocietyCardSelectableComponent,
    SocietyFormComponent,
    SocietyMemberFormComponent,
    SocietyProfilComponent,
    SocietyProfilDocumentsComponent,
    SocietyProfilInfoComponent,
    SocietyProfilTreasuryComponent,
    StorageProgressComponent,
    StoryCardComponent,
    StoryDetailComponent,
    StoryDisplayComponent,
    SubscriptionCardTrialComponent,
    SubscriptionConfirmCancellationComponent,
    SubscriptionErrorComponent,
    SubscriptionMenuComponent,
    SubscriptionRedirectAppleComponent,
    SubscriptionSuccessComponent,
    TenantComponent,
    TenantFormModalComponent,
    ToolbarComponent,
    TreasuryCardComponent2,
    TreasuryEditComponent,
    TreasuryFormComponent,
    UserInfoComponent,
    UserRegisterComponent,
    UserRegistrationProcessPage,
    UserSigninComponent,
    ValuationComponent,
    ValuationRentEstimationComponent,
} from './containers';
import { OmedomDuplicateSharingEmailDirective, OmedomNumberDirective } from './directives';
import {
    OmedomArrayFilterPipe,
    OmedomBytePipe,
    OmedomCapitalizeFirstLetterPipe,
    OmedomIsAuthorisedPipe,
    OmedomNumberPipe,
    OmedomPayplugNumberToPricePipe,
    OmedomSortPipe,
} from './pipes';

@NgModule({
    declarations: [
        AcceptEditShareConditionComponent,
        AcceptShareConditionComponent,
        AcceptShareBankingBalanceToProComponent,
        ActionComponent,
        AddItemComponent,
        AddProCardComponent,
        AddReviewComponent,
        AddReviewComponent,
        AlertComponent,
        AssetFilterBuildingComponent,
        AssetFilterComponent,
        AssetFilterGenericComponent,
        AssetFilterSocietyComponent,
        AssetSharingCardContainer,
        AssetSliderComponent,
        AutocompleteComponent,
        AvatarComponent,
        BankAccountSelectComponent,
        BankCardComponent,
        BankCardWithMouvementComponent,
        BankDeleteConfirmContainer,
        BankEmptyComponent,
        BankEmptyTutoVideoComponent,
        BankHistoryContainer,
        BankInfoComponent,
        BankSyncSuccessComponent,
        BankTransactionComponent,
        BankTransactionCategoryStepContainer,
        BankTransactionConfirmationStepContainer,
        BankTransactionDetailsStepContainer,
        BankTransactionDissociationConfirmContainer,
        BankTransactionListContainer,
        BankTransactionPatrimonyStepContainer,
        BankTransactionRecurrenceStepContainer,
        ButtonChangeSubscriptionComponent,
        ButtonToggleComponent,
        CanvasHeaderComponent,
        CgvComponent,
        ChargeComponent,
        ChargeDeleteComponent,
        ChargeEditTypeComponent,
        ChargeListComponent,
        ChartLegendComponent,
        CheckboxComponent,
        CircularGraphComponent,
        ClientFormComponent,
        ClientListComponent,
        ConfidentialityComponent,
        CreateFirstItemButtonComponent,
        OmedomIsAuthorisedPipe,
        DataTableComponent,
        DateComponent,
        DatePickerComponent,
        DetailedOptionComponent,
        DirectoryCardComponent,
        DirectoryTrustedProListComponent,
        DirectorySearchComponent,
        DirectoryProListComponent,
        DismissModalComponent,
        DisplayRangePriceComponent,
        DocumentCardComponent,
        DocumentDeleteComponent,
        DocumentFilterComponent,
        DocumentFormComponent,
        DocumentListComponent,
        DocumentListFakeDataComponent,
        DocumentListProComponent,
        DocumentSearchComponent,
        DocumentSinglePropertyComponent,
        DocumentSingleSocietyComponent,
        DocumentSortComponent,
        DocumentViewerComponent,
        DocumentViewerInvoiceDetailsComponent,
        DPEInterfaceComponent,
        DpeScoreComponent,
        DropdownComponent,
        EssentialVideoComponent,
        EmissionConsumptionInterfaceComponent,
        FamilyShareCardComponent,
        FormAssociateToComponent,
        FormSectionTitleComponent,
        HeaderComponent,
        HelpContactComponent,
        HelpFaqComponent,
        HelpFeedbackComponent,
        HistoryListComponent,
        IncomeComponent,
        IncomeDeleteComponent,
        IncomeEditTypeComponent,
        IncomeListComponent,
        InfoComponent,
        InputComponent,
        LeaseComponent,
        LeaseFormComponent,
        LevelAssignmentsComponent,
        LevelHomeComponent,
        LevelMapComponent,
        LevelProgressComponent,
        LevelSuccessContainerComponent,
        LevelSuccessPopupComponent,
        LevelUpComponent,
        LicenceCancelComponent,
        LicenceExchangeComponent,
        LicenceGiftComponent,
        LicenceOrderComponent,
        LicenceSendComponent,
        LicenceStatsComponent,
        LicenceResendComponent,
        LiquidityComponent,
        LockComponent,
        MentionsLegalComponent,
        MemberCardComponent,
        MenuAccountDeleteComponent,
        MenuAccountFormComponent,
        MenuSettingsComponent,
        ModalConfirmComponent,
        ModalHeaderComponent,
        ModalPdfComponent,
        ModalSuggestSubscriptionComponent,
        ModalTabComponent,
        MovementCardComponent,
        NotificationCardComponent,
        NotificationContainerComponent,
        NotificationListComponent,
        NotificationPopupComponent,
        OmedomBytePipe,
        OmedomCapitalizeFirstLetterPipe,
        OmedomDuplicateSharingEmailDirective,
        OmedomNumberDirective,
        OmedomNumberPipe,
        OmedomSortPipe,
        OmedomPayplugNumberToPricePipe,
        OmedomArrayFilterPipe,
        PaymentCardComponent,
        PdfPreviewComponent,
        PopoverComponent,
        PricehubbleMissingDataModalComponent,
        ProAlertListComponent,
        ProRecordDetailComponent,
        ProInfoComponent,
        ProAlertCardComponent,
        PropertyCardComponent,
        PropertyCardSelectableComponent,
        PropertyContestFormContainer,
        PropertyFilterItemComponent,
        PropertyFormComponent,
        PropertyInfoComponent,
        PropertyInfoDetailsComponent,
        PropertyInfoPossessionComponent,
        PropertyInfoSettingsComponent,
        PropertyProfilComponent,
        PropertyProfilDocumentsComponent,
        PropertyProfilInfoComponent,
        PropertyProfilTreasuryComponent,
        PropertySocietyFilterComponent,
        PropertySocietySortComponent,
        PropertyTenantComponent,
        RadioComponent,
        RangeComponent,
        ReceiptRelaunchMissingFieldsComponent,
        ReceiptRelaunchMissingTenantEmailComponent,
        ReccomendProComponent,
        RentComponent,
        RentCardComponent,
        ReviewDisplayComponent,
        SectionHeaderComponent,
        SecurityPasswordComponent,
        SelectBottomSheetComponent,
        SelectComponent,
        SelectGridComponent,
        SelectOptionComponent,
        ShareCardComponent,
        ShareFormComponent,
        ShareMultiFormContainer,
        ShareListComponent,
        ShareToProComponent,
        ShareToProCardComponent,
        ShowHidePasswordComponent,
        SidenavComponent,
        SidenavItemComponent,
        SmartPopoverComponent,
        SocietyCardComponent,
        SocietyCardSelectableComponent,
        SocietyInfoComponent,
        SocietyInfoDetailsComponent,
        SocietyInfoMembersComponent,
        SocietyFormComponent,
        SocietyMemberFormComponent,
        SocietyProfilComponent,
        SocietyProfilDocumentsComponent,
        SocietyProfilInfoComponent,
        SocietyProfilTreasuryComponent,
        StarRateComponent,
        StackedBarGraphComponent,
        StepperComponent,
        StickerClicComponent,
        StorageProgressComponent,
        StoryCardComponent,
        StoryDetailComponent,
        StoryDisplayComponent,
        StoryFooterComponent,
        SubTabComponent,
        SubTabComponent,
        SubscriptionCardTrialComponent,
        SubscriptionConfirmCancellationComponent,
        SubscriptionCountdownComponent,
        SubscriptionCurrentComponent,
        SubscriptionErrorComponent,
        SubscriptionRedirectAppleComponent,
        SubscriptionSlideComponent,
        SubscriptionSuccessComponent,
        SuggestSmartComponent,
        TabComponent,
        TenantComponent,
        TenantFormModalComponent,
        TimelineComponent,
        ToggleComponent,
        ToggleWithIconComponent,
        ToolbarComponent,
        TreasuryCardComponent,
        TreasuryCardComponent2,
        TreasuryEditComponent,
        TreasuryFormComponent,
        TwoFactorValidationComponent,
        UserInfoComponent,
        UserRegisterComponent,
        UserSigninComponent,
        ValidationModalComponent,
        ValuationComponent,
        ValuationRentEstimationComponent,
        WarningPopoverComponent,
        WidgetBankingAmountComponent,
        WidgetBankingOverviewComponent,
        WidgetProgressBarComponent,
        WidgetRentabilityComponent,
        ReviewListComponent,
        SubscriptionCurrentComponent,
        SubscriptionMenuComponent,
        SubscriptionSlideComponent,
        ShareFormModalComponent,
        DPEInterfaceComponent,
        DpeScoreComponent,
        UserRegistrationProcessPage,
        RegistrationFirstStepComponent,
        RegistrationSecondStepComponent,
        RegistrationThirdStepComponent,
        ProgressBarComponent,
        QuestionsComponent,
        SelectPropertyCardComponent,
        LoanCardContainer,
        LoanFormContainer,
        LoanProfilComponent,
        LoanSynthesisContainer,
        LoanDetailsContainer,
        LoanInfosStepContainer,
        LoanDueDateStepContainer,
        LoanConfirmationStepContainer,
        SubscriptionCurrentComponent,
        SubscriptionSlideComponent,
        ShareFormModalComponent,
        LoanDeleteConfirmContainer,
        SavingCardContainer,
        SavingSynthesisContainer,
        SavingDetailsContainer,
        SavingConfirmationStepContainer,
        SavingInfosStepContainer,
        SavingProfilComponent,
        SavingMovementsStepContainer,
        SavingFormContainer,
        SavingExitConstraintsStepContainer,
        SavingDeleteConfirmContainer,
        ShareConditionsComponent,
        ChatMessageCardComponent,
        ChatClientListComponent,
        ChatMessageListComponent,
        ChatCardComponent,
        ChatClientCardComponent,
        ChatListComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        NgChartsModule,
        ReactiveFormsModule,
        RouterModule,
        NgOptimizedImage,
    ],
    exports: [
        AcceptEditShareConditionComponent,
        AcceptShareConditionComponent,
        AcceptShareBankingBalanceToProComponent,
        ActionComponent,
        AddItemComponent,
        AddProCardComponent,
        AssetFilterBuildingComponent,
        AssetFilterComponent,
        AssetFilterGenericComponent,
        AssetFilterSocietyComponent,
        AssetSharingCardContainer,
        AssetSliderComponent,
        AutocompleteComponent,
        AvatarComponent,
        BankAccountSelectComponent,
        BankCardComponent,
        BankCardWithMouvementComponent,
        BankDeleteConfirmContainer,
        BankEmptyComponent,
        BankEmptyTutoVideoComponent,
        BankHistoryContainer,
        BankInfoComponent,
        BankSyncSuccessComponent,
        BankTransactionCategoryStepContainer,
        BankTransactionConfirmationStepContainer,
        BankTransactionDetailsStepContainer,
        BankTransactionDissociationConfirmContainer,
        BankTransactionListContainer,
        BankTransactionPatrimonyStepContainer,
        BankTransactionRecurrenceStepContainer,
        ButtonChangeSubscriptionComponent,
        CanvasHeaderComponent,
        CgvComponent,
        ChargeComponent,
        ChargeDeleteComponent,
        ChargeEditTypeComponent,
        ChargeListComponent,
        ChartLegendComponent,
        CheckboxComponent,
        CircularGraphComponent,
        ClientFormComponent,
        ClientListComponent,
        ConfidentialityComponent,
        CreateFirstItemButtonComponent,
        DataTableComponent,
        DateComponent,
        DatePickerComponent,
        DirectoryProListComponent,
        DirectoryTrustedProListComponent,
        DirectorySearchComponent,
        DismissModalComponent,
        DisplayRangePriceComponent,
        DocumentCardComponent,
        DocumentDeleteComponent,
        DocumentFilterComponent,
        DocumentFormComponent,
        DocumentListComponent,
        DocumentListFakeDataComponent,
        DocumentListProComponent,
        DocumentSearchComponent,
        DocumentSinglePropertyComponent,
        DocumentSingleSocietyComponent,
        DocumentSortComponent,
        DocumentViewerComponent,
        DocumentViewerInvoiceDetailsComponent,
        DPEInterfaceComponent,
        DpeScoreComponent,
        EssentialVideoComponent,
        FamilyShareCardComponent,
        FormAssociateToComponent,
        HeaderComponent,
        HelpContactComponent,
        HelpFaqComponent,
        HelpFeedbackComponent,
        HistoryListComponent,
        IncomeComponent,
        IncomeDeleteComponent,
        IncomeEditTypeComponent,
        IncomeListComponent,
        InfoComponent,
        InputComponent,
        LeaseComponent,
        LeaseFormComponent,
        LevelAssignmentsComponent,
        LevelHomeComponent,
        LevelMapComponent,
        LevelProgressComponent,
        LevelSuccessContainerComponent,
        LevelSuccessPopupComponent,
        LevelUpComponent,
        LicenceGiftComponent,
        LicenceOrderComponent,
        LicenceSendComponent,
        LicenceStatsComponent,
        LicenceResendComponent,
        LiquidityComponent,
        LockComponent,
        MentionsLegalComponent,
        MemberCardComponent,
        MenuAccountDeleteComponent,
        MenuAccountFormComponent,
        MenuSettingsComponent,
        ModalHeaderComponent,
        ModalPdfComponent,
        ModalSuggestSubscriptionComponent,
        ModalTabComponent,
        MovementCardComponent,
        NotificationCardComponent,
        NotificationContainerComponent,
        NotificationListComponent,
        NotificationPopupComponent,
        OmedomBytePipe,
        OmedomCapitalizeFirstLetterPipe,
        OmedomIsAuthorisedPipe,
        OmedomDuplicateSharingEmailDirective,
        OmedomNumberDirective,
        OmedomNumberPipe,
        OmedomSortPipe,
        OmedomPayplugNumberToPricePipe,
        OmedomArrayFilterPipe,
        PaymentCardComponent,
        PdfPreviewComponent,
        PopoverComponent,
        PricehubbleMissingDataModalComponent,
        ProAlertListComponent,
        ProRecordDetailComponent,
        ProInfoComponent,
        ProAlertCardComponent,
        PropertyCardComponent,
        PropertyCardSelectableComponent,
        PropertyContestFormContainer,
        PropertyInfoComponent,
        PropertyInfoDetailsComponent,
        PropertyInfoPossessionComponent,
        PropertyInfoSettingsComponent,
        PropertyFormComponent,
        PropertyProfilComponent,
        PropertySocietyFilterComponent,
        PropertySocietySortComponent,
        PropertyTenantComponent,
        RadioComponent,
        RangeComponent,
        ReceiptRelaunchMissingFieldsComponent,
        ReceiptRelaunchMissingTenantEmailComponent,
        ReccomendProComponent,
        RentComponent,
        RentCardComponent,
        ReviewListComponent,
        ReviewDisplayComponent,
        SectionHeaderComponent,
        SecurityPasswordComponent,
        SelectComponent,
        SelectGridComponent,
        ShareCardComponent,
        ShareFormComponent,
        ShareMultiFormContainer,
        ShareListComponent,
        ShareToProComponent,
        ShareToProCardComponent,
        SidenavComponent,
        SidenavItemComponent,
        SocietyCardComponent,
        SocietyCardSelectableComponent,
        SocietyInfoComponent,
        SocietyInfoDetailsComponent,
        SocietyInfoMembersComponent,
        SocietyFormComponent,
        SocietyMemberFormComponent,
        SocietyProfilComponent,
        SocietyProfilDocumentsComponent,
        SocietyProfilInfoComponent,
        SocietyProfilTreasuryComponent,
        StackedBarGraphComponent,
        StepperComponent,
        StickerClicComponent,
        StarRateComponent,
        StorageProgressComponent,
        StoryCardComponent,
        StoryDetailComponent,
        StoryDisplayComponent,
        StoryFooterComponent,
        SubTabComponent,
        SubscriptionCardTrialComponent,
        SubscriptionConfirmCancellationComponent,
        SubscriptionCountdownComponent,
        SubscriptionCurrentComponent,
        SubscriptionErrorComponent,
        SubscriptionMenuComponent,
        SubscriptionRedirectAppleComponent,
        SubscriptionSlideComponent,
        SubscriptionSuccessComponent,
        SuggestSmartComponent,
        TenantComponent,
        TenantFormModalComponent,
        TimelineComponent,
        ToggleComponent,
        ToolbarComponent,
        TreasuryCardComponent,
        TreasuryCardComponent2,
        TreasuryEditComponent,
        TreasuryFormComponent,
        TwoFactorValidationComponent,
        UserInfoComponent,
        UserRegisterComponent,
        UserSigninComponent,
        ValidationModalComponent,
        ValuationComponent,
        WarningPopoverComponent,
        WidgetBankingAmountComponent,
        WidgetBankingOverviewComponent,
        WidgetProgressBarComponent,
        WidgetRentabilityComponent,
        ToggleComponent,
        LoanCardContainer,
        LoanFormContainer,
        LoanProfilComponent,
        LoanSynthesisContainer,
        LoanDetailsContainer,
        LoanInfosStepContainer,
        LoanDueDateStepContainer,
        LoanConfirmationStepContainer,
        LoanDeleteConfirmContainer,
        SavingCardContainer,
        SavingFormContainer,
        SavingSynthesisContainer,
        SavingDetailsContainer,
        SavingConfirmationStepContainer,
        SavingInfosStepContainer,
        SavingProfilComponent,
        SavingMovementsStepContainer,
        SavingExitConstraintsStepContainer,
        SavingDeleteConfirmContainer,
        UserRegistrationProcessPage,
        DPEInterfaceComponent,
        DpeScoreComponent,
        RegistrationFirstStepComponent,
        RegistrationSecondStepComponent,
        RegistrationThirdStepComponent,
        ProgressBarComponent,
        QuestionsComponent,
        SelectPropertyCardComponent,
        ShareConditionsComponent,
        ChatMessageCardComponent,
        ChatClientListComponent,
        ChatMessageListComponent,
        ChatCardComponent,
        ChatClientCardComponent,
        ChatListComponent,
    ],
    providers: [DecimalPipe, CurrencyPipe],
})
export class UiModule { }
