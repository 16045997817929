@if (!editable) {
    <omedom-info
        class="margin-bottom-1"
        icon="info-circle"
        information="Vous ne pouvez pas partager {{
            type === 'property' ? 'ce bien' : 'cette société'
        }} car vous n’en n’avez pas les droits."
    >
    </omedom-info>
}

<div class="margin-bottom-1">
    <strong>Description des rôles :</strong>
</div>
<div class="role-description">
    Lecteur<omedom-popover
        [content]="roleRightAdapter.getReaderCompleteDescription()"
        class="margin-left-1 margin-right-2"
        [withMargin]="false"
        [iconTop]="true"
        [withIcon]="false"
    ></omedom-popover>
    Editeur<omedom-popover
        [content]="roleRightAdapter.getEditorCompleteDescription()"
        class="margin-left-1 margin-right-2"
        [withMargin]="false"
        [iconTop]="true"
        [withIcon]="false"
    ></omedom-popover>
    Propriétaire<omedom-popover
        [content]="roleRightAdapter.getOwnerCompleteDescription()"
        class="margin-left-1 margin-right-2"
        [withMargin]="false"
        [iconTop]="true"
        [withIcon]="false"
    ></omedom-popover>
    <!-- Administrateur
  <app-popover
    [content]="roleRightAdapter.getAdminCompleteDescription()"
    class="margin-left-1 margin-right-2"
    [withMargin]="false"
    [iconTop]="true"
  ></app-popover>-->
</div>
<div class="margin-top-2">
    <strong>Votre rôle :</strong>
    <div class="margin-top-1 current-role">
        <div style="text-transform: capitalize">{{ roleTranslate }}</div>
        <!-- <app-popover
    [content]="roleRightAdapter.getRightsByRole(role)"
    class="margin-left-1"
    [withMargin]="false"
    [iconTop]="true"
  ></app-popover> -->
    </div>
</div>
@if (sharedBy) {
    <hr class="omedom-divider" />
    <div class="margin-bottom-1">
        <strong>Partagé par :</strong>
    </div>
    @if (sharedBy) {
        <omedom-share-card
            [share]="sharedBy"
            [entity]="entity"
            [editable]="false"
            [isShared]="true"
            [type]="type"
        ></omedom-share-card>
    }
}
@if (editable) {
    <div class="flex margin-top-2 margin-bottom-1">
        <strong>Partagé à :</strong>
        <omedom-popover
            [content]="advertissementRules"
            class="margin-left-1 margin-right-2"
            [withMargin]="false"
            [iconTop]="true"
            [withIcon]="false"
        ></omedom-popover>
    </div>
    <div class="list" [@list]="entity?.sharing?.length">
        @for (sharing of entity?.sharing; track sharing.email; let i = $index) {
            <omedom-share-card
                [share]="sharing"
                [entity]="entity"
                [editable]="editable"
                [sharingIndex]="i"
                [type]="type"
            ></omedom-share-card>
        }
    </div>
    @if (!entity?.sharing || (entity?.sharing || []).length === 0) {
        <omedom-create-first-item-button
            @element
            buttonIcon="uil-share-alt"
            message="Partager votre bien"
            (buttonClicked)="addShare()"
        ></omedom-create-first-item-button>
        <omedom-info
            class="w-100 margin-top-1 margin-bottom-1"
            [information]="advertissement"
        >
        </omedom-info>
    } @else {
        @if (editable) {
            <div
                class="omedom-sticky-button-container w-100 top-100 margin-top-2"
            >
                <button
                    class="omedom-button-primary flex-1"
                    (click)="addShare()"
                >
                    <i class="uil uil-share-alt"></i>
                    Ajouter un tiers
                </button>
            </div>
        }
    }
}
