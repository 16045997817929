import { Component, Input } from '@angular/core';
import {
    DPEScore,
    RenovationScenarioBasic,
    RenovationScenarioGranular,
    RenovationType,
    translateRenovationType,
} from '@omedom/data';

@Component({
    selector: 'omedom-renovation-scenario',
    templateUrl: './renovation-scenario.component.html',
    styleUrls: ['./renovation-scenario.component.scss'],
})
export class RenovationScenarioComponent {
    /**
     * @description The actual DPE score to display (required)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {DPEScore}
     * @memberof RenovationScenarioComponent
     */
    @Input({ required: true }) actualDPEScore!: DPEScore;

    /**
     * @description The scenario of renovation to display (required)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {(RenovationScenarioGranular | RenovationScenarioBasic)}
     * @memberof RenovationScenarioComponent
     */
    @Input({ required: true }) scenario!: RenovationScenarioGranular | RenovationScenarioBasic;

    /**
     * @description The result granularity of the scenario (granular or basic) (required)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {('granular' | 'basic')}
     * @memberof RenovationScenarioComponent
     */
    @Input({ required: true }) resultGranularity?: 'granular' | 'basic';

    /**
     * @description The scenario in granular format (if the result granularity is granular)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @readonly
     * @type {(RenovationScenarioGranular | undefined)}
     * @memberof RenovationScenarioComponent
     */
    public get scenarioGranular(): RenovationScenarioGranular | undefined {
        if (this.resultGranularity === 'granular') {
            return this.scenario as RenovationScenarioGranular;
        }
        return undefined;
    }

    /**
     * @description Scenario basic if the result granularity is basic, undefined otherwise
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @readonly
     * @type {(RenovationScenarioBasic | undefined)}
     * @memberof RenovationScenarioComponent
     */
    public get scenarioBasic(): RenovationScenarioBasic | undefined {
        if (this.resultGranularity === 'basic') {
            return this.scenario as RenovationScenarioBasic;
        }
        return undefined;
    }

    constructor() {}

    /**
     * @description Translate the renovation type to French
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @param {RenovationType} text
     * @returns {*}
     * @memberof RenovationScenarioComponent
     */
    translateType(text: RenovationType) {
        return translateRenovationType(text);
    }
}
