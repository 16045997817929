<i class="omedom-icon-crown" id="smartPopover"></i>
<ion-popover #popover class="omedom-popover" trigger="smartPopover">
    <ng-template>
        <div class="omedom-popover-container">
            <span [innerHTML]="text"></span>
            <button
                class="omedom-icon-button-primary"
                style="width: 25%"
                (click)="popover.dismiss()"
                routerLink="/tabs/menu/subscription"
                [queryParams]="{ tab: 'manage' }"
            >
                <i class="omedom-icon-crown"></i>
            </button>
        </div>
    </ng-template>
</ion-popover>
