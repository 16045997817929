import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { EntityTypes, LeaseEntity, OmedomStep, PropertyEntity, RoleState, TenantEntity } from '@omedom/data';
import { LeaseService, RoleService, TenantService, UserService } from '@omedom/services';
import { OmedomRegex } from '@omedom/utils';
import { BehaviorSubject, Subscription, switchMap } from 'rxjs';

import { AlertComponent } from '../../components/alert/alert.component';
import { StepperComponent } from '../../components/stepper/stepper.component';
import { OmedomSubTab } from '../../components/sub-tab/omedom-sub-tab';
import { LeaseFormStep } from './lease-form-step';

@Component({
    selector: 'omedom-lease-form',
    templateUrl: './lease-form.component.html',
    styleUrls: ['./lease-form.component.scss'],
})
export class LeaseFormComponent implements OnChanges, OnInit, OnDestroy {
    /**
     * @description Form of the lease
     * @author Brisset Killian
     * @date 11/04/2024
     * @type {NgForm}
     * @memberof LeaseFormComponent
     */
    @ViewChild(NgForm) form?: NgForm;

    /**
     * @description Stepper component of the lease form component
     * @author Brisset Killian
     * @date 11/04/2024
     * @type {StepperComponent}
     * @memberof LeaseFormComponent
     */
    @ViewChild(StepperComponent) stepperComponent?: StepperComponent;

    /**
     * @description Steps of the lease form component
     * @author Brisset Killian
     * @date 11/04/2024
     * @type {OmedomStep[]}
     * @memberof LeaseFormComponent
     */
    public steps: OmedomStep[] = [
        new OmedomStep({
            id: LeaseFormStep.lease,
            label: 'Bail',
            canGoNext: () => this.isLeaseValid(),
        }),
        new OmedomStep({
            id: LeaseFormStep.rent,
            label: 'Loyers',
            canGoNext: () => this.isRentValid(),
        }),
        new OmedomStep({
            id: LeaseFormStep.tenant,
            label: 'Locataires',
            canGoNext: () => this.isFormValid(),
        }),
    ];

    /**
     * @description Sub tabs of the lease form component
     * @author Brisset Killian
     * @date 11/04/2024
     * @type {OmedomSubTab[]}
     * @memberof LeaseFormComponent
     */
    public subTabs: OmedomSubTab[] = [
        new OmedomSubTab({ id: LeaseFormStep.lease, label: 'Bail' }),
        new OmedomSubTab({ id: LeaseFormStep.rent, label: 'Loyers' }),
        new OmedomSubTab({ id: LeaseFormStep.tenant, label: 'Locataires' }),
    ];

    /**
     * @description Selected sub tab of the lease form component
     * @author Brisset Killian
     * @date 11/04/2024
     * @type {OmedomSubTab}
     * @memberof LeaseFormComponent
     */
    public selectedSubTab: OmedomSubTab = this.subTabs[0];

    /**
     * @description Selected step of the lease form component
     * @author Brisset Killian
     * @date 11/04/2024
     * @type {OmedomStep}
     * @memberof LeaseFormComponent
     */
    public selectedStep: OmedomStep = this.steps[0];

    /**
     * @description Lease form step of the lease form component
     * @author Brisset Killian
     * @date 11/04/2024
     * @memberof LeaseFormComponent
     */
    public leaseFormStep = LeaseFormStep;

    /**
     * @description Is period valid of the lease form component to check if the period of the lease is valid or not in the lease form component
     * @author Brisset Killian
     * @date 11/04/2024
     * @memberof LeaseFormComponent
     */
    public isPeriodValid = false;

    /**
     * @description Lease to create or update in the lease form component
     * @author Brisset Killian
     * @date 11/04/2024
     * @type {Partial<LeaseEntity>}
     * @memberof LeaseFormComponent
     */
    @Input()
    public lease: Partial<LeaseEntity> = {};

    /**
     * @description Property of the lease
     * @author Brisset Killian
     * @date 11/04/2024
     * @type {PropertyEntity}
     * @memberof LeaseFormComponent
     */
    @Input({ required: true })
    public property!: PropertyEntity;

    /**
     * @description Tenants of the lease
     * @author Brisset Killian
     * @date 11/04/2024
     * @memberof LeaseFormComponent
     */
    @Input()
    public tenants: TenantEntity[] = [];

    /**
     * @description Existing tenants of the lease
     * @author Brisset Killian
     * @date 11/04/2024
     * @private
     * @memberof LeaseFormComponent
     */
    private existingTenants: string[] = [];

    /**
     * @description User Role on this property
     * @author ANDRE Felix
     * @type {RoleState}
     * @memberof LeaseFormComponent
     */
    @Input()
    public roleState?: RoleState;

    /**
     * @description Subscriptions for the subscrition roles$
     * @author Brisset Killian
     * @date 11/04/2024
     * @type {Subscription[]}
     * @memberof LeaseFormComponent
     */
    public subscription: Subscription[] = [];

    @Input()
    public leaseUID?: string;

    @Input()
    public canAddAid = true;

    @Output() addRent = new EventEmitter<{
        propertyUID: string | undefined;
        navigationExtras: NavigationExtras;
    }>();

    @Output()
    public finish = new EventEmitter<string>();

    public pending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private leaseService: LeaseService,
        private tenantService: TenantService,
        private toast: ToastController,
        private modalController: ModalController,
        private router: Router,
        private roleService: RoleService,
        private userService: UserService,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.refreshSubTab();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        // verify if the lease is changed for each key
        const leaseChanged =
            changes['lease'] &&
            (changes['lease'].firstChange ||
                (Object.keys(changes['lease'].previousValue).length ===
                    Object.keys(changes['lease'].currentValue).length &&
                    Object.keys(changes['lease'].currentValue).equals(
                        Object.keys(changes['lease'].previousValue),
                    ) &&
                    Object.keys(changes['lease'].previousValue).some(
                        (key) =>
                            changes['lease'].previousValue[key] !==
                            changes['lease'].currentValue[key],
                    )));
        if (leaseChanged || (this.lease && Object.keys(this.lease).length === 1)) {
            await this.refreshSubTab();
        }
    }

    async ngOnDestroy(): Promise<void> {
        this.subscription.forEach((x) => x.unsubscribe());
        this.lease = {
            fiduciary: {
                address: {},
            },
        };
    }

    /**
     * @description Refresh the step and the sub tab of the form
     * @author Brisset Killian
     * @date 11/04/2024
     * @private
     * @returns {*}  {Promise<void>}
     * @memberof LeaseFormComponent
     */
    private async refreshSubTab(): Promise<void> {
        this.selectedSubTab = this.subTabs[0];
        this.selectedStep = this.steps[0];
        if (this.stepperComponent) this.stepperComponent.selectedStepIndex = 0;
        if (this.lease.uid) {
            this.existingTenants = [...this.tenants.map((x) => x.uid)];
        }

        this.lease = {
            ...(this.lease ?? {}),
            fiduciary: {
                ...(this.lease?.fiduciary ?? {}),
                address: { ...(this.lease?.fiduciary?.address ?? {}) },
            },
        };

        this.subscription.forEach((x) => x.unsubscribe());
        const role$ = this.userService.user$
            .pipe(
                switchMap(() =>
                    this.roleService._getRoleState(this.property, EntityTypes.property),
                ),
            )
            .subscribe((roleState) => {
                this.roleState = roleState;
            });
        this.subscription.push(role$);
    }

    /**
     * @description Verify if the lease is valid depending on the TimePeriod and the input filled
     * @author Brisset Killian
     * @date 11/04/2024
     * @returns {*}  {boolean}
     * @memberof LeaseFormComponent
     */
    isLeaseValid(): boolean {
        return this.isTimePeriodValid() && this.isAllInputFilled() && this.isLeaseFiduciaryValid();
    }

    /**
     * @description Verify if leaseType, leaseStart and leaseEnd input are filled
     * @author Brisset Killian
     * @date 11/04/2024
     * @private
     * @returns {*}
     * @memberof LeaseFormComponent
     */
    private isAllInputFilled() {
        return !!this.lease.leaseType && !!this.lease.leaseStart && !!this.lease.leaseEnd;
        //&& !!this.lease.guarantyDeposit;
    }

    private isLeaseFiduciaryValid(): boolean {
        return this.lease.fiduciary
            ? (!this.lease.fiduciary.email ||
                  !!new RegExp(OmedomRegex.emailRegex).exec(this.lease.fiduciary.email)) &&
                  (!this.lease.fiduciary.tel ||
                      !!OmedomRegex.phoneRegex.exec(this.lease.fiduciary.tel))
            : true;
    }

    /**
     * @description Verify if the TimePeriod is valid depending on the leaseStart and leaseEnd
     * @author Brisset Killian
     * @date 11/04/2024
     * @private
     * @returns {*}
     * @memberof LeaseFormComponent
     */
    private isTimePeriodValid() {
        if (!this.lease.leaseStart || !this.lease.leaseEnd) {
            return false;
        }
        this.isPeriodValid = this.lease.leaseStart < this.lease.leaseEnd;
        return this.isPeriodValid;
    }

    /**
     * @description Verify if the lease has a rent
     * @author Brisset Killian
     * @date 11/04/2024
     * @returns {*}  {boolean}
     * @memberof LeaseFormComponent
     */
    isRentValid(): boolean {
        return !!this.lease.rentUID;
    }

    /**
     * @description Verify if the form is valid depending on the form required fields
     * @author Brisset Killian
     * @date 11/04/2024
     * @returns {*}  {boolean}
     * @memberof LeaseFormComponent
     */
    isFormValid(): boolean {
        return this.form?.valid ?? false;
    }

    /**
     * @description Save the lease and the tenants in firebase
     * @author Brisset Killian
     * @date 11/04/2024
     * @returns {*}  {Promise<void>}
     * @memberof LeaseFormComponent
     */
    async save(): Promise<void> {
        if (!this.lease || this.pending$.value) {
            return;
        }
        this.pending$.next(true);
        try {
            if (this.lease.uid) {
                await this.leaseService.update(this.lease);
            } else {
                await this.leaseService.create(this.lease);
            }
            if (!this.lease.uid) {
                throw new Error("Le bail n'a pas été enregistré");
            }
            for (const tenant of this.tenants) {
                tenant.leaseUID = this.lease.uid;
                try {
                    const exist = this.existingTenants.find((x) => x === tenant.uid);
                    if (exist) {
                        await this.tenantService.update(tenant);
                    } else {
                        await this.tenantService.create(tenant);
                    }
                    const toastSuccess = await this.toast.create({
                        position: 'top',
                        color: 'primary',
                        duration: 4000,
                        message: 'Vous avez enregistré un bail.',
                    });
                    await toastSuccess.present();
                } catch (error) {
                    console.error(error);
                    const toastError = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: "Une erreur s'est produite, veuillez réessayer plus tard.",
                    });
                    await toastError.present();
                }
            }
            for (const tenantUid of this.existingTenants) {
                const exist = this.tenants.find((x) => x.uid === tenantUid);
                if (!exist) {
                    await this.tenantService.delete(tenantUid);
                }
            }
            this.pending$.next(false);
            //this.navController.pop();
            if (await this.modalController.getTop()) {
                await this.modalController.dismiss('saved');
            } else {
                this.finish.emit(this.property.uid);
            }
            const toast = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: 'Vous avez enregistré un bail.',
            });
            await toast.present();
        } catch (error) {
            console.error(error);
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
        }
        this.pending$.next(false);
    }

    /**
     * @description Exit alert when the user click on the exit button
     * @author Brisset Killian
     * @date 11/04/2024
     * @param {string} label
     * @param {string} icon
     * @param {string} img
     * @memberof LeaseFormComponent
     */
    async exitClicked(label: string, icon: string, img: string | null) {
        const modal = await this.modalController.create({
            component: AlertComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: { title: label, avatar: icon, image: img },
        });

        await modal.present();

        modal.onDidDismiss().then(async () => {
            if (await this.modalController.getTop()) {
                await this.modalController.dismiss();
            }
        });
    }
}
