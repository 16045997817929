import { ChargeCategoryInfo, IncomeCategoryInfo } from '../entities';
import { AssetTypes } from '../enums';

/**
 * @description Select option model to display select options in the UI
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 09/08/2023
 * @export
 * @class SelectOptionModel
 */
export class SelectOption {
    /**
     * @description Label of the select option (displayed in the UI) (ex: "Loyer", "Eau", "Electricité", etc...)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @type {string}
     * @memberof SelectOptionModel
     */
    label!: string;

    /**
     * @description Id of the select option
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @type {*}
     * @memberof SelectOptionModel
     */
    id: any;

    /**
     * @description Popover of the select option to display info on hover
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @type {string}
     * @memberof SelectOptionModel
     */
    popover?: string;

    /**
     * @description Image of the select option
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @type {string}
     * @memberof SelectOptionModel
     */
    image?: string;

    /**
     * @description Text alternative of the image of the select option
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @type {string}
     * @memberof SelectOptionModel
     */
    imageAlt?: string;

    /**
     * @description Icon of the select option
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @type {string}
     * @memberof SelectOptionModel
     */
    icon?: string;

    /**
     * @description Return true if the select option is selected in the UI
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @type {boolean}
     * @memberof SelectOptionModel
     */
    isSelected?: boolean;

    /**
     * @description Return true if the select is possible
     * @author Brisset Killian
     * @date 16/02/2024
     * @type {boolean}
     * @memberof SelectOption
     */
    isAccesible?: boolean;

    /**
     * @description Purchase year of the property value
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @type {number}
     * @memberof SelectOptionModel
     */
    purchaseYear?: number;

    /**
     * @description Date of data in select option
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @type {Date}
     * @memberof SelectOptionModel
     */
    date?: Date;

    /**
     * @description Notes of data in select option
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @type {string}
     * @memberof SelectOptionModel
     */
    notes?: string;

    /**
     * @description Category info
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @type {(ChargeCategoryInfo | IncomeCategoryInfo)}
     * @memberof SelectOptionModel
     */
    categoryInfo?: ChargeCategoryInfo | IncomeCategoryInfo;

    /**
     * @description asset Type
     * @author ANDRE Felix
     * @type {('building' | 'society' | 'property')}
     * @memberof SelectOption
     */
    assetType?: AssetTypes;

    /**
     * @description Amount of the select option
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/05/2024
     * @memberof SelectOption
     */
    amount?: number;

    /**
     * @description Periodicity of the select option (ex: "Mensuel", "Annuel", etc...)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/05/2024
     * @type {string}
     * @memberof SelectOption
     */
    periodicity?: string;


}
