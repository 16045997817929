import {
    Component,
    forwardRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { SelectOption } from '@omedom/data';

import { OmedomRadioOption } from './omedom-radio-option';

@Component({
    selector: 'omedom-radio',
    templateUrl: './radio.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RadioComponent),
            multi: true,
        },
    ],
})
export class RadioComponent implements ControlValueAccessor, Validator, OnChanges {
    @Input() label?: string;

    @Input() name?: string;

    @Input({ required: true }) options: OmedomRadioOption[] = [];

    @Input() required?: boolean;

    @Input() popover?: string;

    @Input() type: 'column' | 'row' = 'row';

    @Input() disabled?: boolean;

    protected value: any;

    protected isDisabled: boolean = false;

    private onChangeCallback: any;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['options']) {
            this.options = changes['options'].currentValue;
        }
    }

    validate(): null {
        return undefined as any;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(): void { }

    writeValue(newValue: SelectOption | SelectOption[]): void {
        if (newValue !== this.value) {
            this.value = newValue;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        // Ne fonctionne que si c'est du template driven
        this.isDisabled = isDisabled;
    }

    valueChange(option: OmedomRadioOption) {
        this.writeValue(option.id);
        this.onChangeCallback(option.id);
    }
}
