import { Component, Input } from '@angular/core';
import { Renovation, translateEnergyTypeToFrench, translateRenovationType } from '@omedom/data';

@Component({
    selector: 'omedom-renovation-infos',
    templateUrl: './renovation-infos.component.html',
    styleUrls: ['./renovation-infos.component.scss'],
})
export class RenovationInfosComponent {
    /**
     * @description The renovations to display (required)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {Renovation[]}
     * @memberof RenovationInfosComponent
     */
    @Input({ required: true }) renovations: Renovation[] = [];

    /**
     * @description The transformation of the renovations to display in French (readonly)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @readonly
     * @type {Renovation[]}
     * @memberof RenovationInfosComponent
     */
    public get transformationRenovations(): Renovation[] {
        return (this.renovations ?? []).map((renovation) => {
            return {
                ...renovation,
                type: translateRenovationType(renovation.type),
                value: translateEnergyTypeToFrench(renovation.value),
            } as Renovation;
        });
    }

    constructor() {}
}
