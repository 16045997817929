import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Filesystem, WriteFileResult } from '@capacitor/filesystem';
import { HttpClient } from '@angular/common/http';
import { OmedomTransformer } from '@omedom/utils';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    constructor(
        private storage: AngularFireStorage,
        private http: HttpClient,
    ) { }

    public async displayPDF(url: string): Promise<void> {
        const fileName = 'omedom-temp-file.pdf';
        let response: WriteFileResult;
        this.storage
            .refFromURL(url)
            .getDownloadURL()
            .subscribe(async (dlUrl) => {
                const blobFile = await this.http.get(dlUrl, {
                    responseType: 'blob',
                });
                blobFile.subscribe(async (blob: Blob) => {
                    const BlobBase64 = await OmedomTransformer.blobToBase64(blob);

                    // Write the file to the data directory
                    try {
                        response = await Filesystem.writeFile({
                            path: fileName,
                            data: BlobBase64,
                            directory: Directory.Library,
                        });
                    } catch (error) {
                        console.error('File write failed', error);
                    }
                    // Open the file using the device default
                    try {
                        await FileOpener.open({
                            filePath: response.uri,
                            contentType: 'application/pdf',
                            openWithDefault: true,
                        });
                    } catch (error) {
                        console.error('File open failed', error);
                    }
                });
            });
    }
}
