import { Component, Input } from '@angular/core';
import { ClientEntity, ProEntity } from '@omedom/data';
import { ClientService } from '@omedom/services';

@Component({
    selector: 'omedom-accept-edit-share-condition',
    templateUrl: './accept-edit-share-condition.component.html',
    styleUrls: ['./accept-edit-share-condition.component.scss'],
})
export class AcceptEditShareConditionComponent {
    /**
     * @description The pro entity to share the client with
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {ProEntity}
     * @memberof AcceptEditShareConditionComponent
     */
    @Input({ required: true }) pro!: ProEntity;

    /**
     * @description The client entity to share with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {ClientEntity}
     * @memberof AcceptEditShareConditionComponent
     */
    @Input({ required: true }) client!: ClientEntity;

    /**
     * @description Getter for hasAgreedToEditByPro property of the client entity
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {boolean}
     * @memberof AcceptEditShareConditionComponent
     */
    get hasAgreedToEditByPro(): boolean {
        return this.client?.hasAgreedToEditByPro ?? false;
    }

    /**
     * @description Setter for hasAgreedToEditByPro property of the client entity
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @memberof AcceptEditShareConditionComponent
     */
    set hasAgreedToEditByPro(value: boolean) {
        if (this.client) {
            this.client.hasAgreedToEditByPro = value;
            this.updateClientShareCondition(this.client);
        }
    }

    constructor(private clientService: ClientService) {}

    /**
     * @description Update the client share condition in the database with the new value of hasAgreedToEditByPro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @private
     * @param {ClientEntity} client
     * @returns {*}
     * @memberof ShareToProComponent
     */
    private async updateClientShareCondition(client: ClientEntity) {
        if (!client) {
            return;
        }

        const clientValuesToUpdate = {
            uid: client.uid,
            userUID: client.userUID,
            hasAgreedToEditByPro: client.hasAgreedToEditByPro || false,
        };
        try {
            await this.clientService.update(clientValuesToUpdate);
        } catch (error) {
            console.error(error);
        }
    }
}
