<!-- User has subscription -->
<ng-container *ngIf="isLoading">
    <p>Chargement en cours...</p>
    <!-- TODO : Ajouter un vrai loading -->
</ng-container>
@if (!isLoading) {
    <ng-container>
        <ng-container [ngSwitch]="showPage">
            @if (
                showPage !== showPages.subscription &&
                showPage !== showPages.changeSubscription
            ) {
                <h4
                    class="margin-bottom-2 back"
                    (click)="showPage = showPages.subscription"
                    @element
                >
                    <i class="uil uil-angle-left"></i> Retour
                </h4>
            }

            <!-- Subscription view -->
            <ng-container *ngSwitchCase="showPages.subscription">
                <div @element>
                    <div class="current-subscription-header margin-bottom-2">
                        <h2>Abonnement actuel :</h2>
                        @if (pro$ | async; as pro) {
                            <div class="pro-container">
                                <div class="given-by-pro-container">
                                    <span
                                        >Abonnement mis à disposition par</span
                                    >

                                    @if (pro.logo) {
                                        <img
                                            class="pro-logo"
                                            [src]="pro.logo"
                                            alt="logo du professionnel"
                                        />
                                    } @else {
                                        <span>
                                            {{ pro.proFirstName }}
                                            {{ pro.proName }}</span
                                        >
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div class="w-100 margin-bottom-2">
                        <ng-container
                            *ngIf="
                                subscription()?.trial &&
                                !subscription()?.fromFamily &&
                                subscription()?.isSubscribed
                            "
                        >
                            <!-- Trial Card -->
                            <omedom-subscription-card-trial
                                [displayPrice]="true"
                                [familyInvitations]="familyInvitations"
                                (manageFamily)="
                                    showPage = showPages.familyInvitation
                                "
                                (manageSubscription)="manageSubscription()"
                            ></omedom-subscription-card-trial>

                            <!-- Expiration trial info -->
                            <omedom-info
                                class="margin-top-2 margin-bottom-2 w-100"
                                *ngIf="count < 7"
                                id="trial-expiration"
                                iconStart="uil uil-exclamation-triangle"
                                information="Votre offre arrive à son terme. Vous allez passer en abonnement gratuit Essential.<br>
                Afin de garder toutes les fonctionnalités disponibles, veuillez prendre un abonnement."
                            ></omedom-info>
                        </ng-container>

                        <omedom-subscription-current
                            *ngIf="!subscription()?.trial"
                            [subscription]="subscription()"
                            [currentConfiguration]="userConfiguration"
                            [pro$]="pro$"
                            [familyInvitations]="familyInvitations"
                            (manageFamily)="manageFamily()"
                            (manageFamilyInvitations)="
                                showPage = showPages.familyInvitation
                            "
                            (manageSubscription)="manageSubscription()"
                            (leaveFamily)="leaveFamily()"
                            (paymentHistory)="paymentHistory()"
                            (familyInvitation)="
                                showPage = showPages.familyInvitation
                            "
                        ></omedom-subscription-current>
                    </div>
                    <div
                        class="w-100"
                        *ngIf="
                            subscription()?.futureRank &&
                            !subscription()?.canceled
                        "
                    >
                        <h2 class="margin-bottom-2">Abonnement à venir :</h2>
                        <div class="w-100 margin-bottom-2">
                            <omedom-subscription-current
                                *ngIf="
                                    subscription()?.rank !== 'essential' &&
                                    subscription()?.isSubscribed &&
                                    !subscription()?.trial &&
                                    !subscription()?.canceled
                                "
                                (manageSubscription)="manageSubscription()"
                                [subscription]="subscription()"
                                [currentConfiguration]="userConfiguration"
                                [configurations]="configurations"
                            ></omedom-subscription-current>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- END Subscription view -->

            <ng-container *ngSwitchCase="showPages.manage">
                <div [@list]="configurations.length">
                    <div class="w-100">
                        @for (
                            subscriptionFromConfiguration of configurations
                                | arrayFilter
                                    : 'docName'
                                    : subscriptionRank.TRIAL
                                    : false;
                            track $index
                        ) {
                            @if (
                                subscriptionFromConfiguration.isActive ||
                                subscriptionFromConfiguration.docName ===
                                    userConfiguration?.docName
                            ) {
                                <omedom-subscription-slide
                                    class="margin-bottom-2"
                                    [currentSubscription]="subscription()"
                                    [subscriptionName]="
                                        subscriptionFromConfiguration.name ??
                                        subscriptionFromConfiguration.docName
                                    "
                                    [subscriptionDocName]="
                                        subscriptionFromConfiguration.docName
                                    "
                                    [subscriptionColor]="
                                        subscriptionFromConfiguration.color
                                    "
                                    [subscriptionFontColor]="
                                        subscriptionFromConfiguration.fontColor
                                    "
                                    [subscriptionSubTitle]="
                                        subscriptionFromConfiguration.subTitle
                                    "
                                    [subscriptionPrice]="
                                        subscriptionFromConfiguration.price
                                    "
                                    [displayButton]="
                                        shouldDisplayButton(
                                            subscriptionFromConfiguration
                                        )
                                    "
                                    [displayPrice]="
                                        subscriptionFromConfiguration.docName !==
                                        subscriptionRank.ESSENTIAL
                                    "
                                    (chooseEvent)="chooseSubscription($event)"
                                    (cancelEvent)="cancelSubscription()"
                                >
                                </omedom-subscription-slide>
                            }
                        }
                    </div>
                </div>
                <div class="margin-top-auto" @element>
                    <span class="link center-text" (click)="showMention()">
                        Conditions générales de vente
                    </span>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="showPages.history">
                <ng-container *ngIf="payments.length === 0">
                    <omedom-info
                        @element
                        class="margin-top-2 margin-bottom-2 w-100"
                        id="no-payment"
                        iconStart="uil uil-exclamation-triangle"
                        information="Vous n'avez pas encore effectué de paiement."
                    ></omedom-info>
                </ng-container>
                <div [@list]="payments.length">
                    <omedom-info
                        *ngIf="isOnApple"
                        class="margin-top-1 w-100"
                        information="Retrouvez vos factures dans votre compte APPLE."
                    ></omedom-info>
                    <omedom-payment-card
                        *ngFor="let payment of payments"
                        class="margin-top-1"
                        [payment]="payment"
                    ></omedom-payment-card>
                </div>
            </ng-container>

            <!-- Subscription form view -->
            <ng-container *ngSwitchCase="showPages.changeSubscription">
                @if (currentConfiguration) {
                    <div class="omedom-space-between w-100">
                        <h4>
                            {{
                                isChangingSubscription
                                    ? 'Abonnement à venir'
                                    : 'Modification abonnement'
                            }}&nbsp;
                        </h4>
                        <div
                            class="subscription-header"
                            [style.background-color]="
                                currentConfiguration.color
                            "
                            [style.color]="currentConfiguration.fontColor"
                        >
                            <h3>
                                @if (
                                    currentConfiguration.docName !==
                                        subscriptionRank.ESSENTIAL &&
                                    currentConfiguration.docName !==
                                        subscriptionRank.FAMILY &&
                                    currentConfiguration.docName !==
                                        subscriptionRank.NOEL2024
                                ) {
                                    <i
                                        class="omedom-icon-crown margin-right-1"
                                    ></i>
                                }
                                @if (
                                    currentConfiguration.docName ===
                                    subscriptionRank.ESSENTIAL
                                ) {
                                    <i class="uil uil-star margin-right-1"></i>
                                }
                                @if (
                                    currentConfiguration.docName ===
                                    subscriptionRank.FAMILY
                                ) {
                                    <i
                                        class="uil uil-users-alt margin-right-1"
                                    ></i>
                                }
                                @if (
                                    currentConfiguration.docName ===
                                    subscriptionRank.NOEL2024
                                ) {
                                    <i
                                        class="uil uil-snowflake margin-right-1"
                                    ></i>
                                }
                                {{
                                    currentConfiguration.name ??
                                        currentConfiguration.docName ??
                                        '' | capitalizeFirstLetter
                                }}
                            </h3>
                        </div>
                    </div>

                    <hr
                        class="omedom-divider w-100"
                        *ngIf="isSubscribing || isChangingSubscription"
                    />

                    <form
                        [formGroup]="billingForm"
                        class="billing-form"
                        *ngIf="billingForm"
                    >
                        <h3 class="w-100 margin-y-1">
                            Informations de facturation
                        </h3>
                        <omedom-radio
                            name="billingEntityType"
                            class="omedom-radio"
                            [options]="billingEntityTypeOptions"
                            [required]="true"
                            formControlName="billingEntityType"
                            popover="Une personne morale est une entreprise, une association, une collectivité, etc. La personne physique est un particulier."
                        ></omedom-radio>
                        @if (
                            billingForm.get('billingEntityType')!.value ===
                            billingEntityTypeEnum.company
                        ) {
                            <form [formGroup]="companyForm" class="w-100">
                                <omedom-input
                                    name="billingCompanyName"
                                    class="w-100"
                                    label="Raison sociale"
                                    id="billing-company-name"
                                    autocomplete="company_name"
                                    formControlName="billingCompanyName"
                                    [required]="true"
                                ></omedom-input>
                            </form>
                        } @else {
                            <form
                                [formGroup]="individualForm"
                                class="w-100"
                                id="individual-form"
                            >
                                <omedom-input
                                    name="billingFirstname"
                                    class="item"
                                    label="Prénom"
                                    id="billing-firstname"
                                    autocomplete="family-name"
                                    formControlName="billingFirstname"
                                    [required]="true"
                                ></omedom-input>
                                <omedom-input
                                    name="billingName"
                                    class="item"
                                    label="Nom"
                                    id="billing-name"
                                    autocomplete="given-name"
                                    formControlName="billingName"
                                    [required]="true"
                                ></omedom-input>
                            </form>
                        }

                        <omedom-input
                            name="billingEmail"
                            [ngClass]="
                                billingForm.get('billingEntityType')!.value ===
                                billingEntityTypeEnum.company
                                    ? 'item'
                                    : ''
                            "
                            icon="uil uil-envelope"
                            label="E-mail destinataire"
                            id="billing-email"
                            autocomplete="billingEmail"
                            placeholder="E-mail"
                            formControlName="billingEmail"
                            [required]="true"
                        ></omedom-input>
                        <div class="w-100">
                            <p class="w-100 form-label omedom-no-margin">
                                Adresse facturation<sup> *</sup>
                            </p>
                            <omedom-input
                                name="address"
                                icon="uil uil-map-marker-alt"
                                id="address"
                                class="margin-top-1 w100"
                                autocomplete="address-line1"
                                placeholder="N° et rue"
                                formControlName="address"
                                [required]="true"
                            ></omedom-input>
                        </div>
                        <omedom-input
                            name="addressLine2"
                            id="addressLine2"
                            autocomplete="address-line2"
                            placeholder="Complément d'adresse"
                            formControlName="addressLine2"
                        ></omedom-input>

                        <omedom-input
                            name="postalCode"
                            autocomplete="postal-code"
                            class="item"
                            id="postal-code"
                            placeholder="Code postal"
                            formControlName="postalCode"
                            [required]="true"
                            [pattern]="postalCodeRegex"
                        ></omedom-input>

                        <omedom-input
                            name="city"
                            autocomplete="city"
                            class="item"
                            id="city"
                            placeholder="Ville"
                            formControlName="city"
                            [required]="true"
                        ></omedom-input>

                        <omedom-radio
                            name="subscriptionType"
                            class="margin-y-1"
                            [options]="subscriptionTypeOptions"
                            formControlName="subscriptionType"
                            [required]="true"
                        ></omedom-radio>
                    </form>

                    <div class="no-sticky-total-container w-100">
                        <hr class="omedom-divider w-100 omedom-no-margin" />

                        <div
                            class="total-container w-100"
                            *ngIf="billingForm?.get('subscriptionType')?.value"
                        >
                            <strong>Total</strong>
                            <p>
                                <strong class="total">{{
                                    getTotalPrice('TTC')
                                }}</strong>
                                ({{ getTotalPrice('HT') }})
                            </p>
                        </div>

                        <div class="subscription-small">
                            <p>
                                Le passage à un forfait supérieur prend effet
                                immédiatement. Vous pouvez donc bénéficier
                                immédiatement des fonctionnalités
                                supplémentaires de ce forfait.
                            </p>
                        </div>
                        <div @element>
                            <span
                                class="link center-text"
                                (click)="showMention()"
                            >
                                Conditions générales de vente
                            </span>
                        </div>

                        <div class="buttons-container w-100 margin-top-2">
                            <button
                                class="omedom-button-secondary margin-right-2 flex-1"
                                (click)="showPage = showPages.manage"
                            >
                                Annuler
                            </button>
                            <button
                                class="omedom-button-primary flex-1"
                                (click)="submit()"
                                [disabled]="disableSubmit"
                            >
                                @if (isSubscribing) {
                                    <ng-container>
                                        Payer maintenant
                                    </ng-container>
                                }

                                @if (!isSubscribing && isChangingSubscription) {
                                    <ng-container>
                                        Changer mon aboonnement
                                    </ng-container>
                                }

                                @if (
                                    !isSubscribing && !isChangingSubscription
                                ) {
                                    <ng-container> Enregistrer </ng-container>
                                }

                                @if (pending$.asObservable() | async) {
                                    <ion-spinner
                                        color="primary"
                                        class="omedom-upload-spinner"
                                    ></ion-spinner>
                                }
                            </button>
                        </div>
                    </div>
                }
            </ng-container>
            <!-- END Subscription form view -->

            <!-- Family invitation view -->
            <ng-container *ngSwitchCase="showPages.familyInvitation">
                <div
                    class="invitation-container w-100"
                    *ngFor="let invitation of familyInvitations"
                >
                    <p>
                        Vous êtes invité a rejoindre la famille
                        <strong>{{ invitation.familyName }}</strong>
                    </p>
                    <button
                        class="omedom-button-primary"
                        (click)="
                            acceptFamilyInvitation(invitation.invitationUID)
                        "
                    >
                        Accepter
                    </button>
                    <button
                        class="omedom-button-secondary"
                        (click)="
                            refuseFamilyInvitation(invitation.invitationUID)
                        "
                    >
                        Refuser
                    </button>
                </div>
            </ng-container>
            <!-- END Family invitation view -->

            <!-- Family management view -->
            <ng-container *ngSwitchCase="showPages.family">
                <h2 class="margin-bottom-2">Gérer votre famille :</h2>
                <hr class="omedom-divider" />

                <div class="list">
                    <div
                        *ngIf="
                            family &&
                            family.familyMembers &&
                            family.familyMembers.length > 0
                        "
                    >
                        <h2>Membres inscrits :</h2>
                        <omedom-family-share-card
                            *ngFor="
                                let sharing of family?.familyMembers;
                                let i = index
                            "
                            [share]="sharing"
                            [editable]="false"
                            (onRemove)="onRemoveShare($event)"
                        ></omedom-family-share-card>
                    </div>

                    <div *ngIf="family && family.invitations?.length">
                        <h2 class="margin-top-2">Invitations :</h2>
                        <omedom-family-share-card
                            *ngFor="
                                let sharing of family.invitations;
                                let i = index
                            "
                            (onRemove)="onRemoveInvitation($event)"
                            [share]="sharing"
                            [editable]="false"
                        ></omedom-family-share-card>
                    </div>
                </div>
                <ng-container
                    *ngIf="
                        !family ||
                            (family?.familyMembers?.length === 0 &&
                                family?.invitations?.length === 0);
                        else stickyAddShareButton
                    "
                >
                    <omedom-create-first-item-button
                        buttonIcon="uil-user"
                        message="Ajouter un premier membre"
                        (buttonClicked)="addShare()"
                    ></omedom-create-first-item-button>
                </ng-container>

                <ng-template #stickyAddShareButton>
                    <div
                        class="omedom-sticky-button-container w-100 top-100 margin-top-2"
                        *ngIf="true"
                    >
                        <button
                            class="omedom-button-primary flex-1"
                            (click)="addShare()"
                            [disabled]="
                                isAddMemberLoading ||
                                (family?.familyMembers)!.length +
                                    (family?.invitations)!.length >=
                                    4
                            "
                        >
                            <i class="uil uil-user"></i>
                            {{
                                (family?.familyMembers)!.length +
                                    (family?.invitations)!.length >=
                                4
                                    ? 'Famille complète'
                                    : 'Ajouter un membre'
                            }}
                        </button>
                    </div>
                </ng-template>
            </ng-container>
            <!-- END Family management view -->
        </ng-container>
    </ng-container>
}
