@if (isInTrial || isInEssential) {
    @if (!isInApp) {
        <div class="content margin-bottom-2">
            <i
                class="uil uil-stopwatch margin-right-1"
                [class.has-expired]="hasExpired"
            ></i
            >{{ content }}
        </div>
    } @else {
        <button
            class="omedom-icon-button-secondary-outline"
            [class.has-expired]="hasExpired"
        >
            <omedom-popover
                [content]="content"
                [withMargin]="false"
                [iconColor]="hasExpired ? 'red' : 'yellow'"
                [iconTop]="false"
                [withIcon]="true"
                icon="uil uil-stopwatch"
                [displayButton]="true"
                [redirectionButton]="redirectionButton"
            ></omedom-popover>
        </button>
    }
}
