<section class="informations-container">
    <div class="column" style="gap: 20px">
        @if (society?.photo) {
            <img
                [src]="society?.photo"
                class="informations-card-image"
                alt="Image du bien"
            />
        } @else {
            <div class="informations-card-image">
                <i class="uil uil-home"></i>
            </div>
        }
    </div>

    <div class="column">
        <h3 class="informations-card-title">Informations</h3>
        <omedom-society-info-details
            [society]="society"
        ></omedom-society-info-details>
    </div>

    <div class="column">
        <h3 class="informations-card-title">Membres</h3>
        @for (member of society?.members; track i; let i = $index) {
            <omedom-member-card
                [canEditMember]="false"
                [society]="society"
                [index]="i"
                [member]="member"
            ></omedom-member-card>
        }
    </div>
</section>
