@if (mode !== allMode.desktop) {
    <div class="title-container">
        @if (enableBack) {
            <i
                class="uil uil-angle-left back"
                [style.font-size.px]="titleFontSize"
                (click)="back()"
            ></i>
        } @else {
            @if (!isOmedomIcon) {
                <i
                    class="uil uil-{{ icon }}"
                    [style.font-size.px]="titleFontSize"
                ></i>
            }
            @if (isOmedomIcon) {
                <div class="container-icon">
                    @if (isOmedomIcon) {
                        <div
                            class="icon"
                            [style.mask]="iconMask"
                            [style.-webkit-mask]="iconMask"
                            [style.mask-size.px]="titleFontSize"
                        ></div>
                    }
                </div>
            }
        }
        <h1 [style.font-size.px]="titleFontSize">{{ title }}</h1>
    </div>
} @else if (mode === allMode.desktop && user) {
    <div class="title-container">
        <div
            class="account-picture-container margin-right-1"
            (click)="onProfileClick()"
        >
            @if (user?.avatar) {
                <img
                    class="account-picture"
                    [src]="user.avatar"
                    alt="image représentant l'avatar de l'utilisateur"
                />
            } @else {
                <div class="account-default-picture">
                    <i class="uil uil-user-circle"></i>
                </div>
            }
            <i class="uil uil-setting margin-left-1 settings-icon"></i>
        </div>
        <div class="name-valuation-container">
            <h2>
                {{ user.firstname }} {{ user.name
                }}<sup class="smart-icons-container">
                    @if ('accessCrownIcon' | isAuthorised | async) {
                        <i
                            class="omedom-icon-crown margin-left-1 smart-icon"
                        ></i>
                    }
                    @if ('accessFamilyIcon' | isAuthorised | async) {
                        <i
                            class="uil uil-users-alt margin-left-1 smart-icon"
                        ></i>
                    }
                </sup>
            </h2>
            @if ('accessGlobalAssetEstimation' | isAuthorised | async) {
                <div class="valuation-content color-light-black margin-top-1">
                    Valeur indicative de mon patrimoine : &nbsp;

                    <span class="bold">
                        {{
                            totalAssetValue
                                | currency: 'EUR' : 'symbol' : '1.0-0'
                        }}</span
                    >
                    <omedom-popover
                        class="margin-left-1"
                        [content]="globalAssetDetailMessage"
                        [withIcon]="true"
                    >
                    </omedom-popover>
                </div>
            } @else {
                <div class="valuation-content color-light-black margin-top-1">
                    <span> Valeur indicative de mon patrimoine : </span>
                    <div class="subscription-container">
                        <span class="bold blur"> 789000€</span>
                        <omedom-button-change-subscription
                            class="subscription-button"
                        ></omedom-button-change-subscription>
                    </div>
                    <omedom-popover
                        class="margin-left-1"
                        [content]="globalAssetDetailMessage"
                        [withIcon]="true"
                    >
                    </omedom-popover>
                </div>
            }
        </div>
    </div>
}

<ng-content
    class="headband-container"
    [class]="'headband-container-' + mode"
    select="[toolbar-headband]"
></ng-content>

<div class="actions-container" [class]="'actions-container-' + mode">
    <ng-content select="[toolbar-action]"></ng-content>
</div>
