<div class="conditions-container">
    <omedom-checkbox
        class="omedom-checkBox"
        [disabled]="!client"
        [(ngModel)]="hasAgreedToEditByPro"
    >
    </omedom-checkbox>
    <div>
        <span
            >J’accepte que mon pro modifie les informations d’un élément partagé
            et me partage des documents</span
        >
    </div>
</div>
