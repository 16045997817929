import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
    templateUrl: './subscription-success.component.html',
    styleUrls: ['./subscription-success.component.scss'],
})
export class SubscriptionSuccessComponent {
    constructor(
        private readonly modalController: ModalController,
        private readonly navController: NavController,
    ) { }

    protected async dismiss(): Promise<void> {
        await Promise.all([
            this.modalController.dismiss(),
            this.navController.navigateForward('/tabs/menu/subscription'),
        ]);
    }
}
