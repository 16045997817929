<div class="omedom-modal">
    <div class="omedom-modal-header">
        <i class="uil uil-times-circle" (click)="close()"></i>
    </div>

    <div class="omedom-modal-content" style="margin-bottom: 0">
        <div>
            <div class="smart-option-container">
                <omedom-action
                    class="margin-top-2 w-100"
                    [text]="message"
                    actionIcon="omedom-icon-crown"
                    (click)="close()"
                >
                </omedom-action>
                <button
                    class="omedom-button-primary w-100"
                    routerLink="/tabs/menu/subscription"
                    [queryParams]="{ tab: 'manage' }"
                    (click)="close()"
                >
                    Changer mon abonnement
                </button>
            </div>
        </div>
    </div>
</div>
