import { Component, OnInit } from '@angular/core';
import {
    DocumentEntity,
    fakeDocuments,
    TutorialVideoEntity,
    TutorialVideoName,
} from '@omedom/data';
import { TutorialVideoService } from '@omedom/services';

@Component({
    selector: 'omedom-document-list-fake-data',
    templateUrl: './document-list-fake-data.component.html',
    styleUrls: ['./document-list-fake-data.component.scss'],
})
export class DocumentListFakeDataComponent implements OnInit {
    fakeDocuments: DocumentEntity[] = fakeDocuments;

    documentTutorialVideo?: TutorialVideoEntity;

    constructor(private tutorialVideoService: TutorialVideoService) { }

    ngOnInit(): void {
        this.getTutorialVideo();
    }

    private async getTutorialVideo() {
        this.documentTutorialVideo = await this.tutorialVideoService.getYoutubeVideoByName(
            TutorialVideoName.document,
        );

    }
}
