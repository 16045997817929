/* eslint-disable @typescript-eslint/naming-convention */
import { RestEntity } from './rest.entity';
import { Timestamp } from 'firebase/firestore';
import { SubscriptionRank } from '../enums';
import { BillingInformation } from '../entities';

export class PaymentEntity extends RestEntity {
    amount!: number;
    billing!: BillingInterface;
    card!: PaymentCardInterface;
    description!: string;
    is_paid!: boolean;
    is_refunded!: boolean;
    paid_at!: Timestamp;
    payementID!: string;
    propertiesUID!: string[];
    recieptNumber!: number;
    receiptURL!: string;
    shipping!: ShippingInterface;
    subscriptionUID!: string;
    userUID!: string;
}

export interface PaymentInterface {
    allow_save_card?: boolean; // Alternative to save_card.true if the payment gave the possibility to a customer to save a card.On the payment page, saving a card was optional.
    amount: number; // Amount of the payment in cents.  Positive integer
    amount_refunded?: number; // (milliseconds)	Amount that has been refunded in cents. PositiveInteger or 0
    billing: BillingInterface
    card: PaymentCardInterface;
    created_at?: number; // (milliseconds) Creation date.
    currency?: string; // Currency code (three-letter ISO 4217) in which the payment was made https://en.wikipedia.org/wiki/ISO_4217
    description?: string; // Description shown to the customer.
    failure?: FailureInterface | null; // Information about the payment failure.
    hosted_payment?: HostedPaymentInterface; // Information about the payment.
    id?: string; // Payment ID.
    installment_plan_id?: string	// ID of the installment plan related to this payment, if any.
    integration?: string	// Must be set to ‘INTEGRATED_PAYMENT’ if you use IntegratedPayment.
    is_3ds?: boolean | null; //	true if the payment was processed using 3 - D Secure
    is_live?: boolean; // true for a payment in LIVE mode, false in TEST mode.
    is_paid?: boolean;
    is_refunded?: boolean; // 	true if this payment has been fully refunded, false if not
    metadata: PaymentMetadataInterface;
    notification: NotificationInterface; // Data related to notifications.
    object?: 'payment' | 'error'; //
    paid_at: number; // (milliseconds) Date at which the payment has been paid, null if the payment has not yet been paid.
    payment_method?: PaymentMethodInterface; // only for Oney and some other payment methods	Data about the payment method, only available for Oney payments or other Payment Methods.
    refundable_after?: number; // (milliseconds) Date after which the payment can be refunded, null if the payment has not yet been paid.
    refundable_until?: number; // (milliseconds) Date until which the payment can be refunded, null if the payment has not yet been paid.
    save_card?: boolean; // true if the payment was used to save a card. On the payment page, saving a card was mandatory.
    shipping: ShippingInterface;
}

export interface BillingInterface {
    address1: string;
    address2: string;
    city: string;
    company_name?: string | null;
    country: string;
    email: string;
    first_name?: string;
    landline_phone_number?: string;
    language: string;
    last_name?: string;
    mobile_phone_number: string;
    postcode: string;
    state: string;
    title?: title;
};

export interface ShippingInterface {
    address1: string;
    address2: string;
    city: string;
    company_name?: string | null;
    country: string;
    delivery_type: string;
    email: string;
    first_name?: string;
    landline_phone_number?: string;
    language: string;
    last_name?: string;
    mobile_phone_number: string;
    postcode: string;
    state: string;
    title?: title;
};

export interface PaymentCardInterface {
    brand: string;
    country: string;
    exp_month: number;
    exp_year: number;
    id: string;
    last4: number;
};

export interface PaymentMetadataInterface {
    billingInformations?: BillingInformation;
    rank?: SubscriptionRank;
    subscriptionType?: string;
    subscriptionUID?: string;
    updateSubscription?: updateSubscription;
    userUID?: string;
}

enum title {
    mrs = 'mrs',
    mr = 'mr',
    miss = 'miss',
    null = 'null',
}

interface updateSubscription {
    newAmount: number;
}

export interface HostedPaymentInterface {
    payment_url: string; // The payment URL you should redirect your customer to.string
    return_url: string; // The URL the customer will be redirected to after the payment page whether it succeeds or not.string
    cancel_url: string; // The URL the customer will redirected to after a click on ‘Cancel Payment’.string
    paid_at: Timestamp | number; // Date at which the payment goes through.null if the payment has yet to be paid or has failed.timestamp deprecated
    sent_by: string; // By what means the payment URL was sent to the customer, if any.string
}

interface NotificationInterface {
    url: string; // The URL PayPlug will send notifications to.
    response_code: number; // Integer http response code received when calling the URL of your notification page.
}

interface FailureInterface {
    code: string; // Payment failure code if the payment has failed.
    message: string; // Descriptive message explaining the reason of the payment failure.
}

interface PaymentMethodInterface {
    type: string // the type of payment made, one of: oney_x3_with_fees, oney_x4_with_fees, oney_x3_without_fees, oney_x4_without_fees, american_express, bancontact, giropay, ideal, mybank, satispay, sofort.
    is_pending: string // whether the payment is in a pending state for Oney.If this is true, it means that the payer has successfully filled out Oney’s payment form, but Oney is still analyzing the payer’s file.In this case, the payment is neither authorized nor paid yet, but in a pending state.If a notification_url is set, then the payment resource will be posted to it once Oney has made its decision.See Interpreting an Oney payment status.
}

export interface PayplugParametersInterface {
    amount: number; // positive integer	Amount in cents, for instance use 4207 for a 42.07€ payment.Currently, the minimum and maximum amounts are 99 and 2,000,000 cents.Can be different for alternative Payment Methods
    authorized_amount?: number; // positive integer	In order to create a deferred payment, use this field for the payment’s amount instead of amount.
    auto_capture?: boolean; //	In case of a deferred payment, automatically capture the payment after it has been authorized.
    currency: 'EUR' | string;// required	Currency code(three - letter ISO 4217).For now, Euro is the only currency supported(value: EUR).
    billing: BillingInterface; // required	Billing information of the payer.
    shipping: ShippingInterface; // required	Shipping information of the payer.
    payment_context?: PaymentContextInterface; // object optional	Payment context information, currently only required for Oney payments
    hosted_payment?: HostedPaymentInterface; // Object optional	All URLs must start with 'http://' or 'https://' and must be accessible from anywhere on the Internet(which is not the case with local environments).
    notification_url: string // optional	The URL PayPlug will send notifications to.
    payment_method?: string // optional	The Card ID of a saved card, or a specific payment method: oney_x3_with_fees, oney_x4_with_fees, oney_x3_without_fees, or oney_x4_without_fees for Oney payments, american_express, bancontact, giropay, ideal, mybank, satispay, sofort for other Payment Methods.
    initiator?: string // optional	Indicates who initiated the payment(values: PAYER, MERCHANT).Only required if a Card ID is provided.Read more at Pay with a saved card.
    force_3ds: boolean // optional	If true 3 - D Secure will be activated for the payment, else, PayPlug will decide whether it should be activated or not based on default configuration.
    save_card: boolean // optional	If true, card information will be saved, and a card ID will be returned once the payment has been done.You must provide a customer when this is set to true.
    allow_save_card?: boolean // optional	Alternative to save_card.If true, the customer will be able to save its card by checking a box on the payment page.A card ID will be returned once the payment has been done.You must provide a customer when this is set to true.
    description: string // optional	Description shown to the customer(maximum length: 80 characters).
    metadata?: PaymentMetadataInterface // object optional	Custom metadata object.
}

interface PaymentContextInterface {
    cart: CartInterface; // object required Items in the payer’s cart
}

interface CartInterface {
    brand: string; // Item’s brand.string required
    expected_delivery_date: string; // Expected delivery date in YYYY - MM - DD format.string required
    delivery_label: string; // Delivery label.string required
    delivery_type: string; // Delivery type(any of: storepickup, networkpickup, travelpickup, carrier, edelivery).string required
    merchant_item_id: string; // ID uniquely identifying this item in the cart.string required
    name: string; // Item’s name.string required
    price: number; // Unit price of the item in cents.positive integer required
    quantity: number; // Number of articles for the given item.positive integer required
    total_amount: number; // Total amount for this item in cents.positive integer required
}
