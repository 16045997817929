<h3>Bail</h3>

@if (typeLeaseOptions.length > 0) {
    <omedom-select
        [(ngModel)]="lease.leaseType"
        [placeholder]="typeLeasePlaceholder"
        label="Type de bail"
        class="margin-top-2"
        [required]="true"
        [options]="typeLeaseOptions"
    ></omedom-select>
}

<omedom-input
    [required]="true"
    class="margin-top-1"
    name="leaseStart"
    icon="uil uil-calendar-alt"
    label="Date de début"
    type="date"
    [(ngModel)]="lease.leaseStart"
    (ngModelChange)="checkTimePeriod()"
>
</omedom-input>

<omedom-input
    [required]="true"
    name="leaseEnd"
    class="margin-top-1"
    icon="uil uil-calendar-alt"
    label="Date de fin"
    type="date"
    [(ngModel)]="lease.leaseEnd"
    (ngModelChange)="checkTimePeriod()"
>
</omedom-input>

<omedom-input
    name="guarantyDeposit"
    class="margin-top-1"
    icon="uil uil-euro"
    label="Dépot de garantie"
    placeholder="0"
    type="number"
    [(ngModel)]="lease.guarantyDeposit"
>
</omedom-input>

@if (lease.fiduciary) {
    <hr class="omedom-divider" />

    <!-- Mandataire -->
    <h3>Mandataire</h3>
    <omedom-input
        name="fiduciaryName"
        [(ngModel)]="lease.fiduciary.name"
        class="margin-top-1"
        placeholder="Nom"
        icon="uil uil-user"
        label="Nom du mandataire"
    ></omedom-input>

    <omedom-input
        name="fiduciaryEmail"
        [(ngModel)]="lease.fiduciary.email"
        class="margin-top-1"
        [pattern]="emailPattern"
        placeholder="E-mail"
        icon="uil uil-envelope"
        label="Email du mandataire"
    ></omedom-input>

    <omedom-input
        name="fiduciaryPhone"
        [(ngModel)]="lease.fiduciary.tel"
        [pattern]="phonePattern"
        class="margin-top-1"
        placeholder="Téléphone"
        icon="uil uil-phone"
        label="Téléphone du mandataire"
    ></omedom-input>

    @if (lease.fiduciary.address) {
        <div class="account-split-field">
            <omedom-input
                class="margin-bottom-1"
                style="width: 180px"
                name="fiduciary-streetNumber"
                type="number"
                [(ngModel)]="lease.fiduciary.address.streetNumber"
                placeholder="N°"
                autocomplete="off"
                [debounce]="300"
                label="Adresse du mandataire"
            ></omedom-input>
            <omedom-input
                class="margin-bottom-1"
                style="width: 140px"
                name="fiduciary-suffixNumber"
                type="text"
                [(ngModel)]="lease.fiduciary.address.suffixNumber"
                placeholder="bis, ter..."
                autocomplete="off"
                [debounce]="300"
            ></omedom-input>
        </div>
        <omedom-input
            class="margin-bottom-1"
            name="fiduciary-street"
            type="text"
            [(ngModel)]="lease.fiduciary.address.street"
            placeholder="Rue"
            autocomplete="off"
            [debounce]="300"
            label=""
        ></omedom-input>
        <omedom-input
            class="margin-bottom-1"
            name="fiduciary-addressLine2"
            type="text"
            [(ngModel)]="lease.fiduciary.address.addressLine2"
            placeholder="Complément d'adresse"
            autocomplete="off"
            [debounce]="300"
            label=""
        ></omedom-input>
        <div class="account-split-field">
            <omedom-input
                name="fiduciary-postalCode"
                type="text"
                [(ngModel)]="lease.fiduciary.address.postalCode"
                placeholder="Code postal"
                autocomplete="off"
                [debounce]="300"
                [pattern]="postalCodeRegex"
            ></omedom-input>
            <omedom-input
                name="fiduciary-city"
                type="text"
                [(ngModel)]="lease.fiduciary.address.city"
                autocomplete="off"
                placeholder="Ville"
                [debounce]="300"
            ></omedom-input>
        </div>
    }
}
