<div class="omedom-modal">
    <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
            <i
                class="uil uil-check-circle color-light-black background-color-light-green"
            ></i>
            <h3>Votre paiement a bien été effectué.</h3>
        </div>
        <i class="uil uil-times-circle" (click)="dismiss()"></i>
    </div>
    <div class="omedom-modal-content">
        <p>
            Merci pour votre achat. Vous pouvez maintenant profiter de votre
            abonnement.
        </p>
    </div>
    <div class="omedom-modal-buttons-wide">
        <button class="omedom-button-primary w-100" (click)="dismiss()">
            OK
        </button>
    </div>
</div>
