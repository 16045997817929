<h1 class="signin-title">Heureux de vous revoir !</h1>

<form class="signin-form" (ngSubmit)="submit()" [formGroup]="signinForm">
    <div class="signin-providers-container">
        <button
            class="gsi-material-button"
            [class]="'gsi-material-button-' + mode"
            type="button"
            (click)="loginGoogle()"
        >
            <div class="gsi-material-button-state"></div>
            <div class="gsi-material-button-content-wrapper">
                <span class="gsi-material-button-contents"
                    >Connectez-vous avec Google</span
                >
                <span style="display: none">Connectez-vous avec Google</span>
                <div class="gsi-material-button-icon">
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        style="display: block"
                    >
                        <path
                            fill="#EA4335"
                            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                        ></path>
                        <path
                            fill="#4285F4"
                            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                        ></path>
                        <path
                            fill="#FBBC05"
                            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                        ></path>
                        <path
                            fill="#34A853"
                            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                        ></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                </div>
            </div>
        </button>

        @if (isAppleSignInAvailable) {
            <button
                class="signin-button-apple"
                [class]="'signin-button-apple-' + mode"
                expand="full"
                type="button"
                (click)="loginApple()"
            >
                <span>Connectez-vous avec Apple</span>
                <ion-icon
                    style="font-size: 25px"
                    name="logo-apple"
                    slot="start"
                ></ion-icon>
            </button>
        }

        <!-- <button
      class="signin-button-facebook"
      expand="full"
      type="button"
      (click)="loginFacebook()"
      >
      <span>Connectez-vous avec Facebook</span>
      <ion-icon
        style="font-size: 25px"
        name="logo-facebook"
        slot="start"
      ></ion-icon>
    </button> -->
    </div>

    <div class="text-center">Ou</div>
    <omedom-input
        [mode]="mode"
        name="email"
        type="email"
        icon="uil-envelope"
        placeholder="E-mail"
        formControlName="email"
        autocomplete="on"
    ></omedom-input>

    <omedom-input
        [mode]="mode"
        name="password"
        type="password"
        icon="uil-padlock"
        [enableShowPassword]="true"
        placeholder="Mot de passe"
        formControlName="password"
    ></omedom-input>

    <a class="signin-link" routerLink="/user/missing-password"
        >Mot de passe oublié ?</a
    >

    <button
        class="omedom-button-primary w-100"
        [class]="'omedom-button-' + mode"
        type="submit"
        [disabled]="signinForm.invalid || pending"
    >
        <i class="uil uil-signin signin-icon"></i>
        Se connecter
    </button>
</form>
