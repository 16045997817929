import { Component, Input, OnInit } from '@angular/core';
import { Confidence, ConfidenceFr, ValuationInfo } from '@omedom/data';
import { OmedomPrice, OmedomProperty } from '@omedom/utils';

@Component({
    selector: 'omedom-valuation-rent-estimation',
    templateUrl: './valuation-rent-estimation.component.html',
    styleUrls: ['./valuation-rent-estimation.component.scss'],
})
export class ValuationRentEstimationComponent implements OnInit {
    @Input() rentValuation?: ValuationInfo;

    @Input() propertySurface?: number;

    lastValuationUpdate?: string;

    meterSquarePriceAvg?: number;
    meterSquarePriceLower?: number;
    meterSquarePriceUpper?: number;

    valuationConfidence: undefined | ConfidenceFr;
    valuationConfidenceColor? = '';

    constructor() {}

    ngOnInit() {
        if (!this.rentValuation) {
            return;
        }
        this.setLastUpdate(this.rentValuation.valuationDate);
        this.setMeterSquarePrice();
        this.setConfidence(this.rentValuation.valuationConfidence);
    }

    private setLastUpdate(lastValUpdate: string) {
        const parts = lastValUpdate.split('-');
        this.lastValuationUpdate = parts[2] + '-' + parts[1] + '-' + parts[0];
    }

    setConfidence(valuationConfidence: Confidence | undefined) {
        if (!valuationConfidence) {
            return;
        }

        this.valuationConfidence = OmedomProperty.adaptConfidence(valuationConfidence);
        this.valuationConfidenceColor =
            OmedomProperty.adaptConfidenceTextColor(valuationConfidence);
    }

    private setMeterSquarePrice() {
        if (!this.propertySurface || !this.rentValuation) {
            return;
        }

        this.meterSquarePriceAvg = OmedomPrice.calcMeterSquarePrice(
            this.rentValuation.value,
            this.propertySurface,
        );
        this.meterSquarePriceLower = OmedomPrice.calcMeterSquarePrice(
            this.rentValuation.valueRange.lower,
            this.propertySurface,
        );
        this.meterSquarePriceUpper = OmedomPrice.calcMeterSquarePrice(
            this.rentValuation.valueRange.upper,
            this.propertySurface,
        );
    }
}
