<omedom-stepper
    #stepper
    [steps]="steps"
    [(selectedStep)]="selectedStep"
    [loading]="(_state | async) === 'pending' || (_pending | async) === true"
    (onSave)="submit()"
    (selectedStepChange)="onSelectedStepChange($event)"
>
    @switch (selectedStep.id) {
        @case (allSteps.userForm) {
            <h2 class="form-title margin-bottom-1">
                {{ 'Ajouter un tiers' }}
            </h2>

            @if (shareForm) {
                <form class="form" [formGroup]="shareForm">
                    <omedom-input
                        name="firstname"
                        icon="uil-user-circle"
                        label="Prénom"
                        placeholder="Prénom"
                        [required]="true"
                        formControlName="firstname"
                    ></omedom-input>
                    <omedom-input
                        name="name"
                        icon="uil-user-circle"
                        label="Nom"
                        placeholder="Nom"
                        [required]="true"
                        formControlName="name"
                    ></omedom-input>

                    <omedom-input
                        name="firstname"
                        icon="uil-envelope"
                        label="Email"
                        type="email"
                        placeholder="E-mail"
                        [required]="true"
                        [pattern]="emailRegex"
                        formControlName="email"
                    ></omedom-input>
                </form>
            }
        }

        @case (allSteps.assetSelection) {
            <h2 class="form-title margin-bottom-1">
                {{ 'Sélection des biens' }}
            </h2>
            <omedom-info
                information="1. Sélectionnez la ou les cartes à partager.<br/>
                2. Vous pouvez modifier le rôle donné au tiers (Lecteur ou Editeur) sur le bandeau de la carte."
                class="margin-bottom-1"
            ></omedom-info>
            <div class="asset-card-list-container">
                @for (assetItem of assetItems; track $index) {
                    @if (
                        assetItem.type !== assetTypes.society ||
                        ('accessManageSociety' | isAuthorised | async)
                    ) {
                        <div class="asset-card-container">
                            @if (
                                assetItem.type === assetTypes.property ||
                                assetItem.type === assetTypes.building
                            ) {
                                <omedom-property-card-selectable
                                    [property]="$any(assetItem.asset)"
                                    [selected]="assetItem.selected"
                                    (selectedChange)="
                                        onSelectedChange(assetItem, $event)
                                    "
                                ></omedom-property-card-selectable>
                            } @else if (assetItem.type === assetTypes.society) {
                                <omedom-society-card-selectable
                                    [society]="$any(assetItem.asset)"
                                    [selected]="assetItem.selected"
                                    (selectedChange)="
                                        onSelectedChange(assetItem, $event)
                                    "
                                ></omedom-society-card-selectable>
                            }
                            @if (assetItem.selected) {
                                <div class="role-container">
                                    @if (getRoleIndex(assetItem.role) > 0) {
                                        <div
                                            class="previous-role"
                                            (click)="
                                                changeRole(
                                                    assetItem,
                                                    'previous'
                                                )
                                            "
                                        >
                                            <i class="uil-arrow-left"></i>
                                        </div>
                                    }
                                    <div
                                        class="role-list"
                                        [style]="{
                                            transform:
                                                'translateX(-' +
                                                getRoleIndex(assetItem.role) *
                                                    100 +
                                                '%)'
                                        }"
                                    >
                                        @for (
                                            roleOption of roleOptions;
                                            track $index
                                        ) {
                                            <div class="role-item">
                                                <div class="role-title">
                                                    <i
                                                        class="{{
                                                            roleOption.icon
                                                        }}"
                                                    ></i>
                                                    <span>{{
                                                        roleOption.label
                                                    }}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    @if (
                                        getRoleIndex(assetItem.role) + 1 <
                                        roleOptions.length
                                    ) {
                                        <div
                                            class="next-role"
                                            (click)="
                                                changeRole(assetItem, 'next')
                                            "
                                        >
                                            <i class="uil-arrow-right"></i>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                } @empty {
                    <div class="empty-asset">Aucun bien à partager</div>
                }
            </div>
        }
    }
</omedom-stepper>
