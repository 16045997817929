<div class="container padding-1">
    <div class="top margin-bottom-1">
        <i [class]="'uil uil-' + icon" class="margin-right-1"></i>
        <span>{{ title }}</span>
    </div>
    <div class="bottom">
        <div class="profit-container">
            @if (isProfit) {
                <div class="profit">
                    {{ value !== 0 ? '+' : '' }}{{ value | currency: 'EUR' }}
                </div>
            } @else {
                <div class="dpe-profit">
                    {{ value | number }} <span class="unit">{{ unit }}</span>
                </div>
            }
        </div>
        @if (!value || value === 0) {
            <div class="no-profit improvment">
                {{ value | currency: 'EUR' }}
            </div>
        } @else {
            <div class="percentage improvment">
                <i [class]="'uil uil-arrow-' + (isProfit ? 'up' : 'down')"></i
                >{{ percentage / 100 | percent: '1.0-2' }}
            </div>
        }
    </div>
</div>
