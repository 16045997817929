@for (tab of subTabs; track tab.id; let i = $index) {
    <div
        (click)="subTabClicked(tab)"
        class="omedom-sub-tab"
        [class]="'omedom-sub-tab-' + mode"
        [class.selected]="selectedSubTab?.id === tab.id"
        [class.disabled]="tab.disabled"
        [class.selected-center]="
            selectedSubTab?.id === tab.id &&
            i > 0 &&
            subTabs.length > 1 &&
            i < subTabs.length - 1
        "
        [class.selected-left]="
            selectedSubTab?.id === tab.id && i === 0 && subTabs.length > 1
        "
        [class.selected-right]="
            selectedSubTab?.id === tab.id &&
            i === subTabs.length - 1 &&
            subTabs.length > 1
        "
    >
        <div
            class="omedom-sub-tab-content"
            [style.margin-left.px]="i > 0 && subTabs.length > 1 ? 5 : 0"
            [style.margin-right.px]="
                i < subTabs.length - 1 && subTabs.length > 1 ? 5 : 0
            "
        >
            @if (tab.icon) {
                <i class="omedom-sub-tab-content-icon" [class]="tab.icon"></i>
            }
            @if (tab.label) {
                <p class="omedom-sub-tab-content-label">{{ tab.label }}</p>
            }
            @if (tab.popover) {
                <omedom-popover [content]="tab.popover"></omedom-popover>
            }
        </div>
    </div>
}
