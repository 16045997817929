import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IncomeEntity, PropertyEntity, PropertyType, RentabilityTypeEnum, UseProperty } from '@omedom/data';
import { PropertyService, UserService } from '@omedom/services';
import { OmedomRentability } from '@omedom/utils';

import { ChooseAvatarTypeComponent } from '../../choose-avatar-type/choose-avatar-type.component';

@Component({
    selector: 'omedom-property-info-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
})
export class PropertyInfoDetailsComponent implements OnInit, OnChanges {
    /**
     * @description Property to display in the details
     * @author Brisset Killian
     * @date 06/06/2024
     * @type {PropertyEntity}
     * @memberof PropertyInfoDetailsComponent
     */
    @Input() property?: PropertyEntity;

    /**
     * @description Edit mode of the component, if true, the user can edit the notes of the property, if false, the user can't edit the notes of the property
     * @author Brisset Killian
     * @date 06/06/2024
     * @memberof PropertyInfoDetailsComponent
     */
    @Input() edit = false;

    /**
     * @description Incomes of the property, to display rentability in the details of the property
     * @author Brisset Killian
     * @date 06/06/2024
     * @type {IncomeEntity[]}
     * @memberof PropertyInfoDetailsComponent
     */
    @Input() incomes: IncomeEntity[] = [];

    /**
     * @description Rentability type enum to display the rentability of the property in the details of the property
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 28/10/2024
     * @memberof PropertyInfoDetailsComponent
     */
    rentabilityTypeEnum = RentabilityTypeEnum;

    /**
     * @description Uses of the property, to display in the details of the property
     * @author Brisset Killian
     * @date 06/06/2024
     * @memberof PropertyInfoDetailsComponent
     */
    uses = UseProperty;

    /**
     * @description Current date to calculate the rentability of the property
     * @author Brisset Killian
     * @date 06/06/2024
     * @memberof PropertyInfoDetailsComponent
     */
    date = new Date().toUTC();

    editable = false;

    /**
     * @description Notes of the property, to display in the details of the property
     * and to edit if the user is in edit mode of the component
     * @author Brisset Killian
     * @date 06/06/2024
     * @type {string}
     * @memberof PropertyInfoDetailsComponent
     */
    notes: string = '';

    get isBuilding(): boolean {
        return this.property?.type === PropertyType.immeuble;
    }

    constructor(
        private userService: UserService,
        private propertyService: PropertyService,
        private modalController: ModalController,
    ) {}

    async ngOnInit() {
        this.notes = this.property?.notes || '';
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['property']) {
            this.property = changes['property'].currentValue;
        }
        if (changes['incomes']) {
            this.incomes = changes['incomes'].currentValue;
        }
    }

    get rentability(): number {
        if (!this.property || !this.incomes) {
            return 0;
        }

        const rentability = OmedomRentability.getPropertyGrossRentability(
            this.property,
            this.incomes,
            this.date.getLastDayOfMonth(),
        );

        return rentability;
    }

    editNote() {
        if (this.edit) {
            this.editable = true;
        }
    }

    cancelNoteEdit(): void {
        this.editable = false;
        this.notes = this.property?.notes || '';
    }

    async saveNote(): Promise<void> {
        this.editable = false;
        if (this.property) {
            this.property.notes = this.notes || '';
            await this.propertyService.update(this.property);
        }
    }

    async deleteNote(): Promise<void> {
        this.notes = '';
    }

    /**
     * @description Edit photo of the property to select a photo or a pictogram
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @memberof PropertyInfoDetailsComponent
     */
    public async editAvatar(): Promise<void> {
        // open a modal to select a photo or a pictogram
        if (this.edit) {
            const modal = await this.modalController.create({
                component: ChooseAvatarTypeComponent,
                breakpoints: [0, 1],
                initialBreakpoint: 1,
                componentProps: {
                    property: this.property,
                },
            });
            await modal.present();
        }
    }
}
