import { Injectable } from '@angular/core';
import { BankAccountEntity, BankAccountType, LoanEntity, PropertyEntity, SavingEntity } from '@omedom/data';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { BankAccountService, LoanService, PropertyService, SavingService, UserService } from '../core';

@Injectable({
    providedIn: 'root',
})
export class AssetCalculationService {
    constructor(
        private userService: UserService,
        private propertyService: PropertyService,
        private bankAccountService: BankAccountService,
        private loanService: LoanService,
        private savingService: SavingService,
    ) {}

    public getUserTotalAssets(): Observable<{
        globalAssetsValue: number;
        totalBankAccount: number;
        totalUserManualLoans: number;
        totalUserManualSavings: number;
    }> {
        return this.userService.user$.pipe(
            filter((user) => !!user),
            switchMap((user) => {
                return combineLatest([
                    this.propertyService.getUserProperties(user.uid),
                    this.bankAccountService._getUserBankAccounts(user.uid),
                    this.loanService._getLoansManualFromUser(user.uid),
                    this.savingService._getSavingsManualFromUser(user.uid),
                ]);
            }),
            map(([userProperties, bankAccounts, userManualLoans, userManualSavings]) => {
                const globalAssetsValue = this.calculPropertiesTotalValue(userProperties);
                const totalBankAccount = this.calculBankGlobalValue(bankAccounts);
                const totalUserManualLoans =
                    this.calculUserManualLoansGloabalValue(userManualLoans);
                const totalUserManualSavings =
                    this.calculUserManualSavingGloabalValue(userManualSavings);
                return {
                    globalAssetsValue,
                    totalBankAccount,
                    totalUserManualLoans,
                    totalUserManualSavings,
                };
            }),
        );
    }

    private calculPropertiesTotalValue(properties: PropertyEntity[]) {
        if (!properties) {
            return 0;
        }
        const globalPropertiesValue = properties.reduce((totalValue: number, property) => {
            const marketValue = property.purchaseDetails?.marketValue;
            const estimationValue = property.valuation?.valuationSale?.value;
            const purchasePrice = property.purchaseDetails?.price;
            const propertyValue = marketValue || estimationValue || purchasePrice || 0;
            return (totalValue += propertyValue);
        }, 0);
        return globalPropertiesValue;
    }

    private calculBankGlobalValue(bankAccounts: BankAccountEntity[]) {
        if (!bankAccounts) {
            return 0;
        }
        const globalBankValue = bankAccounts
            .filter((account) => !account.isMasked)
            .reduce((totalValue: number, bankAccount) => {
                if (bankAccount.type === BankAccountType.Loan) {
                    return (totalValue -= bankAccount.balance);
                }
                return (totalValue += bankAccount.balance);
            }, 0);
        return globalBankValue;
    }

    private calculUserManualLoansGloabalValue(userManualLoans: LoanEntity[]) {
        if (!userManualLoans) {
            return 0;
        }
        const userLoanTotal = userManualLoans.reduce((totalValue: number, manualLoan) => {
            if (!manualLoan?.remainingCapital) {
                return totalValue;
            }
            return (totalValue += manualLoan.remainingCapital);
        }, 0);
        return userLoanTotal;
    }

    private calculUserManualSavingGloabalValue(userManualSaving: SavingEntity[]) {
        if (!userManualSaving) {
            return 0;
        }
        const userLoanTotal = userManualSaving.reduce((totalValue: number, manualSaving) => {
            if (!manualSaving?.balance) {
                return totalValue;
            }
            return (totalValue += manualSaving.balance);
        }, 0);
        return userLoanTotal;
    }
}
