import { Component, Input } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular';
import { map, Observable } from 'rxjs';

import { elementAnimation } from '../../animations';
import { MaintenanceEnum, OmedomIsMaintenancePipe } from '../../pipes';

@Component({
    selector: 'omedom-take-a-meet',
    templateUrl: './take-a-meet.component.html',
    styleUrls: ['./take-a-meet.component.scss'],
    animations: [elementAnimation],
})
export class TakeAMeetComponent {
    /**
     * @description Text to display on the button
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 01/04/2025
     * @type {string}
     * @memberof TakeAMeetComponent
     */
    @Input()
    public text: string = 'Nouveau !';

    /**
     * @description If the component is open or not
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 31/03/2025
     * @type {boolean}
     * @memberof TakeAMeetComponent
     */
    public isOpen: boolean = false;

    /**
     * @description Redirect link to the calendar
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 31/03/2025
     * @type {string}
     * @memberof TakeAMeetComponent
     */
    public calendarUrl: string = 'https://calendly.com/amelia-ghrifa-omedom';

    /**
     * @description Observable to check if the take-a-meet feature is in maintenance mode
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 31/03/2025
     * @type {Observable<boolean>}
     * @memberof TakeAMeetComponent
     */
    public isMaintenance$: Observable<boolean> = this.omedomIsMaintenancePipe
        .transform('take-a-meet')
        .pipe(
            map((maintenance) => {
                return maintenance === MaintenanceEnum.MAINTENANCE;
            }),
        );

    /**
     * Creates an instance of TakeAMeetComponent.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 31/03/2025
     * @param {OmedomIsMaintenancePipe} omedomIsMaintenancePipe
     * @memberof TakeAMeetComponent
     */
    constructor(
        private readonly omedomIsMaintenancePipe: OmedomIsMaintenancePipe,
        private readonly platform: Platform,
    ) {}

    /**
     * @description function to toggle the component
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 31/03/2025
     * @memberof TakeAMeetComponent
     */
    toggle() {
        this.isOpen = !this.isOpen;
    }

    /**
     * @description function to redirect to the calendar
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 31/03/2025
     * @memberof TakeAMeetComponent
     */
    async goToCalendar() {
        if (this.platform.is('capacitor')) {
            try {
                await Browser.open({ url: this.calendarUrl });
            } catch (error) {
                console.error('Failed to open the calendar URL in Capacitor:', error);
            }
        } else {
            window.open(this.calendarUrl, '_blank');
        }
    }
}
