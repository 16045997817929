// TODO: Dans le backoffice mettre en place une interface gestion des templates en déplaçant cet enum en BDD
export enum BrevosTemplatesIds {
    accountDeletionAsked = 37, // Demande de suppression de compte
    subscriptionConfirmedWithReceipt = 40, // Confirmation d'abonnement avec reçu facture
    subscriptionAborted = 41, // Abonnement annulé relance
    emailVerification = 45, // Vérification de l'adresse e-mail pour validation de compte
    endSharingPropertyOrSociety = 48, // Fin de partage d'un bien ou d'une société
    sharingASocietyToMember = 49, // Partage d'une société à un membre
    specialOfferActivated = 58, // Offre spéciale activée
    trialEndsIn7Days = 70, // Fin de période d'essai dans 7 jours
    trialEndsIn1Day = 71, // Fin de période d'essai dans 7 jours
    sharedProrpertyOrSocietyReceived = 78, // Destinataire qui reçoit un bien partagé
    sendRentReceipt = 86, // Quittance
    sendRentRelaunch = 87, // Relance de loyer impayé
    trialEnd3daysAgo = 107, // J+3 fin de période d'essai
    trialEnd15daysAgo = 109, // J+15 fin de période d'essai
    trialEnd1MonthAgo = 110, // M+1 fin de période d'essai
    welcomeClientProInvitation = 117, // Bienvenue au client invité par un pro
    codeProForClient = 118, // Code pro à destination d'un client de pro
    welcomeNewProNotUser = 144, // Bienvenue nouveau pro
    welcomeNewProAsUserTo = 147, // Bienvenue nouveau pro ayant déjà un compte B2C
    sharedPropertyChangeAcces = 148, // Changement d'accès à un bien partagé
    proLicenceForClient = 176, // Le client reçoit une licence d'un professionnel
    trialEndsIn3Days = 179, // La période d'essai se termine dans 3 jours
    trialEndsIn1month = 180, // La période d'essai se termine dans 1 mois
    trialEndsIn1DayTrial15Days = 181, // La période d'essai se termine dans 1 jour
    pricingChangesIn7Days = 183, // Changement de tarif dans 7 jours
    pricingChangesInToday = 184, // Changement de tarif aujourd'hui
    twoFactorsAuthencationCode = 197, // Message avec le code 2FA
    resetPassword = 206, // Lien vers la page de réinitialisation du mot de passe
    famillySubscription = 208, // Le chef de famille prend l'abonnement famille
    famillyInviteMember = 209, // Le membre reçoit l'invitation famille
    famillyMemberAcceptInformHost = 210, // Le membre accepte l'invitation
    famillyMemberConfirm = 211, // Le membre reçoit la confirmation d'activation
    famillyHostEndsSubscription = 212, // Le chef de famille met fin à l'abonnement famille
    famillyMemberRevoked = 213, // Le membre reçoit un mail pour lui dire qu'il a été révoqué
    famillyHostRevokeAMember = 214, // Le chef de famille reçoit la confirmation de révocation d'un membre
    famillyHostConfirmSubscriptionEnds = 215, // Le chef de famille reçoit la confirmation de la fin de l'abonnement
    famillyGuestSubscriptionEnds = 216, // Le membre reçoit la confirmation de la fin de l'abonnement
    famillyGuestRefuseFamilly = 218, // L'invité refuse l'invitation famille
    proClientOpinion = 238, // Avis d'un client à un Pro
    proReceiveMessageFromDirectory = 239, // Un pro reçoit un message depuis l'annuaire pro
    proReceiveAnAlert = 240, // Un pro reçoit une alerte de son espace pro
    proIsInProDirectory = 242, // Le professionnel est visible dans l'annuaire pro
    proReceiveChatMessage = 243, // Un pro reçoit un message de son client
    trialEndsIn1DayTrial7Days = 292, // La période d'essai se termine dans 1 jour
    referralAdded = 297, // Un utilisateur à ajouter un parain et reçoit un mail
    inviteGodChild = 296, // Un utilisateur à invité un filleul
    referredValid = 298, // Un filleul à pris un abonnement annuel et reçoit un mail
    referralValid = 299, // Un filleul à pris un abonnement annuel et le parrain reçoit un mail
    withdrawReferralBalance = 301, // Un parrain à retiré son solde de parrainage
    test = 305, // Test
}
