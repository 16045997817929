import { formatDate } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { IonModal, ModalController, Platform, ToastController } from '@ionic/angular';
import {
    IncomeCategoryProperty,
    IncomeEntity,
    IncomePeriodicity,
    IncomePeriodicityInfo,
    LeaseEntity,
    Mode,
    PropertyEntity,
    RoleState,
    TenantEntity,
    UserEntity,
} from '@omedom/data';
import {
    AnalyticsService,
    IncomeService,
    LeaseService,
    TenantService,
    UserService,
} from '@omedom/services';
import { combineLatest, of, Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { elementAnimation } from '../../../animations';
import { TenantFormModalComponent } from '../../tenant-form/tenant-form-modal.component';

@Component({
    selector: 'omedom-property-tenant',
    templateUrl: './property-tenant.component.html',
    styleUrls: ['./property-tenant.component.scss'],
    animations: [elementAnimation],
})
export class PropertyTenantComponent implements OnInit, OnChanges, OnDestroy {
    @Input({ required: true })
    public property?: PropertyEntity;
    @Input() editable: boolean = true;
    @Input() roleState?: RoleState;

    @Input() showLeaseInfo: boolean = true;

    @Input() showTenantsInfo: boolean = true;

    @Input() showRentState: boolean = true;

    @Input() canAddAid: boolean = true;

    /**
     * @description If true, the app is in desktop mode
     * @author Jérémie Lopez
     * @readonly
     * @type {boolean}
     * @memberof PropertyTenantComponent
     */
    public get isDesktop(): boolean {
        return this.platform.is('desktop');
    }

    @Input()
    public mode: Mode = Mode.app;

    allModes = Mode;

    @ViewChild('removeTenantModal', { static: true })
    modalRemoveTenant?: IonModal;

    @Output()
    addLeaseEvent = new EventEmitter<{
        property: PropertyEntity;
        lease: LeaseEntity;
    }>();

    @Output()
    editLeaseEvent = new EventEmitter<{
        property: PropertyEntity;
        lease: LeaseEntity;
        tenants: TenantEntity[];
    }>();

    @Output()
    addRentEvent = new EventEmitter<{
        lease: LeaseEntity;
        income: IncomeCategoryProperty;
    }>();

    @Output()
    addAidEvent = new EventEmitter<{
        lease: LeaseEntity;
        income: IncomeCategoryProperty;
        tenant: string;
    }>();

    tenantIndexToRemove: number | null = null;

    tenants: TenantEntity[] = [];
    lease: LeaseEntity | null = null;
    rents: IncomeEntity[] = [];
    leaseRent?: IncomeEntity;

    currentIndex = 0;

    public aid: IncomeEntity[] = [];
    public tenantAids: any[] = [];

    currentTenant?: Partial<TenantEntity>;
    tenantEdit?: Partial<TenantEntity>;

    edit?: boolean;

    leasePeriodicityLabel?: string;
    leaseDebitDate?: string;
    afterAddRent?: boolean;
    user?: UserEntity;
    subscriptions: Subscription[] = [];
    constructor(
        private tenantService: TenantService,
        private leaseService: LeaseService,
        private incomeService: IncomeService,
        private router: Router,
        private toast: ToastController,
        private modalController: ModalController,
        private platform: Platform,
        private userService: UserService,
        private analyticsService: AnalyticsService
    ) { }

    ngOnInit() {
        this.refreshData();

        const user$ = this.userService.user$
            .pipe(
                filter((user) => !!user),
                tap((user) => (this.user = user)),
                switchMap(() => {
                    if (this.property) {
                        const lease$ = this.leaseService._getLeaseByProperty(this.property.uid);
                        return lease$;
                    }
                    return of(null);
                })
            )
            .subscribe((lease) => {
                if (lease) {
                    let leaseEnd: Date;
                    let leaseStart: Date;

                    try {
                        leaseEnd = (lease.leaseEnd as any).toDate();
                    } catch {
                        leaseEnd = lease.leaseEnd ? new Date(lease.leaseEnd) : new Date();
                    }

                    try {
                        leaseStart = (lease.leaseStart as any).toDate();
                    } catch {
                        leaseStart = lease.leaseStart ? new Date(lease.leaseStart) : new Date();
                    }
                    this.lease = {
                        ...lease,
                        leaseStart,
                        leaseEnd,
                    } as LeaseEntity;
                    this.refreshData();
                } else {
                    this.lease = null;
                }
            });

        this.subscriptions.push(user$);
    }

    ionViewDidEnter(): void {
        this.analyticsService.setCurrentScreen('Tenant tab');
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    ionViewWillLeave() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['property']) {
            this.property = changes['property'].currentValue;
            await this.refreshData();
        }
    }

    public addLease() {
        if (!this.property) {
            return;
        }
        this.addLeaseEvent.emit({
            property: this.property,
            lease: {
                propertyUID: this.property.uid,
            } as LeaseEntity,
        });
    }
    public editLease() {
        if (!this.property || !this.lease) {
            return;
        }
        this.editLeaseEvent.emit({
            property: this.property,
            lease: this.lease,
            tenants: this.tenants ?? [],
        });
    }
    public async openModal(index?: number): Promise<void> {
        if (!this.edit) {
            this.tenantEdit = {};
            this.tenantEdit.warrantor = {
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
            };
        }
        const modal = await this.modalController.create({
            component: TenantFormModalComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: { lease: this.lease, tenant: this.tenantEdit },
        });

        await modal.present();

        modal.onDidDismiss().then((x) => {
            if (x.data?.state === 'saved' && x.data?.tenant) {
                this.currentTenant = x.data?.tenant;
                this.saveTenant();
            } else {
                this.edit = false;
            }
        });
    }
    public async saveTenant(): Promise<void> {
        if (!this.currentTenant || !this.lease) {
            return;
        }

        const tenantAid = this.aid.find((aid) => aid.uid === this.currentTenant?.helpUID);
        const aidPeriodicity = tenantAid
            ? new IncomePeriodicityInfo(tenantAid.periodicity).label
            : null;
        const aidDebitDate = tenantAid ? tenantAid.debitDate?.toDate().getDate() : null;
        const aidAmount = tenantAid ? tenantAid.amount : null;
        if (!this.edit) {
            this.tenantAids.push({
                tenantUID: this.currentTenant,
                periodicity: aidPeriodicity,
                date: aidDebitDate,
                amount: Math.abs(aidAmount ?? 0),
            });

            this.currentTenant.leaseUID = this.lease.uid;
            try {
                await this.tenantService.create(this.currentTenant);
            } catch (error) {
                console.error(error);
                const toast = await this.toast.create({
                    position: 'top',
                    color: 'danger',
                    duration: 4000,
                    message: "Une erreur s'est produite, veuillez réessayer plus tard.",
                });
                await toast.present();
            }
        } else {
            this.currentTenant.leaseUID = this.lease?.uid;
            try {
                await this.tenantService.update(this.currentTenant);
            } catch (error) {
                console.error(error);
                const toast = await this.toast.create({
                    position: 'top',
                    color: 'danger',
                    duration: 4000,
                    message: "Une erreur s'est produite, veuillez réessayer plus tard.",
                });
                await toast.present();
            }
            const index = this.tenantAids.findIndex((x) => x.tenantUID === this.currentTenant?.uid);
            this.tenantAids[index] = {
                tenantUID: this.currentTenant,
                periodicity: aidPeriodicity,
                date: aidDebitDate,
                amount: Math.abs(aidAmount ?? 0),
            };
        }
        this.edit = false;
    }
    public async removeTenant(index?: number): Promise<void> {
        let tenantIndex: number | null = null;
        if (index) {
            tenantIndex = index;
        } else {
            if (this.tenantIndexToRemove != null) {
                tenantIndex = this.tenantIndexToRemove;
            }
        }

        if (tenantIndex == null) {
            return;
        }
        try {
            this.modalRemoveTenant?.dismiss();
            if (tenantIndex === this.currentIndex && this.currentIndex !== 0) {
                this.prevSlide();
            }
            await this.tenantService.delete(this.tenants[tenantIndex].uid);

            const toast = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: 'Vous avez supprimé un locataire.',
            });
            await toast.present();
        } catch (error) {
            console.error(error);
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
        }
        this.tenantIndexToRemove = null;
    }

    public async openRemoveTenantModal(index: number) {
        this.tenantIndexToRemove = index;
        this.modalRemoveTenant?.present();
    }

    public editTenant(index: number): void {
        this.currentTenant = this.tenants[index];
        this.tenantEdit = { ...this.currentTenant };
        this.edit = true;
        this.openModal(index);
    }

    async deleteConfirmed(modal: IonModal): Promise<void> {
        if (!this.lease) {
            return;
        }

        for (const tenant of this.tenants) {
            await this.tenantService.delete(tenant.uid);
        }
        await this.leaseService.delete(this.lease.uid);

        // TODO faire la suppression des documents quand on aura les documents
        await modal.dismiss();
    }
    public async addRent(): Promise<void> {
        if (!this.lease) {
            return;
        }
        this.addRentEvent.emit({
            lease: this.lease,
            income: IncomeCategoryProperty.rent,
        });

        this.afterAddRent = true;
    }

    /**
     * @description Fetches entities and initialise/update fields
     * @author Hanane Djeddal
     * @return {*}  {Promise<void>}
     * @memberof PropertyTenantComponent
     */
    public async refreshData(): Promise<void> {
        if (this.property) {
            // Get lease for this property

            // Get rents for this property
            const rents$ = this.incomeService
                ._getIncomesByCategoryByPropertyIds(IncomeCategoryProperty.rent, [
                    this.property.uid,
                ])
                .pipe(
                    switchMap((rents) => {
                        this.rents = rents;
                        // Filter deleted rents
                        this.rents = this.rents?.filter((x) => !x.isDeleted);

                        if (this.lease) {
                            this.leaseRent = this.rents.find((x) => x.uid === this.lease?.rentUID);
                            if (this.leaseRent) {
                                this.leasePeriodicityLabel = new IncomePeriodicityInfo(
                                    this.leaseRent.periodicity
                                ).label;
                                if (this.leaseRent.periodicity === IncomePeriodicity.monthly) {
                                    this.leaseDebitDate =
                                        'le ' +
                                        this.leaseRent.debitDate?.toDate().getDate() +
                                        ' du mois';
                                } else {
                                    this.leaseDebitDate = formatDate(
                                        this.leaseRent.debitDate?.toDate() ?? '',
                                        'dd/MM/YYYY',
                                        'fr'
                                    );
                                }
                            }

                            const aid$ = this.incomeService._getIncomesByCategoryByPropertyIds(
                                IncomeCategoryProperty.aid,
                                [this.lease.propertyUID]
                            );
                            const tenants$ = this.tenantService._getTenantsByLease(this.lease.uid);

                            const aidAndTenant$ = combineLatest([aid$, tenants$]);
                            return aidAndTenant$;
                        }
                        return of([[], []]);
                    })
                )
                .subscribe(([aid, tenants]) => {
                    this.aid = aid;
                    this.tenants = tenants;
                    this.tenantAids = [];
                    this.tenants.forEach((tenant) => {
                        const tenantAid = this.aid.find((aid) => aid.uid === tenant.helpUID);
                        const aidPeriodicity = tenantAid
                            ? new IncomePeriodicityInfo(tenantAid.periodicity).label
                            : null;
                        const aidDebitDate = tenantAid
                            ? tenantAid.debitDate?.toDate().getDate()
                            : null;
                        const aidAmount = tenantAid ? tenantAid.amount : null;
                        this.tenantAids.push({
                            tenantUID: tenant.uid,
                            periodicity: aidPeriodicity,
                            date: aidDebitDate,
                            amount: Math.abs(aidAmount ?? 0),
                        });
                    });
                });

            this.subscriptions.push(rents$);
        }
    }

    /**
     * @description Search entities after or not refresh data
     * @author Hanane Djeddal
     * @param {*} [fromRefresher]
     * @return {*}  {Promise<void>}
     * @memberof PropertyTenantComponent
     */
    async refresh(fromRefresher?: any): Promise<void> {
        await this.refreshData();
    }

    public async addAid(tenantUid: string): Promise<void> {
        if (!this.lease) {
            return;
        }

        this.addAidEvent.emit({
            lease: this.lease,
            income: IncomeCategoryProperty.aid,
            tenant: tenantUid,
        });
    }

    prevSlide() {
        this.currentIndex = this.currentIndex > 0 ? this.currentIndex - 1 : this.tenants.length - 1;
        this.updateCarousel();
    }

    nextSlide() {
        this.currentIndex = this.currentIndex < this.tenants.length - 1 ? this.currentIndex + 1 : 0;
        this.updateCarousel();
    }

    updateCarousel() {
        const carouselInner = document.querySelector('.tenant-card-container') as HTMLElement;
        carouselInner.style.transform = `translateX(calc(-${this.currentIndex * 100}% ))`;
    }
}
