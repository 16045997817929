/**
 * @description Enum of EntityType
 * @author ANDRE Felix
 * @export
 * @enum {number}
 */
export enum EntityTypes {
    user = 'UserEntity',
    property = 'PropertyEntity',
    society = 'SocietyEntity',
    document = 'DocumentEntity',
    loan = 'LoanEntity',
    saving = 'SavingEntity',
    story = 'StoryEntity',
}
