<div class="omedom-modal">
    <h2 class="form-title">
        {{
            share
                ? 'Modification du tiers de confiance'
                : 'Ajout d’un tiers de confiance'
        }}
    </h2>

    <button class="form-close-button" (click)="close()">
        <i class="uil uil-times-circle"></i>
    </button>

    @if (shareForm) {
        <form class="form" [formGroup]="shareForm">
            <omedom-input
                name="firstname"
                icon="uil-user-circle"
                label="Prénom"
                placeholder="Prénom"
                [required]="true"
                formControlName="firstname"
            ></omedom-input>
            <omedom-input
                name="name"
                icon="uil-user-circle"
                label="Nom"
                placeholder="Nom"
                [required]="true"
                formControlName="name"
            ></omedom-input>
            <!-- <div class="form-item-radio">
      <app-radio
        label="Qualité"
        name="quality"
        formControlName="quality"
        [options]="qualityOptions"
        [required]="true"
      ></app-radio>
    </div>
    <hr class="omedom-divider omedom-no-margin" /> -->
            <omedom-input
                name="firstname"
                icon="uil-envelope"
                label="Email"
                type="email"
                placeholder="E-mail"
                [required]="true"
                [pattern]="emailRegex"
                formControlName="email"
            ></omedom-input>
            <div class="form-item">
                <div class="omedom-form-label">
                    Rôle
                    <sup>*</sup>
                </div>
                @if (((roleOptions$ | async) || []).length > 0) {
                    <omedom-select
                        formControlName="role"
                        [placeholder]="rolePlaceholder"
                        [options]="(roleOptions$ | async) || []"
                    ></omedom-select>
                }
            </div>
        </form>
    }

    <div class="omedom-sticky-button-container w-100">
        <button
            class="omedom-button-secondary flex-1 margin-right-1"
            (click)="close()"
        >
            Annuler
        </button>

        <button
            class="omedom-button-green flex-1"
            (click)="submit()"
            [disabled]="shareForm?.invalid || (pending$.asObservable() | async)"
        >
            <i class="uil uil-share-alt"></i>
            <span>Partager</span>
            @if (pending$.asObservable() | async) {
                <ion-spinner
                    color="primary"
                    class="form-upload-spinner"
                ></ion-spinner>
            }
        </button>
    </div>
</div>
