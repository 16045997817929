import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Clipboard } from '@capacitor/clipboard';
import { ToastController } from '@ionic/angular';
import {
    ChartDoughnutData,
    OmedomColorsEnum,
    ReferralBalance,
    ReferralEntity,
    UserEntity,
    UserReferralBalance,
} from '@omedom/data';
import { ReferralService, UserService } from '@omedom/services';
import { OmedomRegex } from '@omedom/utils';
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    EMPTY,
    filter,
    map,
    Observable,
    switchMap,
} from 'rxjs';

import { elementAnimation } from '../../../animations';
import { ProgressBarCardLayout, ProgressBarDataInterface } from '../../../components';

interface ReferralInviteForm {
    firstname: FormControl<string>;
    name: FormControl<string>;
    email: FormControl<string>;
}

@Component({
    selector: 'omedom-referral-info',
    templateUrl: './referral-info.container.html',
    styleUrls: ['./referral-info.container.scss'],
    animations: [
        trigger('elementX', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(-15px)' }),
                animate('0.25s', style({ opacity: 1, transform: 'translateX(0px)' })),
            ]),
        ]),
        elementAnimation,
    ],
})
export class ReferralInfoContainer {
    /**
     * @description Event emitted when the subscription of the user changes
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 04/02/2025
     * @type {EventEmitter<void>}
     * @memberof ReferralInfoContainer
     */
    @Output()
    public changeSubscription: EventEmitter<void> = new EventEmitter<void>();

    /**
     * @description
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 29/01/2025
     * @memberof ReferralInfoContainer
     */
    emailPattern = OmedomRegex.emailRegex;

    /**
     * @description
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 29/01/2025
     * @type {FormGroup<ReferralInviteForm>}
     * @memberof ReferralInfoContainer
     */
    referralInviteForm: FormGroup<ReferralInviteForm> = new FormGroup({
        firstname: new FormControl('', {
            validators: [Validators.required],
            nonNullable: true,
        }),
        name: new FormControl('', {
            validators: [Validators.required],
            nonNullable: true,
        }),
        email: new FormControl('', {
            validators: [Validators.required, Validators.pattern(this.emailPattern)],
            nonNullable: true,
        }),
    });

    /**
     * @description Current user data (observable)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 29/01/2025
     * @protected
     * @memberof ReferralInfoContainer
     */
    protected user$ = this.userService.user$;

    /**
     * @description Referrals of the current user (observable)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @type {Observable<ReferralEntity[]>}
     * @memberof ReferralInfoContainer
     */
    public referrals$: Observable<ReferralEntity[]> = this.user$.pipe(
        distinctUntilChanged((prev, curr) => prev?.uid === curr?.uid),
        switchMap((user) => {
            if (!user?.uid) {
                return EMPTY;
            }
            return this.referralService._getReferrals(user.uid).pipe(
                map((referrals) => {
                    return referrals.filter((referral) => referral.referralUserUID);
                }),
            );
        }),
    );

    public balance$: Observable<ReferralBalance> = this.user$.pipe(
        distinctUntilChanged((prev, curr) => prev?.uid === curr?.uid),
        switchMap((user) => {
            if (!user?.uid) {
                return EMPTY;
            }
            return this.referralService._getBalance(user.uid);
        }),
    );

    @Input()
    public progressBarCardLayout?: ProgressBarCardLayout;

    /**
     * @description
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 29/01/2025
     * @memberof ReferralInfoContainer
     */
    treasuryChartData$ = this.balance$.pipe(
        map((balance) => {
            if (!balance) {
                return [];
            }
            const chartDoughnutData = [
                {
                    label: 'Débloqués',
                    value: 0,
                    color: OmedomColorsEnum.lightGreen,
                },
                {
                    label: 'En attente',
                    value: 0,
                    color: OmedomColorsEnum.lightYellow,
                },
                {
                    label: 'Expirés',
                    value: 0,
                    color: OmedomColorsEnum.darkGrey,
                },
            ] as ChartDoughnutData[];

            Object.values(balance).forEach((value) => {
                switch (value.status) {
                    case 'unlocked':
                        chartDoughnutData[0].value += value.amount / 100;
                        break;
                    case 'pending':
                        chartDoughnutData[1].value += value.amount / 100;
                        break;
                    case 'expired':
                        chartDoughnutData[2].value += value.amount / 100;
                        break;
                    default:
                        break;
                }
            });
            return chartDoughnutData.filter((data) => data.value > 0);
        }),
    );

    progressBarData$: Observable<ProgressBarDataInterface[]> = this.treasuryChartData$.pipe(
        map((data) => {
            return data.map((value) => ({
                input: {
                    value: value.value,
                    inGraph: {
                        display: true,
                        textType: 'amount',
                    },
                    inLegend: {
                        display: true,
                        textColor: OmedomColorsEnum.lightBlack,
                        textType: 'amount',
                    },
                },
                label: {
                    value: value.label,
                    inLegend: {
                        display: true,
                        textColor: OmedomColorsEnum.lightBlack,
                    },
                    inGraph: {
                        display: true,
                    },
                },
                color: value.color,
                progressBar: {
                    textColor: OmedomColorsEnum.lightBlack,
                    backgroundColor: value.color,
                },
            }));
        }),
    );

    public canWithdraw$: Observable<boolean> = this.treasuryChartData$.pipe(
        map((data) => {
            return data.some((value) => value.label === 'Débloqués' && value.value >= 20);
        }),
    );

    public vouchers$: Observable<
        {
            date: string;
            amount: number;
            status: 'withdrawn' | 'confirmed';
            link?: string;
        }[]
    > = this.balance$.pipe(
        map((balance) => {
            if (!balance) {
                return [];
            }

            const vouchersObject = Object.values(balance).reduce(
                (acc, value) => {
                    if (value.status === 'withdrawn' || value.status === 'confirmed') {
                        const date = value.date.toISOString();
                        if (!acc[value.status]) {
                            acc[value.status] = {};
                        }
                        if (!acc[value.status][date]) {
                            acc[value.status][date] = {
                                value: 0,
                            };
                        }

                        acc[value.status][date].value += value.amount / 100;
                        if (value.link) {
                            acc[value.status][date].link = value.link;
                        }
                    }
                    return acc;
                },
                {} as {
                    [status in 'withdrawn' | 'confirmed']: {
                        [date: string]: {
                            value: number;
                            link?: string;
                        };
                    };
                },
            );

            return Object.entries(vouchersObject).reduce(
                (acc, [status, values]) => {
                    return acc.concat(
                        Object.entries(values).map(([date, amount]) => ({
                            date,
                            amount: amount.value,
                            status: status as 'withdrawn' | 'confirmed',
                            link: amount.link,
                        })),
                    );
                },
                [] as {
                    date: string;
                    amount: number;
                    status: 'withdrawn' | 'confirmed';
                    link?: string;
                }[],
            );
        }),
    );

    public canAccess$: Observable<boolean> = combineLatest([
        this.treasuryChartData$,
        this.vouchers$,
    ]).pipe(
        map(([treasuryChartData, vouchers]) => {
            return (
                treasuryChartData.some(
                    (value) => value.label === 'Débloqués' && value.value > 20,
                ) || vouchers.length > 0
            );
        }),
    );

    /**
     * @description Pending state (observable) of the form submission (invitation) button
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 04/02/2025
     * @memberof ReferralInfoContainer
     */
    public pending$ = new BehaviorSubject<boolean>(false);

    /**
     * @description Pending state (observable) of the form submission (withdrawal) button
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 04/02/2025
     * @memberof ReferralInfoContainer
     */
    public pendingWithdraw$ = new BehaviorSubject<boolean>(false);

    /**
     * @description Referring user data (observable)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 29/01/2025
     * @type {(Observable<UserEntity | undefined>)}
     * @memberof ReferralsHomePage
     */
    public referringUser$: Observable<UserEntity | undefined> = this.user$.pipe(
        filter((user) => !!user && !!user.referralUserUID),
        map((user) => user.referralUserUID),
        switchMap((referralUserUID) => {
            if (!referralUserUID) {
                return EMPTY;
            }
            return this.userService._get(referralUserUID);
        }),
    );

    public userReferralBalance$: Observable<UserReferralBalance | null> = this.user$.pipe(
        filter((user) => !!user && !!user.uid),
        switchMap((user) => {
            if (!user?.uid) {
                return EMPTY;
            }
            return this.referralService._getBalance(user.uid).pipe(
                map((balance) => {
                    return balance[user.uid] ?? null;
                }),
            );
        }),
    );

    /**
     * Creates an instance of ReferralInfoContainer.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 29/01/2025
     * @param {UserService} userService
     * @memberof ReferralInfoContainer
     */
    constructor(
        private readonly userService: UserService,
        private readonly referralService: ReferralService,
        private readonly toastController: ToastController,
    ) {}

    async sendInvitation() {
        this.pending$.next(true);
        const value = this.referralInviteForm.value as ReferralEntity;

        let message = 'Une erreur est survenue, veuillez réessayer plus tard';
        let toastColor = 'danger';

        try {
            if (!value.email || !value.firstname || !value.name) {
                throw new Error('All fields are required');
            }
            await this.referralService.sendReferralInvitationEmail(
                value.email,
                value.firstname,
                value.name,
            );

            message = 'Invitation envoyée avec succès';
            toastColor = 'success';
        } catch (error: any) {
            if (error?.code) {
                switch (error.code) {
                    case 'functions/already-exists':
                        message = 'Cet utilisateur a déjà un compte validé et/ou parrainé';
                        break;
                    case 'functions/unauthenticated':
                        message = 'Vous devez être connecté pour envoyer une invitation';
                        break;
                    default:
                        break;
                }
            }
        }

        if (await this.toastController.getTop()) {
            await this.toastController.dismiss();
        }

        const toast = await this.toastController.create({
            message,
            duration: 5000,
            position: 'top',
            color: toastColor,
        });

        toast.present();

        this.pending$.next(false);
    }

    async withdrawReferralBalance() {
        this.pendingWithdraw$.next(true);
        try {
            await this.referralService.withdrawReferralBalance();
            const toast = await this.toastController.create({
                message: 'Votre solde a bien été retiré',
                duration: 5000,
                position: 'top',
                color: 'success',
            });
            toast.present();
        } catch (error: any) {
            if (error?.code) {
                switch (error.code) {
                    case 'functions/unauthenticated':
                        const toast = await this.toastController.create({
                            message: 'Vous devez être connecté pour retirer votre solde',
                            duration: 5000,
                            position: 'top',
                            color: 'danger',
                        });
                        toast.present();
                        break;
                    default:
                        break;
                }
            }
        }
        this.pendingWithdraw$.next(false);
    }

    public scrollToElement(id: string) {
        document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    }

    public async copyReferralCode(referralCode: string) {
        let toast: HTMLIonToastElement;
        try {
            await Clipboard.write({
                string: referralCode,
            });
            toast = await this.toastController.create({
                message: 'Code de parrainage copié',
                duration: 2000,
                position: 'top',
                color: 'success',
            });
        } catch (error) {
            toast = await this.toastController.create({
                message: 'Une erreur est survenue, veuillez réessayer plus tard',
                duration: 5000,
                position: 'top',
                color: 'danger',
            });
        }
        toast.present();
    }
}
