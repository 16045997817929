@if (isPropertyTypeValidForValuation) {
    @if (canStartValuation) {
        <omedom-refresh-estimation
            [hasValorisation]="hasValorisation"
            (refreshEstimation)="refreshPricehubble()"
        ></omedom-refresh-estimation>
    }
}
@if (!(isAssimilateHouse || isAssimilateAppartement)) {
    <omedom-info
        iconStart="uil uil-exclamation-triangle"
        information="La valorisation d'un bien est disponible uniquement pour les biens de type maison, appartement, immeuble, hôtel et chalet"
    ></omedom-info>
}
@if (ecRating && canAccessValuation) {
    <div class="dpe-header margin-bottom-1">
        <div class="dpe-title">Diagnostic de Performance Énergétique (DPE)</div>
        @if (currentEnergyLabel) {
            <omedom-dpe-score [score]="currentEnergyLabel"></omedom-dpe-score>
        }
    </div>

    <omedom-info
        class="margin-bottom-1 dpe-info"
        information="Conformément aux standards officiels, la note DPE est déterminée par la performance la plus basse entre la consommation d’énergie et l’émission de gaz à effet de serre.
"
    ></omedom-info>

    <omedom-dpe-interface
        [levels]="energyConsumptionLevels"
        subTitle="Consommation énergétique"
        ratingType="kWh/m²/an"
        [rating]="ecRating"
    >
    </omedom-dpe-interface>
    <omedom-dpe-interface
        [levels]="ghgEmissionLevels"
        subTitle="Emissions de Gaz à effet de serre (GES)"
        ratingType="kgCO₂/m²/an"
        [rating]="ghgRating"
    >
    </omedom-dpe-interface>
}

@if (
    ((propertyRenovations || []).length > 0 || property.energyReport) &&
    canAccessValuation
) {
    <hr class="omedom-divider" />
    <omedom-info
        class="margin-bottom-2 dpe-info"
        information="L'évaluation des coûts de rénovation repose sur l'estimation de votre DPE (calculé ci-dessus). Si vous avez renseigné une note différente dans votre bien, celle-ci ne sera pas prise en compte."
    ></omedom-info>
    @if (property.energyReport) {
        <omedom-renovations-scenario
            [energyReport]="property.energyReport"
        ></omedom-renovations-scenario>
    } @else if (property.renovationCalculation) {
        <omedom-old-renovation
            [currentEnergyLabel]="currentEnergyLabel"
            [propertyRenovations]="propertyRenovations"
        ></omedom-old-renovation>
    }
}
