<!-- <div class="search">
    <omedom-input
        type="search"
        icon="uil-search"
        placeholder="Rechercher..."
    ></omedom-input>
    <button class="omedom-icon-button-secondary-outline">
        <i class="uil uil-filter"></i>
    </button>
    <button class="omedom-icon-button-secondary-outline">
        <i class="uil uil-sort-amount-down"></i>
    </button>
</div>

<div class="list">
    @for (document of documents; track document.uid) {
    <omedom-document-card
        (click)="downloadDocument(document)"
        [document]="document"
    ></omedom-document-card>
    }
</div> -->
@if (property) {
    <omedom-document-list
        [documents]="(documents$ | async) || []"
        [addButton]="!!client?.hasAgreedToEditByPro"
        [properties]="[property]"
        [displayProDocument]="true"
        [roleState]="(roleState$ | async) || defaultRoleState"
        (addDocument)="addDocument.emit($event)"
        (documentClick)="documentClick($event)"
    >
    </omedom-document-list>
}
