<div class="omedom-modal">
    <h2 class="form-title">
        {{ share ? modifyText : addText }}
    </h2>

    <button class="form-close-button" (click)="close()">
        <i class="uil uil-times-circle"></i>
    </button>

    <form class="form" [formGroup]="shareForm!">
        <omedom-input
            name="firstname"
            icon="uil-user-circle"
            label="Prénom"
            placeholder="Prénom"
            formControlName="firstname"
        ></omedom-input>

        <omedom-input
            name="name"
            icon="uil-user-circle"
            label="Nom"
            placeholder="Nom"
            formControlName="name"
        ></omedom-input>

        <omedom-input
            name="firstname"
            icon="uil-envelope"
            label="E-mail"
            type="email"
            placeholder="E-mail"
            [pattern]="emailRegex"
            formControlName="email"
        ></omedom-input>
    </form>

    <div class="omedom-sticky-button-container w-100">
        <button
            class="omedom-button-secondary flex-1 margin-right-1"
            (click)="close()"
        >
            Annuler
        </button>
        <button
            class="omedom-button-green flex-1"
            (click)="submit()"
            [disabled]="shareForm?.invalid"
        >
            <i class="uil" [ngClass]="'uil-' + buttonIcon"></i>
            <span>{{ buttonText }}</span>
            @if (pending$.asObservable() | async) {
                <ion-spinner
                    color="primary"
                    class="form-upload-spinner"
                ></ion-spinner>
            }
        </button>
    </div>
</div>
