<div class="omedom-modal">
    <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
            <i class="uil uil-chart-line background-color-light-green"></i>
        </div>
        @if (!missingDataInView) {
            <i class="uil uil-times-circle" (click)="close()"></i>
        }
    </div>
    <omedom-info
        iconStart=""
        class="margin-top-2"
        information="Des champs sont manquants afin d'obtenir la valorisation de votre bien.<br /><br />
        Tous ne sont pas obligatoires, mais permettront d'affiner la valorisation de votre bien.<br /><br />
        Les informations saisies seront sauvegardées dans la fiche du bien."
    ></omedom-info>
    <div class="margin-bottom-2">
        @if (propertyForm) {
            <form #form="ngForm">
                <div>
                    <hr class="omedom-divider w-100" />
                    <h2 class="margin-y-2">
                        <i class="uil uil-icon uil-user-circle"></i>
                        Informations personnelles
                        <!-- Adresse du bailleur -->
                    </h2>
                </div>
                @if (property) {
                    <form [formGroup]="propertyForm" class="form-inline">
                        @if (missingRequiredData.buildingYear) {
                            <div class="form-item userInformations">
                                <omedom-input
                                    class="margin-bottom-1"
                                    style="width: 170px"
                                    name="buildingYear"
                                    min="1700"
                                    max="2099"
                                    type="number"
                                    formControlName="buildingYear"
                                    placeholder="aaaa"
                                    autocomplete="off"
                                    [required]="true"
                                    [debounce]="300"
                                    label="Année de construction"
                                ></omedom-input>
                            </div>
                        }
                        @if (
                            missingRequiredData.livingArea ||
                            missingRequiredData.landArea
                        ) {
                            @if (isAssimilateHouse) {
                                <omedom-info
                                    class="margin-bottom-1"
                                    information="La surface du terrain doit être égale ou supérieure à celle de la surface habitable"
                                ></omedom-info>
                            }
                            <div class="form-flex margin-bottom-1">
                                @if (missingRequiredData.landArea) {
                                    <div class="margin-right-2" style="flex: 1">
                                        <omedom-input
                                            class="margin-bottom-1"
                                            name="livingSpace"
                                            min="50"
                                            max="100000"
                                            type="number"
                                            formControlName="landArea"
                                            placeholder="50m² min"
                                            autocomplete="off"
                                            [required]="true"
                                            [debounce]="600"
                                            label="Surface du terrain"
                                        ></omedom-input>
                                    </div>
                                }
                                @if (missingRequiredData.livingArea) {
                                    <div
                                        class="userInformations"
                                        style="flex: 1"
                                    >
                                        <omedom-input
                                            class="margin-bottom-1"
                                            name="livingSpace"
                                            [min]="minLivingSpace"
                                            max="2099"
                                            type="number"
                                            formControlName="livingSpace"
                                            [placeholder]="
                                                livingSpacePlaceholder
                                            "
                                            autocomplete="off"
                                            [required]="true"
                                            [debounce]="600"
                                            label="Surface habitable"
                                        ></omedom-input>
                                    </div>
                                }
                            </div>
                        }
                        @if (isMissingAddress && property) {
                            <div class="userInformations">
                                @if (property) {
                                    <form
                                        [formGroup]="addressForm"
                                        class="form-inline"
                                    >
                                        <div>
                                            <omedom-input
                                                class="margin-bottom-1 margin-right-2"
                                                style="width: 140px"
                                                name="streetNumber"
                                                [min]="0"
                                                [max]="9999"
                                                type="number"
                                                formControlName="streetNumber"
                                                placeholder="N°"
                                                autocomplete="off"
                                                [required]="true"
                                                [debounce]="300"
                                                label="Numéro de rue"
                                            ></omedom-input>
                                            <omedom-input
                                                class="margin-bottom-1"
                                                style="width: 140px"
                                                name="suffixNumber"
                                                type="text"
                                                formControlName="suffixNumber"
                                                placeholder="bis, ter"
                                                autocomplete="off"
                                                [required]="false"
                                                [debounce]="300"
                                                label="Suffixe"
                                            ></omedom-input>
                                        </div>
                                        <div>
                                            <omedom-input
                                                class="margin-bottom-1"
                                                name="street"
                                                type="text"
                                                formControlName="street"
                                                placeholder="Rue"
                                                autocomplete="off"
                                                [required]="true"
                                                [debounce]="300"
                                                label="Rue"
                                            ></omedom-input>
                                        </div>
                                        <div>
                                            <omedom-input
                                                class="margin-bottom-1"
                                                name="addressLine2"
                                                type="text"
                                                formControlName="addressLine2"
                                                placeholder="Complément d'adresse"
                                                autocomplete="off"
                                                [debounce]="300"
                                                label="Complément d'adresse"
                                            ></omedom-input>
                                        </div>
                                        <div>
                                            <omedom-input
                                                class="margin-bottom-1"
                                                name="postalCode"
                                                type="number"
                                                formControlName="postalCode"
                                                placeholder="Code postal"
                                                autocomplete="off"
                                                [required]="true"
                                                [pattern]="postalCodeRegex"
                                                [debounce]="300"
                                                label="Code postal"
                                            ></omedom-input>
                                        </div>
                                        <div>
                                            <omedom-input
                                                class="margin-bottom-1"
                                                name="city"
                                                type="text"
                                                formControlName="city"
                                                placeholder="Ville"
                                                autocomplete="off"
                                                [required]="true"
                                                [debounce]="300"
                                                label="Ville"
                                            ></omedom-input>
                                        </div>
                                    </form>
                                }
                            </div>
                        }
                        @if (missingDataOptionalHouse.roomCount) {
                            <div>
                                <div class="form-item margin-bottom-2">
                                    <span class="form-item-label">Pièces:</span>
                                    <div class="form-select">
                                        <div
                                            class="form-option"
                                            (click)="
                                                propertyForm
                                                    .get('roomCount')
                                                    ?.setValue(1)
                                            "
                                            [class.form-option-active]="
                                                propertyForm.get('roomCount')
                                                    ?.value === 1
                                            "
                                        >
                                            <span>1</span>
                                        </div>
                                        <div
                                            class="form-option"
                                            (click)="
                                                propertyForm
                                                    .get('roomCount')
                                                    ?.setValue(2)
                                            "
                                            [class.form-option-active]="
                                                propertyForm.get('roomCount')
                                                    ?.value === 2
                                            "
                                        >
                                            <span>2</span>
                                        </div>
                                        <div
                                            class="form-option"
                                            (click)="
                                                propertyForm
                                                    .get('roomCount')
                                                    ?.setValue(3)
                                            "
                                            [class.form-option-active]="
                                                propertyForm.get('roomCount')
                                                    ?.value === 3
                                            "
                                        >
                                            <span>3</span>
                                        </div>
                                        <div
                                            class="form-option"
                                            (click)="
                                                propertyForm
                                                    .get('roomCount')
                                                    ?.setValue(4)
                                            "
                                            [class.form-option-active]="
                                                propertyForm.get('roomCount')
                                                    ?.value === 4
                                            "
                                        >
                                            <span>4</span>
                                        </div>
                                        <div
                                            class="form-option"
                                            (click)="
                                                propertyForm
                                                    .get('roomCount')
                                                    ?.setValue(5)
                                            "
                                            [class.form-option-active]="
                                                propertyForm.get('roomCount')
                                                    ?.value === 5
                                            "
                                        >
                                            <span>5</span>
                                        </div>
                                        <div
                                            class="form-option"
                                            (click)="
                                                propertyForm
                                                    .get('roomCount')
                                                    ?.setValue(6)
                                            "
                                            [class.form-option-active]="
                                                propertyForm.get('roomCount')
                                                    ?.value === 6
                                            "
                                        >
                                            <span>6+</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        @if (
                            missingDataOptionalHouse.floor ||
                            missingDataOptionalAppartement.floorNumber
                        ) {
                            <div class="form-flex margin-bottom-1">
                                <div class="form-item" style="flex: 1">
                                    @if (isAssimilateHouse) {
                                        <omedom-info
                                            information="Le nombre de niveaux comprend tous les niveaux habitables (sous-sol et combles inclus)"
                                        ></omedom-info>
                                    }
                                    <omedom-input
                                        class="margin-bottom-1"
                                        name="floorNumber"
                                        type="number"
                                        formControlName="floorNumber"
                                        [placeholder]="floorNumberLabel"
                                        autocomplete="off"
                                        [debounce]="300"
                                        [label]="floorNumberLabel"
                                    ></omedom-input>
                                </div>
                                @if (isAppartement) {
                                    <div class="form-item" style="flex: 1">
                                        <omedom-input
                                            class="margin-bottom-1"
                                            name="floor"
                                            type="number"
                                            formControlName="floor"
                                            placeholder="Etage"
                                            autocomplete="off"
                                            [debounce]="300"
                                            label="Etage du logement"
                                        ></omedom-input>
                                    </div>
                                }
                            </div>
                        }
                        <div class="form-flex form-block margin-bottom-1">
                            @if (missingDataOptionalHouse.numberOfWindows) {
                                <div class="form-item" style="flex: 1">
                                    <omedom-input
                                        class="margin-bottom-1"
                                        name="numberOfWindows"
                                        type="number"
                                        formControlName="numberOfWindows"
                                        placeholder=""
                                        autocomplete="off"
                                        [debounce]="300"
                                        label="Nombre de fenêtres"
                                    ></omedom-input>
                                </div>
                            }
                            @if (
                                missingDataOptionalHouse.numberOfExternalFacingWalls
                            ) {
                                <div class="form-item" style="flex: 1">
                                    <omedom-input
                                        class="margin-bottom-1"
                                        name="numberOfExternalFacingWalls"
                                        type="number"
                                        formControlName="numberOfExternalFacingWalls"
                                        placeholder=""
                                        autocomplete="off"
                                        [debounce]="300"
                                        label="Nombre de murs vers l'extérieur"
                                    ></omedom-input>
                                </div>
                            }
                        </div>
                        <div>
                            @if (
                                missingDataOptionalHouse.isolationRenovationPeriod
                            ) {
                                <omedom-select
                                    class="margin-bottom-2"
                                    [options]="isolationRenovationOptions"
                                    formControlName="isolationRenovationPeriod"
                                    label="Période de rénovation"
                                ></omedom-select>
                            }
                        </div>
                        <div>
                            @if (missingDataOptionalHouse.atticType) {
                                <omedom-select
                                    class="margin-bottom-2"
                                    [options]="atticTypeOptions"
                                    formControlName="atticType"
                                    label="Type de grenier"
                                ></omedom-select>
                            }
                        </div>
                        <div>
                            @if (missingDataOptionalHouse.glazingType) {
                                <omedom-select
                                    class="margin-bottom-2"
                                    [options]="glazingTypeOptions"
                                    formControlName="glazingType"
                                    label="Type de vitrage"
                                ></omedom-select>
                            }
                        </div>
                        <!-- @if (missingDataOptionalAppartement.energyLabel) {
              <div
                class="form-flex margin-bottom-2"
                >
                <div class="form-item" style="flex: 1">
                  <span class="form-item-label">Date du DPE</span>
                  <ion-item>
                    <ion-input
                      formControlName="dpeDate"
                      type="date"
                    ></ion-input>
                  </ion-item>
                </div>
                <div class="form-item" style="flex: 1">
                  <span class="form-item-label">Note du DPE</span>
                  <omedom-select
                    formControlName="dpeScore"
                    [placeholder]="dpeScorePlaceHolder"
                    [options]="(dpeScoreOptions$ | async) || []"
                  ></omedom-select>
                </div>
              </div>
            } -->
                        @if (missingDataOptionalAppartement.hasLift) {
                            <div>
                                <omedom-checkbox formControlName="hasLift">
                                    Ascenseur
                                </omedom-checkbox>
                            </div>
                        }
                        @if (missingDataOptionalHouse.hasPool) {
                            <div>
                                <omedom-checkbox formControlName="hasPool">
                                    Piscine
                                </omedom-checkbox>
                            </div>
                        }
                    </form>
                }
            </form>
        }
    </div>
    <div class="omedom-sticky-button-container w-100">
        <!-- <button
    [ngSwitch]="loading.send"
    class="omedom-button-green inline-block flex-1"
    (click)="updateProperty()"
    [disabled]="loading.save || loading.send || form.invalid"
    > -->
        <button
            class="omedom-button-green inline-block flex-1"
            (click)="updateProperty()"
            [disabled]="this.propertyForm?.invalid"
        >
            <span>
                <i class="uil uil-envelope-upload"></i>
                Enregistrer
            </span>
        </button>
    </div>
</div>
