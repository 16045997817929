@if (displayUpdateMessage) {
    <div class="update-message margin-bottom-2 padding-2 bold">
        Nouveau ! Pour obtenir des scénarios de rénovation selon leurs types,
        rafraîchissez la valorisation !
    </div>
}

<div class="margin-bottom-2 grid-table">
    <div></div>
    <div class="header">Coût de rénovation</div>
    <div class="header">Impact sur la valeur du bien</div>
    @for (renovation of propertyRenovations; track $index) {
        <ng-container class="margin-bottom-2">
            <div>
                @if (currentEnergyLabel) {
                    <omedom-dpe-score
                        [score]="currentEnergyLabel"
                    ></omedom-dpe-score>
                }
                <i class="uil uil-arrow-right"></i>
                <omedom-dpe-score
                    [score]="renovation.energyLabelRenovation"
                    class="margin-left-1"
                ></omedom-dpe-score>
            </div>
            <div class="max-width">
                <omedom-display-range-price
                    [valueRange]="renovation.renovationCosts"
                ></omedom-display-range-price>
            </div>
            <div class="max-width">
                <omedom-display-range-price
                    [valueRange]="renovation.salePriceDifference"
                    [withPlus]="true"
                ></omedom-display-range-price>
            </div>
        </ng-container>
    }
</div>
