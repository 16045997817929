import { PostalAddress } from '../interfaces';
import { ProAlertType } from './pro-alert.entity';
import { RestEntity } from './rest.entity';

export enum Civility {
    Mr = 'M',
    Mrs = 'Mme',
    other = 'Autre',
}

export enum FamilyStatus {
    married = 'Marié',
    divorced = 'Divorcé',
    pacs = 'Pacsé',
    concubine = 'Concubin',
    separated = 'Séparé',
    single = 'Célibataire',
    widowed = 'Veuf',
}

export enum MaritalRegime {
    communityProperty = 'Communauté réduite aux acquêts',
    universalCommunity = 'Communauté universelle',
    separationOfProperty = 'Séparation de biens',
    participationInAcquisitions = 'Participation aux acquêts',
    communityOfFurnitureAndAcquisitions = 'Communauté de meubles et acquêts',
    other = 'Autre',
}

export enum PacsRegime {
    separationOfProperty = 'Séparation de biens',
    indivision = 'Indivision',
}

export enum Liberated {
    donationToTheLastLivingPerson = 'Donation au dernier vivant',
    donationSharing = 'Donation partage',
    otherInterVivosDonations = 'Autres donations entre vifs',
    legsUniversals = 'Legs universels',
    legsParticulars = 'Legs particuliers',
    otherTestamentaryProvisions = 'Autres dispositions testamentaires',
}

export enum ProfessionalStatus {
    // 'agriculteur exploitant'
    farmer = 'Agriculteur exploitant',
    // 'artisan, commerçant et chef d'entreprise'
    artisanAndTraderAndBusinessLeader = "Artisan, commerçant et chef d'entreprise",
    // 'cadre, professeur, prof. libérale, scientifique ou artistique'
    cadreProfessorProfLibScientifiqueOrArtistique = 'Cadre, professeur, prof. libérale, scientifique ou artistique',
    // 'Profession intermédiaire'
    intermediateProfession = 'Profession intermédiaire',
    // 'employé'
    employee = 'Employé',
    // 'ouvrier'
    worker = 'Ouvrier',
    // 'retraité'
    retired = 'Retraité',
    // 'Autre personne sans activité professionnelle'
    otherPersonWithoutProfessionalActivity = 'Autre personne sans activité professionnelle',
}

export enum SocialRegime {
    socialSecurity = 'Sécurité sociale',
    MSA = 'MSA',
    agricol = 'Agricole',
}

export enum HouseholdIncomeBrackets {
    lowerThan50K = '< à 50 000€',
    between50KAnd80K = 'De 50 000€ et 80 000€',
    between80KAnd120K = 'De 80 000€ et 120 000€',
    between120KAnd160K = 'De 120 000€ et 160 000€',
    between160KAnd300K = 'De 160 000€ et 300 000€',
    higherThan300K = '> à 300 000€',
}

export enum SlicesOfTheHouseholdSNetBorrowingAssets {
    lowerThan150K = '< à 150 000€',
    between150KAnd750K = 'De 150 000€ et 750 000€',
    between750KAnd150K = 'De 750 000€ et 1 500 000€',
    between1500KAnd5M = 'De 1 500 000€ et 5 000 000€',
    between5MAnd15M = 'De 5 000 000€ et 15 000 000€',
    higherThan15M = '> à 15 000 000€',
}

export interface AlertsEvents {
    type: ProAlertType;
    isEnable: boolean;
    params: { [key: string]: any };
}

export interface Tels {
    /**
     * @description Professional phone number of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {string}
     * @memberof Tels
     */
    pro?: string;

    /**
     * @description House phone number of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {string}
     * @memberof Tels
     */
    house?: string;

    /**
     * @description Mobile phone number of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {string}
     * @memberof Tels
     */
    mobile?: string;
}

export interface ProfessionalSituation {
    /**
     * @description Label of professional situation of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {string}
     * @memberof ProfessionalSituation
     */
    label?: string;

    sector?: string;

    status?: keyof typeof ProfessionalStatus;

    socialRegime?: keyof typeof SocialRegime;

    householdIncomeBracket?: keyof typeof HouseholdIncomeBrackets;

    slicesOfTheHouseholdSNetBorrowingAssets?: keyof typeof SlicesOfTheHouseholdSNetBorrowingAssets;

    /**
     * @description Age of client experience in the professional situation - In years
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {number}
     * @memberof ProfessionalSituation
     */
    seniority?: number;

    /**
     * @description Name of the company of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {string}
     * @memberof ProfessionalSituation
     */
    nameOfsociety?: string;

    /**
     * @description Expected retirement age of the client - In years
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {number}
     * @memberof ProfessionalSituation
     */
    expectedRetirementAge?: number;
}

export interface FiscalSituation {
    /**
     * @description Tax on income of the client - True if the client is taxed on income, false otherwise
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {boolean}
     * @memberof FiscalSituation
     */
    taxOnIncome?: boolean;

    /**
     * @description Tax on real estate wealth of the client - True if the client is taxed on real estate wealth, false otherwise
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {boolean}
     * @memberof FiscalSituation
     */
    taxOnrealEstateWealth?: boolean;
}

export interface Birthplace {
    /**
     * @description City of birth of the client - City of birth of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/09/2024
     * @type {string}
     * @memberof Birthplace
     */
    city?: string;

    /**
     * @description Postal code of birth of the client - Postal code of birth of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/09/2024
     * @type {string}
     * @memberof Birthplace
     */
    postalCode?: string;

    /**
     * @description Country of birth of the client - Country of birth of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/09/2024
     * @type {string}
     * @memberof Birthplace
     */
    country?: string;
}

export interface FamilySituation {
    /**
     * @description Family status of the client - Married, Divorced, Widowed, Pacsé, Célibataire
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 22/08/2024
     * @type {FamilyStatus}
     * @memberof FamilySituation
     */
    familyStatus?: keyof typeof FamilyStatus;

    /**
     * @description Wedding date of the client - Format: DD/MM/YYYY
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 22/08/2024
     * @type {string}
     * @memberof FamilySituation
     */
    weddingDate?: string;

    /**
     * @description Wedding place of the client - City of the wedding of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 22/08/2024
     * @type {string}
     * @memberof FamilySituation
     */
    weddingPlace?: string;

    /**
     * @description Marital regime of the client - Regime or agreement of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 22/08/2024
     * @type {string}
     * @memberof FamilySituation
     */
    maritalRegime?: keyof typeof MaritalRegime;

    /**
     * @description Pacs regime of the client - Regime or agreement of the client in the PACS (Pacte Civil de Solidarité) - PACS is a French civil union between two adults,
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/09/2024
     * @type {string}
     * @memberof FamilySituation
     */
    pacsRegime?: keyof typeof PacsRegime;

    /**
     * @description Liberated of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 22/08/2024
     * @type {Liberated}
     * @memberof FamilySituation
     */
    liberated?: (keyof typeof Liberated)[];

    /**
     * @description Status of protected adults of the client - Regime, family link, date of birth of the protected adults of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 12/09/2024
     * @type {string}
     * @memberof FamilySituation
     */
    statusOfProtectedAdults?: string;

    /**
     * @description Number of people dependent on the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/09/2024
     * @type {number}
     * @memberof FamilySituation
     */
    numberOfDependentPeople?: number;
}

export interface FinancialSynthesis {
    charges?: any;
    incomes?: any;
    treasury?: any;
    debts?: any;
    savingCapacity?: any;
}

export class ClientEntity extends RestEntity {
    /**
     * @description Subscription UID of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public subscriptionUID!: string;

    /**
     * @description Pro UID of the client - Pro can get access to the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public proUID!: string;

    /**
     * @description User UID of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public userUID!: string;

    /**
     * @description Email of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public email!: string;

    /**
     * @description Has pro validate that he accepts to exchange via chat with client
     * @author ANDRE Felix
     * @type {boolean}
     * @memberof ClientEntity
     */
    isValidatedByPro!: boolean;

    /**
     * @description  True if the client mandates the pro, false otherwise
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/09/2024
     * @type {boolean}
     * @memberof ClientEntity
     */
    public isMandated!: boolean;

    /**
     * @description Licence UID of the client - Licence of the client to access the app
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public licenceUID?: string;

    /**
     * @description Firstname of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public firstname?: string;

    /**
     * @description Lastname of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public name?: string;

    /**
     * @description Civility of the client (M, Mme, Mlle, NR)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/08/2024
     * @type {Civility}
     * @memberof ClientEntity
     */
    public civility?: Civility;

    /**
     * @description Birthplace of the client - City of birth of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public birthplace?: Birthplace;

    /**
     * @description Nationality of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public nationality?: string;

    /**
     * @description Country of tax residence of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public countryOfTaxResidence?: string;

    /**
     * @description Tels of the client (pro, house, mobile)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {Tels}
     * @memberof ClientEntity
     */
    public tels?: Tels;

    /**
     * @description Professional email of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public proEmail?: string;

    /**
     * @description Main advisor of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public mainAdvisor?: string;

    /**
     * @description Date of connection of the client - Date of the first connection of the client to the app
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @type {Date}
     * @memberof ClientEntity
     */
    public dateOfConnection?: Date;

    /**
     * @description Notes of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @type {string}
     * @memberof ClientEntity
     */
    public notes?: string;

    /**
     * @description Is PPE of the client - PPE ("Personne Politiquement Exposée")
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {boolean}
     * @memberof ClientEntity
     */
    public isPPE?: boolean;

    /**
     * @description Is linked to PPE of the client - True if the client is linked to a PPE, false otherwise
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {boolean}
     * @memberof ClientEntity
     */
    public isLinkedToPPE?: boolean;

    /**
     * @description Professional situation of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {ProfessionalSituation}
     * @memberof ClientEntity
     */
    public professionalSituation?: ProfessionalSituation;

    /**
     * @description Fiscal situation of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {FiscalSituation}
     * @memberof ClientEntity
     */
    public fiscalSituation?: FiscalSituation;

    /**
     * @description Family situation of the client - Family status, wedding date, wedding place, regime or agreement, liberated
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 22/08/2024
     * @type {FamilySituation}
     * @memberof ClientEntity
     */
    public familySituation?: FamilySituation;

    /**
     * @description Is US person of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/08/2024
     * @type {boolean}
     * @memberof ClientEntity
     */
    public isUSPerson?: boolean;

    /**
     * @description Birthdate of the client - Format: DD/MM/YYYY
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string}
     * @memberof ClientEntity
     * @example 01/01/2000
     */
    public birthdate?: string;

    /**
     * @description Address of the client - Postal address of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {PostalAddress}
     * @memberof ClientEntity
     */
    public address?: PostalAddress;

    /**
     * @description Properties UID of the client - Properties shared by the client with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string[]}
     * @memberof ClientEntity
     */
    public propertiesUID?: string[];

    /**
     * @description Societies UID of the client - Societies shared by the client with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string[]}
     * @memberof ClientEntity
     */
    public societiesUID?: string[];

    /**
     * @description Savings UID of the client - Savings shared by the client with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string[]}
     * @memberof ClientEntity
     */
    public savingsUID?: string[];

    /**
     * @description Loans UID of the client - Loans shared by the client with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string[]}
     * @memberof ClientEntity
     */
    public loansUID?: string[];

    /**
     * @description Bank items UID of the client - Bank items shared by the client with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string[]}
     * @memberof ClientEntity
     */
    public bankItemsUID?: string[];

    /**
     * @description Bank accounts UID of the client - Bank accounts shared by the client with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {string[]}
     * @memberof ClientEntity
     */
    public bankAccountsUID?: string[];

    /**
     * @description has Client accept the condition to share property, society, banckAccount, etc... to a pro
     * @author ANDRE Felix
     * @type {boolean}
     * @memberof ClientEntity
     */
    public hasAgreedToShareToPro!: boolean;

    /**
     * @description has Client accept the condition that a pro can edit his data (property, society, bankAccount, etc...) to a pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/11/2024
     * @type {boolean}
     * @memberof ClientEntity
     */
    public hasAgreedToEditByPro!: boolean;

    /**
     * @description Has Client accept to share his total bank account to a pro
     * @author ANDRE Felix
     * @type {boolean}
     * @memberof ClientEntity
     */
    public hasAgreedToShareBankTotalBalance!: boolean;

    /**
     * @description Financial synthesis of the client - Charges, incomes, treasury, debts, saving capacity of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/09/2024
     * @type {FinancialSynthesis}
     * @memberof ClientEntity
     */
    public financialSynthesis?: FinancialSynthesis;

    /**
     * @description Alerts events of the client - Type, params of the alerts events of the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/09/2024
     * @type {AlertsEvents[]}
     * @memberof ClientEntity
     */
    public alertsEvents?: AlertsEvents[];
}
