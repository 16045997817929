import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    AssetTypes,
    BankTransactionDetailsForm,
    BankTransactionEntity,
    ChargeCategoryInfo,
    ChargeEntity,
    ChargePeriodicity,
    ChargePeriodicityInfo,
    IncomeCategoryInfo,
    IncomeEntity,
    IncomePeriodicity,
    IncomePeriodicityInfo,
    PropertyEntity,
    SelectOption,
    SocietyEntity,
    UserEntity,
} from '@omedom/data';
import {
    ChargeService,
    IncomeService,
    LimitSearchParameter,
    PropertyService,
    SocietyService,
    SortSearchParameter,
    UserService,
    WhereSearchParameter,
} from '@omedom/services';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { elementAnimation } from '../../../../animations';

@Component({
    selector: 'omedom-bank-transaction-details-step',
    templateUrl: './bank-transaction-details-step.container.html',
    styleUrls: ['./bank-transaction-details-step.container.scss'],
    animations: [elementAnimation],
})
export class BankTransactionDetailsStepContainer implements OnInit, OnDestroy {
    /**
     * @description The transaction to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @type {BankTransactionEntity}
     * @memberof BankTransactionDetailsStepContainer
     */
    @Input({ required: true })
    public transaction?: BankTransactionEntity;

    /**
     * @description Patrimony UID to associate the transaction with a treasury item (charge or income) in the user's account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @type {PropertyEntity | SocietyEntity}
     * @memberof BankTransactionDetailsStepContainer
     */
    @Input({ required: true })
    public patrimonyUID?: string;

    /**
     * @description Patrimony type to retrieve the treasuries (charges and incomes)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @type {AssetTypes}
     * @memberof BankTransactionDetailsStepContainer
     */
    @Input({ required: true })
    public patrimonyType?: AssetTypes;

    /**
     * @description Patrimony to associate the transaction with a treasury item (charge or income) in the user's account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @private
     * @type {(PropertyEntity | SocietyEntity)}
     * @memberof BankTransactionDetailsStepContainer
     */
    private patrimony?: PropertyEntity | SocietyEntity;

    /**
     * @description Category identified in the previous form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @type {string}
     * @memberof BankTransactionDetailsStepContainer
     */
    @Input({ required: true })
    public category?: string;

    /**
     * @description User data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @type {Observable<UserEntity>}
     * @memberof BankTransactionDetailsStepContainer
     */
    public user$?: Observable<UserEntity>;

    /**
     * @description Treasuries list (charges and incomes) to display in the form to associate the transaction with a treasury item (charge or income) in the user's account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @type {((ChargeEntity | IncomeEntity)[])}
     * @memberof BankTransactionDetailsStepContainer
     */
    public treasuries: (ChargeEntity | IncomeEntity)[] = [];

    /**
     * @description Options to display in the select input to choose the treasury item to associate with the transaction in the user's account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @type {SelectOption[]}
     * @memberof BankTransactionDetailsStepContainer
     */
    public treasuriesOptions: SelectOption[] = [];

    /**
     * @description Options to display in the select input to choose the periodicity of the treasury item to associate with the transaction in the user's account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @type {SelectOption[]}
     * @memberof BankTransactionDetailsStepContainer
     */
    public periodicityOptions: SelectOption[] = [];

    /**
     * @description List of subscriptions to unsubscribe when the component is destroyed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @private
     * @type {Subscription[]}
     * @memberof BankTransactionDetailsStepContainer
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description Label to display in the form to associate the transaction with a treasury item (charge or income) in the user's account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @memberof BankTransactionDetailsStepContainer
     */
    public selectLabel = 'Associer à une charge ou un revenu';

    /**
     * @description Name of the checkbox to know if the transaction has to create a new entity (charge or income)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @memberof BankTransactionDetailsStepContainer
     */
    public checkboxLabel = 'Nouvelle charge ou nouveau revenu';

    /**
     * @description Form to associate the transaction with a treasury item (charge or income) in the user's account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @type {FormGroup<BankTransactionDetailsForm>}
     * @memberof BankTransactionDetailsStepContainer
     */
    @Input({ required: true })
    public form?: FormGroup<BankTransactionDetailsForm>;

    /**
     * @description Label to display when waiting for data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @memberof BankTransactionDetailsStepContainer
     */
    public pendingLabel = 'En attente';

    /**
     * @description Placeholder to display in the select input to choose the treasury item to associate with the transaction in the user's account when no treasury item is selected yet
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @type {SelectOption}
     * @memberof BankTransactionDetailsStepContainer
     */
    public treasuriesPlaceholder: SelectOption = {
        label: 'Choisir dans ma liste de charges ou revenus',
        id: null,
    };

    /**
     * @description Label to display when no treasury item is found in the user's account to associate with the transaction in the user's account when no treasury item is selected yet
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @memberof BankTransactionDetailsStepContainer
     */
    public emptyTreasuriesLabel =
        'Aucune charge ou revenu trouvé correspondant à cette catégorie et à ce patrimoine';

    constructor(
        private userService: UserService,
        private chargeService: ChargeService,
        private incomeService: IncomeService,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private currencyPipe: CurrencyPipe,
    ) {}

    ngOnInit(): void {
        this.user$ = this.userService.user$;

        if (!this.transaction) {
            return;
        }

        // Check the transaction if it's an income or a charge
        if (this.transaction.amount > 0) {
            this.selectLabel = 'Associer à un revenu';
            this.checkboxLabel = 'Nouveau revenu';
            this.pendingLabel = 'Chargement des revenus...';
            this.treasuriesPlaceholder.label = 'Choisir dans ma liste de revenus';
            this.emptyTreasuriesLabel =
                'Aucun revenu trouvé correspondant à cette catégorie et à ce patrimoine';
        } else {
            this.selectLabel = 'Associer à une charge';
            this.checkboxLabel = 'Nouvelle charge';
            this.pendingLabel = 'Chargement des charges...';
            this.treasuriesPlaceholder.label = 'Choisir dans ma liste de charges';
            this.emptyTreasuriesLabel =
                'Aucune charge trouvée correspondant à cette catégorie et à ce patrimoine';
        }

        // Retrieve periodicity options
        const chargesPeriodicities = Object.values(ChargePeriodicity).map(
            (periodicity) => new ChargePeriodicityInfo(periodicity),
        );
        const incomesPeriodicities = Object.values(IncomePeriodicity).map(
            (periodicity) => new IncomePeriodicityInfo(periodicity),
        );
        const periodicitiesToDisplay =
            this.transaction.amount > 0 ? incomesPeriodicities : chargesPeriodicities;

        this.periodicityOptions = periodicitiesToDisplay.map(
            (periodicity: ChargePeriodicityInfo | IncomePeriodicityInfo) => ({
                id: periodicity.periodicity,
                label: periodicity.label,
            }),
        );

        this.fetchPatrimony();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @description Fetch treasuries of the patrimony selected and filtered by category
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @private
     * @memberof BankTransactionDetailsStepContainer
     */
    private fetchTreasuries(): void {
        if (!this.user$) {
            return;
        }

        this.subscriptions.push(
            this.user$
                .pipe(
                    switchMap((user) => {
                        if (!this.patrimony || !this.category) {
                            return [];
                        }
                        return combineLatest([
                            this.chargeService._search(this.getParameters(user)),
                            this.incomeService._search(this.getParameters(user)),
                        ]);
                    }),
                )
                .subscribe(([charges, incomes]) => {
                    this.treasuries = [...charges, ...incomes];

                    // Get the category and periodicity info to display in the select input
                    const categoryInfo = (treasury: ChargeEntity | IncomeEntity) => {
                        return treasury instanceof IncomeEntity
                            ? new IncomeCategoryInfo(treasury.category)
                            : new ChargeCategoryInfo(treasury.category);
                    };

                    const periodicityInfo = (treasury: ChargeEntity | IncomeEntity) => {
                        return treasury instanceof IncomeEntity
                            ? new IncomePeriodicityInfo(treasury.periodicity)
                            : new ChargePeriodicityInfo(treasury.periodicity);
                    };

                    this.treasuriesOptions = this.treasuries.map((treasury) => ({
                        id: treasury.uid,
                        label: ` ${categoryInfo(treasury).label}${
                            treasury.debitDate?.toDate()
                                ? ` le ${treasury.debitDate?.toDate().getDate()}`
                                : ''
                        } ${periodicityInfo(treasury).label} de ${this.currencyPipe.transform(
                            treasury.amount,
                            'EUR',
                        )}`,
                        icon: categoryInfo(treasury).icon,
                        categoryInfo: categoryInfo(treasury),
                        notes: treasury.notes,
                        date: treasury.debitDate?.toDate(),
                        amount: treasury.amount,
                        periodicity: periodicityInfo(treasury).label,
                    }));

                    // If no treasuries options, reset treasuryUID value and set isNewTreasury to true
                    if (this.treasuriesOptions.length === 0) {
                        this.form?.controls.treasuryUID.setValue(null);
                        this.form?.controls.isNewTreasury.setValue(true);
                    }
                }),
        );
    }

    /**
     * @description Get the patrimony data from the database using the patrimony UID and type
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @private
     * @memberof BankTransactionDetailsStepContainer
     */
    private fetchPatrimony(): void {
        if (!this.patrimonyUID || !this.patrimonyType) {
            return;
        }

        if (
            this.patrimonyType === AssetTypes.property ||
            this.patrimonyType === AssetTypes.building
        ) {
            this.subscriptions.push(
                this.propertyService._get(this.patrimonyUID).subscribe((property) => {
                    this.patrimony = property;
                    this.fetchTreasuries();
                }),
            );
        } else {
            this.subscriptions.push(
                this.societyService._get(this.patrimonyUID).subscribe((society) => {
                    this.patrimony = society;
                    this.fetchTreasuries();
                }),
            );
        }
    }

    /**
     * @description Get the search parameters to retrieve the treasuries (charges and incomes) from the database using the user data, the category and the patrimony UID or society UID depending on the patrimony type (property or society) to associate the transaction with a treasury item (charge or income) in the user's account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @private
     * @param {UserEntity} user
     * @returns {((WhereSearchParameter | SortSearchParameter | LimitSearchParameter)[])}
     * @memberof BankTransactionDetailsStepContainer
     */
    private getParameters(
        user: UserEntity,
    ): (WhereSearchParameter | SortSearchParameter | LimitSearchParameter)[] {
        if (!this.patrimony || !this.category) {
            console.error('Patrimony or category not defined');
            return [];
        }
        return [
            {
                where: 'userUID',
                operator: '==',
                value: user.uid,
            },
            {
                where: 'category',
                operator: '==',
                value: this.category,
            },
            {
                where: this.patrimony instanceof PropertyEntity ? 'propertyUID' : 'societyUID',
                operator: '==',
                value: this.patrimony.uid,
            },
        ];
    }

    /**
     * @description Check if the treasury item to associate with the transaction in the user's account is a new entity (charge or income) or an existing one in the database
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @readonly
     * @type {boolean}
     * @memberof BankTransactionDetailsStepContainer
     */
    public get isTreasurySelected(): boolean {
        return !!this.form?.controls.treasuryUID.value;
    }

    /**
     * @description Check if the transaction has to create a new entity (charge or income)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @readonly
     * @type {boolean}
     * @memberof BankTransactionDetailsStepContainer
     */
    public get isNewTreasury(): boolean {
        return this.form?.controls.isNewTreasury.value ?? true;
    }

    /**
     * @description Check if the treasury selected is already associated with a transaction
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @readonly
     * @type {boolean}
     * @memberof BankTransactionDetailsStepContainer
     */
    public get isAlreadyAssociated(): boolean {
        const treasuryUID = this.form?.controls.treasuryUID.value;

        // Check if treasury uid is defined
        if (!treasuryUID) {
            return false;
        }

        const treasury = this.treasuries.find((treasury) => treasury.uid === treasuryUID);

        // Check if treasury is defined
        if (!treasury) {
            return false;
        }

        return !!treasury?.history?.find(
            (history) => history.transactionID === this.transaction?.bridgeID,
        );
    }
}
