import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { RestEntity } from '@omedom/data';

import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root',
})
export class SettingService<T extends RestEntity> extends RestService<T> {
    constructor(
        protected override firestore: AngularFirestore,
        protected auth: AngularFireAuth,
    ) {
        super(firestore, 'settings');
    }
}
