import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { IonModal, ToastController } from '@ionic/angular';
import {
    AssetCardLayout,
    AssetType,
    ChargeEntity,
    IncomeEntity,
    Mode,
    OwningType,
    PropertyEntity,
    PropertyTab,
    PropertyType,
    SelectOption,
    SocietyEntity,
    Story,
    UserEntity,
} from '@omedom/data';
import { OmedomEnvironment } from '@omedom/environment';
import {
    ChargeService,
    HeaderDropdownClickService,
    IncomeService,
    PropertyService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { OmedomMovement } from '@omedom/utils';
import * as lodash from 'lodash';
import { BehaviorSubject, combineLatest, of, Subscription, tap } from 'rxjs';

@Component({
    selector: 'omedom-property-card',
    templateUrl: './property-card.component.html',
    styleUrls: ['./property-card.component.scss'],
})
export class PropertyCardComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * @description Property Data
     * @author Jérémie Lopez
     * @type {PropertyEntity}
     * @memberof PropertyCardComponent
     */
    @Input() property?: PropertyEntity;

    /**
     * @description Used to remove a property from the parent building
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 31/07/2024
     * @public
     * @memberof PropertyCardComponent
     */
    @Input()
    public canRemoveFromBuilding = false;

    /**
     * @description Used to remove a property from the parent society
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 31/07/2024
     * @public
     * @memberof PropertyCardComponent
     */
    @Input()
    public canRemoveFromSociety = false;

    /**
     * @description Rentals from property
     * @author Jérémie Lopez
     * @type {IncomeEntity[]}
     * @memberof PropertyCardComponent
     */
    incomes?: IncomeEntity[];

    /**
     * @description Charges from property
     * @author Hanane Djeddal
     * @type {ChargeEntity[]}
     * @memberof PropertyCardComponent
     */
    charges?: ChargeEntity[];

    /**
     * @description Show the remove society button
     * @author Jérémie Lopez
     * @memberof PropertyCardComponent
     */
    @Input() showRemoveSociety = false;

    /**
     * @description Show properties in list or card view (list view by default)
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {boolean}
     * @memberof PropertyCardComponent
     * @default true
     */
    @Input() listView = true;

    /**
     * @description
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {boolean}
     * @memberof PropertyCardComponent
     * @default true
     */
    @Input() layout = AssetCardLayout.Default;
    protected assetCardLayout = AssetCardLayout;

    /**
     * @description If this property is Link to a building, and can be remove from it
     * @author ANDRE Felix
     * @memberof PropertyCardComponent
     */
    @Input() isLinkToBuilding = false;

    /**
     * @description Emit when need to refresh data from the parent component
     * @author Jérémie Lopez
     * @memberof PropertyCardComponent
     */
    @Output() refresh = new EventEmitter();

    @Output() propertyClick: EventEmitter<PropertyTab> = new EventEmitter();

    @Input() mode: Mode = Mode.app;

    protected allMode = Mode;

    @Input() showPastAndFutureMovements = true;

    @Input() showValuation = false;

    @Input()
    public showParentAsset = false;

    /**
     * @description Society of the property
     * @author Jérémie Lopez
     * @type {SocietyEntity}
     * @memberof PropertyCardComponent
     */
    society?: SocietyEntity;

    parentProperty?: PropertyEntity;

    isBuilding = false;

    lastMouvement?: Story;

    nextMouvement?: Story;

    date = new Date().toUTC();

    owningPlaceholder = {
        id: null,
        label: 'Détention',
    } as SelectOption;

    owningOptions$ = of([
        {
            id: OwningType.proper,
            label: OwningType.proper,
        } as SelectOption,
        {
            id: OwningType.community,
            label: OwningType.community,
        } as SelectOption,
        {
            id: OwningType.indivision,
            label: OwningType.indivision,
        } as SelectOption,
    ]);

    owningTypes = OwningType;
    newOwningTypeId?: OwningType;

    pending$ = new BehaviorSubject<boolean>(false);
    user?: UserEntity;

    private subscriptions: Subscription[] = [];

    /**
     * @description If the property
     *
     * @memberof PropertyCardComponent
     */
    isAccessible = false;

    constructor(
        private societyService: SocietyService,
        private propertyService: PropertyService,
        private toast: ToastController,
        private userService: UserService,
        private incomeService: IncomeService,
        private chargeService: ChargeService,
        private HeaderDropdownClickService: HeaderDropdownClickService,
        private router: Router,
        private readonly ref: ElementRef,
    ) { }

    public get isSharedProperty(): boolean {
        return !!this.property && !!this.user && this.property?.userUID !== this.user?.uid;
    }

    public get gridTemplateColumns() {
        if (this.mode === this.allMode.desktop) {
            if (this.showPastAndFutureMovements && this.showValuation) {
                return 'grid-template-columns: calc(33% - 40px) calc(67% - 30px) 30px';
            } else if (this.showPastAndFutureMovements && !this.showValuation) {
                return 'grid-template-columns:calc(33% - 15px) calc(67% - 5px)';
            } else if (this.showValuation) {
                return 'grid-template-columns: 1fr 30px';
            } else {
                return 'grid-template-columns: 1fr';
            }
        }
        return '';
    }

    public get parentAsset(): AssetType | undefined {
        return this.parentProperty ?? this.society;
    }

    async ngOnInit(): Promise<void> {
        if (!this.property) {
            return;
        }

        // Check if the property is accessible
        this.isBuilding = this.property.type === PropertyType.immeuble;
        this.isAccessible = this.propertyService.isPropertyAccessible(this.property);

        if (this.showPastAndFutureMovements) {
            const charges$ = this.chargeService._getChargesByProperty(this.property.uid);
            const incomes$ = this.incomeService._getIncomesByProperty(this.property.uid);
            const treasury$ = combineLatest([charges$, incomes$]).subscribe(
                ([charges, incomes]) => {
                    this.charges = charges;
                    this.incomes = incomes;
                    this.getMovement();
                },
            );
            this.subscriptions.push(treasury$);
        }
        const user$ = this.userService.user$
            .pipe(tap((user) => this.getParentAsset(user, this.property)))
            .subscribe((user) => (this.user = user));

        this.subscriptions.push(user$);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const changesProperty = changes['property'];
        if (changesProperty && changesProperty.currentValue) {
            this.isAccessible = this.propertyService.isPropertyAccessible(
                changesProperty.currentValue,
            );

            this.getParentAsset(this.user, changesProperty.currentValue);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @description Get the parent asset of the property (society or parent property) if the user has access to it
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/01/2025
     * @param {UserEntity} [user]
     * @param {PropertyEntity} [property]
     * @returns {*}  {Promise<void>}
     * @memberof PropertyCardComponent
     */
    async getParentAsset(user?: UserEntity, property?: PropertyEntity): Promise<void> {
        if (!user || !property) {
            return;
        }

        const societiesAndSharedUID = [
            ...(user.societiesUID ?? []),
            ...(user.sharedSocietiesUID ?? []),
        ];

        const propertiesAndSharedUID = [
            ...(user.propertiesUID ?? []),
            ...(user.sharedPropertiesUID ?? []),
        ];

        try {
            if (
                property.parentPropertyUID &&
                propertiesAndSharedUID.includes(property.parentPropertyUID)
            ) {
                this.parentProperty = await this.propertyService.get(property.parentPropertyUID);
            }
        } catch (error) {
            if (OmedomEnvironment.currentEnvionment === 'development') {
                console.error(error);
            }
        }

        try {
            if (property.societyUID && societiesAndSharedUID.includes(property.societyUID)) {
                this.society = await this.societyService.get(property.societyUID);
            }
        } catch (error) {
            if (OmedomEnvironment.currentEnvionment === 'development') {
                console.error(error);
            }
        }
    }

    /**
     * @description calculate the next and previous movements
     * @author Hanane Djeddal
     * @private
     * @memberof PropertyCardComponent
     */
    private getMovement(): void {
        if (!this.property || !this.incomes || !this.charges) {
            return;
        }

        const incomes = lodash
            .cloneDeep(this.incomes)
            .filter((x) => x.propertyUID === this.property?.uid);
        const charges = lodash
            .cloneDeep(this.charges)
            .filter((x) => x.propertyUID === this.property?.uid);

        const pastStories = OmedomMovement.getPastMovement(
            charges,
            incomes,
            [this.property],
            this.date,
            false,
            false,
        );
        const futureStories = OmedomMovement.getNextMovement(
            charges,
            incomes,
            [this.property],
            this.date,
            false,
            false,
        );
        const lastMouvement = pastStories.filter((h) => h.date <= this.date).slice(-1)[0];
        const nextMouvement = futureStories.filter((h) => h.date >= this.date).slice(0, 1)[0];

        const emptyStory = {
            propertyImg: '',
            propertyName: '',
            uid: '',
            categoryInfo: null,
            periodicityInfo: null,
            amount: null,
            isCharge: true,
            isReaded: false,
            isPayed: false,
            designation: '',
            date: null,
            userUID: '',
        } as any as Story;
        this.lastMouvement = this.isAccessible && lastMouvement ? lastMouvement : emptyStory;
        this.nextMouvement = this.isAccessible && nextMouvement ? nextMouvement : emptyStory;
    }

    /**
     * @description Remove society from property and update property in database
     * @author Jérémie Lopez
     * @param {IonModal} modal
     * @return {*}  {Promise<void>}
     * @memberof PropertyCardComponent
     */
    public async removeSociety(modal: IonModal): Promise<void> {
        this.pending$.next(true);

        try {
            if (!this.property || !this.newOwningTypeId) {
                throw new Error('Missing property or owning type');
            }

            this.property.societyUID = null as any;

            if (this.property.purchaseDetails) {
                this.property.purchaseDetails.owning = this.newOwningTypeId;
            }
            await this.propertyService.update(this.property);

            const toast = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: 'Ce bien a été retiré de la société.',
            });
            await toast.present();

            this.refresh.emit();
            this.pending$.next(false);

            modal.dismiss();
        } catch (error) {
            console.error(error);
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
            this.pending$.next(false);
        }
    }

    public async unlinkProperty(modal: IonModal) {
        if (!this.property) {
            return;
        }
        try {
            const parentProperty = await this.propertyService.get(this.property.parentPropertyUID);
            if (!parentProperty) {
                return;
            }
            const index = parentProperty.lotsUID.indexOf(this.property.uid);

            if (index > -1) {
                parentProperty.lotsUID.splice(index, 1);
            }
            // We remove the current property uid in the parent
            await this.propertyService.update({
                uid: this.property.parentPropertyUID,
                lotsUID: parentProperty.lotsUID,
            });

            await this.propertyService.update({
                uid: this.property.uid,
                parentPropertyUID: '',
            });
            const toast = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: "Ce bien a été retiré de l'immeuble.",
            });
            await toast.present();

            this.refresh.emit();
            this.pending$.next(false);

            modal.dismiss();
        } catch (err) {
            console.error(err);
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
            this.pending$.next(false);
        }
    }

    public openModal(modal: IonModal): void {
        modal.present();
    }
    /**
     * @description navigate to the property info and store property.uid in service
     * @author ANDRE Felix
     * @memberof PropertyCardComponent
     */
    public onPropertyClick(): void {
        if (!this.property) {
            return;
        }
        this.HeaderDropdownClickService.setAssetUID(this.property.uid);
        this.propertyClick.emit(PropertyTab.info);
    }

    /**
     * @description Get the string address of the asset
     * @author Brisset Killian
     * @date 06/06/2024
     * @param {(PropertyEntity )} property
     * @returns {*}  {string}
     * @memberof PropertyCardComponent
     */
    public getStringAddress(property: PropertyEntity | undefined): string {
        if (property) {
            const streetNumber = property?.address?.streetNumber
                ? property?.address?.streetNumber + ' '
                : '';
            const street = property?.address?.street ?? '';

            const postalCode = property?.address?.postalCode
                ? property?.address?.postalCode + ' '
                : '';
            const city = property?.address?.city ?? '';

            const addressArray = [
                property?.address?.addressLine2,
                streetNumber + street,
                postalCode + city,
            ];

            return addressArray.filter((x) => x?.replace(/\s/g, '')).join(', ');
        }
        return '';
    }
}
