@if (!(isAssimilateHouse || isAssimilateAppartement)) {
    <omedom-info
        iconStart="uil uil-exclamation-triangle"
        information="La valorisation d'un bien est disponible uniquement pour les biens de type maison, appartement, immeuble, hôtel et chalet"
    ></omedom-info>
}
@if (isPropertyTypeValidForValuation) {
    @if (canStartValuation) {
        <omedom-refresh-estimation
            [hasValorisation]="hasValorisation"
            (refreshEstimation)="refreshPricehubble()"
        ></omedom-refresh-estimation>
    }
    @if (propertyValuation && canAccessValuation) {
        <div>
            <div class="valo-title margin-bottom-2">
                Estimation de la valeur du bien
            </div>
            <div class="valuation-container margin-bottom-2">
                <div class="valuation-price margin-right-2">
                    <div class="margin-bottom-1 sub-title">Valeur estimée</div>
                    <span>{{ propertyValuation.value | number }} €</span>
                    <span class="meter-price"
                        >{{ meterSquarePriceAvg | number }} € / m<sup
                            >2</sup
                        ></span
                    >
                </div>
                <div
                    class="valuation-price valuation-interval"
                    style="font-weight: 400"
                >
                    <div class="margin-bottom-1">Fourchette</div>
                    <span
                        >{{ propertyValuation.valueRange.lower | number }} -
                        {{ propertyValuation.valueRange.upper | number }}
                        €</span
                    >
                    <span class="meter-price"
                        >{{ meterSquarePriceLower | number }} -
                        {{ meterSquarePriceUpper | number }} € / m<sup
                            >2</sup
                        ></span
                    >
                </div>
            </div>
            <div class="information-pertinance margin-top-1">
                <span class="information-pertinance">
                    Dernière mise à jour :
                    {{ lastValuationUpdate }}. Indice de confiance de
                    l'estimation :
                </span>

                <span class="bold" [style.color]="valuationConfidenceColor">
                    {{ valuationConfidence }}
                </span>
            </div>
            <hr class="omedom-divider" />
        </div>
        <div>
            <omedom-valuation-rent-estimation
                [rentValuation]="property.valuation?.valuationRentGross"
                [propertySurface]="property.livingSpace"
            ></omedom-valuation-rent-estimation>
        </div>
    }

    @if (!propertyValuation || !canAccessValuation) {
        <omedom-info
            iconStart="uil uil-info-circle"
            information="Pour obtenir une estimation de votre bien, veuillez commencer la valorisation"
        ></omedom-info>
    }
}
