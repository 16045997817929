import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Color, defaultRoleState, RoleState } from '@omedom/data';
import { HeaderDropdownClickService } from '@omedom/services';
import { Subscription } from 'rxjs';

import { AlertComponent } from '../alert/alert.component';
import { OmedomTab } from '../tab/omedom-tab';
import { HeaderPreviousType } from './header-previous-type';

@Component({
    selector: 'omedom-header',
    templateUrl: './header.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
    /**
     * @description Label of the header
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {string}
     * @memberof HeaderComponent
     */
    @Input() label?: string;

    /**
     * @description Image of the header
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {string}
     * @memberof HeaderComponent
     */
    @Input() img?: string;

    /**
     * @description Icon of the header (i icon class) (for example: 'icon-home') (default: 'icon-home')
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {string}
     * @memberof HeaderComponent
     */
    @Input() icon?: string;

    /**
     * @description Background color of the icon
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {string}
     * @memberof HeaderComponent
     */
    @Input() backgroundIcon?: string;

    /**
     * @description Header previous type (default: previous) (previous, close)
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof HeaderComponent
     */
    @Input() previousType = HeaderPreviousType.previous;

    /**
     * @description Previous url redirected on click on previous
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {string}
     * @memberof HeaderComponent
     */
    @Input() previousUrl?: string;

    /**
     * @description Use to back to the previous page
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {boolean}
     * @memberof HeaderComponent
     */
    @Input() useNavController?: boolean;

    /**
     * @description Tabs of the header
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {OmedomTab[]}
     * @memberof HeaderComponent
     */
    @Input() tabs?: OmedomTab[];

    /**
     * @description Selected tab of the header
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {OmedomTab}
     * @memberof HeaderComponent
     */
    @Input() selectedTab?: OmedomTab;

    /**
     * @description If it is society add society icon
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {boolean}
     * @memberof HeaderComponent
     */
    @Input() isSociety?: boolean;

    /**
     * @description If it is shared add shared icon
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {boolean}
     * @memberof HeaderComponent
     */
    @Input() isShared?: boolean;

    /**
     * @description Role state of the user
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {RoleState}
     * @memberof HeaderComponent
     */
    @Input() roleState: RoleState = defaultRoleState;

    /**
     *@description: If the header tab contains an information popover
     * @author: Hanane Djeddal.
     * @type {string}
     * @memberof HeaderComponent
     */
    @Input() popover?: string | string[];

    /**
     * @description: If the avatar of the header tab has a background (case of property) or not
     * @author: Hanane Djeddal.
     * @type {string}
     * @memberof HeaderComponent
     */
    @Input() avatarWithBg?: string;

    /**
     * @description event emitted when the selected tab changes
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof HeaderComponent
     */
    @Output() selectedTabChange = new EventEmitter<OmedomTab>();

    /**
     * @description event emitted when the dropdown button is clicked
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof HeaderComponent
     */
    headerPreviousType = HeaderPreviousType;

    /**
     * @description Colors of the header component
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof HeaderComponent
     */
    colors = Color;

    /**
     * @description Subscription for the header component
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {Subscription}
     * @memberof HeaderComponent
     */
    subscription?: Subscription;

    constructor(
        private navController: NavController,
        private modalController: ModalController,
        private headerDropdownClickService: HeaderDropdownClickService,
        private route: ActivatedRoute
    ) { }

    /**
     * @description Check if the header has navigation (previous or useNavController) to display the back button or not
     * @author Brisset Killian
     * @date 26/04/2024
     * @readonly
     * @type {boolean}
     * @memberof HeaderComponent
     */
    get hasNavigation(): boolean {
        return !!this.previousUrl || !!this.useNavController;
    }

    /**
     * @description Back to the previous page or pop the page if there is no previous page to go back to the previous page
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof HeaderComponent
     */
    back(): void {
        if (window.history.state && window.history.state.navigationId > 0) {
            this.navController.back({
                animationDirection: 'back',
                animated: true,
            });
        } else {
            this.navController.pop();
        }
    }

    /**
     * @description Show a alert modal when the exit button is clicked
     * @author Brisset Killian
     * @date 26/04/2024
     * @param {string} [label]
     * @param {string} [icon]
     * @param {string} [img]
     * @memberof HeaderComponent
     */
    async exitClicked(label?: string, icon?: string, img?: string) {
        const modal = await this.modalController.create({
            component: AlertComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: { title: label, avatar: icon, image: img },
        });

        modal.present();
    }

    /**
     * @description Navigate to the url passed in parameter when the header is clicked
     * @author Brisset Killian
     * @date 26/04/2024
     * @param {string} url
     * @memberof HeaderComponent
     */
    navigateTo(url: string): void {
        this.navController.navigateBack(url);
    }

    /**
     * @description appel a un service pour afficher la page d'edition d'une propriété
     * @author ANDRE Felix
     * @memberof HeaderComponent
     */
    editClicked(): void {
        this.headerDropdownClickService.editClicked(this.route);
    }

    /**
     * @description affiche la modal de suppression d'un bien
     * @author ANDRE Felix
     * @memberof HeaderComponent
     */
    deleteClicked(): void {
        this.headerDropdownClickService.deleteClicked();
    }
}
