import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'omedomArrayFilter',
})
@Injectable({ providedIn: 'root' })
export class OmedomArrayFilterPipe<T> implements PipeTransform {

    transform(items: T[], property: keyof T, value: T, isEqual: boolean = true): T[] {
        if (!items || !property || value === undefined) return items;

        return items.filter(item => {
            if (isEqual) {
                return item[property] === value;
            } else {
                return item[property] !== value;
            }
        });
    }
}
