import { Component, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
    selector: 'omedom-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
    /**
     * @description Avatar of the alert
     * @author Brisset Killian
     * @date 25/04/2024
     * @type {string}
     * @memberof AlertComponent
     */
    @Input() avatar?: string;

    /**
     * @description Title of the alert
     * @author Brisset Killian
     * @date 25/04/2024
     * @type {string}
     * @memberof AlertComponent
     */
    @Input() title?: string;

    /**
     * @description Image of the alert
     * @author Brisset Killian
     * @date 25/04/2024
     * @type {string}
     * @memberof AlertComponent
     */
    @Input() image?: string;

    constructor(private modalController: ModalController, private navController: NavController) { }

    /**
     * @description Dismiss the alert modal
     * @author Brisset Killian
     * @date 25/04/2024
     * @returns {*}  {Promise<void>}
     * @memberof AlertComponent
     */
    async dismiss(): Promise<void> {
        await this.modalController.dismiss('continue');
    }

    /**
     * @description Go back to the previous page and dismiss the alert modal
     * @author Brisset Killian
     * @date 25/04/2024
     * @memberof AlertComponent
     */
    back() {
        this.dismiss();
        this.navController.back();
    }
}
