<div class="omedom-modal">
    <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
            <i
                class="uil uil-trash-alt color-light-black background-color-light-orange"
            ></i>
            <h3>Suppression de banque</h3>
        </div>
        <i class="uil uil-times-circle" (click)="dismiss()"></i>
    </div>
    <div class="omedom-modal-content">
        <p>Êtes-vous sûr⋅e de vouloir supprimer cette banque ?</p>
    </div>
    <div class="omedom-modal-buttons">
        <button
            class="omedom-button-secondary"
            [disabled]="(state$ | async) === 'pending'"
            (click)="dismiss()"
        >
            Annuler
        </button>
        <button
            class="omedom-button-warn"
            [disabled]="(state$ | async) === 'pending'"
            (click)="delete()"
        >
            <i class="uil uil-trash-alt"></i>
            {{ message$ | async }}
            @if ((state$ | async) === 'pending') {
                <ion-spinner></ion-spinner>
            }
        </button>
    </div>
</div>
