import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import {
    ConfigurationEntity,
    DocumentEntity,
    PropertyContestEntity,
    PropertyEntity,
    PropertyPurchaseDetails,
    SelectOption,
    UseProperty,
    UserEntity,
} from '@omedom/data';
import { ConfigurationService, PropertyContestService, PropertyService, UserService } from '@omedom/services';
import { OmedomForm } from '@omedom/utils';
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    filter,
    lastValueFrom,
    Observable,
    of,
    Subscription,
    switchMap,
    take,
} from 'rxjs';

import { elementAnimation } from '../../../animations';

interface RawPropertyContestForm {
    photo: File | null;
    photoExtension: string | null;
    photoName: string | null;
    propertyUID: string | null;
    name: string | null;
    use: UseProperty | null;
    newProperty: boolean;
    description: string;
}

interface PropertyContestForm {
    photo: FormControl<RawPropertyContestForm['photo']>;
    photoExtension: FormControl<RawPropertyContestForm['photoExtension']>;
    photoName: FormControl<RawPropertyContestForm['photoName']>;
    propertyUID: FormControl<RawPropertyContestForm['propertyUID']>;
    name: FormControl<RawPropertyContestForm['name']>;
    use: FormControl<RawPropertyContestForm['use']>;
    newProperty: FormControl<RawPropertyContestForm['newProperty']>;
    description: FormControl<RawPropertyContestForm['description']>;
}

@Component({
    selector: 'omedom-property-contest-form',
    templateUrl: './property-contest-form.container.html',
    styleUrls: ['./property-contest-form.container.scss'],
    animations: [elementAnimation],
})
export class PropertyContestFormContainer implements OnInit, OnDestroy {
    /**
     * @description State of the component
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 09/12/2024
     * @memberof PropertyContestFormContainer
     */
    public _state = new BehaviorSubject<'ok' | 'pending' | 'error'>('pending');

    /**
     * @description User entity
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 09/12/2024
     * @type {UserEntity}
     * @memberof PropertyContestFormContainer
     */
    public user!: UserEntity;

    /**
     * @description Properties options observable
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 09/12/2024
     * @type {Observable<SelectOption[]>}
     * @memberof PropertyContestFormContainer
     */
    public propertiesOptions$: Observable<SelectOption[] | null> = of(null);

    /**
     * @description Configuration observable for the user configuration
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @private
     * @memberof PropertyContestFormContainer
     */
    private configuration$ = this.configurationService.getUserConfiguration();

    /**
     * @description Options for the use select input in the property contest form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 09/12/2024
     * @memberof PropertyContestFormContainer
     */
    useOptions$ = of([
        {
            id: UseProperty.principale,
            label: UseProperty.principale,
        },
        {
            id: UseProperty.secondaire,
            label: UseProperty.secondaire,
        },
        {
            id: UseProperty.locatifParticulier,
            label: UseProperty.locatifParticulier,
        },
        {
            id: UseProperty.locatifCommercial,
            label: UseProperty.locatifCommercial,
        },
        {
            id: UseProperty.locatifPro,
            label: UseProperty.locatifPro,
        },
    ] as SelectOption[]);

    /**
     * @description Placeholder for the use select input in the property contest form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 09/12/2024
     * @memberof PropertyContestFormContainer
     */
    usePlaceholder = {
        id: null,
        label: 'Usage du bien',
    } as SelectOption;

    /**
     * @description Form group for the property contest form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 09/12/2024
     * @type {FormGroup<PropertyContestForm>}
     * @memberof PropertyContestFormContainer
     */
    public propertyContestForm?: FormGroup<PropertyContestForm>;

    /**
     * @description Subscriptions array for the component
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 09/12/2024
     * @private
     * @type {Subscription[]}
     * @memberof PropertyContestFormContainer
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description Boolean to check if the user can add a property or not
     * based on the user configuration and the number of properties he has already added
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @type {boolean}
     * @memberof PropertyContestFormContainer
     */
    public canAddProperty: boolean = false;

    /**
     * @description Property contest entity if the user has already added a property contest
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/12/2024
     * @private
     * @type {PropertyContestEntity}
     * @memberof PropertyContestFormContainer
     */
    public propertyContest?: PropertyContestEntity;

    /**
     * @description Boolean to check if the form is editable or not
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/12/2024
     * @private
     * @type {boolean}
     * @memberof PropertyContestFormContainer
     */
    public isEditable: boolean = false;

    /**
     * @description Getter for the newProperty form control value in the property contest form group
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @readonly
     * @type {boolean}
     * @memberof PropertyContestFormContainer
     */
    public get newProperty(): boolean {
        return this.propertyContestForm?.get('newProperty')?.value ?? false;
    }

    /**
     * @description If true, we are dragging a file in the drop zone
     * @type {boolean}
     * @author Brisset Killian
     * @date 10/12/2024
     * @memberof PropertyContestFormContainer
     */
    public isDragOver: boolean = false;

    /**
     * @description True if the type is different from Img
     * @type {boolean}
     * @author Brisset Killian
     * @date 10/12/2024
     * @memberof PropertyContestFormContainer
     */
    public isValidFileType: boolean = true;

    public showExplanation: boolean = false;

    /**
     * @description File in the app
     * @author Brisset Killian
     * @type {File}
     * @date 10/12/2024
     * @memberof DocumentFormComponent
     */
    public file?: File;

    /**
     * @description If file has changed
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @type {boolean}
     * @date 13/12/2024
     * @memberof PropertyContestFormContainer
     */
    public fileChanged: boolean = false;

    /**
     * @description File reader to read the file and get the preview of the file
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/12/2024
     * @memberof PropertyContestFormContainer
     */
    public fileReader = new FileReader();

    /**
     * @description Preview of the file to show the user the file
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/12/2024
     * @type {(string | ArrayBuffer | null)}
     * @memberof PropertyContestFormContainer
     */
    public preview?: string | ArrayBuffer | null;

    public propertyContestExample: Partial<PropertyContestEntity> = {
        description: `Mon grand-père était ingénieur. D'origine russe, il partait sur les chantiers dans les pays baltes pour superviser et traduire.<br/>
                        Il a ramené un ALGECO en bois, un des derniers qui existent, et l'a "planté", PAF comme ça, sur le terrain de ma grand-mère, au fin fond du Tarn. Sur le cadastre, c'est un bien temporaire... mais qui est là depuis 58 ans !<br/>
                        Une maison de poupée dans la campagne, où nous avons grandi. Orchis abeille, cabanes et soirées arrosées. On est loin du château, sur ce coup là, mais nous y tenons, et c'est comme ça. L'émotionnel c'est sûr, car financièrement, elle n'a pas beaucoup d'intérêt`,
    };

    public previewExample: string =
        'https://ci3.googleusercontent.com/meips/ADKq_NZmmRpz03NikL68GAIlKIbFnAHd3qrzl6G-W0aXHE6sHMQqej5K9IO2raXSjFu4l9QJoNSbBV1yb3hjIbbuNAvKHPLfmBQSivkxlu9t5nxjaRBucKxhSxOmIAK-NInO-7l65Yoljk9w0WLE8AtNfoSXGQ0=s0-d-e1-ft#https://6mwx1.img.bh.d.sendibt3.com/im/sh/b2yuKrP94Ynt.jpg?u=WtVElij8PJZGdKGp0XwLgR4N7JSppjbn';

    /**
     * @description Document passed by the router
     * @author Brisset Killian
     * @type {DocumentEntity}
     * @date 10/12/2024
     * @memberof DocumentFormComponent
     */
    public document?: DocumentEntity;

    /**
     * @description If the file has been uploaded
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/12/2024
     * @memberof PropertyContestFormContainer
     */
    public uploaded$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly propertyService: PropertyService,
        private readonly userService: UserService,
        private readonly configurationService: ConfigurationService,
        private readonly propertyContestService: PropertyContestService,
        private readonly firestore: AngularFirestore,
        private readonly storage: AngularFireStorage,
        private readonly toastController: ToastController,
        private readonly modalController: ModalController,
    ) { }

    ionViewWillEnter() {
        this.ngOnInit();
    }

    ngOnInit(): void {
        this.initPropertyContestForm();

        this.fileReader.onloadend = (e) => {
            this.preview = e.target?.result;
        };

        const initSub = this.userService.user$
            .pipe(
                switchMap((user) => {
                    this.user = user;
                    if (!user) {
                        return of([]);
                    }

                    // Get the properties options and the configuration
                    this.propertiesOptions$ = this.propertyService._getUserPropertiesOptions(
                        this.user.uid,
                    );

                    const propertyContest$ = this.propertyContestService._getUserPropertyContest(
                        user.uid,
                    );

                    // Return the combineLatest of the properties options and the configuration
                    return combineLatest([
                        this.propertiesOptions$,
                        this.configuration$,
                        propertyContest$,
                    ]);
                }),
                filter(([propertiesOptions, configuration, propertyContest]) => {
                    return !!propertiesOptions && !!configuration && propertyContest !== undefined;
                }),
            )
            .subscribe(([propertiesOptions, configuration, propertyContest]) => {
                this.updateStateBasedOnPropertiesAndConfig(
                    propertiesOptions,
                    configuration,
                    propertyContest,
                );
            });

        const propertyContestSub = this.userService.user$
            .pipe(
                filter((user) => !!user),
                distinctUntilChanged((prev, curr) => prev.uid === curr.uid),
                switchMap((user) => {
                    this.user = user;
                    if (!user) {
                        return of(null);
                    }

                    const propertyContest$ = this.propertyContestService._getUserPropertyContest(
                        user.uid,
                    );

                    // Return the combineLatest of the properties options and the configuration
                    return propertyContest$;
                }),
                filter((propertyContest) => {
                    return propertyContest !== undefined;
                }),
                distinctUntilChanged((prev, curr) => {

                    if ((prev === null && curr !== null) || (prev !== null && curr === null)) {
                        return true;
                    } else if (prev !== null && curr !== null) {
                        return prev.isSubmitted !== curr.isSubmitted;
                    }

                    return false;
                }),
            )
            .subscribe((propertyContest) => {
                if (propertyContest) {
                    this.showExplanation = false;
                    if (propertyContest.isSubmitted) {
                        this.isEditable = false;
                    } else {
                        this.isEditable = true;
                    }
                } else {
                    this.showExplanation = true;
                    this.isEditable = true;
                }
            });

        // Push the subscription to the subscriptions array
        this.subscriptions.push(initSub, propertyContestSub);
    }

    ionViewWillLeave() {
        this.ngOnDestroy();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @description Update the state of the component based on the properties options and the user configuration
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @private
     * @param {(SelectOption[] | null)} propertiesOptions
     * @param {ConfigurationEntity} configuration
     * @returns {*}  {void}
     * @memberof PropertyContestFormContainer
     */
    private updateStateBasedOnPropertiesAndConfig(
        propertiesOptions: SelectOption[] | null,
        configuration: ConfigurationEntity,
        propertyContest: PropertyContestEntity | null,
    ): void {
        // If the properties options or the configuration are not defined, we set the state to pending
        if (!propertiesOptions || !configuration || propertyContest === undefined) {
            this._state.next('pending');
            return;
        }

        // Get the property limit from the configuration
        const maxPropertiesAllowed = configuration.propertyLimit ?? 0;

        // If the number of properties is less than the limit or the limit is set to -1, we can add a property
        const NO_LIMIT = -1; // -1 indicates no limit
        if (propertiesOptions.length < maxPropertiesAllowed || maxPropertiesAllowed === NO_LIMIT) {
            this.canAddProperty = true;
        } else {
            this.canAddProperty = false;
        }

        if (propertyContest) {
            this.propertyContest = propertyContest;
            this.initPropertyContestForm(propertyContest);
        }

        this._state.next('ok');
    }

    /**
     * @description Initialize the property contest form group with the form controls and validators needed
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 09/12/2024
     * @memberof PropertyContestFormContainer
     */
    private async initPropertyContestForm(propertyContest?: PropertyContestEntity): Promise<void> {
        const extension = propertyContest?.photoExtension;
        const url = propertyContest?.photo;
        const photoName = propertyContest?.photoName;

        this.propertyContestForm = new FormGroup<PropertyContestForm>(
            {
                photo: new FormControl(null, {
                    validators: [Validators.required],
                }),
                photoExtension: new FormControl(extension ?? null, {
                    validators: [Validators.required],
                }),
                photoName: new FormControl(photoName ?? null, {
                    validators: [Validators.required],
                }),
                newProperty: new FormControl(!propertyContest?.propertyUID, {
                    nonNullable: true,
                }),
                propertyUID: new FormControl(propertyContest?.propertyUID ?? null),
                name: new FormControl(propertyContest?.name ?? null),
                use: new FormControl(null),
                description: new FormControl(propertyContest?.description ?? '', {
                    validators: [Validators.required],
                    nonNullable: true,
                }),
            },
            {
                validators: this.propertyContestValidators.bind(this),
            },
        );

        if (url && extension && photoName) {
            try {
                // create a facked blob

                const file = new File([], `${photoName}.${extension}`, {
                    type: `image/${extension}`,
                });

                this.setDropFile(file, url);
            } catch (error) {
                console.error(error);
            }
        }

        if (this.propertyContestForm && this.propertyContest?.propertyUID) {
            this.propertyContestForm.get('propertyUID')?.setValue(this.propertyContest.propertyUID);
        }
    }

    /**
     * @description Property contest form validators function to check if the form is valid or not
     * based on the form controls values and the user configuration
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @private
     * @param {AbstractControl<RawPropertyContestForm>} formGroup
     * @returns {*}  {null}
     * @memberof PropertyContestFormContainer
     */
    private propertyContestValidators(formGroup: AbstractControl<RawPropertyContestForm>): null {
        // If the newProperty form control is true, we need to check if the name and the use are set
        if (formGroup.get('newProperty')?.value) {
            if (!formGroup.get('name')?.value) {
                formGroup.get('name')?.setErrors({ required: true });
            }
            if (!formGroup.get('use')?.value && this.canAddProperty) {
                // If user can't add a property, we don't have use in property because we don't save it
                formGroup.get('use')?.setErrors({ required: true });
            }

            formGroup.get('propertyUID')?.removeValidators(Validators.required);
            formGroup.get('propertyUID')?.setErrors(null);
        } else {
            // If the newProperty form control is false, we need to check if the propertyUID is set
            if (!formGroup.get('propertyUID')?.value) {
                formGroup.get('propertyUID')?.setErrors({ required: true });
            }

            formGroup.get('name')?.removeValidators(Validators.required);
            formGroup.get('use')?.removeValidators(Validators.required);
            formGroup.get('name')?.setErrors(null);
            formGroup.get('use')?.setErrors(null);
        }
        return null;
    }

    /**
     * @description Drag leave handler for the drop zone to prevent the drop event and hide the user that he can drop the file in the drop zone
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/12/2024
     * @param {DragEvent} event
     * @returns {*}  {void}
     * @memberof PropertyContestFormContainer
     */
    public dropHandler(event: DragEvent): void {
        event.preventDefault();
        this.isDragOver = false;
        const dropFiles = event.dataTransfer?.files;
        if (!dropFiles) {
            return;
        }
        const imgFile = this.getFirstImgFile(dropFiles);
        this.setDropFile(imgFile);
    }

    /**
     * @description Set the file in the app and the input file in the form group
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/12/2024
     * @param {File} [file]
     * @param {string} [url]
     * @memberof PropertyContestFormContainer
     */
    setDropFile(file?: File, url?: string) {
        if (file) {
            this.isValidFileType = true;
            this.file = file;

            if (url) {
                this.preview = url;
                this.fileChanged = false;
            } else {
                this.fileReader.readAsDataURL(this.file);
                this.fileChanged = true;
            }
            this.propertyContestForm?.get('photo')?.setValue(this.file);
            if (this.file.type.split('/') && this.file.type.split('/')[1]) {
                this.propertyContestForm
                    ?.get('photoExtension')
                    ?.setValue(this.file.type.split('/')[1]);
            }

            if (this.file.name?.split('.') && this.file.name.split('.')[0]) {
                this.propertyContestForm?.get('photoName')?.setValue(this.file.name.split('.')[0]);
            }

            this.uploaded$.next(true);
        } else {
            this.propertyContestForm?.get('photo')?.setValue(null);
            this.propertyContestForm?.get('photoExtension')?.setValue(null);
            this.propertyContestForm?.get('photoName')?.setValue(null);
            this.preview = null;
            this.fileChanged = false;
            this.isValidFileType = false;
        }
    }

    /**
     * @description Drag over handler for the drop zone to allow the drop event and show the user that he can drop the file in the drop zone
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/12/2024
     * @param {DragEvent} event
     * @memberof PropertyContestFormContainer
     */
    dragOverHandler(event: DragEvent) {
        event.preventDefault();
        this.isDragOver = true;
    }

    /**
     * @description Get file from the file list and return the first image file if exists, otherwise return undefined
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/12/2024
     * @private
     * @param {FileList} filesUpload
     * @returns {*}  {(File | undefined)}
     * @memberof PropertyContestFormContainer
     */
    private getFirstImgFile(filesUpload: FileList): File | undefined {
        const allowedExtensions = /(\jpg|\jpeg|\png|\tiff|\tif)$/i;

        for (let index = 0; index < filesUpload.length; index++) {
            if (allowedExtensions.exec(filesUpload[index].type)) {
                return filesUpload[index];
            }
        }

        return undefined;
    }

    /**
     * @description Keep the file in the app via the input
     * @author Jérémie Lopez
     * @param {FileList} event
     * @return {*}  {void}
     * @memberof DocumentFormComponent
     */
    public keepFile(event: FileList | null): void {
        if (!event?.item(0)) {
            this.uploaded$.next(false);
            return;
        }
        this.isValidFileType = true;

        // The File object
        this.file = event.item(0) ?? undefined;
        if (this.file) {
            this.fileReader.readAsDataURL(this.file);
            this.fileChanged = true;
            this.propertyContestForm?.get('photo')?.setValue(this.file);
            if (this.file.type.split('/')[1]) {
                this.propertyContestForm
                    ?.get('photoExtension')
                    ?.setValue(this.file.type.split('/')[1]);
            }
            if (this.file.name.split('.') && this.file.name.split('.')[0]) {
                this.propertyContestForm?.get('photoName')?.setValue(this.file.name.split('.')[0]);
            }
        } else {
            this.propertyContestForm?.get('photo')?.setValue(null);
            this.propertyContestForm?.get('photoExtension')?.setValue(null);
            this.propertyContestForm?.get('photoName')?.setValue(null);
            this.preview = null;
            this.fileChanged = false;
            this.isValidFileType = false;
        }
        this.uploaded$.next(true);
    }

    /**
     * @description Delete the file in the app and the input file in the form group
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/12/2024
     * @memberof PropertyContestFormContainer
     */
    public deleteFile(): void {
        delete this.file;
        this.propertyContestForm?.get('photo')?.setValue(null);
        this.propertyContestForm?.get('photoExtension')?.setValue(null);
        this.propertyContestForm?.get('photoName')?.setValue(null);
        this.preview = null;
        this.isValidFileType = true;
    }

    /**
     * @description Save the property contest form values in the database and close the modal
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/12/2024
     * @param {boolean} [isSubmitted]
     * @returns {*}  {Promise<void>}
     * @memberof PropertyContestFormContainer
     */
    public async save(isSubmitted?: boolean): Promise<void> {
        try {
            if (!this.propertyContestForm?.valid) {
                throw new Error('Form is not valid');
            }

            const value = this.propertyContestForm?.value;
            if (
                !value ||
                !value.photo ||
                !value.photoExtension ||
                !value.photoName ||
                !this.user ||
                !(value.photo instanceof File)
            ) {
                throw new Error('Form values are not valid');
            }

            const propertyContest: Partial<PropertyContestEntity> = {
                description: value.description,
                userUID: this.user.uid,
                isSubmitted: isSubmitted ?? false,
                hasEmailSent: false,
            };

            if (this.fileChanged) {
                const fileDoc: {
                    fileURL: File | string;
                    extensionName: string;
                    fileName: string;
                } = {
                    fileURL: value.photo,
                    fileName: value.photo.name.split('.')[0],
                    extensionName: value.photoExtension,
                };

                await this.uploadFile(fileDoc);

                // Si fileURL est un pas un string, on ne peut pas créer le document
                if (typeof fileDoc.fileURL !== 'string') {
                    throw new Error('File URL is not a string');
                }

                propertyContest.photo = fileDoc.fileURL;
                propertyContest.photoExtension = fileDoc.extensionName;
                propertyContest.photoName = fileDoc.fileName;
            }

            if (value.newProperty && value.name) {
                propertyContest.name = value.name;
                propertyContest.propertyUID = null;

                // TODO: Create the property if can
                if (this.canAddProperty && value.use) {
                    const property: Partial<PropertyEntity> = {
                        userUID: this.user.uid,
                        sharing: [],
                    };
                    property.name = value.name;
                    if (propertyContest.photo || this.propertyContest?.photo) {
                        property.photo = propertyContest.photo ?? this.propertyContest?.photo;
                    }
                    property.purchaseDetails = {
                        use: [value.use],
                    } as PropertyPurchaseDetails;

                    const ref = await this.propertyService.create(property);

                    propertyContest.propertyUID = ref.id;
                }
            } else if (!value.newProperty && value.propertyUID) {
                propertyContest.propertyUID = value.propertyUID;
                // Find the property in the properties options
                const propertiesOptions = await lastValueFrom(
                    this.propertiesOptions$.pipe(take(1)),
                );

                const property = propertiesOptions?.find(
                    (property) => property.id === value.propertyUID,
                );

                if (property) {
                    propertyContest.name = property.label;
                }
            }

            if (this.propertyContest?.uid) {
                propertyContest.uid = this.propertyContest.uid;
                await this.propertyContestService.update(propertyContest);
            } else {
                await this.propertyContestService.create(propertyContest);
            }

            await this.modalController.dismiss();
            const toast = await this.toastController.create({
                message: isSubmitted
                    ? 'Votre participation a bien été prise en compte'
                    : 'Vos informations ont bien été enregistrées, pensez à envoyer votre participation',
                duration: 5000,
                position: 'top',
                color: 'success',
            });

            await toast.present();
        } catch (error) {
            console.error(error);
            const toast = await this.toastController.create({
                message: 'Une erreur est survenue, veuillez réessayer plus tard',
                duration: 5000,
                position: 'top',
                color: 'danger',
            });

            await toast.present();
        }
    }

    /**
     * @description Upload the file to the storage and return the file URL to save it in the database
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/12/2024
     * @private
     * @param {({
     *         fileURL: File | string;
     *         extensionName: string;
     *         fileName: string;
     *     })} value
     * @returns {*}  {Promise<void>}
     * @memberof PropertyContestFormContainer
     */
    private async uploadFile(value: {
        fileURL: File | string;
        extensionName: string;
        fileName: string;
    }): Promise<void> {
        // Check if the file is a file
        if ((value.fileURL as unknown) instanceof File) {
            const file = value.fileURL as unknown as File;
            const uid = this.firestore.createId();
            const path = `documents/${uid}/${value.fileName}.${value.extensionName}`;

            // Upload the file
            const url = await OmedomForm.uploadFile(file, path, this.storage);

            // Set the file url
            value.fileURL = url;
        }
    }
}
