import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ModalController, ToastController } from '@ionic/angular';
import { PropertyEntity, PropertyType, UserEntity } from '@omedom/data';
import { PropertyService, UserService } from '@omedom/services';
import { BehaviorSubject, Subscription } from 'rxjs';

import { OmedomRadioOption } from '../../components/radio';

@Component({
    selector: 'omedom-menu-account-delete',
    templateUrl: './menu-account-delete.component.html',
    styleUrls: ['./menu-account-delete.component.scss'],
})
export class MenuAccountDeleteComponent implements OnInit, OnDestroy {
    /**
     * @description User data
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof MenuAccountDeleteComponent
     */
    public user?: UserEntity;

    /**
     * @description If true, unsub the user to newsletter after delete account
     * @author Jérémie Lopez
     * @memberof MenuAccountDeleteComponent
     */
    public subNewsletter = false;

    /**
     * @description If true, the app is pending an action
     * @author Jérémie Lopez
     * @memberof MenuAccountDeleteComponent
     */
    public pending$ = new BehaviorSubject<boolean>(false);

    yesNoOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({ id: true, label: 'Oui' }),
        new OmedomRadioOption({ id: false, label: 'Non' }),
    ];

    /**
     * @description Quantity of property owned by user
     * @author Jérémie Lopez
     * @memberof MenuAccountDeleteComponent
     */
    public sharedByUserProperties: PropertyEntity[] = [];

    /**
     * @description Quantity of building owned by user
     * @author Jérémie Lopez
     * @memberof MenuAccountDeleteComponent
     */
    public buildingList: PropertyEntity[] = [];

    /**
     * @description List of subscriptions to delete after destroy component
     * @author Jérémie Lopez
     * @private
     * @type {Subscription[]}
     * @memberof MenuAccountDeleteComponent
     */
    private subscriptions: Subscription[] = [];

    constructor(
        private userService: UserService,
        private functions: AngularFireFunctions,
        private modal: ModalController,
        private toast: ToastController,
        private propertyService: PropertyService
    ) { }

    ngOnInit(): void {
        const user$ = this.userService.user$.subscribe(async (user) => {
            if (user) {
                this.user = user;
                this.subNewsletter = user.newsletter;
                this.sharedByUserProperties = (
                    await this.propertyService.search([
                        {
                            where: 'userUID',
                            operator: '==',
                            value: user.uid,
                        },
                    ])
                ).filter((property) => {
                    if (property.sharing?.length > 0) {
                        return !!property;
                    }
                    return false;
                });

                this.buildingList = await this.propertyService.search([
                    {
                        where: 'userUID',
                        operator: '==',
                        value: user.uid,
                    },
                    {
                        where: 'type',
                        operator: '==',
                        value: PropertyType.immeuble,
                    },
                ]);
            }
        });

        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @description Dismiss the modal
     * @author Jérémie Lopez
     * @memberof MenuAccountDeleteComponent
     */
    public dismiss(): void {
        this.modal.dismiss();
    }

    /**
     * @description Delete the account
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof MenuAccountDeleteComponent
     */
    public async delete(): Promise<void> {
        this.pending$.next(true);

        if (!this.user) {
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
            this.pending$.next(false);
            return;
        }

        const callback$ = this.functions
            .httpsCallable('deactivateUser')({
                userUID: this.user.uid,
                unsubNewsletter: !this.subNewsletter,
            })
            .subscribe(
                async () => {
                    await this.userService.logout();
                    const toast = await this.toast.create({
                        position: 'top',
                        color: 'warning',
                        duration: 4000,
                        // message: 'Votre compte a bien été supprimé.',
                        message: 'La suppression de votre compte a bien été prise en compte.',
                    });
                    await toast.present();
                    this.dismiss();
                    this.pending$.next(false);
                },
                async (error) => {
                    console.error(error);
                    const toast = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: "Une erreur s'est produite, veuillez réessayer plus tard.",
                    });
                    await toast.present();
                    this.pending$.next(false);
                },
            );

        this.subscriptions.push(callback$);
    }
}
