import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Animation,
    AnimationController,
    GestureController,
    GestureDetail,
    IonModal,
    ModalController,
} from '@ionic/angular';
import {
    AllChargeCategories,
    AllIncomeCategories,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeEntity,
    ChargeHistory,
    ChargeListModel,
    ChargePeriodicity,
    ChargePeriodicityInfo,
    EntityTypes,
    IncomeEntity,
    IncomeHistory,
    IncomeListModel,
    IncomePeriodicity,
    PropertyType,
    RoleState,
    Sharing,
    Story,
} from '@omedom/data';
import {
    AnalyticsService,
    ChargeService,
    IncomeService,
    PropertyService,
    RoleService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { Timestamp } from 'firebase/firestore';
import { Observable } from 'rxjs';

import { TreasuryList } from '../../class/treasury-list';
import { ChargeDeleteComponent, IncomeDeleteComponent, OmedomRadioOption } from '../../components';
import { OmedomFormatNumber } from '../../functions/format-number';
import { OmedomNumberPipe } from '../../pipes/number.pipe';

@Component({
    selector: 'omedom-story-detail',
    templateUrl: './story-detail.component.html',
    styleUrls: ['./story-detail.component.scss'],
})
export class StoryDetailComponent
    extends TreasuryList<
        ChargeEntity | IncomeEntity,
        AllChargeCategories | AllIncomeCategories,
        ChargePeriodicity | ChargePeriodicity
    >
    implements OnInit, AfterViewInit {
    private _story?: Story;
    get story(): Story {
        return this._story as Story;
    }

    @Input() set story(storyValue: Story) {
        if (storyValue !== this._story) {
            this._story = storyValue;
            this.showAllNote = false;
            this.updateOwner();
            this.updateSharing();
            this.updateRoleRight();
        }
        this._story = storyValue;
    }

    get intervalDate(): string {
        if (this.story) {
            const date = new Date(this.story.date);

            return (
                'Du ' +
                date.getFirstDayOfMonth().toLocaleDateString() +
                ' au ' +
                date.getLastDayOfMonth().toLocaleDateString()
            );
        }
        return '';
    }

    @Output() updateFinished = new EventEmitter();

    @Output() nextStory = new EventEmitter();

    @Output() exitStories = new EventEmitter();

    /**
     * @description Swipe event, triggered when swipe to right or left
     * @author ANDRE Felix
     * @memberof StoryDetailComponent
     */
    @Output() swipe = new EventEmitter<number>();
    /**
     * @description ion-content tag, use for swipe
     * @author ANDRE Felix
     * @type {ElementRef}
     * @memberof StoryDetailComponent
     */
    @ViewChild('ionContent', { static: false, read: ElementRef })
    ionContent?: ElementRef;
    /**
     * @description Animate story to disapear upward, when click on 'yes'
     * @author ANDRE Felix
     * @private
     * @type {Animation}
     * @memberof StoryDetailComponent
     */
    private fadeOutTop?: Animation;
    /**
     * @description Make element appear with transition on opacity
     * @author ANDRE Felix
     * @private
     * @type {Animation}
     * @memberof StoryDetailComponent
     */
    private fadeIn?: Animation;
    /**
     * @description Animate story to disapear downward, when click on 'no'
     * @author ANDRE Felix
     * @private
     * @type {Animation}
     * @memberof StoryDetailComponent
     */
    private fadeOutBottom?: Animation;

    isEdit = false;
    /**
     * @description full name of the owner of the property of this charge / income
     * @author ANDRE Felix
     * @type {string}
     * @memberof StoryDetailComponent
     */
    ownerFullName?: string;

    /**
     * @description Does user have right to edit this story
     * @author ANDRE Felix
     * @memberof StoryDetailComponent
     */
    isStoryEditable = false;

    /**
     * @description Role right of the user on the story
     * @author ANDRE Felix
     * @type {RoleState}
     * @memberof StoryDetailComponent
     */
    roleRight?: RoleState;

    /**
     * @description List of user that the property is sharing with the user of the story
     * @author Brisset Killian
     * @date 19/04/2024
     * @type {Sharing[]}
     * @memberof StoryDetailComponent
     */
    sharing?: Sharing[];

    /**
     * @description Show all sharing of the property of the charge / income or not, by default false, show only the first sharing
     * @author Brisset Killian
     * @date 19/04/2024
     * @memberof StoryDetailComponent
     */
    seeAllSharing = false;

    /**
     * @description Show all note of the property of the charge / income or not, by default false, show only the first line of the note
     * @author Brisset Killian
     * @date 23/04/2024
     * @memberof StoryDetailComponent
     */
    showAllNote = false;

    isSocietyStory = false;

    isBuildingStory = false;

    amount?: string;

    proposeTransactionOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({ id: true, label: 'Oui' }),
        new OmedomRadioOption({ id: false, label: 'Non' }),
    ];

    propose = true;

    isWaitingData = true;

    @Input()
    public override isDesktop = false;

    constructor(
        userService: UserService,
        propertyService: PropertyService,
        societyService: SocietyService,
        activatedRoute: ActivatedRoute,
        modalController: ModalController,
        router: Router,
        private chargeService: ChargeService,
        private incomeService: IncomeService,
        private numberPipe: OmedomNumberPipe,
        private animationCtrl: AnimationController,
        private elRef: ElementRef,
        private gestureCtrl: GestureController,
        private cdRef: ChangeDetectorRef,
        private renderer: Renderer2,
        private roleService: RoleService,
        private analyticsService: AnalyticsService,
    ) {
        super(
            userService,
            propertyService,
            activatedRoute,
            modalController,
            router,
            societyService,
        );
    }

    override async ngOnInit(): Promise<void> {
        if (!this.story) {
            return;
        }
        this.amount = this.story.amount.toString();

        const service = this.story.isCharge ? this.chargeService : this.incomeService;
        const treasury = await service.get(this.story.uid);
        await this.updateRoleRight();
        if (!treasury) {
            return;
        }
        const isPunctual = treasury.periodicity === 'punctual' ? true : false;
        if (isPunctual && this.roleRight?.update) {
            if (!treasury.isReaded) {
                treasury.isReaded = true;
                const copy = {};
                await Object.assign(copy, treasury);
                await service.update(copy);
            }
        } else if (this.isStoryBeforeToday() && treasury.history) {
            const history = treasury.history.find(
                (histo) => histo.date.toDate().getTime() === this.story?.date.getTime(),
            );
            if (history && this.roleRight?.update && !history.isReaded) {
                history.isReaded = true;
                const copy = {};
                await Object.assign(copy, treasury);
                await service.update(copy);
            }
        }

        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        this.analyticsService.logEvent('openStory');

        // Animation
        this.fadeOutTop = this.animationCtrl
            .create()
            .addElement(this.elRef.nativeElement)
            .duration(250)
            .easing('ease-out')
            .fromTo('transform', 'translateY(0px)', 'translateY(-200px)')
            .fromTo('opacity', '1', '0');

        this.fadeOutBottom = this.animationCtrl
            .create()
            .addElement(this.elRef.nativeElement)
            .duration(250)
            .easing('ease-out')
            .fromTo('transform', 'translateY(0px)', 'translateY(200px)')
            .fromTo('opacity', '1', '0');

        this.fadeIn = this.animationCtrl
            .create()
            .addElement(this.elRef.nativeElement)
            .duration(250)
            .easing('ease-in')
            .fromTo('opacity', '0', '1');
        // Swipe gesture
        const gesture = this.gestureCtrl.create({
            el: this.ionContent?.nativeElement,
            gestureName: 'swipe',
            onMove: (detail: GestureDetail) => this.onMove(detail),
            onEnd: (ev: GestureDetail) => this.onEnd(ev),
        });
        gesture.enable();
    }
    /**
     * @description Move the story to right and left during swipe movment
     * @author ANDRE Felix
     * @param {GestureDetail} ev
     * @memberof StoryDetailComponent
     */
    onMove(ev: GestureDetail): void {
        this.renderer.setStyle(
            this.ionContent?.nativeElement,
            'transform',
            `translateX(${ev.deltaX}px)`,
        );
    }
    /**
     * @description fire swip event if swipe more than 150px
     * @author ANDRE Felix
     * @param {GestureDetail} ev
     * @memberof StoryDetailComponent
     */
    onEnd(ev: GestureDetail): void {
        this.renderer.setStyle(this.ionContent?.nativeElement, 'transform', `translateX(0px)`);
        if (ev.deltaX < 150 || ev.deltaX > 150) {
            this.swipe.emit(ev.deltaX);
        }
        this.cdRef.detectChanges();
    }

    toggleEdit(): void {
        this.isEdit = !this.isEdit;
        this.amount = this.numberPipe.transform(this.story?.amount ?? 0);
    }

    async updateTreasury(): Promise<void> {
        if (!this.story) {
            return;
        }
        const service = this.story.isCharge ? this.chargeService : this.incomeService;
        const treasury = await service.get(this.story.uid);
        if (!this.amount || !treasury) {
            return;
        }
        const amount = +this.numberPipe.parse(this.amount);

        const isPunctual = treasury.periodicity === 'punctual';
        if (isPunctual) {
            treasury.amount = amount;
        } else {
            const history = treasury.history?.find(
                (x) => x.date.toDate().getTime() === this.story?.date.getTime(),
            );
            if (history) {
                history.amount = amount;
            } else {
                treasury.history?.push({
                    amount: amount,
                    date: Timestamp.fromDate(this.story.date),
                    isReaded: true,
                    isPayed: false,
                } as ChargeHistory | IncomeHistory);
            }
        }

        const copy = {};
        await Object.assign(copy, treasury);
        await service.update(copy);

        this.story.amount = amount;

        this.toggleEdit();
    }

    async storyNotValidateClick(): Promise<void> {
        if (!this.story) {
            return;
        }
        const service = this.story.isCharge ? this.chargeService : this.incomeService;
        const treasury = await service.get(this.story.uid);
        if (!treasury) {
            return;
        }
        const isPunctual = treasury.periodicity === 'punctual';
        if (isPunctual) {
            treasury.isReaded = true;
            if (treasury.isPayed) {
                treasury.isPayed = false;
            }
        } else {
            const history = treasury.history?.find(
                (x) => x.date.toDate().getTime() === this.story?.date.getTime(),
            );
            if (history) {
                history.isReaded = true;
                if (history.isPayed) {
                    history.isPayed = false;
                }
            } else {
                treasury.history?.push({
                    amount: treasury.amount,
                    date: Timestamp.fromDate(this.story.date),
                    isReaded: true,
                    isPayed: false,
                } as ChargeHistory | IncomeHistory);
            }
        }

        const copy = {};
        await Object.assign(copy, treasury);
        await service.update(copy);

        await this.fadeOutBottom?.play();
        this.nextStory.emit(false);
        await this.fadeOutBottom?.stop();
        await this.fadeIn?.play();
        await this.fadeIn?.stop();
    }

    async storyValidateClick(): Promise<void> {
        if (!this.story) {
            return;
        }
        const service = this.story.isCharge ? this.chargeService : this.incomeService;
        const treasury = await service.get(this.story.uid);

        if (!treasury) {
            return;
        }
        const isPunctual = treasury.periodicity === 'punctual';
        if (isPunctual) {
            treasury.isReaded = true;
            treasury.isPayed = true;
        } else {
            const history = treasury.history?.find(
                (x) => x.date.toDate().getTime() === this.story?.date.getTime(),
            );

            if (history) {
                history.isReaded = true;
                history.isPayed = true;
            } else {
                treasury.history?.push({
                    amount: treasury.amount,
                    date: Timestamp.fromDate(this.story.date),
                    isReaded: true,
                    isPayed: true,
                } as ChargeHistory | IncomeHistory);
            }
        }

        const copy = {};
        await Object.assign(copy, treasury);
        await service.update(copy);

        await this.fadeOutTop?.play();
        this.nextStory.emit(true);
        await this.fadeOutTop?.stop();
        await this.fadeIn?.play();
        await this.fadeIn?.stop();
    }

    formatAmountNumber(newValue: string) {
        OmedomFormatNumber.formatOmedomInput(newValue, this.numberPipe, 'storyAmount');
    }

    formatPeriodicity(date: Date, periodicity: ChargePeriodicity | IncomePeriodicity): string {
        switch (periodicity) {
            case ChargePeriodicity.punctual:
                return 'le ' + date.toLocaleDateString();
            case ChargePeriodicity.monthly:
                return 'le ' + date.getDate() + ' de chaque mois';
            case ChargePeriodicity.bimonthly:
                return 'le ' + date.getDate() + ' tous les deux mois';
            case ChargePeriodicity.quarterly:
                return 'le ' + date.getDate() + ' tous les trois mois';
            case ChargePeriodicity.halfYearly:
                return 'le ' + date.getDate() + ' tous les six mois';
            case ChargePeriodicity.yearly:
                return (
                    'le ' +
                    date.getDate() +
                    ' ' +
                    date.toLocaleDateString('fr-FR', { month: 'long' }) +
                    ' de chaque année'
                );
            default:
                return '';
        }
    }

    public openModal(modal: IonModal): void {
        modal.present();
    }

    async updateRoleRight() {
        if (!this.story) {
            return;
        }
        this.roleRight = await this.roleService.getRoleState(this.story, EntityTypes.story);
        this.isWaitingData = false;
    }

    /**
     * @description Update first and last name of the owner of the property of the charge / income
     * @author ANDRE Felix
     * @private
     * @memberof StoryDetailComponent
     */
    private async updateOwner() {
        if (!this.story?.ownerUID) {
            return;
        }
        this.ownerFullName = '';
        const owner = await this.userService.get(this.story.ownerUID);
        // I make the name in lowerCase except the first letter i transform to capatile letter

        const firstName =
            owner?.firstname?.charAt(0).toUpperCase() ??
            '' + owner?.firstname?.slice(1).toLowerCase() ??
            '';
        const name =
            owner?.name?.charAt(0).toUpperCase() ?? '' + owner?.name?.slice(1).toLowerCase() ?? '';
        this.ownerFullName = `${firstName} ${name}`;
    }

    private async updateSharing() {
        this.seeAllSharing = false;
        this.sharing = [];
        this.isSocietyStory = false;
        this.isBuildingStory = false;

        if (!this.story) {
            return;
        }

        const treasury = this.story.isCharge
            ? await this.chargeService.get(this.story.uid)
            : await this.incomeService.get(this.story.uid);
        if (treasury && treasury.propertyUID) {
            const property = await this.propertyService.get(treasury.propertyUID);
            if (property) {
                this.sharing = property.sharing;
                this.isBuildingStory = property.type === PropertyType.immeuble;
            }
        } else if (treasury && treasury.societyUID) {
            this.isSocietyStory = true;
            const society = await this.societyService.get(treasury.societyUID);
            if (society) {
                this.sharing = society.sharing;
            }
        }
    }

    loadTreasury(): Observable<ChargeEntity[]> {
        let charges$: Observable<ChargeEntity[]>;

        if (!!this.propertyUid) {
            charges$ = this.chargeService._getChargesByProperty(this.propertyUid);
        } else if (!!this.societyUid) {
            charges$ = this.chargeService._getChargesBySociety(this.societyUid);
        } else {
            charges$ = this.chargeService._getUserChargesByPropertyIds(
                this.properties.map((x) => x.uid),
            );
        }

        return charges$;
    }
    getCategoryInfo(category: string): ChargeCategoryInfo {
        return new ChargeCategoryInfo(category as ChargeCategoryProperty);
    }

    getPeriodicityInfo(periodicity: string): ChargePeriodicityInfo {
        return new ChargePeriodicityInfo(periodicity as ChargePeriodicity);
    }

    async deleteClicked(story: Story): Promise<void> {
        const treasuryListModel = story as any as ChargeListModel | IncomeListModel;
        if (treasuryListModel.isCharge) {
            await this.showDeleteComponent(ChargeDeleteComponent, treasuryListModel);
        } else {
            await this.showDeleteComponent(IncomeDeleteComponent, treasuryListModel);
        }
    }

    isStoryBeforeToday() {
        if (!this.story) {
            return true;
        }
        return this.story.date <= new Date();
    }
}
