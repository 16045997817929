<omedom-info
    information="
    Ici, vous retrouverez l’ensemble de votre patrimoine et les éléments que vous souhaitez partager à votre Pro.<br><br>
    Pour commencer, veuillez lire et accepter les conditions de partage ci-dessous<br><br>
    Puis sélectionnez les éléments que vous souhaitez partager.<br><br>
    Une validation vous sera demandée pour chaque élément sélectionné. <br><br>
    Bien entendu, vous pouvez à tout moment révoquer le partage d’un ou plusieurs éléments en les désélectionnant.  "
    class="margin-bottom-2"
>
</omedom-info>
<omedom-accept-share-condition
    [pro]="pro"
    [client]="client"
    class="margin-bottom-2"
></omedom-accept-share-condition>
@if (client && client.hasAgreedToShareToPro) {
    <omedom-accept-edit-share-condition
        [pro]="pro"
        [client]="client"
        @element
        class="margin-bottom-2"
    ></omedom-accept-edit-share-condition>
    <div @element class="title">Biens immobiliers :</div>
    <div
        class="list card-view card-selection"
        [@list]="userProperties.length + userSocieties.length"
    >
        @for (property of userProperties; track property.uid) {
            <omedom-share-to-pro-card
                [title]="property.name"
                [subTitle]="property.type"
                [photo]="property.photo"
                [proLogo]="pro.logo"
                [selectedUIDs]="client.propertiesUID || []"
                [assetUID]="property.uid"
                [assetType]="getPropertyType(property)"
                (selectedChange)="
                    selectAsset(property, $event, getPropertyType(property))
                "
            >
            </omedom-share-to-pro-card>
        } @empty {
            <div class="empty-asset">
                Vous n'avez aucun bien immobilié à partager
            </div>
        }
    </div>

    <div @element class="title">Sociétés immobilières:</div>
    <div
        class="list card-view card-selection"
        [@list]="userProperties.length + userSocieties.length"
    >
        @for (society of userSocieties; track society.uid) {
            <omedom-share-to-pro-card
                [title]="society.name"
                [subTitle]="society.type"
                [photo]="society.photo"
                [proLogo]="pro.logo"
                [selectedUIDs]="client.societiesUID || []"
                [assetUID]="society.uid"
                [assetType]="allAssetTypeName.society"
                (selectedChange)="
                    selectAsset(society, $event, allAssetTypeName.society)
                "
            >
            </omedom-share-to-pro-card>
        } @empty {
            <div class="empty-asset">
                Vous n'avez aucune société immobilière à partager
            </div>
        }
    </div>

    <div @element class="title">Crédits :</div>
    <div
        class="list card-view card-selection"
        [@list]="userProperties.length + userSocieties.length"
    >
        @for (loan of userLoans; track loan.uid) {
            <omedom-share-to-pro-card
                [title]="loan.name"
                [subTitle]="loan.type || ''"
                [proLogo]="pro.logo"
                [selectedUIDs]="client.loansUID || []"
                [assetUID]="loan.uid"
                [assetType]="allAssetTypeName.loan"
                (selectedChange)="
                    selectAsset(loan, $event, allAssetTypeName.loan)
                "
            >
            </omedom-share-to-pro-card>
        } @empty {
            <div class="empty-asset">Vous n'avez aucun crédit à partager</div>
        }
    </div>

    <div @element class="title">Épargnes :</div>
    <div
        class="list card-view card-selection"
        [@list]="userProperties.length + userSocieties.length"
    >
        @for (saving of userSavings; track saving.uid) {
            <omedom-share-to-pro-card
                [title]="saving.name"
                [subTitle]="saving.type || ''"
                [proLogo]="pro.logo"
                [selectedUIDs]="client.savingsUID || []"
                [assetUID]="saving.uid"
                [assetType]="allAssetTypeName.saving"
                (selectedChange)="
                    selectAsset(saving, $event, allAssetTypeName.saving)
                "
            >
            </omedom-share-to-pro-card>
        } @empty {
            <div class="empty-asset">Vous n'avez aucune épargne à partager</div>
        }
    </div>

    <div @element class="title">
        Partager le solde global de vos comptes bancaires :
    </div>
    <omedom-accept-share-banking-balance-to-pro
        [client]="client"
        [bankItems]="userBankItems"
        [bankAccounts]="userBankAccounts"
        (valueChanged)="shareGlobalBalanceChanged($event)"
    ></omedom-accept-share-banking-balance-to-pro>
}
