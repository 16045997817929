import { Component, Input, OnChanges } from '@angular/core';
import { DPELevelType, DPEScore, energyConsumptionLevels } from '@omedom/data';

@Component({
    selector: 'omedom-dpe-score',
    templateUrl: './dpe-score.component.html',
    styleUrls: ['./dpe-score.component.scss'],
})
export class DpeScoreComponent implements OnChanges {
    /**
     * @description The score to display (A, B, C, D, E, F, G) (required)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {DPEScore}
     * @memberof DpeScoreComponent
     */
    @Input() score?: DPEScore;

    /**
     * @description The level to display with letter and color
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {DPELevelType}
     * @memberof DpeScoreComponent
     */
    level?: DPELevelType;

    /**
     * @description List of levels to display
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {DPELevelType[]}
     * @memberof DpeScoreComponent
     */
    levels: DPELevelType[] = energyConsumptionLevels;

    constructor() {}

    ngOnChanges() {
        if (this.score === undefined) {
            return;
        }

        const upperScore = this.score.toUpperCase();

        this.level = this.levels.filter((level) => {
            return level.letter === upperScore;
        })[0];

        const index = this.levels.findIndex((level) => {
            return level.letter === upperScore;
        });

        if (index) {
            this.level = this.levels[index];
        }
    }
}
