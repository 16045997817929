import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { ClientEntity, defaultRoleState, DocumentEntity, EntityTypes, LoanEntity, ModalTab, RoleState } from '@omedom/data';
import { DocumentService, RoleService } from '@omedom/services';
import { lastValueFrom, map, Observable, of, take } from 'rxjs';

import { DocumentViewerComponent } from '../../document';
import { LoanDetailsLayout } from '../loan-details/loan-details.container';

@Component({
    selector: 'omedom-loan-profil',
    templateUrl: './loan-profil.component.html',
    styleUrls: ['./loan-profil.component.scss'],
})
export class LoanProfilComponent implements OnInit {
    @Input({ required: true })
    public loan!: LoanEntity;

    @Input({ required: true })
    public client!: ClientEntity;

    @Input({ required: true })
    public documents$: Observable<DocumentEntity[]> = of([]);

    public roleState$: Observable<RoleState> = of(defaultRoleState);

    public defaultRoleState = defaultRoleState;

    public tabs: ModalTab[] = [
        {
            label: 'Informations',
            name: 'info',
            index: 0,
            active: true,
        },
        {
            label: 'Documents',
            name: 'documents',
            index: 1,
            active: false,
        },
    ];

    @ViewChild('info', { static: true })
    public infoTemplate?: TemplateRef<HTMLElement>;

    @ViewChild('documents', { static: true })
    public documentsTemplate?: TemplateRef<HTMLElement>;

    public layout: LoanDetailsLayout = LoanDetailsLayout.DEFAULT;

    constructor(
        private readonly documentService: DocumentService,
        private readonly modalController: ModalController,
        private readonly roleService: RoleService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {}

    ngOnInit() {
        if (!this.loan || !this.client) {
            return;
        }

        this.documents$ = this.documentService._search([
            {
                where: 'userUID',
                operator: '==',
                value: this.client.userUID,
            },
            {
                where: 'clientUID',
                operator: '==',
                value: this.client.uid,
            },
            {
                where: 'loanUID',
                operator: '==',
                value: this.loan.uid,
            },
        ]);

        this.roleState$ = this.roleService._getRoleState(this.loan, EntityTypes.loan);
    }

    public get activeTab(): ModalTab {
        // Get active tab
        return this.tabs.find((x) => x.active) ?? this.tabs[0];
    }

    public getTemplate(): TemplateRef<HTMLElement> | null {
        // Get active tab
        const tab = this.activeTab;

        switch (tab.name) {
            case 'info':
                return this.infoTemplate ?? null;
            case 'documents':
                return this.documentsTemplate ?? null;
            default:
                return null;
        }
    }

    protected async documentClick(documentUid: string): Promise<void> {
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { documentViewer: 'open' },
            queryParamsHandling: 'merge',
        });

        const document = await lastValueFrom(
            this.documents$.pipe(
                map((documents) => documents.find((doc) => doc.uid === documentUid)),
                take(1),
            ),
        );
        const modal = await this.modalController.create({
            component: DocumentViewerComponent,
            componentProps: {
                document,
                readOnly: true,
            },

            cssClass: 'max-heigth-mode',
        });

        await modal.present();

        await modal.onDidDismiss().then(
            async (
                overlayEventDetail: OverlayEventDetail<{
                    document?: DocumentEntity;
                }>,
            ) => {
                await this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: { documentViewer: null },
                    queryParamsHandling: 'merge',
                });

                if (overlayEventDetail.data?.document) {
                    this.editDocument(overlayEventDetail.data.document);
                }
            },
        );
    }

    addDocument(): void {
        // Emit event
        this.modalController.dismiss({
            action: 'addDocument',
            saving: this.loan,
        });
    }

    editDocument(document: DocumentEntity): void {
        // Emit event
        this.modalController.dismiss({
            action: 'editDocument',
            document,
        });
    }
}
