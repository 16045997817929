import { Confidence, ConfidenceFr, DPEScore } from '../../entities';

const valueInfo = {
    value: 510000,
    valueRange: {
        lower: 550000,
        upper: 470000,
    },
    valuationConfidence: Confidence.good,
    valuationDate: '',
};

const propertyValuation = {
    valuationSale: valueInfo,
    valuationRentGross: valueInfo,
    valuationRentNet: valueInfo,
};

const renovationFake = {
    energyLabelRenovation: 'a',
    percentageImprovements: {
        rentGrossValuation: 0,
        rentNetValuation: 0,
        rentValuation: 0,
        saleValuation: 0.59,
    },
    renovationCosts: {
        avg: 10500,
        max: 14250,
        min: 6750,
    },
    renovationCostsPerSqm: {
        avg: 70,
        max: 90,
        min: 45,
    },
    rentNetDifference: {
        avg: 0,
        max: 0,
        min: 0,
    },
    salePriceDifference: {
        avg: 6400,
        max: 7018,
        min: 5643,
    },
};

const fakeRenovation = {
    currentEnergyLabel: DPEScore.A,
    renovations: [renovationFake],
};

export const fakeValorisation = {
    livingSpace: 120,
    isPropertyTypeValidForValuation: false,
    dpeRating: 87,
    currentEnergyLabel: DPEScore.B,
    energyLabelEstimated: DPEScore.B,
    propertyValuation: propertyValuation,
    propertyRenovations: fakeRenovation,
    valuationConfidence: ConfidenceFr.good,
    valuationConfidenceColor: '#00c29a',
    hasValorisation: false,
    lastValuationUpdate: '',
    meterSquarePriceAvg: 3000,
    meterSquarePriceLower: 2700,
    meterSquarePriceUpper: 3500,
    lastValorisationUpdate: '',
    isAssimilateAppartement: false,
    isAssimilateHouse: true,
};
