import { Component, Input } from '@angular/core';
import {
    DPEScore,
    EnergyReportBasic,
    EnergyReportGranular,
    RenovationScenarioBasic,
    RenovationScenarioGranular,
} from '@omedom/data';

@Component({
    selector: 'omedom-renovations-scenario',
    templateUrl: './renovation-scenarios.component.html',
    styleUrls: ['./renovation-scenarios.component.scss'],
})
export class RenovationScenariosComponent {
    /**
     * @description The energy report of property
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {(EnergyReportGranular | EnergyReportBasic)}
     * @memberof RenovationScenariosComponent
     */
    @Input() energyReport?: EnergyReportGranular | EnergyReportBasic;

    /**
     * @description The scenarios of renovation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {((RenovationScenarioGranular | RenovationScenarioBasic)[])}
     * @memberof RenovationScenariosComponent
     */
    public get scenarios(): (RenovationScenarioGranular | RenovationScenarioBasic)[] {
        const validResultGranularity = ['granular', 'basic'];

        if (
            !this.energyReport ||
            !validResultGranularity.includes(this.energyReport.resultGranularity)
        ) {
            return [];
        }

        return this.energyReport.renovationScenarios;
    }

    /**
     * @description The DPE score of the property before renovation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/02/2025
     * @readonly
     * @type {(DPEScore | undefined)}
     * @memberof RenovationScenariosComponent
     */
    public get actualDPEScore(): DPEScore | undefined {
        const validResultGranularity = ['granular', 'basic'];

        if (
            !this.energyReport ||
            !validResultGranularity.includes(this.energyReport.resultGranularity)
        ) {
            return undefined;
        }
        return this.energyReport?.currentEnergyLabel.value as DPEScore;
    }

    constructor() {}
}
