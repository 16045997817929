import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
    DPEScore,
    EnergyLabel,
    GlazingTypeFr,
    IsolationRenovationPeriodFr,
    PropertyEntity,
} from '@omedom/data';
import { OmedomPricehubble, OmedomTransformPricehubbleData } from '@omedom/utils';
import { BehaviorSubject } from 'rxjs';

import { slideYInOutAnimation } from '../../../animations/slideYInOut';

const defaultEnergieConsoLabel = 'Note et valeur conso énergie';
const defaultGhgLabel = 'Note et valeur émission GES';
const consoUnit = 'kWh/m²/an';
const ghgUnit = 'kgCO2/m²/an';

@Component({
    selector: 'omedom-property-info-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    animations: [slideYInOutAnimation],
})
export class PropertyInfoSettingsComponent implements OnInit, OnChanges {
    @Input()
    set property(value: PropertyEntity | undefined) {
        this._property = value;

        if (!!value?.amenities?.length) {
            this.indoorLayouts = value.amenities.filter((x) =>
                ['Cuisine équipée', 'Cheminée', 'Climatisation', 'Alarme'].includes(x),
            );
            this.outdoorLayouts = value.amenities.filter((x) =>
                [
                    'Balcon / Terrasse',
                    'Jardin / Cour',
                    'Garage / Parking',
                    'Cave / Sous sol',
                    'Piscine',
                    'Dépendance(s)',
                ].includes(x),
            );
            this.buildingLayouts = value.amenities.filter((x) =>
                ['Ascenseur', 'Gardien / Concierge', 'Digicode', 'Interphone'].includes(x),
            );
            this.heatLayouts = value.amenities
                .filter((x) =>
                    [
                        'Chauffage collectif',
                        'Chauffage individuel',
                        'Chauffage au gaz',
                        'Chauffage éléctrique',
                        'Chauffage au fuel',
                    ].includes(x),
                )
                .map((x) => x.split(' ').splice(-1)[0]);
        } else {
            this.indoorLayouts = [];
            this.outdoorLayouts = [];
            this.buildingLayouts = [];
            this.heatLayouts = [];
        }
    }

    private _property?: PropertyEntity | undefined;

    get property(): PropertyEntity | undefined {
        return this._property;
    }

    indoorLayouts: string[] = [];

    outdoorLayouts: string[] = [];

    buildingLayouts: string[] = [];

    heatLayouts: string[] = [];

    isolationRenovationPeriod: undefined | IsolationRenovationPeriodFr = undefined;

    glazingType: undefined | GlazingTypeFr = undefined;

    atticType: undefined | string = undefined;

    /**
     * @description Display diagnostic info after collapse
     * @author Hanane Djeddal
     * @memberof PropertyInfoSettingsComponent
     */
    public displayDiagnosticForm = new BehaviorSubject<boolean>(false);

    /**
     * @description Display diagnostic info after collapse
     * @author Hanane Djeddal
     * @memberof PropertyInfoSettingsComponent
     */
    public displayRentForm = new BehaviorSubject<boolean>(false);

    /**
     * @description Display diagnostic info after collapse
     * @author Hanane Djeddal
     * @memberof PropertyInfoSettingsComponent
     */
    public displayAmentiesForm = new BehaviorSubject<boolean>(false);

    isAssimilateHouse = false;
    isAssimilateAppartement = false;

    energyConsEstimated?: string;
    energyCons?: string;
    ghg?: string;
    hasGhgEstimated?: string;
    ghgEstimated?: { label: EnergyLabel; measure: number };

    /**
     * @description Label for energy consumption
     * @author ANDRE Felix
     * @memberof PropertyInfoSettingsComponent
     */
    consoEnergieLabel = defaultEnergieConsoLabel;

    /**
     * @description Label for ghg emission
     * @author ANDRE Felix
     * @memberof PropertyInfoSettingsComponent
     */
    ghgLabel = defaultGhgLabel;

    /**
     * @description
     * @author ANDRE Felix
     * @memberof PropertyInfoSettingsComponent
     */
    public displayPhysicalData = new BehaviorSubject<boolean>(false);

    ngOnInit(): void {
        this.isolationRenovationPeriod = OmedomTransformPricehubbleData.transformIsolation(
            this.property?.dpeDetails?.estimationInformations?.isolationRenovationPeriod,
        );

        this.glazingType = OmedomTransformPricehubbleData.transformGlazingType(
            this.property?.dpeDetails?.estimationInformations?.glazingType,
        );

        this.atticType = OmedomTransformPricehubbleData.transformAtticType(
            this.property?.dpeDetails?.estimationInformations?.atticType,
        );
        this.isAssimilateHouse = OmedomPricehubble.isPropertyAssimilateHouse(this.property?.type);
        this.isAssimilateAppartement = OmedomPricehubble.isPropertyAssimilateAppartement(
            this.property?.type,
        );
        this.setDpeValues(this.property);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['property']) {
            this.property = changes['property'].currentValue;
            this.setDpeValues(this.property);
        }
    }

    private setDpeValues(property: PropertyEntity | undefined): void {
        if (!property) {
            return;
        }
        this.setEnergyConso(property);
        this.setGhg(property);
    }

    /**
     * @description Set energy consumption value based on user input or estimation value if user has not provided any value
     * @author ANDRE Felix
     * @private
     * @param {(PropertyEntity | undefined)} property
     * @memberof PropertyInfoSettingsComponent
     */
    private setEnergyConso(property: PropertyEntity | undefined): void {
        const hasEnergyProvidedByUser = !!(
            property?.dpeDetails?.dpeScore || property?.dpeDetails?.dpeValue
        );
        let params;

        if (hasEnergyProvidedByUser) {
            params = {
                value: property?.dpeDetails?.dpeValue,
                score: property?.dpeDetails?.dpeScore,
                message: 'conso énergétique',
            };
        } else {
            const energyConsumption =
                property?.dpeDetails?.estimationResult?.energyConsumption?.[0];

            params = {
                value: energyConsumption?.measure?.value,
                score: energyConsumption?.label?.value,
                message: 'conso énergétique (estimation)',
            };
        }
        const result = this.getConsoValueFormated(params.score, params.value, params.message);
        if (result) {
            this.consoEnergieLabel = result.label;
            this.energyCons = result.value;
        }
    }

    /**
     * @description Set ghg value based on user input or estimation value if user has not provided any value
     * @author ANDRE Felix
     * @private
     * @param {(PropertyEntity | undefined)} property
     * @memberof PropertyInfoSettingsComponent
     */
    private setGhg(property: PropertyEntity | undefined): void {
        const hasGhgProvidedByUser = !!(
            property?.dpeDetails?.ghgScore || property?.dpeDetails?.ghgValue
        );
        let params;
        if (hasGhgProvidedByUser) {
            params = {
                value: property?.dpeDetails?.ghgValue,
                score: property?.dpeDetails?.ghgScore,
                message: 'émission GES',
            };
        } else {
            const ghgEstimated = OmedomPricehubble.getGhgEstimated(property);
            params = {
                value: ghgEstimated?.measure,
                score: ghgEstimated?.label,
                message: 'émission GES (estimation)',
            };
        }
        const result = this.getConsoValueFormated(
            params.score,
            params.value,
            params.message,
            ghgUnit,
        );

        if (result) {
            this.ghgLabel = result.label;
            this.ghg = result.value;
        }
    }

    private getConsoValueFormated(
        score: DPEScore | undefined,
        value: number | undefined,
        message: string,
        unit = consoUnit,
    ) {
        if (score && value) {
            return {
                label: `Note et valeur ${message}`,
                value: `${score.toUpperCase()} - ${value} ${unit}`,
            };
        }

        if (score) {
            return {
                label: `Note ${message}`,
                value: `${score.toUpperCase()}`,
            };
        }

        if (value) {
            return {
                label: `Valeur ${message}`,
                value: `${value} ${unit}`,
            };
        }
        return;
    }
}
