export enum IsolationRenovationPeriodFr {
    moreThan10Years = 'Plus de 10 ans',
    last10Years = 'Moins de 10 ans',
    notRenovated = 'Pas de rénovation',
}

export enum GlazingTypeFr {
    single = 'Simple',
    double = 'Double',
    triple = 'Triple',
}

export enum AtticTypeFr {
    lostAtticSpace = 'Combles non aménagées',
    flatRoof = 'Toiture plate',
    convertedAttic = 'Combles aménagées',
}
