<omedom-modal-tab [tabs]="tabs"></omedom-modal-tab>
<div class="client-content">
    <div class="informations-container">
        <div class="column">
            <ng-container *ngIf="user.avatar; else avatar">
                <img
                    [src]="user.avatar"
                    class="informations-card-image"
                    alt="Avatar de l'utilisateur"
                />
                <div>
                    <span class="omedom-title"
                        >Mise à jour des informations client</span
                    >
                    <span
                        *ngIf="
                            additionalInformationForm &&
                            additionalInformationForm.value.updated
                        "
                    >
                        le
                        {{
                            additionalInformationForm.value.updated
                                | date: 'dd/MM/yyyy à HH:mm'
                        }}
                    </span>
                </div>
            </ng-container>

            <ng-template #avatar>
                <div class="informations-card-image">
                    <i class="uil uil-user-circle"></i>
                </div>
            </ng-template>
        </div>

        <ng-container [ngTemplateOutlet]="getTemplate()"></ng-container>
    </div>
</div>

<ng-template #maritalStatus>
    <form
        class="form-container"
        *ngIf="maritalStatusForm"
        [formGroup]="maritalStatusForm"
    >
        <ng-container [ngSwitch]="activeTab.name">
            <ng-container *ngSwitchCase="'professionalSituation'">
                <h3>Situation professionnelle</h3>

                <ng-container formGroupName="professionalSituation">
                    <div class="two-columns-container">
                        <div class="column">
                            <omedom-input
                                name="label"
                                type="text"
                                label="Libellé de la profession"
                                icon="uil-suitcase"
                                formControlName="label"
                                [placeholder]="'Libellé de la profession'"
                            ></omedom-input>
                            <omedom-input
                                name="sector"
                                label="Secteur d'activité"
                                icon="uil-suitcase"
                                formControlName="sector"
                                [placeholder]="'Secteur d\'activité'"
                            ></omedom-input>
                            <omedom-input
                                name="expectedRetirementAge"
                                type="number"
                                label="Départ en retraite prévu à l'âge de"
                                icon="uil-suitcase"
                                formControlName="expectedRetirementAge"
                                [placeholder]="
                                    'Age de départ à la retraite prévu'
                                "
                            ></omedom-input>

                            <omedom-select
                                name="householdIncomeBracket"
                                label="Tranche de revenus du foyer"
                                formControlName="householdIncomeBracket"
                                [options]="householdIncomeBracketOptions"
                                [placeholder]="
                                    householdIncomeBracketPlaceholders
                                "
                            ></omedom-select>
                        </div>
                        <div class="column">
                            <omedom-input
                                name="nameOfsociety"
                                type="text"
                                label="Nom de la société"
                                icon="uil-suitcase"
                                formControlName="nameOfsociety"
                                [placeholder]="'Nom de la société'"
                            ></omedom-input>
                            <omedom-select
                                name="status"
                                label="Statut"
                                formControlName="status"
                                [options]="statusOptions"
                                [placeholder]="statusPlaceholders"
                            ></omedom-select>
                            <omedom-input
                                name="seniority"
                                type="number"
                                label="Ancienneté"
                                icon="uil-suitcase"
                                formControlName="seniority"
                                [placeholder]="'Ancienneté'"
                            ></omedom-input>
                            <omedom-select
                                name="slicesOfTheHouseholdSNetBorrowingAssets"
                                label="Tranche de patrimoine net d'emprunt(s) du foyer"
                                formControlName="slicesOfTheHouseholdSNetBorrowingAssets"
                                [options]="
                                    slicesOfTheHouseholdSNetBorrowingAssetsOptions
                                "
                                [placeholder]="
                                    slicesOfTheHouseholdSNetBorrowingAssetsPlaceholders
                                "
                            ></omedom-select>
                        </div>
                    </div>
                    <omedom-select
                        name="socialRegime"
                        label="Régime social"
                        formControlName="socialRegime"
                        [options]="socialRegimeOptions"
                        [placeholder]="socialRegimePlaceholders"
                    ></omedom-select>
                </ng-container>
            </ng-container>
            <div class="two-columns-container">
                <div class="column">
                    <ng-container *ngSwitchCase="'maritalStatus'">
                        <h3>État civil</h3>
                        <omedom-radio
                            name="civility"
                            formControlName="civility"
                            [options]="maritalStatusOptions"
                        ></omedom-radio>
                        <div class="two-columns-container">
                            <div class="column">
                                <omedom-input
                                    name="firstname"
                                    type="text"
                                    label="Prénom"
                                    icon="uil-user-circle"
                                    formControlName="firstname"
                                    [placeholder]="user.firstname"
                                ></omedom-input>

                                <omedom-input
                                    name="national"
                                    label="Nationalité"
                                    formControlName="nationality"
                                    placeholder="Nationalité"
                                ></omedom-input>

                                <omedom-input
                                    name="birthdate"
                                    type="text"
                                    label="Date de naissance"
                                    icon="uil-calendar-alt"
                                    formControlName="birthdate"
                                    placeholder="jj/mm/aaaa"
                                    (keyup)="keyClientBirthdatePress($event)"
                                    [pattern]="bdayRegex"
                                ></omedom-input>
                                <ng-container formGroupName="birthplace">
                                    <omedom-input
                                        name="birthplacePostalCode"
                                        type="text"
                                        label="Code postal de naissance"
                                        icon="uil-map-marker"
                                        formControlName="postalCode"
                                        [placeholder]="
                                            'Code postal de naissance'
                                        "
                                    ></omedom-input>
                                </ng-container>
                            </div>
                            <div class="column">
                                <omedom-input
                                    name="name"
                                    type="text"
                                    label="Nom"
                                    icon="uil-user-circle"
                                    formControlName="name"
                                    [placeholder]="user.name"
                                ></omedom-input>

                                <omedom-input
                                    name="countryOfTaxResidence"
                                    label="Pays de résidence fiscale"
                                    formControlName="countryOfTaxResidence"
                                    placeholder="Pays de résidence fiscale"
                                ></omedom-input>

                                <ng-container formGroupName="birthplace">
                                    <omedom-input
                                        name="birthplaceCountry"
                                        type="text"
                                        label="Pays de naissance"
                                        icon="uil-map-marker"
                                        formControlName="country"
                                        [placeholder]="'Pays de naissance'"
                                    ></omedom-input>
                                    <omedom-input
                                        name="birthplaceCity"
                                        type="text"
                                        label="Commune de naissance"
                                        icon="uil-map-marker"
                                        formControlName="city"
                                        [placeholder]="'Commune de naissance'"
                                    ></omedom-input>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'contact'">
                        <h3>Adresse</h3>
                        <ng-container formGroupName="address">
                            <div class="two-columns-container">
                                <div class="column">
                                    <omedom-input
                                        name="streetNumber"
                                        type="text"
                                        label="Numéro de rue"
                                        icon="uil-map-marker"
                                        formControlName="streetNumber"
                                        [placeholder]="
                                            '' +
                                            (user.address?.streetNumber ||
                                                'Numéro de rue')
                                        "
                                    ></omedom-input>
                                </div>
                                <div class="column">
                                    <omedom-input
                                        name="suffixNumber"
                                        type="text"
                                        label="Complément"
                                        icon="uil-map-marker"
                                        formControlName="suffixNumber"
                                        [placeholder]="
                                            user.address?.suffixNumber ||
                                            'bis, ter, etc.'
                                        "
                                    ></omedom-input>
                                </div>
                            </div>
                            <omedom-input
                                name="street"
                                type="text"
                                label="Rue"
                                icon="uil-map-marker"
                                formControlName="street"
                                [placeholder]="user.address?.street || 'Rue'"
                            ></omedom-input>
                            <omedom-input
                                name="addressLine2"
                                type="text"
                                label="Complément d'adresse"
                                icon="uil-map-marker"
                                formControlName="addressLine2"
                                [placeholder]="
                                    user.address?.addressLine2 ||
                                    'Complément d\'adresse'
                                "
                            ></omedom-input>
                            <div class="two-columns-container">
                                <div class="column">
                                    <omedom-input
                                        name="postalCode"
                                        type="text"
                                        label="Code postal"
                                        icon="uil-map-marker"
                                        formControlName="postalCode"
                                        [placeholder]="
                                            user.address?.postalCode ||
                                            'Code postal'
                                        "
                                    ></omedom-input>
                                </div>
                                <div class="column">
                                    <omedom-input
                                        name="city"
                                        type="text"
                                        label="Ville"
                                        icon="uil-map-marker"
                                        formControlName="city"
                                        [placeholder]="
                                            user.address?.city || 'Ville'
                                        "
                                    ></omedom-input>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="column">
                    <ng-container *ngSwitchCase="'maritalStatus'">
                        <h3>Personne US</h3>
                        <omedom-radio
                            name="isUSPerson"
                            formControlName="isUSPerson"
                            [options]="isUSPerson"
                        ></omedom-radio>
                        <h3>Personne Politiquement Exposée</h3>
                        <omedom-radio
                            name="isPPE"
                            label="Personne  Politiquement Exposée (PPE)"
                            formControlName="isPPE"
                            [options]="isPPEOptions"
                        ></omedom-radio>
                        <omedom-radio
                            name="isLinkedToPPE"
                            label="Personne liée à une PPE"
                            formControlName="isLinkedToPPE"
                            [options]="isLinkedToPPEOptions"
                        ></omedom-radio>
                    </ng-container>

                    <ng-container *ngSwitchCase="'contact'">
                        <h3>Contact</h3>
                        <ng-container formGroupName="contact">
                            <div class="two-columns-container">
                                <div class="column">
                                    <omedom-input
                                        name="telMobile"
                                        type="tel"
                                        label="Téléphone Personnel"
                                        icon="uil-phone"
                                        formControlName="telMobile"
                                        [placeholder]="
                                            user.tel || 'Téléphone Personnel'
                                        "
                                        [pattern]="phoneRegex"
                                    ></omedom-input>
                                </div>
                                <div class="column">
                                    <omedom-input
                                        name="telHouse"
                                        type="tel"
                                        label="Téléphone Domicile"
                                        icon="uil-phone"
                                        formControlName="telHouse"
                                        [placeholder]="'Téléphone Domicile'"
                                        [pattern]="phoneRegex"
                                    ></omedom-input>
                                </div>
                            </div>
                            <omedom-input
                                name="emailPerso"
                                type="email"
                                label="E-mail Personnel"
                                icon="uil-envelope"
                                formControlName="emailPerso"
                                [placeholder]="user.email || 'E-mail Personnel'"
                            ></omedom-input>
                            <omedom-input
                                name="telPro"
                                type="tel"
                                label="Téléphone Professionnel"
                                icon="uil-phone"
                                formControlName="telPro"
                                [placeholder]="'Téléphone Professionnel'"
                                [pattern]="phoneRegex"
                            ></omedom-input>
                            <omedom-input
                                name="emailPro"
                                type="email"
                                label="E-mail Professionnel"
                                icon="uil-envelope"
                                formControlName="emailPro"
                                [placeholder]="
                                    user.email || 'E-mail Professionnel'
                                "
                            ></omedom-input>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <!--    <div class="column"> -->
            <ng-container *ngSwitchCase="'familySituation'">
                <h3>Situation familiale</h3>
                <ng-container formGroupName="familySituation">
                    <omedom-select
                        label="Situation familiale"
                        formControlName="familyStatus"
                        [options]="familyStatusOptions"
                        [placeholder]="familyStatusPlaceholders"
                    ></omedom-select>
                    <div
                        class="two-columns-container"
                        *ngIf="
                            familyStatus === 'married' ||
                            familyStatus === 'pacs'
                        "
                    >
                        <omedom-input
                            name="weddingDate"
                            type="text"
                            [label]="
                                'Date du ' +
                                (familyStatus === 'married'
                                    ? 'mariage'
                                    : 'PACS')
                            "
                            formControlName="weddingDate"
                            icon="uil-calendar-alt"
                            placeholder="jj/mm/aaaa"
                            (keyup)="keyWeddingDatePress($event)"
                            [pattern]="bdayRegex"
                        ></omedom-input>
                        <omedom-input
                            name="weddingPlace"
                            type="text"
                            [label]="
                                'Lieu du ' +
                                (familyStatus === 'married'
                                    ? 'mariage'
                                    : 'PACS')
                            "
                            formControlName="weddingPlace"
                            [placeholder]="
                                'Lieu du' +
                                (familyStatus === 'married'
                                    ? 'mariage'
                                    : 'PACS')
                            "
                        ></omedom-input>
                    </div>

                    @if (familyStatus === 'married') {
                        <omedom-select
                            name="maritalRegime"
                            type="text"
                            label="Régime matrimonial"
                            formControlName="maritalRegime"
                            [options]="maritalRegimeOptions"
                            [placeholder]="regimePlaceholders"
                        ></omedom-select>
                    } @else if (familyStatus === 'pacs') {
                        <omedom-select
                            name="pacsRegime"
                            type="text"
                            label="Régime PACS"
                            formControlName="pacsRegime"
                            [options]="pacsRegimeOptions"
                            [placeholder]="regimePlaceholders"
                        ></omedom-select>
                    }
                    <omedom-select
                        label="Libéralités"
                        name="liberated"
                        formControlName="liberated"
                        [options]="liberatedOptions"
                        [placeholder]="liberatedPlaceholders"
                        [isMultiple]="true"
                    ></omedom-select>

                    <omedom-select
                        name="numberOfDependentPeople"
                        label="Nombre d'enfants à charge"
                        formControlName="numberOfDependentPeople"
                        [options]="numberOfDependentPeopleOptions"
                        [placeholder]="numberOfDependentPeoplePlaceholders"
                    ></omedom-select>
                    <omedom-input
                        name="statusOfProtectedAdults"
                        label="Si présence de majeurs protégés, status (régime, lien familial, date de naissance)"
                        formControlName="statusOfProtectedAdults"
                        class="textarea"
                        [rows]="3"
                        placeholder="Si présence de majeurs protégés, status (régime, lien familial, date de naissance)"
                    ></omedom-input>
                </ng-container>
            </ng-container>

            <!-- </div>
            </div> -->
        </ng-container>
        <div class="omedom-sticky-button-container">
            <button
                class="omedom-button-green w-100"
                type="submit"
                (click)="submit()"
                [disabled]="
                    !maritalStatusForm.valid ||
                    !additionalInformationForm?.valid ||
                    (pending$ | async)
                "
            >
                Enregistrer
                <ion-spinner *ngIf="pending$ | async"></ion-spinner>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #additionalInformation>
    <form
        class="form-container"
        *ngIf="additionalInformationForm"
        [formGroup]="additionalInformationForm"
    >
        <h3>Informations complémentaires</h3>
        <omedom-input
            name="mainAdvisor"
            type="text"
            label="Conseiller principal"
            icon="uil-user-circle"
            formControlName="mainAdvisor"
            [placeholder]="'Conseiller principal'"
        ></omedom-input>

        <omedom-input
            name="dateOfConnection"
            type="date"
            label="Date d'entrée en relation"
            icon="uil-calendar-alt"
            formControlName="dateOfConnection"
            placeholder="Date d'entrée en relation"
        ></omedom-input>
        <omedom-input
            name="notes"
            label="Notes"
            class="textarea"
            icon="uil-sticky-note"
            formControlName="notes"
            [placeholder]="'Notes'"
        ></omedom-input>

        <button
            class="omedom-button-green"
            type="submit"
            (click)="submit()"
            [disabled]="
                !maritalStatusForm?.valid ||
                !additionalInformationForm.valid ||
                (pending$ | async)
            "
        >
            Enregistrer
            <ion-spinner *ngIf="pending$ | async"></ion-spinner>
        </button>
    </form>
</ng-template>
