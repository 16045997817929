<div class="container">
    @if (documentForm) {
        <div class="container-content">
            @if (!document || roleRight?.create) {
                <div
                    class="file-container w-100 padding-2 margin-bottom-2"
                    [ngClass]="{ 'drag-over': isDragOver }"
                    (drop)="dropHandler($event)"
                    (dragover)="dragOverHandler($event)"
                    (dragleave)="isDragOver = false"
                    (click)="$any(fileinput).click()"
                >
                    @if (isDesktop) {
                        <div class="drop-label margin-y-2 w-100">
                            <i class="uil uil-import margin-right-1"></i>
                            <span>Déposer une image/pdf ou cliquer ici</span>
                        </div>
                    }
                    @if (!document && !isDesktop) {
                        <button
                            class="omedom-button-primary"
                            (click)="
                                $any(fileinput).click($event);
                                $event.stopPropagation()
                            "
                        >
                            <i class="uil uil-import"></i>
                            <span>Importer une image ou pdf </span>
                        </button>
                    }
                    @if (document && roleRight?.delete && !isDesktop) {
                        <button
                            class="omedom-button-secondary"
                            (click)="
                                $any(fileinput).click($event);
                                $event.stopPropagation()
                            "
                        >
                            <i class="uil uil-import"></i>
                            <span> Remplacer le document</span>
                        </button>
                    }
                    <input
                        #fileinput
                        name="file"
                        id="file"
                        class="omedom-invisible"
                        type="file"
                        accept="image/*, application/pdf"
                        (change)="keepFile($any($event.target).files)"
                    />
                    @if (!isValidFileType) {
                        <div
                            class="margin-y-1 color-red"
                            style="font-size: 14px"
                        >
                            Seul les images et pdf peuvent être importés
                        </div>
                    }
                    @if (file) {
                        <div class="file-detail margin-top-2">
                            <span>{{ file.name }}</span>
                            <button
                                class="omedom-icon-button-warn"
                                (click)="deleteFile(); $event.stopPropagation()"
                            >
                                <i class="uil uil-trash-alt"></i>
                            </button>
                        </div>
                    }
                </div>
            }
            <omedom-storage-progress
                [staging]="file?.size ?? 0"
                [user$]="user$"
            ></omedom-storage-progress>

            <form [formGroup]="documentForm" class="w-100">
                <omedom-input
                    class="margin-top-2 flex-1"
                    name="name"
                    label="Nom"
                    [required]="true"
                    placeholder="Nom"
                    formControlName="name"
                ></omedom-input>
                <omedom-input
                    class="margin-top-2 flex-1"
                    type="date"
                    name="date"
                    label="Date du document"
                    formControlName="date"
                ></omedom-input>
                @if (documentType) {
                    @if (isDocumentTypeFree) {
                        <omedom-input
                            label="Type de document"
                            class="margin-top-2 flex-1"
                            name="type"
                            formControlName="type"
                            [placeholder]="typePlaceholder.label"
                        ></omedom-input>
                    } @else {
                        <omedom-select
                            label="Type de document"
                            class="margin-top-2 flex-1"
                            name="type"
                            formControlName="type"
                            [placeholder]="typePlaceholder"
                            [options]="typeOptions || []"
                        ></omedom-select>
                    }
                }
                @if (isReceipt) {
                    <hr class="omedom-divider" />
                    <omedom-input
                        class="flex-1"
                        name="invoiceNumber"
                        type="text"
                        label="N° de facture"
                        formControlName="invoiceNumber"
                    ></omedom-input>
                    <omedom-input
                        class="margin-top-2 flex-1"
                        name="amount"
                        icon="uil uil-euro"
                        type="number"
                        label="Montant HT"
                        formControlName="amount"
                    ></omedom-input>
                    <omedom-input
                        class="margin-top-2 flex-1"
                        name="totalAmount"
                        icon="uil uil-euro"
                        type="number"
                        label="Montant TTC"
                        formControlName="totalAmount"
                    ></omedom-input>
                    <!-- @if (getVatRate) {
                        <p class="omedom-form-label flex-1">TVA appliquée</p>
                        <span class="flex-1">
                            <i class="uil uil-percentage margin-x-2"></i
                            >{{ getVatRate }}
                        </span>
                    } -->

                    <omedom-input
                        class="margin-top-2 flex-1"
                        type="date"
                        name="dueDate"
                        label="Date de paiement"
                        formControlName="dueDate"
                    ></omedom-input>
                    <omedom-select
                        label="Type de facture"
                        class="margin-top-2 flex-1"
                        name="invoiceType"
                        formControlName="invoiceType"
                        [options]="invoiceTypeOptions || []"
                    ></omedom-select>
                    @if (isConstructionWork) {
                        <omedom-input
                            class="margin-top-2 flex-1"
                            name="constructionWorkDetail"
                            type="text"
                            label="Type de travaux"
                            formControlName="constructionWorkDetail"
                        ></omedom-input>
                    }
                    <omedom-input
                        class="margin-top-2 flex-1"
                        name="supplierName"
                        label="Fournisseur"
                        placeholder="Nom du fournisseur"
                        formControlName="supplierName"
                    ></omedom-input>
                    <p class="label">Adresse du fournisseur</p>
                    <form [formGroup]="addressForm" class="form-inline">
                        <div>
                            <omedom-input
                                class="margin-right-1"
                                name="supplierStreetNumber"
                                style="width: 150px"
                                [min]="0"
                                [max]="9999"
                                type="number"
                                formControlName="supplierStreetNumber"
                                placeholder="N°"
                                autocomplete="off"
                                [debounce]="300"
                            ></omedom-input>
                            <omedom-input
                                class="margin-top-1"
                                name="supplierSuffixNumber"
                                style="width: 100px"
                                type="text"
                                formControlName="supplierSuffixNumber"
                                placeholder="bis, ter"
                                autocomplete="off"
                                [debounce]="300"
                            ></omedom-input>
                        </div>
                        <div>
                            <omedom-input
                                class="margin-top-1"
                                name="supplierStreet"
                                type="text"
                                formControlName="supplierStreet"
                                placeholder="Rue"
                                autocomplete="off"
                                [debounce]="300"
                            ></omedom-input>
                        </div>
                        <div>
                            <omedom-input
                                class="margin-top-1"
                                name="supplierAddressLine2"
                                type="text"
                                formControlName="supplierAddressLine2"
                                placeholder="Complément d'adresse"
                                autocomplete="off"
                                [debounce]="300"
                            ></omedom-input>
                        </div>
                        <div>
                            <omedom-input
                                class="margin-top-1 margin-right-1"
                                name="supplierPostalCode"
                                style="width: 150px"
                                type="text"
                                formControlName="supplierPostalCode"
                                placeholder="Code postal"
                                autocomplete="off"
                                [pattern]="postalCodeRegex"
                                [debounce]="300"
                            ></omedom-input>
                            <omedom-input
                                class="margin-top-1"
                                name="supplierCity"
                                style="width: calc(100% - 160px)"
                                type="text"
                                formControlName="supplierCity"
                                placeholder="Ville"
                                autocomplete="off"
                                [debounce]="300"
                            ></omedom-input>
                        </div>
                    </form>
                }

                @if (
                    !(propertyUid || societyUid || loanUid || savingUid) &&
                    (roleRight ? roleRight.create : true) &&
                    !isPro
                ) {
                    <hr class="omedom-divider" />
                    @if ((assetOptions || []).length > 0) {
                        <omedom-select
                            label="Associer au bien :"
                            class="flex-1"
                            name="assetUID"
                            formControlName="assetUID"
                            [disabled]="isOptionPropertyReady"
                            [placeholder]="propertyPlaceholder"
                            [options]="assetOptions || []"
                        ></omedom-select>
                    }
                    @if ((loanOptions || []).length > 0) {
                        <omedom-select
                            label="Associer à un crédit :"
                            class="margin-top-2 flex-1"
                            name="loanUID"
                            formControlName="loanUID"
                            [placeholder]="loanPlaceholder"
                            [options]="loanOptions || []"
                        ></omedom-select>
                    }
                    @if ((savingOptions || []).length > 0) {
                        <omedom-select
                            label="Associer à une épargne :"
                            class="margin-top-2 flex-1"
                            name="savingUID"
                            formControlName="savingUID"
                            [placeholder]="savingPlaceholder"
                            [options]="savingOptions || []"
                        ></omedom-select>
                    }
                }
            </form>

            <div class="omedom-sticky-button-container w-100 margin-top-2">
                <button
                    class="omedom-button-secondary flex-1 margin-right-1"
                    type="button"
                    (click)="navigationBack()"
                >
                    Annuler
                </button>
                <button
                    class="omedom-button-primary flex-1"
                    type="button"
                    (click)="submit()"
                    [disabled]="disableSubmit"
                >
                    @if (!document) {
                        <i class="uil uil-file-plus-alt"></i>
                    }
                    {{ document ? 'Enregistrer' : 'Ajouter' }}
                    @if (pending$.asObservable() | async) {
                        <ion-spinner
                            color="primary"
                            class="omedom-upload-spinner"
                        ></ion-spinner>
                    }
                </button>
            </div>
        </div>
    }
</div>
