import { Component, Input, OnInit } from '@angular/core';
import { NumericRange } from '@omedom/data';

@Component({
    selector: 'omedom-display-range-price',
    templateUrl: './display-range-price.component.html',
    styleUrls: ['./display-range-price.component.scss'],
})
export class DisplayRangePriceComponent implements OnInit {
    @Input({ required: true }) valueRange!: NumericRange;

    @Input() withPlus = false;
    min?: number;
    max?: number;
    average?: number;

    ngOnInit(): void {
        if (this.valueRange) {
            this.min = Math.round(this.valueRange.min);
            this.max = Math.round(this.valueRange.max);
            this.average = Math.round(this.valueRange.avg);
        }
    }
}
