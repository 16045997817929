@if (energyReport) {
    @if (scenarios) {
        <div class="title margin-bottom-2">Scénarios de rénovation</div>
        <div class="scenario-container">
            @for (scenario of scenarios; track $index) {
                @if (actualDPEScore) {
                    <omedom-renovation-scenario
                        [actualDPEScore]="actualDPEScore"
                        [scenario]="scenario"
                        [resultGranularity]="energyReport.resultGranularity"
                    ></omedom-renovation-scenario>
                }
            }
        </div>
    }
}
