<h2 class="form-title">Informations du membre</h2>

<button class="form-close-button" (click)="close()">
    <i class="uil uil-times-circle"></i>
</button>

@if (memberForm) {
    <form class="form" [formGroup]="memberForm">
        <div class="form-item">
            <span>Prénom<sup>*</sup></span>
            <ion-item class="margin-top-1">
                <ion-label>
                    <i class="uil uil-user-circle"></i>
                </ion-label>
                <ion-input
                    type="text"
                    formControlName="firstname"
                    placeholder="Prénom"
                ></ion-input>
            </ion-item>
        </div>
        <div class="form-item">
            <span>Nom<sup>*</sup></span>
            <ion-item class="margin-top-1">
                <ion-label>
                    <i class="uil uil-user-circle"></i>
                </ion-label>
                <ion-input
                    type="text"
                    formControlName="name"
                    placeholder="Nom"
                ></ion-input>
            </ion-item>
        </div>
        <div class="form-item">
            <span>% de détention</span>
            <ion-item class="margin-top-1">
                <ion-label>
                    <i class="uil uil-percentage"></i>
                </ion-label>
                <ion-input
                    type="number"
                    min="0"
                    max="100"
                    step="0.01"
                    formControlName="ownershipPercentage"
                    placeholder="0,00 - 100,00"
                ></ion-input>
            </ion-item>
        </div>
        @if ('accessManageSociety' | isAuthorised | async) {
            <div class="form-item">
                <span>Téléphone</span>
                <ion-item class="margin-top-1">
                    <ion-label>
                        <i class="uil uil-phone"></i>
                    </ion-label>
                    <ion-input
                        type="tel"
                        formControlName="tel"
                        placeholder="Téléphone"
                    ></ion-input>
                </ion-item>
            </div>
        }
        <div class="form-item-radio {{ isCreator ? 'margin-bottom-1' : '' }}">
            <omedom-radio
                label="Statut du membre"
                name="status"
                formControlName="status"
                [options]="statusOptions"
                [required]="true"
            ></omedom-radio>
        </div>
        @if (
            ('accessManageSociety' | isAuthorised | async) &&
            isCreator === false
        ) {
            <hr class="omedom-divider omedom-no-margin" />
            <span
                class="form-member-title"
                [style.color]="
                    ('accessManageSociety' | isAuthorised | async)
                        ? '#04151C'
                        : '#A8ADAF'
                "
            >
                <i class="uil uil-share-alt"></i>
                Informer et partager la société :
            </span>
            @if (('accessManageSociety' | isAuthorised | async) === false) {
                <omedom-action
                    text="Fonctionnalité Premium, changez d'abonnement."
                    actionIcon="omedom-icon-crown"
                    [simpleIcon]="true"
                >
                </omedom-action>
            }
            <div class="form-item">
                <span>E-mail<sup>*</sup></span>
                <ion-item class="margin-top-1">
                    <ion-label>
                        <i class="uil uil-envelope"></i>
                    </ion-label>
                    <ion-input
                        type="email"
                        formControlName="email"
                        placeholder="E-mail"
                    ></ion-input>
                </ion-item>
            </div>
        }
    </form>
}

<div class="omedom-sticky-button-container w-100 margin-top-2 margin-bottom-1">
    <button
        class="omedom-button-secondary flex-1 margin-right-1"
        (click)="close()"
    >
        <span>Annuler</span>
    </button>

    <button
        class="omedom-button-green flex-1"
        (click)="submit()"
        [disabled]="memberForm?.invalid || (pending$.asObservable() | async)"
    >
        <i class="uil uil-save"></i>
        <span>Enregistrer</span>
        @if (pending$.asObservable() | async) {
            <ion-spinner
                color="primary"
                class="form-upload-spinner"
            ></ion-spinner>
        }
    </button>
</div>
