<div class="container modal">
    <h2 class="margin-bottom-2">Coordonnées de mon pro de confiance</h2>

    @if (recomendForm) {
        <form
            [formGroup]="recomendForm"
            (ngSubmit)="onSubmit()"
            class="padding-left-1"
        >
            <omedom-input
                label="Nom de l'entreprise"
                [boldLabel]="true"
                placeholder="Nom entreprise"
                formControlName="companyName"
                [required]="true"
                class="margin-bottom-2"
            ></omedom-input>
            <omedom-input
                label="Profession"
                [boldLabel]="true"
                formControlName="profession"
                placeholder="Profession du pro"
                class="margin-bottom-2"
                [required]="true"
            ></omedom-input>
            <omedom-input
                label="E-mail"
                [boldLabel]="true"
                formControlName="email"
                placeholder="E-mail du pro"
                class="margin-bottom-2"
                [required]="true"
                [error]="getErrorMessage('email', recomendForm)"
            ></omedom-input>
            <omedom-input
                label="Nom et prénom"
                [boldLabel]="true"
                placeholder="Nom et prénom du pro"
                formControlName="fullName"
                class="margin-bottom-2"
            ></omedom-input>
            <omedom-input
                formControlName="phone"
                type="tel"
                label="Téléphone"
                [boldLabel]="true"
                placeholder="Téléphone de l'entreprise"
                class="margin-bottom-2"
            ></omedom-input>

            <div class="omedom-form-actions">
                <button
                    class="omedom-button-secondary"
                    type="button"
                    style="flex: 1"
                    (click)="cancel()"
                >
                    Annuler
                </button>
                <button
                    class="omedom-button-yellow"
                    type="submit"
                    style="flex: 1"
                    [disabled]="!recomendForm.valid || (pending$ | async)"
                >
                    <i class="uil uil-save"></i>
                    Envoyer
                    @if (pending$ | async) {
                        <ion-spinner></ion-spinner>
                    }
                </button>
            </div>
        </form>
    }
</div>
