import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import {
    defaultRoleState,
    DocumentEntity,
    DocumentTypeUsed,
    FilterParameter,
    PropertyEntity,
    RoleState,
    SocietyEntity,
    SortParameter,
    UserEntity,
} from '@omedom/data';
import { UserService } from '@omedom/services';
import { Observable } from 'rxjs';

import { elementAnimation } from '../../../animations';

@Component({
    selector: 'omedom-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss'],
    animations: [
        trigger('list', [
            transition('* => *', [
                // each time the binding value changes
                query(
                    ':enter',
                    [
                        style({ opacity: 0, transform: 'translateY(30px)' }),
                        stagger(100, [
                            animate(
                                '0.5s',
                                style({
                                    opacity: 1,
                                    transform: 'translateY(0px)',
                                }),
                            ),
                        ]),
                    ],
                    { optional: true },
                ),
            ]),
        ]),
        elementAnimation,
    ],
})
export class DocumentListComponent implements OnInit, OnChanges {
    /**
     * @description List of documents owned by the user or shared to him
     * @author Jérémie Lopez
     * @type {DocumentEntity[]}
     * @memberof DocumentListComponent
     */
    @Input()
    public documents: DocumentEntity[] = [];

    /**
     * @description List of properties owned by the user or shared to him
     * @author Jérémie Lopez
     * @type {PropertyEntity[]}
     * @memberof DocumentListComponent
     */
    @Input()
    public properties: PropertyEntity[] = [];

    /**
     * @description display add button in list, used in desktop
     * @author ANDRE Felix
     * @memberof DocumentListComponent
     */
    @Input() displayAddButtonInList = false;

    /**
     * @description If the user is a pro user or not, to display the correct type of document
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/01/2025
     * @memberof DocumentListComponent
     */
    @Input()
    displayProDocument = false;

    /**
     * @description If the storage progress is displayed or not in the document list component
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/01/2025
     * @memberof DocumentListComponent
     */
    @Input()
    showStorageProgress = true;

    /**
     * @description List of societies owned by the user or shared to him
     * @author Brisset Killian
     * @date 19/06/2024
     * @type {SocietyEntity[]}
     * @memberof DocumentListComponent
     */
    @Input()
    public societies: SocietyEntity[] = [];

    /**
     * @description If the component is used in the property tab
     * @author Brisset Killian
     * @date 19/06/2024
     * @memberof DocumentListComponent
     */
    @Input()
    public isPropertyTab = false;

    @Input() roleState: RoleState = defaultRoleState;

    @Input() activeConsole = false;

    /**
     * @description If the add button is displayed in the search bar or not
     * @author Brisset Killian
     * @date 25/06/2024
     * @memberof DocumentListComponent
     */
    @Input()
    addButtonInSearchBar = false;

    /**
     * @description If the add button is displayed in the search bar or not
     * @author Brisset Killian
     * @date 28/06/2024
     * @memberof DocumentListComponent
     */
    @Input()
    addButton = true;

    /**
     * @description The type of document used, like for client, pro and importable, or pro and not importable,
     *  that will allow to display the correct type
     * @author ANDRE Felix
     * @type {DocumentTypeUsed}
     * @memberof DocumentListComponent
     */
    @Input() DocumentTypeUsed: DocumentTypeUsed = DocumentTypeUsed.client;

    /**
     * @description if type is free, user can search in an input of type text
     * @author ANDRE Felix
     * @memberof DocumentListComponent
     */
    @Input() isTypeFree = false;

    /**
     * @description Emit the documentUID when a document is clicked on the list of documents
     * @author Brisset Killian
     * @date 19/06/2024
     * @memberof DocumentListComponent
     */
    @Output()
    public documentClick = new EventEmitter<string>();

    /**
     * @description Emit when the user wants to add a document to a property or a society
     * @author Brisset Killian
     * @date 19/06/2024
     * @memberof DocumentListComponent
     */
    @Output()
    public addDocument = new EventEmitter<void>();

    /**
     * @description List of documents filtered
     * @author Jérémie Lopez
     * @type {DocumentEntity[]}
     * @memberof DocumentListComponent
     */
    public filteredDocuments: DocumentEntity[] = [];

    public filterData: FilterParameter[] = [];

    @Input()
    public sortData: SortParameter = { where: 'name', order: 'asc' };

    public searchData = '';

    public user$: Observable<UserEntity>;

    constructor(
        private userService: UserService,
        private router: Router,
    ) {
        this.user$ = this.userService.user$;
    }

    async ngOnInit(): Promise<void> {
        this.operateData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['properties']?.currentValue) {
            this.properties = changes['properties'].currentValue;
        }

        if (changes['documents']?.currentValue) {
            this.documents = changes['documents'].currentValue;
        }

        if (changes['societies']?.currentValue) {
            this.societies = changes['societies'].currentValue;
        }

        this.operateData();
    }

    /**
     * @description Filter data
     * @author Jérémie Lopez
     * @param {string} value
     * @memberof DocumentListComponent
     */
    private operateData(): void {
        this.filteredDocuments = this.filterDocumentByUserType(this.documents);

        this.filteredDocuments = this.filteredDocuments.filter(
            (element) => element?.name?.toLowerCase()?.includes(this.searchData),
        );

        this.filterData.forEach((filter) => {
            if (filter.where === 'type') {
                this.filteredDocuments = this.filteredDocuments.filter(
                    (element) => element.type === filter.value,
                );
            }

            if (filter.where === 'propertyUID') {
                this.filteredDocuments = this.filteredDocuments.filter(
                    (element) => element.propertyUID === filter.value,
                );
            }

            if (filter.where === 'societyUID') {
                this.filteredDocuments = this.filteredDocuments.filter(
                    (element) => element.societyUID === filter.value,
                );
            }

            if (filter.where === 'startDate') {
                this.filteredDocuments = this.filteredDocuments.filter(
                    (element) => element.date.getTime() >= filter.value?.seconds,
                );
            }

            if (filter.where === 'endDate') {
                this.filteredDocuments = this.filteredDocuments.filter(
                    (element) => element.date.getTime() <= filter.value?.seconds,
                );
            }
        });

        this.filteredDocuments.sort((a, b) => {
            let result = 0;

            const aCreatedDate = this.getDate(a.created);
            const bCreatedDate = this.getDate(b.created);

            const aDate = this.getDate(a.date);
            const bDate = this.getDate(b.date);

            const dateDiff = aDate.getTime() - bDate.getTime();
            const dateCreatedDiff = bCreatedDate.getTime() - aCreatedDate.getTime();
            switch (this.sortData.where) {
                case 'name':
                    result =
                        a.name.localeCompare(b.name) * (this.sortData.order === 'asc' ? 1 : -1);
                    break;

                case 'date':
                    result = dateDiff * (this.sortData.order === 'asc' ? 1 : -1);
                    break;

                case 'created':
                    result = dateCreatedDiff * (this.sortData.order === 'asc' ? -1 : 1);
                    break;

                default:
                    result = dateCreatedDiff;
                    break;
            }

            return result;
        });
    }

    /**
     * @description if user is Client, (he's connected to app), we filter document where isForPro at false
     * It allows old code to works without modifying documents load, but would be better to do so
     * @author ANDRE Felix
     * @private
     * @param {DocumentEntity[]} documents
     * @returns {*}
     * @memberof DocumentListComponent
     */
    private filterDocumentByUserType(documents: DocumentEntity[]) {
        if (this.DocumentTypeUsed !== DocumentTypeUsed.client) {
            return documents;
        }

        const clientDocuments = documents.filter((document) => {
            const isForClient = !document?.isForPro || this.displayProDocument;
            return isForClient;
        });

        return clientDocuments;
    }
    private getDate(date: any): Date {
        if (date) {
            const seconds = date.seconds;
            // if seconds is defined, it's a firestore date
            // else it's a date object or a number of seconds
            return new Date(seconds ? seconds * 1000 : date);
        } else {
            const firstDate = new Date('1970-01-01');
            return firstDate;
        }
    }

    public filter(filter: FilterParameter[]): void {
        this.filterData = filter;
        this.operateData();
    }

    public sort(sort: SortParameter): void {
        this.sortData = sort;
        this.operateData();
    }

    public search(value: string): void {
        this.searchData = value;
        this.operateData();
    }

    /**
     * @description Emit the documentUID when a document is clicked on the list of documents
     * @author Brisset Killian
     * @date 19/06/2024
     * @param {string} documentUID
     * @memberof DocumentListComponent
     */
    public documentClicked(documentUID: string): void {
        this.documentClick.emit(documentUID);
    }

    /**
     * @description Go to the document form to add a document to a property or a society
     * @author Brisset Killian
     * @date 19/06/2024
     * @memberof DocumentListComponent
     */
    public goToDocumentForm(): void {
        this.addDocument.emit();
    }
}
