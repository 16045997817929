import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LeaseEntity } from '@omedom/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestService } from './rest.service';

@Injectable({ providedIn: 'root' })
export class LeaseService extends RestService<LeaseEntity> {
    protected override builder = LeaseEntity;

    constructor(protected override firestore: AngularFirestore) {
        super(firestore, 'leases');
    }

    /**
     * @description Get the lease of a property by its UID
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} propertyUID UID of the property
     * @returns {Promise<LeaseEntity>} Lease of the property
     * @memberof LeaseService
     * @example
     * const lease = await leaseService.getLeaseByProperty(propertyUID);
     */
    public async getLeaseByProperty(propertyUID: string): Promise<LeaseEntity> {
        // Search the lease
        const search = await this.search([
            { where: 'propertyUID', operator: '==', value: propertyUID },
        ]);

        // Get the first result
        const lease = search[0];

        // Return the lease
        return lease;
    }

    /**
     * @description Get the lease of a property by its UID in realtime
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} propertyUID UID of the property
     * @returns {Observable<LeaseEntity>} Lease of the property
     * @memberof LeaseService
     * @example
     * const lease = await leaseService.getLeaseByProperty(propertyUID);
     */
    public _getLeaseByProperty(propertyUID: string): Observable<LeaseEntity> {
        return this._search([{ where: 'propertyUID', operator: '==', value: propertyUID }]).pipe(
            // Get the first result
            map((search) => search[0]),
        );
    }
}
