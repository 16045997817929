@switch (state$ | async) {
    @case ('pending') {
        <ng-container *ngTemplateOutlet="pending"></ng-container>
    }
    @default {
        <ng-container *ngTemplateOutlet="content"></ng-container>
    }
}

<ng-template #content>
    @if (bank) {
        <div class="card-header" (click)="toggle()">
            <div class="bank-header">
                <img
                    class="bank-logo"
                    [src]="bank.logoUrl"
                    [alt]="bank.name"
                    [title]="bank.name"
                />
                <div class="bank-balance">
                    <h3>{{ bank.name }}</h3>
                    <div class="bank-balance-title">
                        @if (item.status !== statusCode.OK) {
                            <i
                                class="uil uil-exclamation-triangle color-yellow"
                            ></i>
                        }
                        @if (balance) {
                            <span class="bank-balance-solde">Solde : </span>
                        }
                        @if (balance) {
                            <span
                                class="bank-balance-amount"
                                [style.color]="
                                    !balance
                                        ? '#72757E'
                                        : balance > 0
                                          ? '#00C29A'
                                          : '#FF563F'
                                "
                            >
                                {{
                                    showBalance
                                        ? (balance | omedomNumber)
                                        : '--,--'
                                }}
                                €</span
                            >
                        }
                    </div>
                </div>
            </div>
            <div class="last-treasury">
                <span class="last-treasury-date">{{
                    lastIncome?.date | date: 'dd/MM'
                }}</span>
                <div class="last-treasury-category-container">
                    <span class="last-treasury-category">{{
                        lastIncome?.categoryInfo?.label
                    }}</span>
                    <span
                        class="last-treasury-amount"
                        [style.color]="
                            !lastIncome?.amount ? '#04151C' : '#00C29A'
                        "
                    >
                        {{
                            lastIncome?.amount
                                ? (lastIncome?.amount ?? 0 | omedomNumber)
                                : '--,--'
                        }}
                        €</span
                    >
                </div>
            </div>
            <div class="last-treasury">
                <span class="last-treasury-date">{{
                    lastCharge?.date | date: 'dd/MM'
                }}</span>
                <div class="last-treasury-category-container">
                    <span class="last-treasury-category">{{
                        lastCharge?.categoryInfo?.label
                    }}</span>
                    <span
                        class="last-treasury-amount"
                        [style.color]="
                            !lastCharge?.amount ? '#04151C' : '#FF563F'
                        "
                    >
                        {{
                            lastCharge?.amount
                                ? (lastCharge?.amount ?? 0 | omedomNumber)
                                : '--,--'
                        }}
                        €</span
                    >
                </div>
            </div>
        </div>
        @if (seeMore) {
            <div class="card-content margin-top-1" [@list]="accounts.length">
                @if (errorMessage$ | async; as errorMessage) {
                    <div class="card-error-message">
                        <p class="omedom-no-margin">{{ errorMessage }}</p>
                    </div>
                }
                @if (item.status === statusCode.OK) {
                    <div class="card-actions margin-bottom-1">
                        @if (showSynthesisButton) {
                            <button
                                class="omedom-button-secondary card-action-extend"
                                id="synthesis"
                                [routerLink]="['/tabs/bank/', item.uid]"
                                [queryParams]="{
                                    tab: 'transactions'
                                }"
                            >
                                Mouvements bancaires
                            </button>
                        }
                        <ng-container
                            [ngTemplateOutlet]="delete"
                        ></ng-container>
                    </div>
                } @else {
                    <div class="card-actions margin-bottom-1">
                        @if (actionEnabled$ | async) {
                            <button
                                class="omedom-button-secondary card-action-extend"
                                [disabled]="
                                    (actionState$ | async) === 'pending'
                                "
                                (click)="action()"
                            >
                                {{ actionMessage$ | async }}
                                @if ((actionState$ | async) === 'pending') {
                                    <ion-spinner></ion-spinner>
                                }
                            </button>
                        }
                        <ng-container
                            [ngTemplateOutlet]="delete"
                        ></ng-container>
                    </div>
                }
                @for (account of accounts; track account.bridgeID) {
                    <div class="account-line margin-bottom-1">
                        <span class="account-name">{{ account.name }}</span>
                        <span>{{ account.balance | omedomNumber }} €</span>
                    </div>
                }
            </div>
        }
    } @else {
        <i class="uil uil-exclamation-triangle"></i>
        <span>Impossible de récupérer les données bancaires</span>
    }
</ng-template>

<ng-template #pending>
    <div class="pending-container">
        <span>Chargement des données bancaires...</span>
        <ion-spinner></ion-spinner>
    </div>
</ng-template>

<ng-template #delete>
    @if (showDeleteButton) {
        <button class="omedom-button-warn-secondary" (click)="deleteItem()">
            <i class="uil uil-trash-alt"></i>
        </button>
    }
</ng-template>
