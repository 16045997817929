import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { defaultRoleState, DocumentEntity, RoleState } from '@omedom/data';
import { DocumentService, PdfService } from '@omedom/services';

import { DocumentDeleteComponent } from '../../containers/document';
import { ModalOverrideDocumentValidationComponent } from '../modal-override-document-validation/modal-override-document-validation.component';

interface ActionButton {
    label: string;
    color: string;
    icon?: string;
    closeModal?: boolean;
    callback: () => void;
}

@Component({
    selector: 'omedom-modal-pdf',
    templateUrl: './modal-pdf.component.html',
    styleUrls: ['./modal-pdf.component.scss'],
})
export class ModalPdfComponent implements OnInit {
    @Input() fileURL = '';

    /**
     * @description UID of the document create in BDD, use for delete the document
     * @author ANDRE Felix
     * @memberof ModalPdfComponent
     */
    @Input()
    documentUID = '';

    @Input()
    userRoleState: RoleState = defaultRoleState;

    @Input()
    infoLabel = '';

    @Input()
    title = '';

    @Input()
    private modalControllerParentInstance?: ModalController;

    @Input()
    propertyUID: string | undefined = undefined;

    @Input()
    actionButton?: ActionButton;

    @Input()
    public verificationFunction: any;

    public isAndroid = this.platform.is('android') || false;

    private propertyDocuments: DocumentEntity[] = [];

    public documentOverridable: DocumentEntity | undefined;

    public actionButtonIsDisabled = false;

    constructor(
        private platform: Platform,
        private PDFService: PdfService,
        private toastController: ToastController,
        private modalController: ModalController,
        private navController: NavController,
        private documentService: DocumentService
    ) { }

    async ngOnInit() {
        const currentDocument = await this.documentService.get(this.documentUID);
        if (currentDocument) {
            this.findExistingDocument(currentDocument);
        }
    }

    public async close() {
        this.modalController.dismiss();
        this.modalControllerParentInstance?.dismiss();
    }

    public async overrideDocument() {
        const modal = await this.modalController.create({
            component: ModalOverrideDocumentValidationComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: {
                validateMessage: 'Oui',
                infoMessage: 'Voulez-vous remplacer le document déjà existant ?',
                titleMessage: '',
                modalControllerParentInstance: this.modalController,
            },
        });

        modal.onDidDismiss().then((callback) => {
            const isOverriding = callback.data;
            if (isOverriding && this.documentOverridable) {
                this.documentService.delete(this.documentOverridable.uid);
            }
            this.close();
        });

        if (this.documentOverridable) {
            await modal.present();
        } else {
            this.close();
        }
    }

    private async deleteFile() {
        let toast: HTMLIonToastElement;
        try {
            await this.PDFService.deletePDF(this.documentUID);

            toast = await this.toastController.create({
                position: 'top',
                color: 'warning',
                duration: 3000,
                message: 'Le pdf a été supprimé',
            });
        } catch (err) {
            console.error('error', err);
            toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 3000,
                message: "Le pdf n'a pas pu être supprimé",
            });
        }
        await toast.present();
        this.close();
    }

    public async openDeleteModal(): Promise<void> {
        const modal = await this.modalController.create({
            component: DocumentDeleteComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
        });

        await modal.present();

        modal.onDidDismiss().then(async (callback) => {
            if (callback?.data) {
                await this.deleteFile();

                this.navController.back();
            }
        });
    }

    private async findExistingDocument(currentDocument: DocumentEntity) {
        if (!this.propertyUID) {
            return;
        }

        this.propertyDocuments = await this.documentService.getPropertyDocuments(this.propertyUID);
        const filaNameInclude = `fiche_informative_du_bien_`;
        const documentFiltered = this.propertyDocuments.filter((document) => {
            return (
                document.fileName.includes(filaNameInclude) ||
                currentDocument.name === document.name
            );
        });
        if (documentFiltered.length > 0) {
            documentFiltered.sort((a, b) => {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
            });
            // Index 1 because index 0 is the document created
            this.documentOverridable = documentFiltered[1];
        }
    }

    public async onClickActionButton() {
        if (this.actionButton) {
            await this.actionButton.callback();
            if (this.actionButton.closeModal) {
                this.close();
            }
        }
    }
}
