<div class="container">
    <omedom-input
        name="search"
        icon="uil-search"
        class="flex-1 margin-right-2"
        placeholder="Rechercher..."
        [(ngModel)]="searchData"
        (ngModelChange)="sendSearch()"
        [debounce]="300"
    ></omedom-input>
    @if (addButtonInSearchBar) {
        <button class="omedom-icon-button-primary" (click)="openAdd()">
            <i class="uil uil-plus"></i>
        </button>
    }
    <button class="omedom-icon-button-secondary-outline" (click)="openFilter()">
        <i class="uil uil-filter"></i>
    </button>

    <button class="omedom-icon-button-secondary-outline" (click)="openSort()">
        <i class="uil uil-sort-amount-down"></i>
    </button>
</div>
