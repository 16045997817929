import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { SignInWithApple, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Platform } from '@ionic/angular';
import { AppPlatforms, LogLevel, ProviderEnum, RoleEnum, SubscriptionDto, UserEntity } from '@omedom/data';
import { OmedomEnvironment } from '@omedom/environment';
import { OmedomHash } from '@omedom/utils';
import { GoogleAuthProvider, UserInfo } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { Timestamp } from 'firebase/firestore';
import { fromEvent, lastValueFrom, Observable, of } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { LogService } from './log.service';
import { RestService } from './rest.service';
import { SubscriptionService } from './subscription.service';
import { UseService } from './use.service';

@Injectable({
    providedIn: 'root',
})
export class UserService extends RestService<UserEntity> {
    protected override builder = UserEntity;

    /**
     * @description Minimum delay for auto logout the user after the last connection in seconds (2 minutes) - 120 seconds
     * This delay depends on firebase auth lastSignInTime which is updated every 2 minutes
     * @author Brisset Killian
     * @date 29/07/2024
     * @private
     * @memberof UserService
     */
    private readonly MIN_DELAY_FOR_AUTO_LOGOUT = 120; // 2 minutes

    /**
     * @description User Observable
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @type {Observable<UserEntity>}
     * @memberof UserService
     */
    public user$!: Observable<UserEntity>;

    /**
     * @description User subscription
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 24/06/2024
     * @private
     * @type {SubscriptionDto}
     * @memberof UserService
     */
    private subscription: SubscriptionDto | undefined;

    constructor(
        protected auth: AngularFireAuth,
        protected override firestore: AngularFirestore,
        protected functions: AngularFireFunctions,
        protected router: Router,
        protected useService: UseService,
        protected platform: Platform,
        protected subscriptionService: SubscriptionService,
        protected logService: LogService,
    ) {
        super(firestore, 'users');
        // Set the persistence to local
        this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

        // Get the user
        this.setUser();

        if (OmedomEnvironment.currentEnvionment === 'development') {
            this.autoLogoutUser(7200);
        } else {
            // Auto logout user after 30 minutes of last connection
            this.autoLogoutUser();
        }
    }

    public setUser() {
        this.user$ = this.auth.authState.pipe(
            switchMap((user) => {
                if (user) {
                    // Check if the user is in the dev environment and if he is not an omedom user
                    if (OmedomEnvironment.currentEnvionment === 'development') {
                        const parse = user?.email?.includes('@omedom.com');

                        if (!parse) {
                            this.logout();
                        }
                    }
                    return this.firestore
                        .doc<UserEntity>(`users/${user.uid}`)
                        .valueChanges()
                        .pipe(
                            tap(async (data) => {
                                if (data) {
                                    await this.verifyProvider(data);
                                    await this.addConnnection(data);
                                }
                            }),
                        );
                } else {
                    return of(null);
                }
            }),
        ) as Observable<UserEntity>;
    }

    /**
     * @description Auto logout the user after a delay of inactivity in seconds (default 30 minutes) - 1800 seconds
     * @author Brisset Killian
     * @date 29/07/2024
     * @param {number} [delay=1800]
     * @memberof UserService
     */
    public autoLogoutUser(delay: number = 1800): void {
        this.auth.authState
            .pipe(
                filter((user) => !!user), // Filter the user
                map((user) => {
                    // If the user is not defined, we return
                    if (!user) return;

                    // Get the last connection date
                    const lastSignInTime = user.metadata.lastSignInTime;

                    // If the last connection date is not defined, we return
                    if (!lastSignInTime) return;

                    // Get the delay in milliseconds
                    const delayInMilliseconds =
                        Math.max(this.MIN_DELAY_FOR_AUTO_LOGOUT, delay) * 1000;

                    // Get the last connection date in milliseconds
                    const lastSignInDate = new Date(lastSignInTime).getTime();

                    // Get the current date in milliseconds
                    const now = Date.now();

                    // Get the difference between the last connection date and the current date in milliseconds
                    const delta = now - lastSignInDate;

                    // If the difference is greater than the delay, we logout the user
                    if (delta > delayInMilliseconds) {
                        this.logout();
                    }
                }),
                takeUntil(fromEvent(window, 'unload')), // Unsubscribe when the window is closed
            )
            .subscribe(); // Subscribe to the observable
    }

    /**
     * @description Update the user in the database with the new data passed in parameter
     * @author Brisset Killian
     * @date 15/04/2024
     * @param {UserEntity} user
     * @returns {*}
     * @memberof UserService
     */
    public async verifyProvider(user: UserEntity) {
        const userState = await this.auth.currentUser;

        if (!userState) {
            throw new Error('auth/user-not-found');
        }

        const providerData = userState.providerData;

        if (providerData && providerData.length > 0) {
            const providers = providerData as firebase.UserInfo[];
            const provider = providers.find(
                (provider) =>
                    provider.providerId === 'google.com' || provider.providerId === 'apple.com',
            );
            if (!provider) {
                return;
            }
            const providerEnum =
                provider.providerId === 'google.com' ? ProviderEnum.google : ProviderEnum.apple;

            if (user.provider !== providerEnum) {
                await this.update({
                    uid: user.uid,
                    provider: providerEnum,
                });
            }
        }
    }

    public async getUserByEmail(email: string) {
        const users = await this.search([
            {
                where: 'email',
                operator: '==',
                value: email,
            },
        ]);

        if (users.length > 0) {
            return users[0];
        }
        return undefined;
    }

    /**
     * @description Login the user with email and password
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @param {string} email The email of the user
     * @param {string} password The password of the user
     * @return {Promise<firebase.auth.UserCredential>}
     * @memberof UserService
     * @throws {Error} auth/dev-environment-only
     * @throws {Error} auth/credential-error
     * @throws {Error} auth/user-not-found
     * @throws {Error} auth/wrong-password
     * @throws {Error} auth/too-many-requests
     * @example
     * await this.userService.loginEmailAndPassword(email, password);
     */
    public async loginEmailAndPassword(
        email: string,
        password: string,
    ): Promise<firebase.auth.UserCredential> {
        // Check if the user is in the dev environment and if he is not an omedom user
        if (OmedomEnvironment.currentEnvionment === 'development') {
            const parse = email.includes('@omedom.com');

            if (!parse) {
                throw new Error('auth/dev-environment-only');
            }
        }

        return this.auth.signInWithEmailAndPassword(email, password);
    }

    public async loginEmailAndPasswordAndCode(
        email: string,
        password: string,
        code: string,
    ): Promise<firebase.auth.UserCredential> {
        // Check if the user is in the dev environment and if he is not an omedom user
        if (OmedomEnvironment.currentEnvionment === 'development') {
            const parse = email.includes('@omedom.com');

            if (!parse) {
                throw new Error('auth/dev-environment-only');
            }
        }

        return this.auth.signInWithEmailAndPassword(email, password);
    }

    public async loginWithCustomToken(token: string): Promise<firebase.auth.UserCredential> {
        return this.auth.signInWithCustomToken(token);
    }

    /**
     * @description Login the user with email and password and check if he is a pro user or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @param {string} email The email of the user
     * @param {string} password The password of the user
     * @return {Promise<firebase.auth.UserCredential>}
     * @memberof UserService
     * @throws {Error} auth/dev-environment-only
     * @throws {Error} auth/credential-error
     * @throws {Error} auth/user-not-found
     * @throws {Error} auth/wrong-password
     * @throws {Error} auth/too-many-requests
     * @example
     * await this.userService.loginEmailAndPassword(email, password);
     */
    public async loginEmailAndPasswordPro(
        email: string,
        password: string,
    ): Promise<firebase.auth.UserCredential> {
        // Check if the email is from a pro user
        const checking = await lastValueFrom(
            this.functions.httpsCallable<{ email: string }, { isPro: boolean }>('checkProUser')({
                email,
            }),
        );

        if (!checking.isPro) {
            throw { code: 'auth/user-not-pro' };
        }

        return this.loginEmailAndPassword(email, password);
    }

    /**
     * @description Login the user with email and password and check if he is a admin user or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @param {string} email The email of the user
     * @param {string} password The password of the user
     * @return {Promise<firebase.auth.UserCredential>}
     * @memberof UserService
     * @throws {Error} auth/dev-environment-only
     * @throws {Error} auth/credential-error
     * @throws {Error} auth/user-not-found
     * @throws {Error} auth/wrong-password
     * @throws {Error} auth/too-many-requests
     * @example
     * await this.userService.loginEmailAndPassword(email, password);
     */
    public async loginEmailAndPasswordAdmin(
        email: string,
        password: string,
    ): Promise<firebase.auth.UserCredential> {
        // Check if the email is from a pro user
        const checking = await lastValueFrom(
            this.functions.httpsCallable<{ email: string }, { isAdmin: boolean }>('checkAdminUser')(
                {
                    email,
                },
            ),
        );

        if (!checking.isAdmin) {
            throw { code: 'auth/user-not-admin' };
        }

        return this.loginEmailAndPassword(email, password);
    }

    /**
     * @description Login with Google
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @return {*}
     * @memberof UserService
     */
    public async loginGoogle(): Promise<firebase.auth.UserCredential> {
        let credential;
        if (!this.platform.is('mobile')) {
            const provider = new GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');

            credential = await this.auth.signInWithPopup(provider);
        } else {
            const googleUser = await GoogleAuth.signIn();
            const provider = GoogleAuthProvider.credential(
                googleUser.authentication.idToken,
                googleUser.authentication.accessToken,
            );

            credential = await this.auth.signInWithCredential(provider);
        }

        await this.loginWithProvider(credential, ProviderEnum.google);

        return credential;
    }

    /**
     * @description Login with Apple
     * @author Brisset Killian
     * @return {*}
     * @memberof UserService
     */
    public async loginApple(isProduction: boolean): Promise<firebase.auth.UserCredential> {
        let credential: firebase.auth.UserCredential;
        let user: Partial<UserEntity> = {};
        const provider = new firebase.auth.OAuthProvider('apple.com');
        if (!this.platform.is('mobile')) {
            provider.addScope('email');
            provider.addScope('name');

            credential = await this.auth.signInWithPopup(provider);
        } else {
            const nonce = 'test new nonce';
            const nonceHex = await OmedomHash.sha256(nonce);

            let options: SignInWithAppleOptions = {
                clientId: 'com.omedom.omedom',
                redirectURI: isProduction
                    ? 'https://omedom-prod.firebaseapp.com/__/auth/handler'
                    : 'https://omedom-77fe5.firebaseapp.com/__/auth/handler',
                scopes: 'email name',
                state: '123456',
                nonce: nonceHex,
            };

            const signInWithAppleResponse = await SignInWithApple.authorize(options);
            await this.logService.create({
                message: 'apple signin',
                level: LogLevel.debug,
                context: { data: signInWithAppleResponse },
            });

            user.name = signInWithAppleResponse.response.familyName ?? '';
            user.firstname = signInWithAppleResponse.response.givenName ?? '';

            const oAuthCredential = provider.credential({
                idToken: signInWithAppleResponse.response.identityToken,
                rawNonce: nonce, // Pass the original nonce here
            });

            credential = await this.auth.signInWithCredential(oAuthCredential);
        }

        await this.loginWithProvider(credential, ProviderEnum.apple, user);

        return credential;
    }

    // public async loginFacebook(): Promise<firebase.auth.UserCredential> {
    //     let credential: firebase.auth.UserCredential;
    //     const provider = new FacebookAuthProvider();
    //     provider.addScope('email');
    //     provider.addScope('name');

    //     credential = await this.auth.signInWithPopup(provider);

    //     return credential;
    // }

    /**
     * @description Login with provider (Google, Apple, Facebook, etc.) and update the user in the database if it's a new user or if the provider has changed
     * @author Brisset Killian
     * @date 15/04/2024
     * @private
     * @param {firebase.auth.UserCredential} credential
     * @param {ProviderEnum} provider
     * @memberof UserService
     */
    private async loginWithProvider(
        credential: firebase.auth.UserCredential,
        provider: ProviderEnum,
        user?: Partial<UserEntity>,
    ) {
        if (!credential || !credential.additionalUserInfo) {
            throw new Error('auth/credential-error');
        }

        const userState = await this.auth.currentUser;
        if (!userState) {
            throw new Error('auth/user-not-found');
        }

        if (credential.additionalUserInfo.isNewUser) {
            const firstname =
                user?.firstname ?? userState.displayName?.split(' ')[0]?.replaceAll('+', ' ') ?? '';
            const name =
                user?.name ?? userState.displayName?.split(' ')[1]?.replaceAll('+', ' ') ?? '';

            const registryPlatform = this.platform.is('android')
                ? AppPlatforms.android
                : this.platform.is('ios')
                  ? AppPlatforms.ios
                  : AppPlatforms.web;

            await this.firestore.doc<UserEntity>(`users/${userState.uid}`).set(
                {
                    uid: userState.uid as string,
                    created: new Date(userState.metadata.creationTime ?? new Date()),
                    updated: new Date(),
                    email: userState.email ?? '',
                    name: name,
                    avatar: userState.photoURL as string,
                    firstname: firstname,
                    firstVisit:
                        credential.additionalUserInfo && credential.additionalUserInfo.isNewUser
                            ? false
                            : true,
                    finishedTutorials: {
                        home: true,
                        property: true,
                    },
                    newsletter: false,
                    role: [RoleEnum.customer],
                    provider: provider,
                    address: {
                        city: '',
                        street: '',
                        postalCode: '',
                    },
                    memberSocietiesUID: [],
                    cgvValidatedVersion: '',
                    registryPlatform: registryPlatform,
                    shareWithProConditions: false,
                },
                {
                    merge: true,
                },
            );
        } else {
            await this.update({
                uid: userState.uid,
                provider: provider,
            });
        }
        await new Promise((resolve) =>
            this.user$.subscribe((user) => {
                if (user) {
                    resolve(user);
                }
            }),
        );
    }

    /**
     * @description Check if the user is an email password user or not
     * @author Brisset Killian
     * @date 13/06/2024
     * @returns {*}  {Promise<boolean>}
     * @memberof UserService
     */
    public async isEmailPasswordUser(): Promise<boolean> {
        const user = await this.auth.currentUser;
        if (user && user.providerData) {
            return user.providerData.some((provider) => provider?.providerId === 'password');
        }
        return false;
    }

    /**
     * @description Check if the user is an Apple user or not
     * @author Brisset Killian
     * @date 13/06/2024
     * @memberof UserService
     */
    public async getAppleUser(): Promise<UserInfo | null | undefined> {
        const user = await this.auth.currentUser;
        if (user && user.providerData) {
            return user.providerData.find((provider) => provider?.providerId === 'apple.com');
        }
        return undefined;
    }

    /**
     * @description Register an user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @param {Partial<UserEntity>} user The user to register
     * @param {string} password The password of the user
     * @return {Promise<void>}
     * @memberof UserService
     */
    public async registerEmailAndPassword(
        user: Partial<UserEntity>,
        password: string,
    ): Promise<string> {
        // Check if email, role and password are defined
        if (!user.email || !user.role || !password) {
            throw new Error('auth/missing-field');
        }

        // Check if the user is in the dev environment and if he is not an omedom user
        if (OmedomEnvironment.currentEnvionment === 'development') {
            const parse = user.email.includes('@omedom.com');

            if (!parse) {
                throw new Error('auth/dev-environment-only');
            }
        }

        // Init the credential
        let credential: firebase.auth.UserCredential;

        try {
            // Create the user
            credential = await this.auth.createUserWithEmailAndPassword(user.email, password);

            const userState = await this.auth.currentUser;

            // Check if the user is created
            if (!userState) {
                throw new Error('auth/user-not-found');
            }

            // await sendEmailVerification(userState);

            await this.firestore.doc<UserEntity>(`users/${credential?.user?.uid}`).set(
                {
                    uid: credential?.user?.uid as string,
                    created: new Date(),
                    updated: new Date(),
                    email: user.email,
                    name: user.name ?? '',
                    firstname: user.firstname ?? '',
                    newsletter: user.newsletter ?? false,
                    role: user.role,
                    birthdate: user.birthdate ?? '',
                    address: user.address ?? {
                        city: '',
                        street: '',
                        postalCode: '',
                    },
                    memberSocietiesUID: user.memberSocietiesUID ?? [],
                    cgvValidatedVersion: user.cgvValidatedVersion,
                    registryPlatform: user.registryPlatform ?? AppPlatforms.web,
                    shareWithProConditions: user.shareWithProConditions ?? false,
                },
                {
                    merge: true,
                },
            );

            return userState.uid;
        } catch (error: any) {
            throw error?.code;
        }
    }

    /**
     * @description Logout the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @param {boolean} [noRouting] If true, the routing will not be done
     * @return {Promise<void>}
     * @memberof UserService
     */
    public async logout(noRouting?: boolean): Promise<void> {
        // Logout the user
        await this.auth.signOut();

        // If noRouting is false, redirect to the home page
        if (!noRouting) {
            await this.router.navigate(['/']);
        }
    }

    /**
     * @description Update the email of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @param {string} oldEmail The old email of the user
     * @param {string} newEmail The new email of the user
     * @param {string} password The password of the user
     * @return {Promise<void>}
     * @throws auth/user-not-found
     * @memberof UserService
     * @exemple
     * await this.userService.updateEmail(oldEmail, newEmail, password);
     */
    public async updateEmail(oldEmail: string, newEmail: string, password: string): Promise<void> {
        // Login the user with his email and password
        await this.loginEmailAndPassword(oldEmail, password);

        // Get the current user
        const currentUser = await this.auth.currentUser;

        if (!currentUser) {
            throw new Error('auth/user-not-found');
        }

        // Update the email of the user
        return currentUser?.updateEmail(newEmail);
    }

    /**
     * @description Update the password of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @param {string} email The email of the user
     * @param {string} oldPassword The old password of the user
     * @param {string} newPassword The new password of the user
     * @return {Promise<void>}
     * @throws auth/user-not-found
     * @memberof UserService
     * @exemple
     * await this.userService.updatePassword(email, oldPassword, newPassword);
     */
    public async updatePassword(
        email: string,
        oldPassword: string,
        newPassword: string,
    ): Promise<void> {
        // Login the user with his email and password
        await this.loginEmailAndPassword(email, oldPassword);

        // Get the current user
        const currentUser = await this.auth.currentUser;

        if (!currentUser) {
            throw new Error('auth/user-not-found');
        }

        // Update the password of the user
        return currentUser.updatePassword(newPassword);
    }

    /**
     * @description Send reset password email
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @param {string} email The email of the user
     * @return {Promise<void>}
     * @memberof UserService
     * @exemple
     * await this.userService.missingPassword(email);
     */
    public async missingPassword(email: string): Promise<void> {
        const callable = this.functions.httpsCallable<{ email: string }, void>(
            'sendMissingPasswordEmail',
        );

        return lastValueFrom(callable({ email }));
    }

    /**
     * @description Delete the account of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @param {string} email The email of the user
     * @param {string} password The password of the user
     * @return {Promise<void>}
     * @throws auth/user-not-found
     * @memberof UserService
     * @exemple
     * await this.userService.deleteUser(email, password);
     */
    public async deleteUser(email: string, password: string): Promise<void> {
        // Login the user with his email and password
        await this.loginEmailAndPassword(email, password);

        // Get the current user
        const currentUser = await this.auth.currentUser;

        if (!currentUser) {
            throw new Error('auth/user-not-found');
        }

        // Delete the user in the database
        await this.delete(currentUser.uid);

        // Delete the user in the auth
        return currentUser?.delete();
    }

    /**
     * @description Checks if the email of the current user is verified or not
     * @author Hanane Djeddal
     * @returns {Promise<boolean>}
     * @memberof UserService
     * @example
     * const state = await this.userService.getAuthState();
     */
    public async getAuthState(): Promise<boolean> {
        // Get the current user
        const currentUser = await this.auth.currentUser;

        if (!currentUser) {
            return false;
        }

        // Reload the user
        // await currentUser.reload();

        // Get the state of the email verification
        const state = currentUser.emailVerified;

        return state;
    }

    /**
     * @description Generate and send verification email
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @param {UserEntity} user The user to send the email
     * @return {Promise<void>}
     * @memberof UserService
     * @example
     * await this.userService.generateVerficationEmail(user);
     */
    public async generateVerficationEmail(user: Partial<UserEntity>): Promise<void> {
        await lastValueFrom(this.functions.httpsCallable('createEmailVerification')({ user }));
    }

    /**
     * @description Add a connection to the user's account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @private
     * @param {UserEntity} user The user to add the connection
     * @return {Promise<void>}
     * @memberof UserService
     */
    private async addConnnection(user: UserEntity): Promise<void> {
        // If the user has already a connection at the same day, we don't add a new one
        const lastConnectionDate = user.connections?.date;

        // Get the current date
        const now = new Date().toUTC();

        // If the user has already a connection at the same day, we don't add a new one
        if (lastConnectionDate && lastConnectionDate.toDate().isSameDay(now)) {
            return;
        }

        // Add a connection to the user
        await this.update({
            uid: user.uid,
            connections: {
                date: Timestamp.fromDate(now),
                count: user.connections?.count ? user.connections.count + 1 : 1,
            },
        });

        // Create a new connection in the database
        await this.useService.create({
            action: 'login',
            userUID: user.uid,
            date: Timestamp.fromDate(now),
        });
    }

    /**
     * @description Check if the user exist with his uid
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/10/2023
     * @param {string} uid
     * @returns {Observable<boolean>}
     * @memberof UserService
     * @example
     * // Use in validator
     * ...
     * this.formBuilder.group({
     *    userUID: new FormControl<string>(pro?.userUID ?? '', {
     *       validators: [Validators.required],
     *      asyncValidators: [OmedomValidators.isUserExist(this.userService)],
     *   })
     * });
     *
     */
    public isUserExist(uid: string): Observable<boolean> {
        return this.functions
            .httpsCallable<{ uid: string }, { isUserExist: boolean }>('isUserExist')({ uid })
            .pipe(map((data) => data.isUserExist));
    }

    /**
     * @description Check email and code association to reset password and return a boolean if it's correct or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/06/2024
     * @param {string} email
     * @param {string} code
     * @returns {Promise<boolean>}
     * @memberof UserService
     */
    public checkMissingPasswordCode(email: string, code: string): Promise<boolean> {
        return lastValueFrom(
            this.functions
                .httpsCallable<{ email: string; code: string }, { success: boolean }>(
                    'checkMissingPasswordCode',
                )({
                    email,
                    code,
                })
                .pipe(map((data) => data.success)),
        );
    }

    /**
     * @description Reset the password of the user account with the email, the password and the code passed in parameter
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/06/2024
     * @param {string} email
     * @param {string} password
     * @param {string} code
     * @returns {Promise<void>}
     * @memberof UserService
     */
    public resetPassword(email: string, password: string, code: string): Promise<void> {
        return lastValueFrom(
            this.functions.httpsCallable<{ email: string; password: string; code: string }, void>(
                'resetPassword',
            )({
                email,
                password,
                code,
            }),
        );
    }
}
