import { BankAccountType, BankStatusCode } from '../../enums';
import { BankLoanDetails, BankSavingsDetails, BankStatusReport, Bridge } from '../../interfaces';
import { RestEntity } from '../rest.entity';

export class BankAccountEntity extends RestEntity implements Bridge, BankStatusReport {
    /**
     * @description The ID of the stuff in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankAccountEntity
     */
    public bridgeID!: number;

    /**
     * @description The status of the bank account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {BankStatusCode}
     * @memberof BankAccountEntity
     */
    public status!: BankStatusCode;

    /**
     * @description The status code info of the bank account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankAccountEntity
     */
    public statusCodeInfo?: string;

    /**
     * @description The status code description of the bank account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankAccountEntity
     */
    public statusCodeDescription?: string;

    /**
     * @description The user UID of the bank account owner
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankAccountEntity
     */
    public userUID!: string;

    /**
     * @description Item linked to the bank account in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankAccountEntity
     */
    public itemID!: number;

    /**
     * @description Bank linked to the bank account in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankAccountEntity
     */
    public bankID!: number;

    /**
     * @description Name of the bank account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankAccountEntity
     */
    public name!: string;

    /**
     * @description Balance of the bank account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankAccountEntity
     */
    public balance!: number;

    /**
     * @description Last refresh date of the bank account data in Bridge (Transaction, balance, etc.)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {Date}
     * @memberof BankAccountEntity
     */
    public lastRefresh?: Date;

    /**
     * @description Type of the bank account (Checking, Saving, etc.) in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {BankAccountType}
     * @memberof BankAccountEntity
     */
    public type!: BankAccountType;

    /**
     * @description Currency code of the bank account in Bridge (ISO 4217 code, e.g. EUR, USD, etc.)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankAccountEntity
     */
    public currencyCode: string = 'EUR';

    /**
     * @description If the account is a pro account, it's true and need more validation
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {boolean}
     * @memberof BankAccountEntity
     */
    public isPro: boolean = false;

    /**
     * @description The IBAN of the bank account in Bridge (International Bank Account Number, ISO 13616-1:2007 standard). Not given by every bank.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankAccountEntity
     */
    public iban?: string;

    /**
     * @description Loan details of the bank account in Bridge. Only if the account is a loan account (BankAccountType.Loan) and the bank provide the loan details. Not given by every bank.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {BankLoanDetails}
     * @memberof BankAccountEntity
     */
    public loanDetails?: BankLoanDetails;

    /**
     * @description Savings details of the bank account in Bridge. Only if the account is a savings account (BankAccountType.Savings) and the bank provide the savings details. Not given by every bank.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {BankSavingsDetails}
     * @memberof BankAccountEntity
     */
    public savingsDetails?: BankSavingsDetails;

    /**
     * @description If the account is masked, it's true and need more validation.
     * Masked account don't take in account the balance, the transactions and valuation. It's only for display purpose.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/02/2025
     * @type {boolean}
     * @memberof BankAccountEntity
     */
    public isMasked?: boolean;

    constructor(data: Partial<BankAccountEntity>) {
        super(data);

        // Check if type is defined
        if (!data.type) {
            this.type = BankAccountType.Unknown;
        }

        // Check if status is not defined
        if (!this.status) {
            this.status = BankStatusCode.OK;
        }

        // Check if balance is not defined
        if (!this.balance) {
            this.balance = 0;
        }
    }
}
