<div class="container">
    <div>
        <omedom-dpe-score [score]="actualDPEScore"></omedom-dpe-score>

        <i class="uil uil-arrow-right margin-x-1"></i>
        <omedom-dpe-score
            [score]="scenario.energyLabel.value"
            class="margin-left-1"
        ></omedom-dpe-score>
    </div>
    @if (scenarioGranular) {
        <div class="scenario-info">
            <omedom-renovation-infos
                [renovations]="scenarioGranular.renovations"
            ></omedom-renovation-infos>
        </div>
    }
    <hr class="omedom-divider" />
    <div class="scenario-info">
        <div class="renovation-line text-grey">
            <div>Coût total</div>
            <div class="bold">
                {{ scenario.totalRenovationsCosts.value | currency: 'EUR' }}
            </div>
        </div>
    </div>
    <div class="renovation-card-cointainer scenario info w-100">
        <omedom-renovation-amelioration-card
            title="Impact estimé sur le prix du bien"
            icon="chart-line"
            [isProfit]="true"
            [percentage]="scenario.percentageImprovements.saleValuation"
            [value]="scenario.salePriceDifference.value"
        ></omedom-renovation-amelioration-card>
        @if (scenarioGranular) {
            <omedom-renovation-amelioration-card
                title="Consommation énergétique"
                icon="bolt"
                [percentage]="
                    scenarioGranular.percentageImprovements.energyConsumption
                "
                [value]="scenarioGranular.energyConsumption.measure.value"
                unit="kWh/m²/an"
            ></omedom-renovation-amelioration-card>

            <omedom-renovation-amelioration-card
                title="Emission de CO2"
                icon="temperature"
                [percentage]="
                    scenarioGranular.percentageImprovements.ghgEmission
                "
                [value]="48"
                unit="kgCO₂/m²/an"
            ></omedom-renovation-amelioration-card>
        }
    </div>
</div>
