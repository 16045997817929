import { Component, Input, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LeaseEntity, LeaseType, SelectOption } from '@omedom/data';
import { OmedomRegex } from '@omedom/utils';

@Component({
    selector: 'omedom-lease-form-lease',
    templateUrl: './lease.component.html',
    styleUrls: ['./lease.component.scss'],
})
export class LeaseComponent implements OnInit {
    @Input() lease: Partial<LeaseEntity> = {};

    /**
     * @description Placeholder of select component
     * @author Jérémie Lopez
     * @memberof LeaseFormComponent
     */
    public typeLeasePlaceholder = {
        id: null,
        label: 'Type de bail',
    } as SelectOption;

    /**
     * @description List type of lease in the select component
     * @author Jérémie Lopez
     * @memberof LeaseFormComponent
     */
    public typeLeaseOptions = [
        { id: LeaseType.empty, label: LeaseType.empty } as SelectOption,
        { id: LeaseType.furnished, label: LeaseType.furnished } as SelectOption,
        { id: LeaseType.coleasing, label: LeaseType.coleasing } as SelectOption,
        { id: LeaseType.student, label: LeaseType.student } as SelectOption,
        { id: LeaseType.mobility, label: LeaseType.mobility } as SelectOption,
    ];

    /**
     * @description Pattern for email validation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 04/03/2025
     * @memberof LeaseComponent
     */
    public emailPattern = OmedomRegex.emailRegex;

    /**
     * @description Pattern for phone validation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 04/03/2025
     * @memberof LeaseComponent
     */
    public phonePattern = OmedomRegex.phoneRegex;

    /**
     * @description Pattern for postal code validation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 05/03/2025
     * @memberof LeaseComponent
     */
    public postalCodeRegex = OmedomRegex.postalCodeRegex;

    /**
     * Creates an instance of LeaseComponent.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 04/03/2025
     * @param {ToastController} toast
     * @memberof LeaseComponent
     */
    constructor(private toast: ToastController) {}

    ngOnInit(): void {}

    async checkTimePeriod() {
        if (!this.isDateInputFilled()) {
            return;
        }
        if (!this.isTimePeriodValid()) {
            await this.displayToastInvalidDate();
        }
    }

    private isDateInputFilled() {
        return !!this.lease.leaseStart && !!this.lease.leaseEnd;
    }

    private isTimePeriodValid() {
        if (!this.lease.leaseStart || !this.lease.leaseEnd) {
            return false;
        }
        return this.lease.leaseStart < this.lease.leaseEnd;
    }

    private async displayToastInvalidDate() {
        const toast = await this.toast.create({
            position: 'top',
            color: 'danger',
            duration: 5000,
            message: 'Veuillez renseigner une date de début antérieure à la date de fin',
        });

        await toast.present();
    }
}
