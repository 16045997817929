import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ConfigurationEntity,
    ProEntity,
    SubscriptionDto,
    SubscriptionRank,
    SubscriptionType,
    UserFamilyInvitation,
} from '@omedom/data';
import { Observable } from 'rxjs';

@Component({
    selector: 'omedom-subscription-current',
    templateUrl: './subscription-current.component.html',
    styleUrls: ['./subscription-current.component.scss'],
})
export class SubscriptionCurrentComponent implements OnInit {
    protected readonly SubscriptionRank = SubscriptionRank;

    @Input() subscription?: SubscriptionDto;
    @Input() pro$?: Observable<ProEntity | undefined>;
    @Input() currentConfiguration?: ConfigurationEntity;
    @Input() configurations?: ConfigurationEntity[];
    @Input() familyInvitations!: UserFamilyInvitation[];
    @Output() manageFamily = new EventEmitter();
    @Output() manageFamilyInvitations = new EventEmitter();
    @Output() manageSubscription = new EventEmitter();
    @Output() leaveFamily = new EventEmitter();
    @Output() paymentHistory = new EventEmitter();
    @Output() familyInvitation = new EventEmitter();

    get futureConfigurationName(): string {
        const futureConfiguration = this.configurations?.find(
            (config) => config.docName === this.subscription?.futureRank,
        );
        return futureConfiguration?.name ?? futureConfiguration?.docName ?? '';
    }

    protected readonly SubscriptionType = SubscriptionType;

    ngOnInit() {
        if (this.configurations && this.subscription?.futureRank) {
            this.currentConfiguration = this.configurations.find(
                (config) => config.docName === this.subscription?.futureRank,
            );
        }
    }
}
