import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PaymentEntity } from '@omedom/data';

import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentService extends RestService<PaymentEntity> {
    constructor(protected override firestore: AngularFirestore) {
        super(firestore, 'payments');
    }

    /**
     * @description Get all payments by user UID
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID User UID
     * @returns {Promise<PaymentEntity[]>} Payments of the user
     * @memberof PaymentService
     * @example
     * const payments = await paymentService.getPaymentsByUser(userUID);
     */
    public getPaymentsByUser(userUID: string): Promise<PaymentEntity[]> {
        return this.search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            }, {
                sortBy: 'created',
                direction: 'desc',
            }
        ]);
    }
}
