import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { IonContent, IonModal, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import {
    atticTypeOptions,
    DetentionType,
    DPEScoreOption,
    glazingTypeOptions,
    isolationRenovationPeriodOptions,
    ManagementRentType,
    MAX_BUILDING_YEAR,
    MAX_FLOOR_NUMBER,
    MAX_LAND_AREA,
    MAX_LIVING_AREA,
    MIN_BUILDING_YEAR,
    MIN_FLOOR_NUMBER,
    MIN_LAND_AREA,
    MIN_LIVING_AREA,
    OwningType,
    PopupTypes,
    PropertyEntity,
    PropertyPurchaseDetails,
    PropertyType,
    RentType,
    SelectOption,
    SocietyEntity,
    SubscriptionDto,
    UseProperty,
    UserEntity,
} from '@omedom/data';
import { AnalyticsService, PropertyService, SocietyService, SubscriptionService, UserService } from '@omedom/services';
import { OmedomPricehubble, OmedomProperty, OmedomRegex, OmedomTransformer } from '@omedom/utils';
import { BehaviorSubject, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { slideYInOutAnimation } from '../../../animations';
import { AlertComponent } from '../../../components/alert/alert.component';
import { OmedomRadioOption } from '../../../components/radio';
import { OmedomSubTab } from '../../../components/sub-tab/omedom-sub-tab';
import { ValidationModalComponent } from '../../../components/validation-modal/validation-modal.component';
import { OmedomFormatNumber } from '../../../functions';
import { OmedomNumberPipe } from '../../../pipes';
import { is } from 'date-fns/locale';

@Component({
    selector: 'omedom-property-form',
    templateUrl: './property-form.component.html',
    styleUrls: ['./property-form.component.scss'],
    animations: [slideYInOutAnimation],
})
export class PropertyFormComponent implements OnInit, OnDestroy {
    @Input()
    public container?: IonContent;

    @Input()
    public property?: PropertyEntity;

    @Input()
    public firstProperty = false;

    @Input()
    public isDesktop = true;

    /**
     * @description If true, the form is in pro edit mode
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/11/2024
     * @memberof PropertyFormComponent
     */
    @Input()
    public isProEdit = false;

    /**
     * @description UID of the parent property, like building
     * @author ANDRE Felix
     * @type {string}
     * @memberof PropertyFormComponent
     */
    @Input()
    public parentPropertyUID?: string;

    @Input()
    public societyUID?: string;

    /**
     * @description Send the last active step
     * @author Jérémie Lopez
     * @memberof PropertyFormComponent
     */
    @Output()
    public changeStep = new EventEmitter<number>();

    /**
     * @description Send the submit event
     * @author Jérémie Lopez
     * @memberof PropertyFormComponent
     */
    @Output()
    public submitEvent = new EventEmitter<void>();

    // @Output()
    // public close = new EventEmitter<void>();

    @Output()
    public finishToEdit = new EventEmitter<{
        property: PropertyEntity;
        createdPropertyId: string;
        wantToShare: boolean;
    }>();

    /**
     * @description Return true if the property is assigned to be rent
     * @author Jérémie Lopez
     * @readonly
     * @type {boolean}
     * @memberof PropertyFormComponent
     */
    public get isRentalProperty(): boolean {
        const use = this.purchaseForm?.get('use')?.value;

        return (
            use === UseProperty.locatifCommercial ||
            use === UseProperty.locatifParticulier ||
            use === UseProperty.locatifPro
        );
    }

    public get addressForm(): UntypedFormGroup {
        return this.detailForm?.get('address') as UntypedFormGroup;
    }

    /**
     * @description Active step of the step form
     * @author Jérémie Lopez
     * @memberof PropertyFormComponent
     */
    public activeStep = 0;

    /**
     * @description Form Group details
     * @author Jérémie Lopez
     * @type {FormGroup}
     * @memberof PropertyFormComponent
     */
    public detailForm?: UntypedFormGroup;

    /**
     * @description Form Group location
     * @author Jérémie Lopez
     * @type {FormGroup}
     * @memberof PropertyFormComponent
     */
    public settingsForm?: UntypedFormGroup;

    /**
     * @description Form Group purchase infos
     * @author Jérémie Lopez
     * @type {FormGroup}
     * @memberof PropertyFormComponent
     */
    public purchaseForm?: UntypedFormGroup;

    /**
     * @description Form Group rent infos
     * @author Jérémie Lopez
     * @type {FormGroup}
     * @memberof PropertyFormComponent
     */
    public rentForm?: UntypedFormGroup;

    /**
     * @description If true, the app is pending to upload photo
     * @author Jérémie Lopez
     * @memberof PropertyFormComponent
     */
    public pendingPhoto$ = new BehaviorSubject<boolean>(false);

    /**
     * @description If true, the app is pending
     * @author Jérémie Lopez
     * @memberof PropertyFormComponent
     */
    public pending$ = new BehaviorSubject<boolean>(false);

    public isCapacitor = this.platform.is('capacitor') || false;

    public isAndroid = this.platform.is('android') || false;

    /**
     * @description List of properties divisible
     * @author Jérémie Lopez
     * @type {PropertyEntity[]}
     * @memberof PropertyFormComponent
     */
    public properties: PropertyEntity[] = [];

    /**
     * @description List of society
     * @author Jérémie Lopez
     * @type {SocietyEntity[]}
     * @memberof PropertyFormComponent
     */
    public societies: SocietyEntity[] = [];

    codepostalRegex = OmedomRegex.postalCodeRegex;

    typeBienPlaceholder = {
        id: null,
        label: 'Type de bien',
    } as SelectOption;

    typeBienOptions$ = of([
        {
            id: PropertyType.appartement,
            label: PropertyType.appartement,
        } as SelectOption,
        {
            id: PropertyType.batimentAgricole,
            label: PropertyType.batimentAgricole,
        } as SelectOption,
        {
            id: PropertyType.bureaux,
            label: PropertyType.bureaux,
        } as SelectOption,
        {
            id: PropertyType.chalet,
            label: PropertyType.chalet,
        } as SelectOption,
        {
            id: PropertyType.hotel,
            label: PropertyType.hotel,
        } as SelectOption,
        {
            id: PropertyType.immeuble,
            label: PropertyType.immeuble,
        } as SelectOption,
        {
            id: PropertyType.localCommercial,
            label: PropertyType.localCommercial,
        } as SelectOption,
        {
            id: PropertyType.maison,
            label: PropertyType.maison,
        } as SelectOption,
        {
            id: PropertyType.parkingBox,
            label: PropertyType.parkingBox,
        } as SelectOption,
        {
            id: PropertyType.terrain,
            label: PropertyType.terrain,
        } as SelectOption,
        {
            id: PropertyType.other,
            label: PropertyType.other,
        } as SelectOption,
    ]);

    usePlaceholder = {
        id: null,
        label: 'Usage du bien',
    } as SelectOption;

    useOptions$ = of([
        {
            id: UseProperty.principale,
            label: UseProperty.principale,
        },
        {
            id: UseProperty.secondaire,
            label: UseProperty.secondaire,
        },
        {
            id: UseProperty.locatifParticulier,
            label: UseProperty.locatifParticulier,
        },
        {
            id: UseProperty.locatifCommercial,
            label: UseProperty.locatifCommercial,
        },
        {
            id: UseProperty.locatifPro,
            label: UseProperty.locatifPro,
        },
    ] as SelectOption[]);

    detentionPlaceholder = {
        id: null,
        label: 'Mode de detention',
    } as SelectOption;

    detentionOptions$ = of([
        {
            id: DetentionType.pleine,
            label: DetentionType.pleine,
            popover:
                "Vous disposez totalement du bien : vous pouvez le vendre, l'occuper et percevoir les loyers.",
        } as SelectOption,
        {
            id: DetentionType.nu,
            label: DetentionType.nu,
            popover: 'Vous disposez du bien uniquement pour le vendre.',
        } as SelectOption,
        {
            id: DetentionType.usufruit,
            label: DetentionType.usufruit,
            popover: "Vous disposez du bien uniquement pour l'occuper ou percevoir les loyers.",
        } as SelectOption,
        {
            id: DetentionType.usufruitTemporaire,
            label: DetentionType.usufruitTemporaire,
            popover:
                "Vous disposez, pour une durée déterminée, du bien uniquement pour l'occuper ou percevoir les loyers.",
        } as SelectOption,
    ]);

    owningPlaceholder = {
        id: null,
        label: 'Détention',
    } as SelectOption;

    owningOptions$ = of([
        {
            id: OwningType.proper,
            label: OwningType.proper,
        } as SelectOption,
        {
            id: OwningType.society,
            label: OwningType.society,
        } as SelectOption,
        {
            id: OwningType.community,
            label: OwningType.community,
        } as SelectOption,
        {
            id: OwningType.indivision,
            label: OwningType.indivision,
        } as SelectOption,
    ]);

    societiesPlaceholder = {
        id: null,
        label: 'Société',
    } as SelectOption;

    societiesOptions = [] as SelectOption[];

    propertiesPlaceholder = {
        id: null,
        label: 'Bien',
    } as SelectOption;

    propertiesOptions = [] as SelectOption[];

    rentTypePlaceholder = {
        id: null,
        label: 'Type de location',
    } as SelectOption;

    rentTypeOptions$ = of([
        {
            id: RentType.empty,
            label: RentType.empty,
        } as SelectOption,
        {
            id: RentType.furnished,
            label: RentType.furnished,
        } as SelectOption,
    ]);

    managementRentTypePlaceholder = {
        id: null,
        label: 'Type de gestion locative',
    } as SelectOption;

    managementRentTypeOptions$ = of([
        {
            id: ManagementRentType.direct,
            label: ManagementRentType.direct,
        } as SelectOption,
        {
            id: ManagementRentType.mandatory,
            label: ManagementRentType.mandatory,
        } as SelectOption,
        {
            id: ManagementRentType.agency,
            label: ManagementRentType.agency,
        } as SelectOption,
        {
            id: ManagementRentType.platform,
            label: ManagementRentType.platform,
        } as SelectOption,
    ]);

    dpeScorePlaceHolder = {
        id: null,
        label: 'Note de la consommation énergétique',
    } as SelectOption;

    ghgScorePlaceHolder = {
        id: null,
        label: 'Note du GES',
    } as SelectOption;

    dpeScoreOptions$ = of(DPEScoreOption);

    /**
     * @description Return true if detention is set to society
     * @author Jérémie Lopez
     * @memberof PropertyFormComponent
     */
    public displaySocietyField = new BehaviorSubject<boolean>(false);

    /**
     * @description Return true if the propety is divisible
     * @author Jérémie Lopez
     * @memberof PropertyFormComponent
     */
    public displayDivisibleField = new BehaviorSubject<boolean>(false);

    /**
     * @description Return true if the property is a child of an other property
     * @author Jérémie Lopez
     * @memberof PropertyFormComponent
     */
    public displayChildPropertyField = new BehaviorSubject<boolean>(false);

    /**
     * @description Display Rent Form after collapse
     * @author Jérémie Lopez
     * @memberof PropertyFormComponent
     */
    public displayRentForm = new BehaviorSubject<boolean>(false);

    /**
     * @description Display Amenities after collapse
     * @author Jérémie Lopez
     * @memberof PropertyFormComponent
     */
    public displayAmenities = new BehaviorSubject<boolean>(false);

    /**
     * @description Display Financial data after collapse
     * @author ANDRE Felix
     * @memberof PropertyFormComponent
     */
    public displayFinancialData = new BehaviorSubject<boolean>(false);
    /**
     * @description Display Physical data after collapse
     * @author ANDRE Felix
     * @memberof PropertyFormComponent
     */
    public displayDescription = new BehaviorSubject<boolean>(false);

    /**
     * @description Display Assurance Form after collapse
     * @author Hanane Djeddal
     * @memberof PropertyFormComponent
     */
    public displayAssuranceForm = new BehaviorSubject<boolean>(false);

    /**
     * @description Display diagnostic Form after collapse
     * @author Hanane Djeddal
     * @memberof PropertyFormComponent
     */
    public displayDiagnosticForm = new BehaviorSubject<boolean>(false);

    yesNoOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({ id: true, label: 'Oui' }),
        new OmedomRadioOption({ id: false, label: 'Non' }),
    ];

    public subTabs: OmedomSubTab[] = [
        new OmedomSubTab({ id: 0, label: 'Détails' }),
        new OmedomSubTab({ id: 1, label: 'Détention' }),
        new OmedomSubTab({ id: 2, label: 'Paramètres' }),
    ];

    public selectedSubTab: OmedomSubTab = this.subTabs[0];

    private user?: UserEntity;
    /**
     * @description Use previous type to clear or not the 'use' input
     * example: a building can have several 'use', need to clear when we change the type
     * @author ANDRE Felix
     * @memberof PropertyFormComponent
     */
    previousPropertyType?: string;

    /**
     * @description List of subscriptions that will deleted
     * @author Jérémie Lopez
     * @private
     * @type {Subscription[]}
     * @memberof PropertyFormComponent
     */
    private subscriptions: Subscription[] = [];

    public alertLabel?: string;

    isBuilding = false;
    isSociety = false;
    /**
     * @description Determine if we can change the type of a property
     * Example: for a building we lot, we can't change his type (otherwise, we will have to manage things in backend)
     * @author ANDRE Felix
     * @memberof PropertyFormComponent
     */
    canChangePropertyType = true;

    isolationRenovationOptions = isolationRenovationPeriodOptions;
    atticTypeOptions = atticTypeOptions;
    glazingTypeOptions = glazingTypeOptions;
    isAssimilateHouse = false;
    isAssimilateAppartement = false;
    labelFloorNumber = 'Nombre de niveaux';

    /**
     * @description List of properties that can be linked to a building or a society
     * @author Didier Pascarel
     * @type {PropertyEntity[]}
     * @memberof PropertyFormComponent
     */
    private propertiesList: PropertyEntity[] = [];

    /**
     * @description UID of the property added in a parent entity like building or society
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 29/07/2024
     * @type {string}
     * @memberof PropertyFormComponent
     */
    public importedExistingPropertyUID?: string;

    /**
     * @description if a property can have several floor
     * @author ANDRE Felix
     * @memberof PropertyFormComponent
     */
    canHaveFloor = true;

    /**
     * @description Subscription of user observable
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 31/12/2024
     * @private
     * @type {Observable<SubscriptionDto>}
     * @memberof PropertyFormComponent
     */
    private readonly subscription$: Observable<SubscriptionDto | undefined> = toObservable(
        this.subscriptionService.subscription,
    );

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly userService: UserService,
        private readonly storage: AngularFireStorage,
        private readonly propertyService: PropertyService,
        private readonly societyService: SocietyService,
        private readonly toastController: ToastController,
        private readonly route: ActivatedRoute,
        private readonly numberPipe: OmedomNumberPipe,
        private readonly modalController: ModalController,
        private readonly subscriptionService: SubscriptionService,
        private readonly platform: Platform,
        private readonly analyticsService: AnalyticsService,
        private readonly navController: NavController,
        private readonly router: Router,
    ) { }

    async ngOnInit(): Promise<void> {
        // On edit: open on the same tab
        const tab_id = this.route.snapshot.paramMap.get('id');
        const index = tab_id ? +tab_id - 1 : 0;
        this.selectedSubTab = this.subTabs[index];
        const date = new Date();

        if (this.property && this.property.type === PropertyType.immeuble) {
            this.canChangePropertyType = false;
        }

        this.isAssimilateHouse = OmedomPricehubble.isPropertyAssimilateHouse(this.property?.type);
        this.isAssimilateAppartement = OmedomPricehubble.isPropertyAssimilateAppartement(
            this.property?.type,
        );
        if (this.isAssimilateHouse) {
            this.labelFloorNumber = 'Nombre de niveaux';
        }
        this.alertLabel = this.property ? "Edition d'un bien" : "Ajout d'un bien";

        this.canHaveFloor = OmedomProperty.CanHaveFloor(this.property?.type);

        // Retrieve societyUID in query params of the route
        const societyUID =
            this.societyUID ?? this.route.snapshot.queryParamMap.get('societyUID') ?? null;
        const parentPropertyUID =
            this.parentPropertyUID ??
            this.route.snapshot.queryParamMap.get('parentPropertyUID') ??
            null;
        if (parentPropertyUID) {
            this.parentPropertyUID = parentPropertyUID;
        }
        if (this.parentPropertyUID) {
            this.typeBienOptions$ = this.typeBienOptions$.pipe(
                map((options) => options.filter((option) => option.id !== PropertyType.immeuble)),
            );
        }
        this.activeStep = (this.societyUID || this.parentPropertyUID) && this.isDesktop ? 0 : 1;

        // Retrieve society data from societyUID
        let society: SocietyEntity | undefined;
        if (societyUID) {
            society = await this.societyService.get(societyUID);
            this.displaySocietyField.next(true);
        }

        this.isBuilding = this.property?.type === PropertyType.immeuble;
        this.detailForm = this.formBuilder.group(
            {
                name: [this.property?.name ?? '', [Validators.required]],
                type: [
                    {
                        value: this.property?.type ? this.property?.type : '',
                        disabled: !this.canChangePropertyType,
                    },
                ],
                divisible: [this.property?.divisible ?? null, []],
                childProperty: [!!this.property?.propertyUID, []],
                parentPropertyUID: [this.property?.parentPropertyUID ?? '', []],
                photo: [this.property?.photo ?? null, []],
                buildingYear: [
                    this.property?.buildingYear ?? '',
                    [Validators.max(MAX_BUILDING_YEAR), Validators.min(MIN_BUILDING_YEAR)],
                ],
                assuranceName: [this.property?.assuranceDetails?.assuranceName ?? '', []],
                assuranceContractNumber: [
                    this.property?.assuranceDetails?.assuranceContractNumber ?? '',
                    [],
                ],
                notes: [this.property?.notes ?? '', [Validators.maxLength(300)]],
                address: this.formBuilder.group({
                    streetNumber: [this.property?.address?.streetNumber ?? null, []],
                    suffixNumber: [this.property?.address?.suffixNumber ?? null, []],

                    street: [this.property?.address?.street ?? '', []],
                    addressLine2: [this.property?.address?.addressLine2 ?? '', []],
                    postalCode: [this.property?.address?.postalCode ?? '', []],
                    city: [this.property?.address?.city ?? '', []],
                }),
            },
            { validators: this.buildingYearValidator() },
        );

        this.settingsForm = this.formBuilder.group(
            {
                roomCount: [this.property?.roomCount ?? 0, []],
                livingSpace: [this.property?.livingSpace ?? null, []],
                landArea: [this.property?.landArea ?? null, []],
                amenities: [this.property?.amenities ?? [], []],
                dpeDate: [this.property?.dpeDetails?.dpeDate ?? null, []],
                dpeScore: [
                    this.property?.dpeDetails?.dpeScore ? this.property.dpeDetails.dpeScore : null,
                    [],
                ],
                dpeValue: [this.property?.dpeDetails?.dpeValue ?? null, []],
                ghgScore: [this.property?.dpeDetails?.ghgScore ?? null, []],
                ghgValue: [this.property?.dpeDetails?.ghgValue ?? null, []],
                floor: [this.property?.floor ?? '', []],
                floorNumber: [this.property?.floorNumber ?? '', []],
                numberOfWindows: [
                    this.property?.dpeDetails?.estimationInformations?.numberOfWindows ?? null,
                    [],
                ],
                numberOfExternalFacingWalls: [
                    this.property?.dpeDetails?.estimationInformations
                        ?.numberOfExternalFacingWalls ?? null,
                    [],
                ],
                isolationRenovationPeriod: [
                    this.property?.dpeDetails?.estimationInformations?.isolationRenovationPeriod ??
                    null,
                    [],
                ],
                atticType: [
                    this.property?.dpeDetails?.estimationInformations?.atticType ?? null,
                    [],
                ],
                glazingType: [
                    this.property?.dpeDetails?.estimationInformations?.glazingType ?? null,
                    [],
                ],
            },
            {
                validators: [
                    this.floorValidator(),
                    this.floorOnBuildingValidator(),
                    this.livingSpaceValidator(),
                    this.landAreaValidator(),
                    this.floorNumberValidator(),
                    this.spaceValidator(),
                ],
            },
        );

        if (this.property?.societyUID) {
            this.displaySocietyField.next(true);
        }

        let use: UseProperty | UseProperty[] = this.property?.purchaseDetails?.use ?? [];

        if (!Array.isArray(use)) {
            use = [use];
        } else {
            use = use.filter((u) => !!u);
        }

        this.purchaseForm = this.formBuilder.group({
            use: [this.property ? use : null, [Validators.required]],
            detention: [this.property?.purchaseDetails?.detention ?? '', []],
            owning: [
                {
                    value:
                        this.property?.purchaseDetails?.owning ??
                        (society?.uid ? OwningType.society : null),
                    disabled: !!society?.uid,
                },
                [],
            ],
            societyUID: [
                {
                    value: this.property?.societyUID ?? society?.uid ?? null,
                    disabled: !!society?.uid,
                },

                [],
            ],
            year: [
                this.property?.purchaseDetails?.year ?? '',
                [Validators.min(1800), Validators.max(date.getFullYear())],
            ], //date.getFullYear()
            price: [this.property?.purchaseDetails?.price ?? null, [Validators.min(0)]],
            notaryFees: [this.property?.purchaseDetails?.notaryFees ?? null, [Validators.min(0)]],
            marketValue: [this.property?.purchaseDetails?.marketValue ?? null, [Validators.min(0)]],
            agencyFees: [this.property?.purchaseDetails?.agencyFees ?? null, [Validators.min(0)]],
            propertyTax: [this.property?.purchaseDetails?.propertyTax ?? null, [Validators.min(0)]],
            housingTax: [this.property?.purchaseDetails?.housingTax ?? null, [Validators.min(0)]],
            // firstVisit : true,
        });

        this.rentForm = this.formBuilder.group({
            type: [this.property?.rentDetails?.type ? this.property.rentDetails.type : null, []],
            managementType: [
                this.property?.rentDetails?.managementType
                    ? this.property.rentDetails.managementType
                    : null,
                [],
            ],
        });

        const displaySocietyField$ = this.purchaseForm
            ?.get('owning')
            ?.valueChanges.subscribe((value) => {
                if (value === OwningType.society) {
                    this.displaySocietyField.next(true);
                } else {
                    this.displaySocietyField.next(false);
                }
            });

        const conditionPropertyType$ = this.detailForm
            ?.get('type')
            ?.valueChanges.subscribe((value) => {
                switch (value) {
                    case PropertyType.bureaux:
                    case PropertyType.appartement:
                    case PropertyType.localCommercial:
                    case PropertyType.parkingBox:
                        this.displayDivisibleField.next(false);
                        this.displayChildPropertyField.next(true);
                        break;

                    case PropertyType.batimentAgricole:
                    case PropertyType.chalet:
                    case PropertyType.maison:
                    case PropertyType.terrain:
                    case PropertyType.other:
                        this.displayDivisibleField.next(false);
                        this.displayChildPropertyField.next(false);
                        break;

                    case PropertyType.immeuble:
                        this.displayDivisibleField.next(true);
                        this.displayChildPropertyField.next(false);
                        break;

                    default:
                        this.displayDivisibleField.next(true);
                        this.displayChildPropertyField.next(true);
                        break;
                }

                if (this.displayDivisibleField.value) {
                    this.detailForm?.get('divisible')?.setValue(null);
                } else {
                    this.detailForm?.get('divisible')?.setValue(false);
                }

                if (this.displayChildPropertyField.value) {
                    this.detailForm?.get('childProperty')?.setValue(null);
                } else {
                    this.detailForm?.get('childProperty')?.setValue(false);
                }
            }) as Subscription;
        const user$ = this.userService.user$.subscribe(async (user) => {
            this.user = user;

            this.societies = await this.societyService.search([
                {
                    where: 'userUID',
                    operator: '==',
                    value: this.user.uid,
                },
            ]);

            this.societiesOptions = this.societies.filter(Boolean).map((society_) => {
                if (society_.toSelectOption) return society_.toSelectOption();
                return {
                    id: society_.uid,
                    label: society_.name,
                };
            });

            // Retrieve divisible properties list
            this.properties = await this.propertyService.search([
                {
                    where: society ? 'societyUID' : 'userUID',
                    operator: '==',
                    value: society ? society.uid : this.user.uid,
                },
                {
                    where: 'divisible',
                    operator: '==',
                    value: true,
                },
            ]);

            // Retrieve properties list that can be linked to a building or a society
            if (societyUID) {
                this.propertiesList = (
                    await this.propertyService.getUserProperties(this.user.uid)
                ).filter((property) => !property.societyUID);
            } else if (parentPropertyUID) {
                this.propertiesList = await this.getLinkablePropertyToBuilding();
            }

            //List of properties that can be linked to a building or a society
            this.propertiesOptions = this.propertiesList
                .filter(Boolean)
                .map((property) => {
                    console.log('Filtered', property);

                    // exclude property already present in society or building
                    // if (property.toSelectOption) {
                    const propertyOption = property.toSelectOption();
                    return propertyOption;
                    // };
                    // const propertyOption: SelectOption = {
                    //     id: property.uid,
                    //     label: property.name,
                    //     icon: property.photo ? undefined : 'uil uil-home',
                    //     image: property.photo,
                    //     purchaseYear: property.purchaseDetails?.year,
                    //     assetType: AssetTypes.property,
                    // };
                    // return propertyOption
                });
        });
        const scroll$ = this.changeStep.subscribe(async () => {
            await this.container?.scrollToTop(200);
        });

        this.subscriptions.push(user$, scroll$, conditionPropertyType$);
        if (displaySocietyField$) {
            this.subscriptions.push(displaySocietyField$);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    private floorOnBuildingValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const buildingFloors = control.get('floorNumber');
            const apartmentFloor = control.get('floor');

            if (!this.canHaveFloor) {
                return null;
            }

            if (buildingFloors && apartmentFloor) {
                const buildingFloorsValue = buildingFloors.value;
                const apartmentFloorValue = apartmentFloor.value;

                if (apartmentFloorValue > buildingFloorsValue) {
                    this.displayErrorToast(
                        "L'étage de votre appartement ne peut pas dépasser le nombre d'étages de l'immeuble",
                    );
                    return { floorInvalid: true };
                }
            }

            return null;
        };
    }

    private buildingYearValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.property?.uid) {
                return null;
            }
            const buildingYear = control.get('buildingYear');

            if (buildingYear?.value) {
                const buildingYearValue = buildingYear.value;
                if (buildingYearValue < 1000) {
                    // we don't display error message if buildingYear hasn't 4 digit
                    return null;
                }

                if (
                    buildingYearValue < MIN_BUILDING_YEAR ||
                    buildingYearValue > MAX_BUILDING_YEAR
                ) {
                    this.displayErrorToast(
                        "L'année de construction doit être entre 1700 et l'année courant plus trois ans",
                    );
                    return { buildingYearInvalid: true };
                }
            }

            return null;
        };
    }

    private livingSpaceValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.property?.uid) {
                return null;
            }
            const livingSpace = control.get('livingSpace');

            if (livingSpace?.value) {
                const livingSpaceValue = livingSpace.value;

                if (livingSpaceValue < MIN_LIVING_AREA || livingSpaceValue > MAX_LIVING_AREA) {
                    this.displayErrorToast(
                        `La surface habitable doit être minimum de ${MIN_LIVING_AREA} m² et maximum de 1000m²`,
                    );
                    return { livingAreaInvalid: true };
                }
            }

            return null;
        };
    }

    public spaceValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (
                !OmedomPricehubble.isPropertyAssimilateHouse(
                    (this.detailForm?.get('type')?.value ?? this.property?.type) as
                    | PropertyType
                    | undefined,
                )
            ) {
                return null;
            }
            const livingSpace = control.get('livingSpace');
            const landArea = control.get('landArea');

            if (livingSpace && landArea) {
                const livingSpaceValue = livingSpace.value;
                const landAreaValue = landArea.value;

                if (livingSpaceValue > landAreaValue) {
                    this.displayErrorToast(
                        'La surface du terrain doit être égale ou supérieure à celle de la surface habitable',
                    );
                    return { spaceInvalid: true };
                }
            }

            return null;
        };
    }

    private landAreaValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.property?.uid || !this.isAssimilateHouse) {
                return null;
            }
            const landArea = control.get('landArea');

            if (landArea?.value) {
                const landAreaValue = landArea.value;
                if (landAreaValue < MIN_LAND_AREA || landAreaValue > MAX_LAND_AREA) {
                    this.displayErrorToast(
                        'La surface du terrain doit être minimum de 10 m² et maximum de 100.000m²',
                    );
                    return { livingAreaInvalid: true };
                }
            }

            return null;
        };
    }

    private floorNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.property?.uid || !this.canHaveFloor) {
                return null;
            }
            const floorNumber = control.get('floorNumber');

            if (floorNumber) {
                const floorNumberValue = floorNumber.value;
                if (floorNumberValue < MIN_FLOOR_NUMBER || floorNumberValue > MAX_FLOOR_NUMBER) {
                    this.displayErrorToast(
                        "L'immeuble doit avoir minimum 1 étage et maximum de 20 étages",
                    );
                    return { floorNumberInvalid: true };
                }
            }

            return null;
        };
    }

    private floorValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.property?.uid || !this.canHaveFloor) {
                return null;
            }
            const floor = control.get('floor');

            if (floor) {
                const floorValue = floor.value;
                if (floorValue < MIN_FLOOR_NUMBER || floorValue > MAX_FLOOR_NUMBER) {
                    this.displayErrorToast(
                        "L'appartement doit être soit au rdc et au maximum, au 20ème étage",
                    );
                    return { floorInvalid: true };
                }
            }

            return null;
        };
    }

    private async displayErrorToast(message: string) {
        const toast = await this.toastController.create({
            position: 'top',
            color: 'danger',
            duration: 5000,
            message: message,
        });

        await toast.present();
    }

    /**
     * @description Upload photo to firebase storage
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof PropertyFormComponent
     */
    public async uplaodAvatar(): Promise<void> {
        if (this.isCapacitor) {
            const photoIsAllowed = await Camera.checkPermissions().then(async (result) => {
                if (result.camera === 'granted' && result.photos === 'granted') {
                    return true;
                } else {
                    const permission = await Camera.requestPermissions();
                    if (permission.photos === 'granted' && permission.camera === 'granted') {
                        return true;
                    } else {
                        return false;
                    }
                }
            });
            if (!photoIsAllowed) {
                const toast = await this.toastController.create({
                    position: 'top',
                    color: 'warning',
                    duration: 3000,
                    message: 'Accès aux fichiers refusé.',
                });

                await toast.present();
                return;
            }
        }
        let image: Photo;
        if (this.isCapacitor && this.isAndroid) {
            image = await Camera.getPhoto({
                quality: 70,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                source: CameraSource.Prompt,
                promptLabelPhoto: 'Depuis la galerie',
                promptLabelPicture: 'Prendre une photo',
                saveToGallery: true,
            });
        } else {
            image = await Camera.getPhoto({
                quality: 70,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                source: CameraSource.Photos,
                saveToGallery: true,
            });
        }
        const myId = uuidv4();

        if (!image.base64String) {
            this.pendingPhoto$.next(false);
            return;
        }

        const file = OmedomTransformer.base64ToFile(image.base64String, myId + '.jpg');
        const path = `assets/images/${file.name}`;
        this.pendingPhoto$.next(true);

        // The main task
        const task = this.storage.upload(path, file);

        // The file's download URL
        task.then(
            (upload) => {
                upload.ref.getDownloadURL().then(async (url) => {
                    if (url && this.property) {
                        this.detailForm?.get('photo')?.setValue(url);
                        await this.propertyService.update({
                            uid: this.property.uid,
                            photo: url,
                        });
                    } else if (url) {
                        this.detailForm?.get('photo')?.setValue(url);
                    }
                });
            },
            (error) => {
                console.error(error);
            },
        );
        this.pendingPhoto$.next(false);
    }

    /**
     * @description Return if the option amenities is selected
     * @author Jérémie Lopez
     * @param {string} amenities
     * @return {*}  {boolean}
     * @memberof PropertyFormComponent
     */
    public isSelectedAmenities(amenities: string): boolean {
        const array = this.settingsForm?.get('amenities')?.value as Array<string>;

        return !!array?.find((element) => element === amenities);
    }

    /**
     * @description Toggle amenities in the array
     * @author Jérémie Lopez
     * @param {string} amenities
     * @memberof PropertyFormComponent
     */
    public toggleAmenities(amenities: string): void {
        const array = this.settingsForm?.get('amenities')?.value as Array<string>;
        const index = array.findIndex((element) => element === amenities);

        if (index >= 0) {
            array.splice(index, 1);
        } else {
            array.push(amenities);
        }

        this.settingsForm?.get('amenities')?.setValue(array);
    }

    formatNumber(newValue: string, inputId: string, control: AbstractControl | null) {
        OmedomFormatNumber.format(newValue, this.numberPipe, inputId);
        if (control) {
            control.patchValue(this.numberPipe.transform(newValue));
        }
    }

    /**
     * @description Submit data to create or edit property
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof PropertyFormComponent
     */
    public async submit(): Promise<void> {
        this.pending$.next(true);

        const propertyUpdated: Partial<PropertyEntity> = this.getPropertyFormValue();

        if (this.property) {
            await this.saveUpdatedProperty(propertyUpdated);
        } else {
            await this.saveCreatedProperty(propertyUpdated);
        }
        this.resetForm();
    }

    /**
     * @description Save updated property in database and display a toast message to confirm the action to the user
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 12/02/2025
     * @private
     * @memberof PropertyFormComponent
     */
    private resetForm(): void {
        this.detailForm?.reset();
        this.settingsForm?.reset();
        this.purchaseForm?.reset();
        this.rentForm?.reset();
        this.pending$.next(false);
    }

    private getPropertyFormValue() {
        let property: Partial<PropertyEntity> = {};
        if (this.property) {
            property = this.property;
        } else {
            property.userUID = this.user?.uid;
            property.sharing = [];
        }

        // Detail Form
        property.name = this.detailForm?.get('name')?.value;
        property.type = this.detailForm?.get('type')?.value ?? null;
        property.divisible = this.detailForm?.get('divisible')?.value ?? null;
        property.photo = this.detailForm?.get('photo')?.value;
        property.address = {
            streetNumber: this.detailForm?.get('address')?.get('streetNumber')?.value,
            suffixNumber: this.detailForm?.get('address')?.get('suffixNumber')?.value,
            street: this.detailForm?.get('address')?.get('street')?.value,
            addressLine2: this.detailForm?.get('address')?.get('addressLine2')?.value,
            postalCode: this.detailForm?.get('address')?.get('postalCode')?.value,
            city: this.detailForm?.get('address')?.get('city')?.value,
        };
        property.buildingYear = this.detailForm?.get('buildingYear')?.value;
        property.assuranceDetails = {
            assuranceName: this.detailForm?.get('assuranceName')?.value,
            assuranceContractNumber: this.detailForm?.get('assuranceContractNumber')?.value,
        };
        property.notes = this.detailForm?.get('notes')?.value;

        // Settings Form
        property.roomCount = this.settingsForm?.get('roomCount')?.value;
        property.livingSpace = this.settingsForm?.get('livingSpace')?.value
            ? +this.numberPipe.parse(this.settingsForm?.get('livingSpace')?.value)
            : 0;
        property.landArea = this.settingsForm?.get('landArea')?.value
            ? +this.numberPipe.parse(this.settingsForm?.get('landArea')?.value)
            : 0;
        property.amenities = this.settingsForm?.get('amenities')?.value;
        property.dpeDetails = {
            ...this.property?.dpeDetails,
            dpeDate: this.settingsForm?.get('dpeDate')?.value,
            dpeScore: this.settingsForm?.get('dpeScore')?.value ?? null,
            dpeValue: this.settingsForm?.get('dpeValue')?.value ?? null,
            ghgValue: this.settingsForm?.get('ghgValue')?.value ?? null,
            ghgScore: this.settingsForm?.get('ghgScore')?.value ?? null,

            estimationInformations: {
                numberOfWindows: this.settingsForm?.get('numberOfWindows')?.value,
                numberOfExternalFacingWalls: this.settingsForm?.get('numberOfExternalFacingWalls')
                    ?.value,
                isolationRenovationPeriod:
                    this.settingsForm?.get('isolationRenovationPeriod')?.value ?? null,
                atticType: this.settingsForm?.get('atticType')?.value,
                glazingType: this.settingsForm?.get('glazingType')?.value ?? null,
            },
        };

        if (this.displayRentForm) {
            property.rentDetails = {
                type: this.rentForm?.get('type')?.value ?? null,
                managementType: this.rentForm?.get('managementType')?.value ?? null,
            };
        }

        if (property.type === PropertyType.appartement) {
            property.floor = this.settingsForm?.get('floor')?.value ?? null;
        }
        property.floorNumber = this.settingsForm?.get('floorNumber')?.value ?? null;

        // Purchase Form

        let use = this.purchaseForm?.get('use')?.value ?? null;
        if (Array.isArray(use)) {
            use = use.map((use: UseProperty) => use);
        } else {
            use = [use];
        }
        property.purchaseDetails = {
            use: use,
            detention: this.purchaseForm?.get('detention')?.value ?? null,
            owning: this.purchaseForm?.get('owning')?.value ?? null,
            year: this.purchaseForm?.get('year')?.value,
            price: this.purchaseForm?.get('price')?.value
                ? +this.numberPipe.parse(this.purchaseForm?.get('price')?.value)
                : 0,
            notaryFees: this.purchaseForm?.get('notaryFees')?.value
                ? +this.numberPipe.parse(this.purchaseForm?.get('notaryFees')?.value)
                : 0,
            marketValue: this.purchaseForm?.get('marketValue')?.value
                ? +this.numberPipe.parse(this.purchaseForm?.get('marketValue')?.value)
                : 0,
            agencyFees: this.purchaseForm?.get('agencyFees')?.value
                ? +this.numberPipe.parse(this.purchaseForm?.get('agencyFees')?.value)
                : 0,
            propertyTax: this.purchaseForm?.get('propertyTax')?.value
                ? +this.numberPipe.parse(this.purchaseForm?.get('propertyTax')?.value)
                : 0,
            housingTax: this.purchaseForm?.get('housingTax')?.value
                ? +this.numberPipe.parse(this.purchaseForm?.get('housingTax')?.value)
                : 0,
        };
        property.societyUID =
            this.purchaseForm?.get('owning')?.value === OwningType.society
                ? this.purchaseForm?.get('societyUID')?.value ?? null
                : null;

        property.lotsUID = [];
        property.parentPropertyUID = property.parentPropertyUID ?? this.parentPropertyUID ?? '';

        return property;
    }

    private async saveUpdatedProperty(property: Partial<PropertyEntity>) {
        try {
            await this.propertyService.update(property);
            this.analyticsPropertyCreated();
            this.pending$.next(false);

            const toast = await this.toastController.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: 'Vous avez modifié un bien.',
            });
            await toast.present();

            // this.submitEvent.emit();
        } catch (error) {
            console.error(error);
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
            this.pending$.next(false);
        }
        this.navController.back();
        if (await this.modalController.getTop()) {
            this.modalController.dismiss();
        }
    }

    private async saveCreatedProperty(partialProperty: Partial<PropertyEntity>) {
        try {
            const subscription = await lastValueFrom(this.subscription$.pipe(take(1)));
            const renewDate = new Date(subscription?.renewDate ?? 0);
            // renewDateString, pattern : '11/9/2024', '9/11/2024', '2/12/2024'
            const renewDateString = `${renewDate.getDate()}/${renewDate.getMonth() + 1
                }/${renewDate.getFullYear()}`;

            partialProperty.accesible = {
                firstProperty: !this.propertyService.hasAtLeastOneProperty$.value,
                renewDate: renewDateString,
            };
            const propertyCreated = await this.propertyService.create(partialProperty);
            const property = (await propertyCreated.get()).data() as PropertyEntity;
            this.analyticsPropertyCreated();
            this.propertyService.propertyCreated();

            await this.updateUserPropertyUID(propertyCreated.id);
            await this.displaySaveSuccessToast();
            await this.openValidationModal(property, propertyCreated.id);

            if (await this.modalController.getTop()) {
                this.modalController.dismiss();
            }
        } catch (error) {
            console.error(error);
            this.displaySaveErrorToast();
        }
    }

    private analyticsPropertyCreated() {
        this.analyticsService.logEvent('Property created');
    }

    private async updateUserPropertyUID(propertyUID: string) {
        if (!this.user) {
            return;
        }
        if ((this.user?.propertiesUID?.length ?? 0) > 0) {
            this.user?.propertiesUID?.push(propertyUID);
        } else {
            this.user.propertiesUID = [propertyUID];
        }

        await this.userService.update(this.user);
    }

    private async displaySaveSuccessToast() {
        // TODO refacto popuValidated, je ne comprends pas la condition du if
        const popupValidated =
            this.user?.popups?.filter((popup) => {
                if (
                    popup.name === 'To banking connexion' &&
                    popup.version === 1 &&
                    popup.type === PopupTypes.redirection &&
                    popup.isValidated === true
                ) {
                    return true;
                } else {
                    return false;
                }
            }).length ?? 0 > 0;

        if (
            !!this.user?.bridgeUUID ||
            (this.user?.propertiesUID?.length ?? 0) <= 1 ||
            popupValidated
        ) {
            const toast = await this.toastController.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: 'Vous avez créé un bien.',
            });
            await toast.present();
        } else {
            this.pending$.next(false);
        }
    }

    private async displaySaveErrorToast() {
        const toast = await this.toastController.create({
            position: 'top',
            color: 'danger',
            duration: 4000,
            message: "Une erreur s'est produite, veuillez réessayer plus tard.",
        });
        await toast.present();
        this.pending$.next(false);
    }

    async exitClicked(label: string, icon: string, img: string | null) {
        const modal = await this.modalController.create({
            component: AlertComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: { title: label, avatar: icon, image: img },
        });

        await modal.present();

        await modal.onDidDismiss().then(async (callback) => {
            if (!(callback?.data === 'continue')) {
                this.resetForm();
            }

            if (await this.modalController.getTop()) {
                this.modalController.dismiss();
            }
        });
    }

    async onSave() {
        this.submit();
    }

    /**
     * @description Open validation modal after property creation
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 29/07/2024
     * @param {PropertyEntity} property // The property ???
     * @param {string} createdPropertyId // The id of the created property
     * @memberof PropertyFormComponent
     */
    async openValidationModal(
        property: PropertyEntity,
        createdPropertyId: string,
        isImport?: boolean,
    ) {
        const modal = await this.modalController.create({
            component: ValidationModalComponent,
            canDismiss: true,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            backdropDismiss: true,
            componentProps: {
                title: 'Propriété ajoutée',
                // validateButtonMessage: isImport || this.firstProperty ? 'Terminer' : 'Partager ce bien à un tiers',
                validateButtonMessage: isImport ? 'Terminer' : 'Partager ce bien à un tiers',// TODO: Replace with this when redirection to share form is OK here
                cancelButtonMessage: isImport ? undefined : 'Terminer',
            },
        });

        await modal.present();

        if (!isImport) {
            // On dismiss,we have a boolean to know if we want to continue to fill the property or not
            await modal.onDidDismiss().then((overlayEventDetail: OverlayEventDetail<boolean>) => {
                // const wantToShare = (overlayEventDetail.data && !this.firstProperty) ?? false;
                const wantToShare = overlayEventDetail.data ?? false; // TODO: Replace with this when redirection to share form is OK here
                console.log('wantToShare', wantToShare);
                this.finishToEdit.emit({
                    createdPropertyId,
                    property,
                    wantToShare,
                });
            });
        }
    }

    public typeChanged(propertyType: SelectOption | undefined) {
        if (propertyType && this.needToCleanUseInput(propertyType.id)) {
            this.purchaseForm?.get('use')?.setValue(null);
        }
        if (propertyType?.id === PropertyType.immeuble) {
            this.isBuilding = true;
            // const use = this.purchaseForm?.get('use')?.setValue(null);
        } else {
            this.isBuilding = false;
        }
        this.isAssimilateHouse = OmedomPricehubble.isPropertyAssimilateHouse(propertyType?.id);
        this.isAssimilateAppartement = OmedomPricehubble.isPropertyAssimilateAppartement(
            propertyType?.id,
        );
        if (this.isAssimilateAppartement) {
            this.labelFloorNumber = 'Nombre total d’étage(s) immeuble';
        } else {
            this.labelFloorNumber = 'Nombre de niveaux';
        }

        this.canHaveFloor = OmedomProperty.CanHaveFloor(propertyType?.id);

        const floorForm = this.settingsForm?.get('floor');
        if (propertyType && this.canHaveCurrentFloor(propertyType?.id)) {
            floorForm?.enable();
        } else {
            floorForm?.disable();
        }
        this.previousPropertyType = propertyType?.id;
    }

    canHaveCurrentFloor(propertyType: PropertyType) {
        const canHaveCurrentFloor =
            propertyType === PropertyType.appartement ||
            propertyType === PropertyType.bureaux ||
            propertyType === PropertyType.localCommercial;

        return canHaveCurrentFloor;
    }

    private needToCleanUseInput(propertyType: PropertyType) {
        // We clean the 'use' input when several 'use are selected in building, when we change from building to another type

        const isPreviousTypeBuilding = this.previousPropertyType === PropertyType.immeuble;
        const isNewtypeNotBuilding = propertyType !== PropertyType.immeuble;
        const isMultipleUseInInput = this.purchaseForm?.get('use')?.value?.length > 1;

        return isPreviousTypeBuilding && isNewtypeNotBuilding && isMultipleUseInInput;
    }

    public addExistingProperty(modal: IonModal): void {
        modal.present();
    }
    private async getLinkablePropertyToBuilding() {
        const [userProperties, currentProperty] = await Promise.all([
            this.propertyService.getUserProperties(this.user!.uid),
            this.propertyService.get(this.parentPropertyUID!),
        ]);

        const filteredProperties = userProperties.filter((property) => {
            if (property.uid === currentProperty?.uid) {
                return false;
            }
            if (property.type === PropertyType.immeuble) {
                return false;
            }
            if (property.parentPropertyUID) {
                return false;
            }
            if (currentProperty?.lotsUID.includes(property.uid)) {
                return false;
            }
            return true;
        });

        return filteredProperties;
    }

    public async importProperty(): Promise<boolean> {
        this.pending$.next(true);
        try {
            if (!this.importedExistingPropertyUID) {
                throw new Error('Veuillez sélectionner un bien à importer.');
            }

            if (!this.parentPropertyUID && !this.societyUID) {
                throw new Error(
                    'Veuillez sélectionner une société ou un immeuble pour importer le bien.',
                );
            }

            const newProperty = this.propertiesList.find(
                (property) => property.uid === this.importedExistingPropertyUID,
            );
            if (!newProperty) {
                throw new Error("Le bien à importer n'existe pas.");
            }

            if (this.parentPropertyUID) {
                const parentProperty = await this.propertyService.get(this.parentPropertyUID);
                if (!parentProperty) {
                    throw new Error("L'immeuble parent n'existe plus.");
                }

                await Promise.all([
                    this.propertyService.update({
                        uid: newProperty.uid,
                        parentPropertyUID: this.parentPropertyUID,
                    }),
                    this.propertyService.update({
                        uid: parentProperty.uid,
                        lotsUID: [...parentProperty.lotsUID, newProperty.uid],
                    }),
                ]).catch((error) => {
                    console.error('Error while updating parent property and new property:', error);
                    throw new Error("Une erreur s'est produite, veuillez réessayer plus tard.");
                });
            } else if (this.societyUID) {
                await this.propertyService
                    .update({
                        uid: newProperty.uid,
                        societyUID: this.societyUID,
                        purchaseDetails: {
                            ...newProperty!.purchaseDetails,
                            owning: OwningType.society,
                        } as PropertyPurchaseDetails,
                    })
                    .catch((error) => {
                        console.error('Error while updating society property:', error);
                        throw new Error("Une erreur s'est produite, veuillez réessayer plus tard.");
                    });
            } else {
                throw new Error(
                    'Veuillez sélectionner une société ou un immeuble pour importer le bien.',
                );
            }

            this.openValidationModal(newProperty, newProperty.uid, true);

            this.pending$.next(false);

            return this.modalController.dismiss();
        } catch (error) {
            console.error(error);
            this.displayErrorToast("Une erreur s'est produite, veuillez réessayer plus tard.");
            this.pending$.next(false);
            return this.modalController.dismiss();
        }
    }

    /**
     * @description Redirect to subscription page to manage subscription when user click on the button "Gérer mon abonnement" in the modal "Bien ajouté" after property creation
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 07/02/2025
     * @memberof PropertyFormComponent
     */
    public manageSubscription(): void {
        this.router.navigate(['/tabs/menu/subscription'], { queryParams: { tab: 'manage' } });
    }
}
