<div class="flex-1">
    <!-- invite to choose between select a property or create it -->
    @if (activeStep === 0 && (parentPropertyUID || societyUID)) {
        <div class="button-list" [style.width]="'100%'">
            <button
                class="omedom-button-toggle"
                (click)="addExistingProperty(modal)"
            >
                <i class="omedom-button-toggle-icon uil uil-import"></i>
                <span class="omedom-button-toggle-title">
                    <span>Ajouter un bien existant</span>
                </span>
            </button>
            <button class="omedom-button-toggle" (click)="activeStep = 1">
                <i class="omedom-button-toggle-icon uil uil-plus"></i>
                <span class="omedom-button-toggle-title">
                    <span>Créer un nouveau bien</span>
                </span>
            </button>
        </div>
    }

    <!-- form if choose to create a new property-->
    @if (activeStep === 1 && detailForm) {
        <ng-container class="flex-1">
            <form [formGroup]="detailForm" class="form">
                @if (!property) {
                    <omedom-form-section-title
                        title="Détails"
                    ></omedom-form-section-title>
                }
                <div class="form-item">
                    <span class="form-item-label">Avatar du bien</span>
                    <div class="form-avatar-container">
                        @if (detailForm.get('photo')?.value) {
                            <img
                                class="form-avatar"
                                [src]="detailForm.get('photo')?.value"
                                alt="Avatar du bien"
                            />
                        } @else {
                            <div class="form-default-avatar">
                                <i class="uil uil-home"></i>
                            </div>
                        }
                        <button
                            class="omedom-button-secondary flex-1"
                            (click)="uplaodAvatar()"
                            type="button"
                            [disabled]="pendingPhoto$.asObservable() | async"
                        >
                            <i class="uil uil-import"></i>
                            <span>Importer une photo</span>
                            @if (pendingPhoto$.asObservable() | async) {
                                <ion-spinner
                                    color="primary"
                                    class="form-upload-spinner"
                                ></ion-spinner>
                            }
                        </button>
                    </div>
                </div>
                <div class="form-item">
                    <span class="form-item-label">Nom<sup>*</sup></span>
                    <ion-item>
                        <ion-label>
                            <i class="uil uil-home"></i>
                        </ion-label>
                        <ion-input
                            type="text"
                            formControlName="name"
                            placeholder="Nom"
                            maxlength="30"
                        ></ion-input>
                    </ion-item>
                </div>
                <div class="form-item">
                    <span class="form-item-label">Type de bien</span>
                    <omedom-select
                        formControlName="type"
                        [placeholder]="typeBienPlaceholder"
                        [options]="(typeBienOptions$ | async) || []"
                        (selectExited)="typeChanged($any($event))"
                    ></omedom-select>
                </div>
                <hr class="omedom-divider" />
                @if (property) {
                    <form [formGroup]="addressForm" class="form-inline">
                        <div class="form-item">
                            <span class="form-item-label">Adresse</span>
                            @if (property.valuation) {
                                <omedom-info
                                    information="Attention, l'adresse modifiée ne sera pas prise en compte pour l'estimation du bien."
                                ></omedom-info>
                            }
                            <div class="form-flex">
                                <ion-item style="width: 120px">
                                    <ion-label>
                                        <i class="uil uil-map-marker"></i>
                                    </ion-label>
                                    <ion-input
                                        type="number"
                                        [min]="0"
                                        [max]="9999"
                                        formControlName="streetNumber"
                                        placeholder="N°"
                                    ></ion-input>
                                </ion-item>
                                <ion-item style="width: 120px">
                                    <ion-label>
                                        <i class="uil uil-map-marker"></i>
                                    </ion-label>
                                    <ion-input
                                        type="text"
                                        formControlName="suffixNumber"
                                        placeholder="bis, ter"
                                    ></ion-input>
                                </ion-item>
                            </div>
                            <ion-item style="flex: 1" class="street-form">
                                <ion-label>
                                    <i class="uil uil-map-marker"></i>
                                </ion-label>
                                <ion-input
                                    type="text"
                                    formControlName="street"
                                    placeholder="Rue"
                                ></ion-input>
                            </ion-item>
                            <ion-item style="flex: 1">
                                <ion-input
                                    type="text"
                                    formControlName="addressLine2"
                                    placeholder="Batiment A"
                                ></ion-input>
                            </ion-item>
                            <div class="form-flex">
                                <ion-item style="flex: 1">
                                    <ion-input
                                        type="text"
                                        formControlName="postalCode"
                                        placeholder="Code Postal"
                                        [pattern]="codepostalRegex"
                                    ></ion-input>
                                </ion-item>
                                <ion-item style="flex: 1">
                                    <ion-input
                                        type="text"
                                        formControlName="city"
                                        placeholder="Ville"
                                    ></ion-input>
                                </ion-item>
                            </div>
                        </div>
                    </form>

                    <hr class="omedom-divider" />

                    <div
                        class="form-collapse"
                        (click)="
                            displayAssuranceForm.next(
                                !displayAssuranceForm.value
                            )
                        "
                    >
                        <span> Assurance habitation</span>
                        <i
                            class="uil"
                            [class.uil-angle-down]="!displayAssuranceForm.value"
                            [class.uil-angle-up]="displayAssuranceForm.value"
                        ></i>
                    </div>
                    @if (displayAssuranceForm | async) {}
                    <div class="form" @slideYInOut>
                        <div class="form-item">
                            <span class="form-item-label"
                                >Nom de l'assureur</span
                            >
                            <ion-item>
                                <ion-input
                                    type="text"
                                    formControlName="assuranceName"
                                    placeholder="Nom de l’assureur"
                                ></ion-input>
                            </ion-item>
                        </div>
                        <div class="form-item">
                            <span class="form-item-label">N° de contrat</span>
                            <ion-item>
                                <ion-input
                                    type="text"
                                    formControlName="assuranceContractNumber"
                                    placeholder="N° de contrat"
                                ></ion-input>
                            </ion-item>
                        </div>
                    </div>

                    <hr class="omedom-divider" />

                    <div class="form-item-inline">
                        <span class="form-item-label"
                            >Année de construction</span
                        >
                        <ion-item style="flex: 1">
                            <ion-label>
                                <i class="uil uil-schedule"></i>
                            </ion-label>
                            <ion-input
                                type="number"
                                [min]="1700"
                                [max]="2099"
                                formControlName="buildingYear"
                                placeholder="aaaa"
                            ></ion-input>
                        </ion-item>
                    </div>

                    <hr class="omedom-divider" />

                    <div class="form-item margin-bottom-2">
                        <span class="form-item-label">Notes</span>
                        <ion-item>
                            <ion-input
                                type="text"
                                formControlName="notes"
                                placeholder="Exemples : coordonnées, code WiFi"
                            ></ion-input>
                        </ion-item>
                    </div>

                    @if (
                        (displayDivisibleField | async) ||
                        (displayChildPropertyField | async)
                    ) {
                        <hr class="omedom-divider" />
                        @if (displayDivisibleField | async) {
                            <omedom-radio
                                label="Ce bien est-il divisible ?"
                                name="divisible"
                                formControlName="divisible"
                                popover="Un bien divisible vous permettra de créer plusieurs biens à l’intérieur de ce dernier."
                                [options]="yesNoOptions"
                                [required]="true"
                            ></omedom-radio>
                        }
                        @if (detailForm.get('childProperty')?.value === true) {
                            <div class="form-item">
                                @if ((propertiesOptions ?? []).length > 0) {
                                    <omedom-select
                                        formControlName="propertyUID"
                                        [placeholder]="propertiesPlaceholder"
                                        [options]="propertiesOptions"
                                    ></omedom-select>
                                }
                            </div>
                        }
                    }
                }

                @if (
                    ('accessManageBuilding' | isAuthorised | async) === false &&
                    !isProEdit
                ) {
                    <omedom-action
                        text="Fonctionnalité Premium, changez d'abonnement."
                        actionIcon="omedom-icon-crown"
                        (actionClicked)="manageSubscription()"
                    >
                    </omedom-action>
                }
            </form>
            @if (purchaseForm) {
                <form [formGroup]="purchaseForm" class="form">
                    @if (!property) {
                        <omedom-form-section-title
                            title="Détention"
                            class="margin-top-2"
                        ></omedom-form-section-title>
                    }
                    <div class="form-item">
                        <span class="form-item-label">Détention</span>
                        <omedom-select
                            formControlName="owning"
                            [placeholder]="owningPlaceholder"
                            [options]="(owningOptions$ | async) || []"
                        ></omedom-select>
                    </div>
                    @if (
                        ('accessManageSociety' | isAuthorised | async) &&
                        (displaySocietyField | async) &&
                        isProEdit
                    ) {
                        <div class="form-item">
                            <span class="form-item-label">Société</span>
                            @if (societiesOptions.length > 0) {
                                <omedom-select
                                    formControlName="societyUID"
                                    [placeholder]="societiesPlaceholder"
                                    [options]="societiesOptions"
                                    [disabled]="isProEdit"
                                ></omedom-select>
                            }
                        </div>
                    }
                    @if (
                        ('accessManageSociety' | isAuthorised | async) ===
                            false &&
                        (displaySocietyField | async) &&
                        !isProEdit
                    ) {
                        <omedom-action
                            text="Fonctionnalité Premium, changez d'abonnement."
                            actionIcon="omedom-icon-crown"
                            (actionClicked)="manageSubscription()"
                        >
                        </omedom-action>
                    }
                    @if (property) {
                        <div class="form-item">
                            <span class="form-item-label"
                                >Mode de détention</span
                            >
                            <omedom-select
                                formControlName="detention"
                                [placeholder]="detentionPlaceholder"
                                [options]="(detentionOptions$ | async) || []"
                            ></omedom-select>
                        </div>

                        <hr class="omedom-divider" />
                    }
                    <div class="form-item">
                        <span class="form-item-label"
                            >Usage du bien<sup>*</sup></span
                        >
                        <omedom-select
                            formControlName="use"
                            [placeholder]="usePlaceholder"
                            [options]="(useOptions$ | async) || []"
                            [isMultiple]="isBuilding"
                        ></omedom-select>
                    </div>
                    @if (property) {
                        <hr class="omedom-divider" />

                        <div
                            class="form-collapse"
                            (click)="
                                displayFinancialData.next(
                                    !displayFinancialData.value
                                )
                            "
                        >
                            <img
                                src="/assets/icon/tax-fonciere.svg"
                                alt="données financières"
                                title="données financières"
                                class="info-icon margin-right-1"
                            />
                            <span>Données financières</span>
                            <i
                                class="uil"
                                [class.uil-angle-down]="
                                    !displayFinancialData.value
                                "
                                [class.uil-angle-up]="
                                    displayFinancialData.value
                                "
                            ></i>
                        </div>
                        @if (displayFinancialData.value) {
                            <div class="form" @slideYInOut>
                                <div class="form-item-inline">
                                    <span class="form-item-label"
                                        >Année d'acquisition</span
                                    >
                                    <ion-item>
                                        <ion-label>
                                            <i class="uil uil-schedule"></i>
                                        </ion-label>
                                        <ion-input
                                            type="number"
                                            min="1800"
                                            max="2099"
                                            formControlName="year"
                                            placeholder="aaaa"
                                        ></ion-input>
                                    </ion-item>
                                </div>

                                <div class="form-item-inline">
                                    <span class="form-item-label"
                                        >Prix d'acquisition</span
                                    >
                                    <ion-item>
                                        <ion-input
                                            id="price"
                                            appOmedomNumber
                                            (ionChange)="
                                                formatNumber(
                                                    $any($event).detail.value,
                                                    'price',
                                                    purchaseForm.get('price')
                                                )
                                            "
                                            [value]="
                                                purchaseForm.get('price')?.value
                                                    | omedomNumber
                                            "
                                            [debounce]="125"
                                            placeholder="Prix d'acquisition"
                                        ></ion-input>
                                        <ion-label>
                                            <i class="uil uil-euro"></i>
                                        </ion-label>
                                    </ion-item>
                                </div>

                                <div class="form-item-inline">
                                    <span class="market-value form-item-label">
                                        Valeur vénale
                                        <omedom-popover
                                            content="Valeur estimée de votre bien (rentrée manuellement)."
                                        ></omedom-popover>
                                    </span>
                                    <ion-item>
                                        <ion-input
                                            id="marketValue"
                                            appOmedomNumber
                                            (ionChange)="
                                                formatNumber(
                                                    $any($event).detail.value,
                                                    'marketValue',
                                                    purchaseForm.get(
                                                        'marketValue'
                                                    )
                                                )
                                            "
                                            [value]="
                                                purchaseForm.get('marketValue')
                                                    ?.value | omedomNumber
                                            "
                                            [debounce]="125"
                                            placeholder="Valeur vénale"
                                        ></ion-input>
                                        <ion-label>
                                            <i class="uil uil-euro"></i>
                                        </ion-label>
                                    </ion-item>
                                </div>

                                <hr class="omedom-divider" />
                                <div class="form-item-inline">
                                    <span class="form-item-label"
                                        >Frais de notaire</span
                                    >
                                    <ion-item>
                                        <ion-input
                                            id="notaryFees"
                                            appOmedomNumber
                                            (ionChange)="
                                                formatNumber(
                                                    $any($event).detail.value,
                                                    'notaryFees',
                                                    purchaseForm.get(
                                                        'notaryFees'
                                                    )
                                                )
                                            "
                                            [value]="
                                                purchaseForm.get('notaryFees')
                                                    ?.value | omedomNumber
                                            "
                                            [debounce]="125"
                                            placeholder="Frais de notaire"
                                        ></ion-input>
                                        <ion-label>
                                            <i class="uil uil-euro"></i>
                                        </ion-label>
                                    </ion-item>
                                </div>
                                <div class="form-item-inline">
                                    <span class="form-item-label"
                                        >Frais d'agence</span
                                    >
                                    <ion-item>
                                        <ion-input
                                            id="agencyFees"
                                            appOmedomNumber
                                            (ionChange)="
                                                formatNumber(
                                                    $any($event).detail.value,
                                                    'agencyFees',
                                                    purchaseForm.get(
                                                        'agencyFees'
                                                    )
                                                )
                                            "
                                            [value]="
                                                purchaseForm.get('agencyFees')
                                                    ?.value | omedomNumber
                                            "
                                            [debounce]="125"
                                            placeholder="Frais d'agence"
                                        ></ion-input>
                                        <ion-label>
                                            <i class="uil uil-euro"></i>
                                        </ion-label>
                                    </ion-item>
                                </div>
                                <hr class="omedom-divider" />
                                <div class="form-item-inline">
                                    <span class="form-item-label"
                                        >Taxe foncière N-1</span
                                    >
                                    <ion-item>
                                        <ion-input
                                            id="propertyTax"
                                            appOmedomNumber
                                            (ionChange)="
                                                formatNumber(
                                                    $any($event).detail.value,
                                                    'propertyTax',
                                                    purchaseForm.get(
                                                        'propertyTax'
                                                    )
                                                )
                                            "
                                            [value]="
                                                purchaseForm.get('propertyTax')
                                                    ?.value | omedomNumber
                                            "
                                            [debounce]="125"
                                            placeholder="Taxe foncière N-1"
                                        ></ion-input>
                                        <ion-label>
                                            <i class="uil uil-euro"></i>
                                        </ion-label>
                                    </ion-item>
                                </div>
                                <div class="form-item-inline">
                                    <span class="form-item-label"
                                        >Taxe d'habitation N-1</span
                                    >
                                    <ion-item>
                                        <ion-input
                                            id="housingTax"
                                            appOmedomNumber
                                            (ionChange)="
                                                formatNumber(
                                                    $any($event).detail.value,
                                                    'housingTax',
                                                    purchaseForm.get(
                                                        'housingTax'
                                                    )
                                                )
                                            "
                                            [value]="
                                                purchaseForm.get('housingTax')
                                                    ?.value | omedomNumber
                                            "
                                            [debounce]="125"
                                            placeholder="Taxe d’habitation N-1"
                                        ></ion-input>
                                        <ion-label>
                                            <i class="uil uil-euro"></i>
                                        </ion-label>
                                    </ion-item>
                                </div>
                            </div>
                        }
                        <hr
                            class="omedom-divider"
                            style="margin-bottom: 20px"
                        />
                    }
                </form>
            }
            <!-- </ng-container> -->
            <!-- Settings Form -->
            @if (property) {
                @if (settingsForm) {
                    <form [formGroup]="settingsForm" class="form">
                        <div
                            class="form-collapse"
                            (click)="
                                displayDescription.next(
                                    !displayDescription.value
                                )
                            "
                        >
                            <img
                                src="/assets/icon/tax-habitation.svg"
                                alt="données physiques"
                                title="données physiques"
                                class="info-icon margin-right-1"
                            />
                            <span>Données physiques</span>
                            <i
                                class="uil"
                                [class.uil-angle-down]="
                                    !displayDescription.value
                                "
                                [class.uil-angle-up]="displayDescription.value"
                            ></i>
                        </div>
                        @if (property && displayDescription.value) {
                            <div class="form" @slideYInOut>
                                <div class="form-item">
                                    <span class="form-item-label">Pièces</span>
                                    <div class="form-select">
                                        <div
                                            class="form-option"
                                            (click)="
                                                settingsForm
                                                    .get('roomCount')
                                                    ?.setValue(1)
                                            "
                                            [class.form-option-active]="
                                                settingsForm.get('roomCount')
                                                    ?.value === 1
                                            "
                                            [class.form-option-disabled]="
                                                settingsForm.get('roomCount')
                                                    ?.value !== 0 &&
                                                settingsForm.get('roomCount')
                                                    ?.value !== 1
                                            "
                                        >
                                            <span>1</span>
                                        </div>
                                        <div
                                            class="form-option"
                                            (click)="
                                                settingsForm
                                                    .get('roomCount')
                                                    ?.setValue(2)
                                            "
                                            [class.form-option-active]="
                                                settingsForm.get('roomCount')
                                                    ?.value === 2
                                            "
                                            [class.form-option-disabled]="
                                                settingsForm.get('roomCount')
                                                    ?.value !== 0 &&
                                                settingsForm.get('roomCount')
                                                    ?.value !== 2
                                            "
                                        >
                                            <span>2</span>
                                        </div>
                                        <div
                                            class="form-option"
                                            (click)="
                                                settingsForm
                                                    .get('roomCount')
                                                    ?.setValue(3)
                                            "
                                            [class.form-option-active]="
                                                settingsForm.get('roomCount')
                                                    ?.value === 3
                                            "
                                            [class.form-option-disabled]="
                                                settingsForm.get('roomCount')
                                                    ?.value !== 0 &&
                                                settingsForm.get('roomCount')
                                                    ?.value !== 3
                                            "
                                        >
                                            <span>3</span>
                                        </div>
                                        <div
                                            class="form-option"
                                            (click)="
                                                settingsForm
                                                    .get('roomCount')
                                                    ?.setValue(4)
                                            "
                                            [class.form-option-active]="
                                                settingsForm.get('roomCount')
                                                    ?.value === 4
                                            "
                                            [class.form-option-disabled]="
                                                settingsForm.get('roomCount')
                                                    ?.value !== 0 &&
                                                settingsForm.get('roomCount')
                                                    ?.value !== 4
                                            "
                                        >
                                            <span>4</span>
                                        </div>
                                        <div
                                            class="form-option"
                                            (click)="
                                                settingsForm
                                                    .get('roomCount')
                                                    ?.setValue(5)
                                            "
                                            [class.form-option-active]="
                                                settingsForm.get('roomCount')
                                                    ?.value === 5
                                            "
                                            [class.form-option-disabled]="
                                                settingsForm.get('roomCount')
                                                    ?.value !== 0 &&
                                                settingsForm.get('roomCount')
                                                    ?.value !== 5
                                            "
                                        >
                                            <span>5</span>
                                        </div>
                                        <div
                                            class="form-option"
                                            (click)="
                                                settingsForm
                                                    .get('roomCount')
                                                    ?.setValue(6)
                                            "
                                            [class.form-option-active]="
                                                settingsForm.get('roomCount')
                                                    ?.value === 6
                                            "
                                            [class.form-option-disabled]="
                                                settingsForm.get('roomCount')
                                                    ?.value !== 0 &&
                                                settingsForm.get('roomCount')
                                                    ?.value !== 6
                                            "
                                        >
                                            <span>6+</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-flex">
                                    <div class="form-item" style="flex: 1">
                                        <span class="form-item-label"
                                            >Surface du terrain</span
                                        >
                                        <ion-item>
                                            <ion-label>
                                                <i class="uil uil-tape"></i>
                                            </ion-label>
                                            <ion-input
                                                id="landArea"
                                                appOmedomNumber
                                                (ionChange)="
                                                    formatNumber(
                                                        $any($event).detail
                                                            .value,
                                                        'landArea',
                                                        settingsForm.get(
                                                            'landArea'
                                                        )
                                                    )
                                                "
                                                [value]="
                                                    settingsForm.get('landArea')
                                                        ?.value | omedomNumber
                                                "
                                                [debounce]="125"
                                                placeholder="Surface en m2"
                                            ></ion-input>
                                        </ion-item>
                                    </div>
                                    <div class="form-item" style="flex: 1">
                                        <span class="form-item-label"
                                            >Surface habitable</span
                                        >
                                        <ion-item>
                                            <ion-label>
                                                <i class="uil uil-tape"></i>
                                            </ion-label>
                                            <ion-input
                                                id="livingSpace"
                                                appOmedomNumber
                                                (ionChange)="
                                                    formatNumber(
                                                        $any($event).detail
                                                            .value,
                                                        'livingSpace',
                                                        settingsForm.get(
                                                            'livingSpace'
                                                        )
                                                    )
                                                "
                                                [value]="
                                                    settingsForm.get(
                                                        'livingSpace'
                                                    )?.value | omedomNumber
                                                "
                                                [debounce]="125"
                                                placeholder="Surface en m2"
                                            ></ion-input>
                                        </ion-item>
                                    </div>
                                </div>
                                <div class="form-flex">
                                    @if (canHaveFloor) {
                                        <div class="form-item" style="flex: 1">
                                            @if (isAssimilateHouse) {
                                                <omedom-info
                                                    information="Le nombre de niveaux comprend tous les niveaux habitables (sous-sol et combles inclus)"
                                                ></omedom-info>
                                            }
                                            <omedom-input
                                                class="margin-bottom-1"
                                                name="floorNumber"
                                                type="number"
                                                formControlName="floorNumber"
                                                placeholder="Étage(s)"
                                                autocomplete="off"
                                                [debounce]="300"
                                                [label]="labelFloorNumber"
                                            ></omedom-input>
                                        </div>
                                    }
                                    @if (isAssimilateAppartement) {
                                        <div class="form-item" style="flex: 1">
                                            <omedom-input
                                                class="margin-bottom-1"
                                                name="floor"
                                                type="number"
                                                formControlName="floor"
                                                placeholder="Étage"
                                                autocomplete="off"
                                                [debounce]="300"
                                                label="Etage du logement"
                                            ></omedom-input>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        <hr class="omedom-divider" />
                        <div
                            class="form-collapse"
                            (click)="
                                displayDiagnosticForm.next(
                                    !displayDiagnosticForm.value
                                )
                            "
                        >
                            <i
                                class="uil uil-signal margin-right-1 rotate-90"
                            ></i>
                            <span>Diagnostic de performance énergétique</span>
                            <i
                                class="uil"
                                [class.uil-angle-down]="
                                    !displayDiagnosticForm.value
                                "
                                [class.uil-angle-up]="
                                    displayDiagnosticForm.value
                                "
                            ></i>
                        </div>
                        @if (property && displayDiagnosticForm | async) {
                            <div class="form" @slideYInOut>
                                <div class="form-flex margin-bottom-1">
                                    <div class="form-item" style="flex: 1">
                                        <span class="form-item-label"
                                            >Date du DPE</span
                                        >
                                        <ion-item>
                                            <ion-input
                                                formControlName="dpeDate"
                                                type="date"
                                            ></ion-input>
                                        </ion-item>
                                    </div>
                                    <div class="form-item" style="flex: 1">
                                        <span class="form-item-label"
                                            >Note du DPE</span
                                        >
                                        <omedom-select
                                            formControlName="dpeScore"
                                            [placeholder]="dpeScorePlaceHolder"
                                            [options]="
                                                (dpeScoreOptions$ | async) || []
                                            "
                                        ></omedom-select>
                                    </div>
                                </div>
                                <omedom-info
                                    information="Pensez à importer votre diagnostic dans vos documents."
                                    class="margin-bottom-1"
                                >
                                </omedom-info>
                                <div>
                                    <div
                                        class="form-flex form-block margin-bottom-1"
                                    >
                                        <div
                                            class="form-item"
                                            style="flex: 1; min-width: 200px"
                                        >
                                            <omedom-input
                                                class="margin-bottom-1"
                                                name="numberOfWindows"
                                                type="number"
                                                formControlName="numberOfWindows"
                                                autocomplete="off"
                                                [debounce]="300"
                                                label="Nombre de fenêtres"
                                            ></omedom-input>
                                        </div>
                                        <div
                                            class="form-item"
                                            style="flex: 1; min-width: 200px"
                                        >
                                            <omedom-input
                                                class="margin-bottom-1"
                                                name="numberOfExternalFacingWalls"
                                                type="number"
                                                formControlName="numberOfExternalFacingWalls"
                                                autocomplete="off"
                                                [debounce]="300"
                                                label="Nombre de murs vers l'extérieur"
                                            ></omedom-input>
                                        </div>
                                    </div>
                                    <div>
                                        <omedom-select
                                            class="margin-bottom-2"
                                            [options]="
                                                isolationRenovationOptions
                                            "
                                            formControlName="isolationRenovationPeriod"
                                            label="Periode de rénovation"
                                        ></omedom-select>
                                    </div>
                                    <div class="form-flex margin-bottom-1">
                                        <div class="form-item" style="flex: 1">
                                            <omedom-select
                                                class="margin-bottom-2"
                                                [options]="glazingTypeOptions"
                                                formControlName="glazingType"
                                                label="Type de vitrage"
                                            ></omedom-select>
                                        </div>
                                        @if (isAssimilateHouse) {
                                            <div
                                                class="form-item"
                                                style="flex: 1"
                                            >
                                                <omedom-select
                                                    class="margin-bottom-2"
                                                    [options]="atticTypeOptions"
                                                    formControlName="atticType"
                                                    label="Type de grenier"
                                                ></omedom-select>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        @if (isRentalProperty) {
                            <hr class="omedom-divider" />
                            <div
                                class="form-collapse"
                                (click)="
                                    displayRentForm.next(!displayRentForm.value)
                                "
                            >
                                <img
                                    src="/assets/icon/location.svg"
                                    alt="location"
                                    title="location"
                                    class="info-icon margin-right-1"
                                />
                                <span class="form-item-label">Location</span>
                                <i
                                    class="uil"
                                    [class.uil-angle-down]="
                                        !displayRentForm.value
                                    "
                                    [class.uil-angle-up]="displayRentForm.value"
                                ></i>
                            </div>
                            @if (rentForm && (displayRentForm | async)) {
                                <form
                                    [formGroup]="rentForm"
                                    class="form form-no-margin"
                                >
                                    <div class="form-item">
                                        <span class="form-item-label"
                                            >Type de location</span
                                        >
                                        <omedom-select
                                            formControlName="type"
                                            [placeholder]="rentTypePlaceholder"
                                            [options]="
                                                (rentTypeOptions$ | async) || []
                                            "
                                        ></omedom-select>
                                    </div>
                                    <div class="form-item">
                                        <span class="form-item-label"
                                            >Type de gestion locative</span
                                        >
                                        <omedom-select
                                            formControlName="managementType"
                                            [placeholder]="
                                                managementRentTypePlaceholder
                                            "
                                            [options]="
                                                (managementRentTypeOptions$
                                                    | async) || []
                                            "
                                        ></omedom-select>
                                    </div>
                                </form>
                            }
                        }
                        <hr class="omedom-divider" />
                        <div
                            class="form-collapse"
                            (click)="
                                displayAmenities.next(!displayAmenities.value)
                            "
                        >
                            <img
                                src="/assets/icon/union.svg"
                                alt="commodités"
                                title="Commodités"
                                class="margin-right-1"
                                style="width: 18px"
                            />
                            <span>Commodités</span>
                            <i
                                class="uil"
                                [class.uil-angle-down]="!displayAmenities.value"
                                [class.uil-angle-up]="displayAmenities.value"
                            ></i>
                        </div>
                        @if (displayAmenities | async) {
                            <div class="form-mega-checkbox" @slideYInOut>
                                <div class="commodity-group">
                                    <span class="form-label-header">
                                        <i class="uil uil-home"></i>
                                        Intérieur
                                    </span>
                                    <div class="form-checkbox-list">
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Cuisine équipée'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Cuisine équipée'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label>
                                                Cuisine équipée
                                            </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities('Cheminée')
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Cheminée'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Cheminée </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities('Alarme')
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Alarme'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Alarme </ion-label>
                                        </ion-item>
                                    </div>
                                </div>
                                <div class="commodity-group">
                                    <span class="form-label-header">
                                        <i class="uil uil-trees"></i>
                                        Extérieur
                                    </span>
                                    <div class="form-checkbox-list">
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Balcon / Terrasse'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Balcon / Terrasse'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label>
                                                Balcon / Terrasse
                                            </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Jardin / Cour'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Jardin / Cour'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label>
                                                Jardin / Cour
                                            </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Garage / Parking'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Garage / Parking'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label>
                                                Garage / Parking
                                            </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Cave / Sous sol'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Cave / Sous sol'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label>
                                                Cave / Sous sol
                                            </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities('Piscine')
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Piscine'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Piscine </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Dépendance(s)'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Dépendance(s)'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label>
                                                Dépendance(s)
                                            </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities('Parc')
                                                "
                                                [checked]="
                                                    isSelectedAmenities('Parc')
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Parc </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities('Forêt')
                                                "
                                                [checked]="
                                                    isSelectedAmenities('Forêt')
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Forêt </ion-label>
                                        </ion-item>
                                    </div>
                                </div>
                                <div class="commodity-group">
                                    <span class="form-label-header">
                                        <i class="uil uil-building"></i>
                                        Immeuble
                                    </span>
                                    <div class="form-checkbox-list">
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities('Ascenseur')
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Ascenseur'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Ascenseur </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Gardien / Concierge'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Gardien / Concierge'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label>
                                                Gardien / Concierge
                                            </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities('Digicode')
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Digicode'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Digicode </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Interphone'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Interphone'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Interphone </ion-label>
                                        </ion-item>
                                    </div>
                                </div>
                                <div class="commodity-group">
                                    <span class="form-label-header">
                                        <i
                                            class="uil uil-temperature-quarter"
                                        ></i>
                                        Chauffage
                                    </span>
                                    <div class="form-checkbox-list">
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Chauffage collectif'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Chauffage collectif'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Collectif </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Chauffage indiviuel'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Chauffage indiviuel'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Individuel </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Chauffage au gaz'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Chauffage au gaz'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Gaz </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Chauffage éléctrique'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Chauffage éléctrique'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Electrique </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Chauffage au fuel'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Chauffage au fuel'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Fuel </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Chauffage au bois'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Chauffage au bois'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label> Bois </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Pompe à chaleur'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Pompe à chaleur'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label>
                                                Pompe à chaleur
                                            </ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-checkbox
                                                class="omedom-checkBox"
                                                (ionChange)="
                                                    toggleAmenities(
                                                        'Climatiseur réversible'
                                                    )
                                                "
                                                [checked]="
                                                    isSelectedAmenities(
                                                        'Climatiseur réversible'
                                                    )
                                                "
                                            ></ion-checkbox>
                                            <ion-label>
                                                Climatiseur réversible
                                            </ion-label>
                                        </ion-item>
                                    </div>
                                </div>
                            </div>
                        }
                    </form>
                }
            }
        </ng-container>
    }
</div>
<!-- end form if choose to create a new property--->

@if (activeStep === 1) {
    <div class="form-stepper-button-container">
        @if (!firstProperty) {
            <button
                class="flex-1 omedom-button omedom-button-secondary"
                (click)="exitClicked(alertLabel ?? '', 'uil uil-home', null)"
            >
                Annuler
            </button>
        }
        <button
            class="flex-1 omedom-button omedom-button-green"
            (click)="submit()"
            [disabled]="
                detailForm?.invalid ||
                settingsForm?.invalid ||
                purchaseForm?.invalid ||
                (pending$.asObservable() | async)
            "
        >
            <i class="uil uil-save"></i>
            <span>Enregistrer</span>
            @if (pending$.asObservable() | async) {
                <ion-spinner
                    color="primary"
                    class="form-upload-spinner"
                ></ion-spinner>
            }
        </button>
    </div>
}

<ion-modal
    #modal
    [breakpoints]="[0, 1]"
    initialBreakpoint="1"
    [canDismiss]="true"
>
    <ng-template>
        <div class="omedom-modal">
            <div class="omedom-modal-header">
                <div class="omedom-modal-header-title">
                    <i
                        class="uil uil-import color-light-black background-color-light-green"
                    ></i>
                    @if (parentPropertyUID) {
                        <h3>Importer un bien dans l'immeuble</h3>
                    }
                    @if (societyUID) {
                        <h3>Importer un bien dans la société</h3>
                    }
                </div>
                <i class="uil uil-times-circle" (click)="modal.dismiss()"></i>
            </div>
            <div class="omedom-modal-content">
                <omedom-info information="Veuillez choisir un bien à importer.">
                </omedom-info>
                <omedom-select
                    [(ngModel)]="importedExistingPropertyUID"
                    label="Bien"
                    class="margin-top-1"
                    [placeholder]="propertiesPlaceholder"
                    [options]="propertiesOptions"
                ></omedom-select>
            </div>
            <div class="omedom-sticky-button-container w-100 margin-top-2">
                <button
                    class="omedom-button-secondary"
                    (click)="modal.dismiss()"
                >
                    Annuler
                </button>
                <button
                    class="omedom-button-green margin-left-2"
                    (click)="importProperty()"
                    [disabled]="
                        !importedExistingPropertyUID ||
                        (pending$.asObservable() | async)
                    "
                >
                    <i class="uil uil-import"></i>
                    Importer
                    @if (pending$.asObservable() | async) {
                        <ion-spinner
                            color="primary"
                            class="omedom-upload-spinner"
                        ></ion-spinner>
                    }
                </button>
            </div>
        </div>
    </ng-template>
</ion-modal>
