import { Timestamp } from 'firebase/firestore';

import { NotificationEventName, PostalAddress } from '../interfaces';
import { RestEntity } from './rest.entity';

export interface EventNotification {
    name: NotificationEventName;
    title: string;
    push: boolean;
    email: boolean;
}

// export interface QualityShared {
//     uid: string;
//     quality: QualityEnum;
// }

export interface MultiFactor {
    email?: {
        enabled?: boolean;
        validated?: boolean;
        code?: string;
    };
    phone?: {
        enabled?: boolean;
        validated?: boolean;
        code?: string;
    };
}

export interface FinishedTutorials {
    home?: boolean;
    property?: boolean;
    desktopFirstVisit?: boolean;
    desktopNoelContest?: boolean;
}

export interface EmailVerification {
    code?: string;
    attempts?: number;
}

export interface MissingPassword {
    code?: string;
    date?: Date;
}

export interface Popup {
    name?: string;
    version?: number;
    type: PopupTypes;
    isValidated: boolean;
    keyDate?: Date;
}

/**
 * @description dom represent dom point the user had when the the eventDom is trigger, like an historic
 * @author ANDRE Felix
 * @export
 * @interface EventDom
 */
export interface EventDom {
    dom: number;
    date: Date;
    name: string;
}

export interface Connections {
    date: Timestamp;
    count: number;
}

export interface NotificationSettings {
    android?: string;
    ios?: string;
    web?: string;
}

export interface accountDeletingInfo {
    dateToDelete: string;
    signature: string;
    publicKey: string;
}

/**
 * @description User Entity
 * @author Jérémie Lopez
 * @export
 * @class UserEntity
 * @extends {RestEntity}
 */
export class UserEntity extends RestEntity {
    /**
     * @description Email of the user
     * @author Jérémie Lopez
     * @type {string}
     * @memberof UserEntity
     */
    public email!: string;

    /**
     * @description Name of the user
     * @author Jérémie Lopez
     * @type {string}
     * @memberof UserEntity
     */
    public name!: string;

    /**
     * @description Name of the user
     * @author Jérémie Lopez
     * @type {string}
     * @memberof UserEntity
     */
    public firstname!: string;

    /**
     * @description Boolean to know if the user receive newsletter
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof UserEntity
     */
    public newsletter!: boolean;

    /**
     * @description Roles of the user
     * @author Jérémie Lopez
     * @type {RoleEnum[]}
     * @memberof UserEntity
     */
    public role!: RoleEnum[];

    /**
     * @description Code of the user to share with people
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 29/01/2025
     * @type {string}
     * @memberof UserEntity
     */
    public referralCode!: string;

    /**
     * @description UID of the user who referred the current user to the app
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 29/01/2025
     * @type {string}
     * @memberof UserEntity
     */
    public referralUserUID?: string;

    /**
     * @description List of properties owning by aggregation
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof UserEntity
     */
    public propertiesUID?: string[];

    /**
     * @description List of societies owning by aggregation
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof UserEntity
     */
    public societiesUID?: string[];

    /**
     * @description List of bank accounts owning by aggregation
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof UserEntity
     */
    public accountsUID?: string[];

    /**
     * @description List of documents owning by aggregation
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof UserEntity
     */
    public documentsUID?: string[];

    /**
     * @description List of shared properties with the user by aggregation
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof UserEntity
     */
    public sharedPropertiesUID?: string[];

    /**
     * @description List of quality for each shared property
     * @author Jérémie Lopez
     * @type {QualityShared[]}
     * @memberof UserEntity
     */
    // public qualitySharedProperties?: QualityShared[];

    /**
     * @description List of shared societies with the user by aggregation
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof UserEntity
     */
    public sharedSocietiesUID?: string[];

    /**
     * @description List of member societies with the user by aggregation
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof UserEntity
     */
    public memberSocietiesUID?: string[];

    /**
     * @description List of quality for each shared society
     * @author Jérémie Lopez
     * @type {QualityShared[]}
     * @memberof UserEntity
     */
    // public qualitySharedSocieties?: QualityShared[];

    /**
     * @description Gaol of the user in the app
     * @author Jérémie Lopez
     * @type {GoalEnum}
     * @memberof UserEntity
     */
    public goal?: GoalEnum;

    /**
     * @description Url of the avatar picture
     * @author Jérémie Lopez
     * @type {string}
     * @memberof UserEntity
     */
    public avatar?: string;

    /**
     * @description Notification settings of the user
     * @author Jérémie Lopez
     * @type {NotificationSettings}
     * @memberof UserEntity
     */
    public notification?: NotificationSettings;

    /**
     * @description Enable all notification type
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof UserEntity
     */
    public notificationsEnabled?: boolean;

    /**
     * @description Enable push notification
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof UserEntity
     */
    public notificationPushEnabled?: boolean;

    /**
     * @description Enable email notification
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof UserEntity
     */
    public notificationEmailEnabled?: boolean;

    /**
     * @description List of event notification enabled
     * @author Jérémie Lopez
     * @type {EventNotification[]}
     * @memberof UserEntity
     */
    public notificationsEvent?: EventNotification[];

    /**
     * @description Tel number
     * @author Jérémie Lopez
     * @type {string}
     * @memberof UserEntity
     */
    public tel?: string;

    /**
     * @description Multifactor authentification enable for the user (email, sms)
     * @author Brisset Killian
     * @date 16/04/2024
     * @type {MultiFactor}
     * @memberof UserEntity
     */
    public multiFactor?: MultiFactor;

    /**
     * @description Birthdate of the user
     * @author Jérémie Lopez
     * @type {Date}
     * @memberof UserEntity
     */
    public birthdate?: string;

    /**
     * @description Address of the user
     * @author Jérémie Lopez
     * @type {PostalAddress}
     * @memberof UserEntity
     */
    public address?: PostalAddress;

    /**
     * @description If the user is logged in with provider
     * @author Jérémie Lopez
     * @type {ProviderEnum}
     * @memberof UserEntity
     */
    public provider?: ProviderEnum;

    /**
     * @description If true, the user has confirmed infos after register with provider
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof UserEntity
     */
    public confirmedInformation?: boolean;

    /**
     * @description If true, the user has passed the onboarding process
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof UserEntity
     */
    public tutorialFinished?: boolean;

    /**
     * @description A list of all the app tutorials, if true the tutorial has been passed.
     * @author Hanane Djeddal
     * @type {FinishedTutorials}
     * @memberof UserEntity
     */
    public finishedTutorials?: FinishedTutorials;

    /**
     * @description If true, the user has passed the onboarding process
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof UserEntity
     */
    public firstVisit?: boolean;

    /**
     * @description If true, the user's redirect on dashboard
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {boolean}
     * @memberof UserEntity
     */
    public firstProperty?: boolean;

    /**
     * @description If true, the user's redirect on dashboard
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {EmailVerification}
     * @memberof UserEntity
     */
    public emailVerification?: EmailVerification;

    /**
     * @description If true, the user's redirect on dashboard
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {Popup[]}
     * @memberof UserEntity
     */
    public popups?: Popup[];

    /**
     * @description List of dates of CGV validations
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {string}
     * @memberof UserEntity
     */
    public cgvValidatedVersion?: string;

    /**
     * @description ID of the associated subscription created in the app.
     * @author Jérémie Lopez
     * @type {string}
     * @memberof UserEntity
     */
    public subscriptionUID?: string;

    /**
     * @description ID of the associated user account created in bridge.
     *
     * @type {string}
     * @memberof UserEntity
     */
    public bridgeUUID?: string;

    /**
     * @description Current number of dom of the user
     * @author Jérémie Lopez
     * @type {number}
     * @memberof UserEntity
     */
    public dom?: number;

    /**
     * @description List of dom events of the user
     * @author Jérémie Lopez
     * @type {EventDom[]}
     * @memberof UserEntity
     */
    public eventsDom?: EventDom[];

    /**
     * @description Connexion infos of the user
     * @author Jérémie Lopez
     * @type {Connexion}
     * @memberof UserEntity
     */
    public connections?: Connections;

    /**
     * @description If the user is a pro
     * @author Jérémie Lopez
     * @type {string}
     * @memberof UserEntity
     */
    public proUID?: string;

    /**
     * @description Preference of displaying properties and societies
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {boolean}
     * @memberof UserEntity
     */
    public displayPropertyList?: boolean;

    /**
     * @description Plateform where the user has registered the app
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {boolean}
     * @memberof UserEntity
     */
    public registryPlatform?: AppPlatforms;

    /**
     * @description Storage used by the user in the app (in bytes) (default: 0)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/09/2023
     * @type {number}
     * @memberof UserEntity
     */
    public storageUsed?: number;

    /**
     * @description If the user use a pro licence to accept share conditions with pro (default: false)
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {boolean}
     * @memberof UserEntity
     */
    public shareWithProConditions?: boolean;

    /**
     * @description If the user has a temporary password (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/10/2023
     * @type {boolean}
     * @memberof UserEntity
     */
    public hasTemporaryPassword?: boolean;

    /**
     * @description Missing password infos for the user to reset it on the app
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/06/2024
     * @type {MissingPassword}
     * @memberof UserEntity
     */
    public missingPassword?: MissingPassword;

    /**
     * @description Use after user delete his account, it indicate the remaining time before deleting it
     * @author ANDRE Felix
     * @type {RangeEnergyNumberMeasure}
     * @memberof UserEntity
     */
    public accountDeleting?: accountDeletingInfo;

    constructor(data: Partial<UserEntity>) {
        super(data);

        // If missing password exist, check if date is a Timestamp and convert it to a Date
        if (data.missingPassword) {
            const date: any = data.missingPassword.date;

            this.missingPassword = {
                code: data.missingPassword.code,
                date: date.seconds ? new Date(date.seconds * 1000) : date,
            };
        }
    }
}

/**
 * @description Role possible for a user
 * @author Jérémie Lopez
 * @export
 * @enum {number}
 */
export enum RoleEnum {
    admin = 'admin',
    customer = 'customer',
    pro = 'pro',
}

/**
 * @description Goal of user to use the app
 * @author Jérémie Lopez
 * @export
 * @enum {number}
 */
export enum GoalEnum {
    financial = 'Mieux suivre les finances de mon patrimoine',
    documents = 'Centraliser mes documents liés à mon patrimoine',
    transmission = 'Faciliter la transmission de mon patrimoine',
    easy = 'Simplifier la communication avec un tiers',
    other = 'Aucun des quatre',
}

/**
 * @description Provider enable for the app
 * @author Jérémie Lopez
 * @export
 * @enum {number}
 */
export enum ProviderEnum {
    google = 'Google',
    apple = 'Apple',
}
export type ProviderKeysType = keyof typeof ProviderEnum;

/**
 * @description Quality of the shared property
 * @author Jérémie Lopez
 * @export
 * @enum {number}
 */
export enum QualityEnum {
    particular = 'Particulier',
    professional = 'Professionnel',
}

/**
 * @description Types of possible popup
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @export
 * @enum {number}
 */
export enum PopupTypes {
    offer = 'offer',
    redirection = 'redirection',
}

/**
 * @description Platform where the applicaton is available
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @export
 * @enum {number}
 */
export enum AppPlatforms {
    android = 'Android',
    ios = 'iOS',
    web = 'Web',
}
