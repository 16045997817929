@if (society) {
    <omedom-modal-header>{{ society.name }}</omedom-modal-header>
    <omedom-modal-tab [tabs]="tabs"></omedom-modal-tab>
    <div class="container">
        <ng-container *ngTemplateOutlet="getTemplate()"></ng-container>
    </div>
} @else {
    <omedom-modal-header>Chargement...</omedom-modal-header>
    <div class="container">
        <div class="loading-container">
            <ion-spinner></ion-spinner>
        </div>
    </div>
}

<ng-template #loading>
    <omedom-modal-header>Chargement...</omedom-modal-header>

    <div class="container">
        <div class="loading-container">
            <ion-spinner></ion-spinner>
        </div>
    </div>
</ng-template>

<!-- Informations -->
<ng-template #info>
    <omedom-society-profil-info
        [society]="society"
    ></omedom-society-profil-info>
</ng-template>

<!-- Tresorerie -->
<ng-template #treasury>
    <omedom-society-profil-treasury
        [society]="society"
    ></omedom-society-profil-treasury>
</ng-template>

<!-- Documents -->
<ng-template #documents>
    <omedom-society-profil-documents
        [society]="society"
        [client]="client"
        (addDocument)="addDocument()"
        (editDocument)="editDocument($event)"
    ></omedom-society-profil-documents>
</ng-template>
