import { DPELevelType } from '../types';

export const ghgEmissionLevels: DPELevelType[] = [
    { letter: 'A', color: '#E4C1F9', active: false, min: 0, max: 7 },
    { letter: 'B', color: '#C39BD3', active: false, min: 8, max: 12 },
    { letter: 'C', color: '#9B59B6', active: false, min: 13, max: 32 },
    { letter: 'D', color: '#8E44AD', active: false, min: 33, max: 52 },
    { letter: 'E', color: '#6C3483', active: false, min: 53, max: 73 },
    { letter: 'F', color: '#5B2C6F', active: false, min: 74, max: 103 },
    { letter: 'G', color: '#4A235A', active: false, min: 104, max: Math.min() },
];

export const energyConsumptionLevels: DPELevelType[] = [
    { letter: 'A', color: '#428f3d', active: false, min: 0, max: 81 },
    { letter: 'B', color: '#7aac5c', active: false, min: 82, max: 121 },
    { letter: 'C', color: '#acca51', active: false, min: 122, max: 210 },
    { letter: 'D', color: '#ffcf4f', active: false, min: 211, max: 280 },
    { letter: 'E', color: '#fca43a', active: false, min: 281, max: 363 },
    { letter: 'F', color: '#f35c23', active: false, min: 364, max: 454 },
    { letter: 'G', color: '#cf181e', active: false, min: 455, max: Math.min() },
];
