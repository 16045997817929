import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { SubscriptionRank } from '@omedom/data';
import { lastValueFrom } from 'rxjs';

@Component({
    templateUrl: './subscription-error.component.html',
    styleUrls: ['./subscription-error.component.scss'],
})
export class SubscriptionErrorComponent implements OnInit {
    @Input({ required: true }) subscription: SubscriptionRank = SubscriptionRank.PREMIUM;
    constructor(
        private readonly functions: AngularFireFunctions,
        private readonly modalController: ModalController,
        private readonly router: Router,
        private readonly toastController: ToastController
    ) { }

    async ngOnInit(): Promise<void> {
        const callable = this.functions.httpsCallable('sendPurchasecancelledEmail');
        const result = await lastValueFrom(callable({}));
        if (result?.message) {
            const toast = await this.toastController.create({
                position: 'top',
                message: 'Abonnement mis à jour avec succès.',
                duration: 3000,
                color: 'success',
            });
            toast.present();
        }
    }

    protected dismiss(): void {
        this.modalController.dismiss();
    }

    protected async retry(): Promise<void> {
        await this.modalController.dismiss();

        const queryParams = {
            menu: 'subscription',
            tab: 'manage',
        };
        this.router.navigate(['/menu'], {
            queryParams,
        });
    }
}
