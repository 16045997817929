import { Component, Input, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { SubscriptionDto, SubscriptionRank, UserEntity } from '@omedom/data';
import { SubscriptionService } from '@omedom/services';
import { PopoverRedirectionButtonInterface } from '..';

@Component({
    selector: 'omedom-subscription-countdown',
    templateUrl: './subscription-countdown.component.html',
    styleUrls: ['./subscription-countdown.component.scss'],
})
export class SubscriptionCountdownComponent implements OnInit {
    @Input({ required: true }) user!: UserEntity;

    @Input({ required: true }) isInApp!: boolean;

    /**
     * @description If user is in trial to display countdown
     * @author ANDRE Felix
     * @memberof SubscriptionCountdownComponent
     */
    isInTrial = false;

    /**
     * @description If the user is in essential
     * @author ANDRE Felix
     * @memberof SubscriptionCountdownComponent
     */
    isInEssential = false;

    /**
     * @description has the subscription expired
     * @author ANDRE Felix
     * @memberof SubscriptionCountdownComponent
     */
    hasExpired = false;

    /**
     * @description Number of day between end of trial
     * @author ANDRE Felix
     * @type {number}
     * @memberof SubscriptionCountdownComponent
     */
    dayCountodownTrialEnd?: number;

    /**
     * @description content to display, it contained information about countdown before premium expiration.
     * @author ANDRE Felix
     * @memberof SubscriptionCountdownComponent
     */
    content = '';

    /**
     * @description Subscription of the user
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 17/02/2025
     * @private
     * @memberof SubscriptionCountdownComponent
     */
    private readonly sub = this.subscriptionService.subscription();

    protected readonly redirectionButton: PopoverRedirectionButtonInterface = {
        router: {
            route: ['tabs/menu/subscription'],
            params: { queryParams: { tab: 'manage' } },
        },
        text: 'Changer mon abonnement',
    };


    constructor(
        private readonly subscriptionService: SubscriptionService,
        private readonly toastController: ToastController,
    ) { }

    ngOnInit() {
        if (!this.sub) {
            return;
        }
        this.checkSubscriptionRank(this.sub);

        if (!this.isInTrial && !this.isInEssential) {
            return;
        }
        this.setDataToDisplay(this.sub);
        const isContentDisplayed = this.isInApp && !!this.dayCountodownTrialEnd;

        if (isContentDisplayed) {
            this.displayAlertMessage(this.dayCountodownTrialEnd);
        }

    }

    private checkSubscriptionRank(subscription: SubscriptionDto) {
        this.isInTrial = this.checkTrial(subscription);
        this.isInEssential = this.checkEssential(subscription);
    }

    private checkTrial(subscription: SubscriptionDto) {
        if (subscription.rank === SubscriptionRank.TRIAL) {
            return true;
        }
        return false;
    }

    private checkEssential(subscription: SubscriptionDto) {
        if (subscription.rank === SubscriptionRank.ESSENTIAL) {
            return true;
        }
        return false;
    }

    private setDataToDisplay(subscription: SubscriptionDto) {
        this.dayCountodownTrialEnd = this.calculEndTrialCountdown(subscription);
        this.content = this.setContent(this.dayCountodownTrialEnd);
    }

    private calculEndTrialCountdown(subscription: SubscriptionDto) {
        const renewDateMs = new Date(subscription.renewDate).getTime();
        const todayMs = new Date().getTime();
        const diffInMs = renewDateMs - todayMs;
        const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
        return diffInDays;
    }

    private setContent(trialCountdown: number | undefined) {
        if ((!this.isInTrial && !this.isInEssential) || !trialCountdown) {
            return '';
        }
        this.hasExpired = trialCountdown <= 0;

        if (this.hasExpired) {
            return `Votre offre découverte est terminée, passez à l'offre premium pour profiter de toutes les fonctionnalités`;
        } else {
            return `Votre offre découverte se termine dans ${this.dayCountodownTrialEnd} jour(s)`;
        }
    }

    private async displayAlertMessage(dayCountodownTrialEnd: number | undefined) {
        if (!dayCountodownTrialEnd || !this.isInApp) {
            return;
        }

        const toast = await this.toastController.create({
            message: this.content,
            duration: 4000,
            position: 'top',
            color: 'warning',
        });

        toast.present();
    }
}
