import 'cordova-plugin-purchase';

import { Component, Input, OnDestroy, OnInit, Signal } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import {
    BillingEntityTypeEnum,
    BillingInformation,
    ConfigurationEntity,
    FamilyDto,
    PaymentEntity,
    ProEntity,
    Sharing,
    SubscriptionDto,
    SubscriptionEntity,
    SubscriptionRank,
    SubscriptionType,
    UserEntity,
    UserFamilyInvitation,
} from '@omedom/data';
import {
    AnalyticsService,
    ConfigurationService,
    FamilyService,
    PaymentService,
    PropertyService,
    ProService,
    SubscriptionService,
    UserService,
} from '@omedom/services';
import { OmedomRegex } from '@omedom/utils';
import { BehaviorSubject, combineLatest, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { elementAnimation, listAnimation } from '../../../animations';
import { CgvComponent, OmedomRadioOption, ShareFormModalComponent } from '../../../components';
import { OmedomPayplugNumberToPricePipe } from '../../../pipes';
import {
    SubscriptionConfirmCancellationComponent,
} from '../subscription-confirm-cancellation/subscription-confirm-cancellation.component';
import { SubscriptionRedirectAppleComponent } from '../subscription-redirect-apple/subscription-redirect-apple.component';
import { CurrencyPipe } from '@angular/common';

enum ShowPage {
    subscription = 'subscription',
    manage = 'manage',
    history = 'history',
    changeSubscription = 'change-subscription',
    familyInvitation = 'family-invitation',
    family = 'family',
}

export interface IBillingForm {
    address: FormControl<string | null>;
    addressLine2: FormControl<string | null>;
    city: FormControl<string>;

    postalCode: FormControl<string>;

    billingEntityType: FormControl<BillingEntityTypeEnum>;

    billingEmail: FormControl<string>;

    subscriptionType: FormControl<SubscriptionType>;
}

export interface ICompanyForm {
    billingCompanyName: FormControl<string | null>;
}

export interface IIndividualForm {
    billingFirstname: FormControl<string>;
    billingName: FormControl<string>;
}

@Component({
    selector: 'omedom-subscription-menu',
    templateUrl: './subscription-menu.component.html',
    styleUrls: ['./subscription-menu.component.scss'],
    animations: [elementAnimation, listAnimation],
})
export class SubscriptionMenuComponent implements OnInit, OnDestroy {
    @Input({ required: true }) environment!: { cgvCurrentDate?: string };
    private cgvCurrentDate: string = '';

    public payments: PaymentEntity[] = [];
    public isAppleDevice: boolean = false;

    protected pending$ = new BehaviorSubject<boolean>(false);

    private readonly now = new Date();

    protected family?: FamilyDto;

    protected isLoading: boolean = true;

    protected isAddMemberLoading: boolean = true;

    protected subscription: Signal<SubscriptionDto | undefined> =
        this.subscriptionService.subscription;

    public showPages = ShowPage;

    public showPage: ShowPage = ShowPage.subscription;

    public selectedRank: SubscriptionRank = SubscriptionRank.ESSENTIAL;

    public selectedPeriod: SubscriptionType = SubscriptionType.ANNUALLY;

    /**
     * @description: Allows to switch the icon at the header
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @memberof SubscriptionPage
     */
    private user?: UserEntity;
    protected userConfiguration?: ConfigurationEntity;
    protected configurations: ConfigurationEntity[] = [];
    protected familyInvitations: UserFamilyInvitation[] = [];
    protected readonly subscriptionRank = SubscriptionRank;
    protected readonly SubscriptionType = SubscriptionType;
    protected subscriptionPeriodicity: SubscriptionType = SubscriptionType.ANNUALLY;
    protected subscriptionTypeOptions: OmedomRadioOption[] = [];

    /**
     * @description: Billing entity type options for the radio button in the form
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 31/01/2025
     * @type {OmedomRadioOption[]}
     * @memberof MenuSubscriptionComponent
     */
    protected billingEntityTypeOptions: OmedomRadioOption[] = [];

    /**
     * @description Billing entity type enum for the radio button in the form
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 31/01/2025
     * @memberof MenuSubscriptionComponent
     */
    protected billingEntityTypeEnum = BillingEntityTypeEnum;

    /**
     * @description Billing informations for the subscription
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 31/01/2025
     * @type {BillingInformation}
     * @memberof MenuSubscriptionComponent
     */
    protected billingInformations: BillingInformation = {
        address: '',
        addressLine2: '',
        postalCode: '',
        city: '',
        billingFirstname: '',
        billingName: '',
        billingCompanyName: '',
        billingEntityType: BillingEntityTypeEnum.individual,
        billingEmail: '',
    };

    /**
     * @description: Regex for postal code (french postal code format only) to verify if postal code is a 5 digits number
     * @author Killian Brisset
     * @type {RegExp}
     * @memberof SubscriptionTogglePage
     */
    public postalCodeRegex: RegExp = OmedomRegex.postalCodeRegex;

    public pro$: Observable<ProEntity | undefined> = of(undefined);

    /**
     * @description: Check if user buy a new subscription or only update his billing informations
     * @author Didier Pascarel
     * @memberof SubscriptionTogglePage
     * @type {boolean}
     */
    private readonly subcriptions: Subscription[] = [];

    protected currentConfiguration?: ConfigurationEntity;

    protected billingForm!: FormGroup<IBillingForm>;

    protected individualForm!: FormGroup<IIndividualForm>;

    protected companyForm!: FormGroup<ICompanyForm>;

    get disableSubmit(): boolean {
        const billingEntityType = this.billingForm?.get('billingEntityType')?.value;
        return (
            this.pending$.value ||
            !this.billingForm?.valid ||
            !billingEntityType ||
            (billingEntityType === BillingEntityTypeEnum.individual &&
                !this.individualForm?.valid) ||
            (billingEntityType === BillingEntityTypeEnum.company && !this.companyForm?.valid)
        );
    }

    /**
     * @description Check if the user is on an Apple device or not to display the right content for the user
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 03/02/2025
     * @memberof MenuSubscriptionComponent
     */
    isOnApple = false;

    /**
     * @description address of the user to display in the form if the user has not already filled it in his profile
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 03/02/2025
     * @memberof MenuSubscriptionComponent
     */
    private address = '';

    public products: CdvPurchase.Product[] = [];

    private store: CdvPurchase.Store = CdvPurchase.store;

    constructor(
        private readonly modalController: ModalController,
        private readonly toastController: ToastController,
        private readonly route: ActivatedRoute,
        private readonly platform: Platform,
        private readonly functions: AngularFireFunctions,
        private readonly subscriptionService: SubscriptionService,
        private readonly configurationService: ConfigurationService,
        private readonly propertyService: PropertyService,
        private readonly paymentService: PaymentService,
        private readonly familyService: FamilyService,
        private readonly userService: UserService,
        private readonly analyticsService: AnalyticsService,
        private readonly payplugNumberToPricePipe: OmedomPayplugNumberToPricePipe,
        private readonly proService: ProService,
        private readonly currencyPipe: CurrencyPipe,
    ) { }

    async ngOnInit() {
        if (this.environment['cgvCurrentDate']) {
            this.cgvCurrentDate = this.environment['cgvCurrentDate'];
        }
        await this.platform.ready();

        this.isOnApple = this.platform.is('ios') || this.isMac();
        this.isAppleDevice = this.platform.is('ios') && this.platform.is('cordova');
        const userConfiguration$ = this.configurationService.getUserConfiguration();
        const configurations$ = this.configurationService.getConfigurations();
        const familyInvitations$ = this.familyService.getUserInvitations();
        const family$ = this.familyService.getFamily();

        const user$ = this.userService.user$;

        const proUID = this.subscription()?.proUID;
        this.pro$ = proUID ? this.proService._get(proUID) : of(undefined);
        const subcription$ = combineLatest([
            userConfiguration$,
            configurations$,
            familyInvitations$,
            user$,
            family$,
        ]).subscribe(
            async ([userConfiguration, configurations, familyInvitations, user, family]) => {
                this.user = user;
                this.payments = await this.paymentService.getPaymentsByUser(this.user.uid);
                this.userConfiguration = userConfiguration;

                this.address =
                    (user.address?.streetNumber ? user.address?.streetNumber + ' ' : '') +
                    (user.address?.suffixNumber ? user.address?.suffixNumber + ' ' : '') +
                    (user.address?.street ? user.address?.street + ' ' : '');

                const ranksNewUser: string[] = [
                    SubscriptionRank.ESSENTIAL,
                    SubscriptionRank.TRIAL,
                    SubscriptionRank.SMART,
                ];

                const rankAllwaysShow: string[] = [SubscriptionRank.ESSENTIAL];

                this.configurations = configurations
                    .filter((config) => config.isActive)
                    .filter(
                        (config) =>
                            config.docName !== userConfiguration.docName ||
                            (config.docName && rankAllwaysShow.includes(config.docName)),
                    )
                    .filter((config) => {
                        if (config.isOnlyForNewUser && userConfiguration.docName) {
                            return ranksNewUser.includes(userConfiguration.docName);
                        }
                        return true;
                    })
                    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

                if (!this.configurations.includes(this.userConfiguration)) {
                    this.configurations.unshift(this.userConfiguration);
                }

                this.familyInvitations = familyInvitations;
                this.isLoading = false;
                this.family = family;

                this.billingForm = new FormGroup<IBillingForm>({
                    address: new FormControl(
                        (this.subscription()?.billingInformations?.address || this.address) ?? null,
                        {
                            validators: [Validators.required],
                        },
                    ),
                    addressLine2: new FormControl(
                        (this.subscription()?.billingInformations?.addressLine2 ||
                            user.address?.addressLine2) ??
                        null,
                    ),
                    city: new FormControl(
                        (this.subscription()?.billingInformations?.city || user.address?.city) ??
                        '',
                        {
                            validators: [Validators.required],
                            nonNullable: true,
                        },
                    ),
                    postalCode: new FormControl(
                        (this.subscription()?.billingInformations?.postalCode ||
                            user.address?.postalCode) ??
                        '',
                        {
                            validators: [Validators.required],
                            nonNullable: true,
                        },
                    ),

                    billingEntityType: new FormControl(
                        this.subscription()?.billingInformations?.billingEntityType ??
                        BillingEntityTypeEnum.individual,

                        {
                            validators: [Validators.required],
                            nonNullable: true,
                        },
                    ),
                    billingEmail: new FormControl(
                        (this.subscription()?.billingInformations?.billingEmail || user.email) ??
                        '',
                        {
                            validators: [Validators.required],
                            nonNullable: true,
                        },
                    ),
                    subscriptionType: new FormControl(
                        this.subscription()?.subscriptionType ?? SubscriptionType.ANNUALLY,
                        {
                            validators: [Validators.required],
                            nonNullable: true,
                        },
                    ),
                });

                this.individualForm = new FormGroup<IIndividualForm>({
                    billingFirstname: new FormControl(
                        (this.subscription()?.billingInformations?.billingFirstname ||
                            user.firstname) ??
                        '',
                        { nonNullable: true },
                    ),
                    billingName: new FormControl(
                        (this.subscription()?.billingInformations?.billingName || user.name) ?? '',
                        { nonNullable: true },
                    ),
                });

                this.companyForm = new FormGroup<ICompanyForm>({
                    billingCompanyName: new FormControl(
                        this.subscription()?.billingInformations?.billingCompanyName ?? null,
                        {
                            validators: [Validators.required],
                        },
                    ),
                });

                this.billingInformations = {
                    address:
                        (this.subscription()?.billingInformations?.address || this.address) ?? '',
                    addressLine2:
                        (this.subscription()?.billingInformations?.addressLine2 ||
                            user.address?.addressLine2) ??
                        '',
                    city:
                        (this.subscription()?.billingInformations?.city || user.address?.city) ??
                        '',
                    postalCode:
                        (this.subscription()?.billingInformations?.postalCode ||
                            user.address?.postalCode) ??
                        '',
                    billingFirstname:
                        this.subscription()?.billingInformations?.billingFirstname ||
                        user.firstname,
                    billingName: this.subscription()?.billingInformations?.billingName || user.name,
                    billingCompanyName:
                        this.subscription()?.billingInformations?.billingCompanyName ?? '',
                    billingEntityType:
                        this.subscription()?.billingInformations?.billingEntityType ||
                        BillingEntityTypeEnum.individual,
                    billingEmail:
                        this.subscription()?.billingInformations?.billingEmail || user.email,
                };
                this.pending$.next(false);
            },
        );

        const tab$ = this.route.queryParams.subscribe((params) => {
            if (params['tab'] && Object.values(ShowPage).includes(params['tab'])) {
                this.showPage = params['tab'] as ShowPage;
            }
        });

        this.subcriptions.push(subcription$, tab$);
    }

    async initApple() {
        await this.platform.ready();
        this.isAppleDevice = this.platform.is('ios') && this.platform.is('cordova');

        if (this.isAppleDevice) {
            await this.registerAppleProducts();
            this.setupAppleListeners();

            this.store.ready(() => {
                this.products = this.store.products;
            });
        }
    }

    ngOnDestroy(): void {
        this.subcriptions.forEach((sub) => sub.unsubscribe());
    }

    async ionViewDidEnter(): Promise<void> {
        await this.analyticsService.setCurrentScreen('Subscription Page');
    }

    private async registerAppleProducts() {
        if (!this.currentConfiguration?.appleIds) {
            return;
        }
        try {
            const { ProductType, Platform } = CdvPurchase;
            const appleIds: CdvPurchase.IRegisterProduct[] = Object.values(
                this.currentConfiguration.appleIds,
            ).map((appleId) => {
                return {
                    id: appleId,
                    type: ProductType.PAID_SUBSCRIPTION,
                    platform: Platform.APPLE_APPSTORE,
                    group: '21171479',
                };
            });
            this.store.register(appleIds);

            await this.store.initialize([Platform.APPLE_APPSTORE]);
        } catch (error) {
            console.error('Error while registering Apple products: ', error);
        }
    }

    private setupAppleListeners() {
        this.store.when().approved((transaction) => {
            if (transaction.transactionId !== 'appstore.application') {
                this.finishAppleTransaction(transaction);
            }
        });
    }

    private async finishAppleTransaction(transaction: CdvPurchase.Transaction) {
        const selectedSubscriptionType = this.billingForm?.get('subscriptionType')?.value as
            | SubscriptionType
            | undefined;

        if (!selectedSubscriptionType || !this.currentConfiguration?.price || !this.user) {
            return;
        }

        try {
            let subscriptionNumberOfmonths: number = 1;
            switch (selectedSubscriptionType) {
                case SubscriptionType.MONTHLY:
                    subscriptionNumberOfmonths = 1;
                    break;
                case SubscriptionType.ANNUALLY:
                    subscriptionNumberOfmonths = 12;
                    break;
                default:
                    subscriptionNumberOfmonths = 1;
                    break;
            }

            const renewDate = this.now.addMonths(subscriptionNumberOfmonths);
            const propertiesUID = (await this.propertyService.getUserProperties(this.user.uid)).map(
                (p) => p.uid,
            );
            const rank = this.currentConfiguration.docName as SubscriptionRank;
            const description = `Abonnement OMEDOM - ${rank.charAt(0).toUpperCase() + rank.slice(1)
                } 1 ${selectedSubscriptionType === SubscriptionType.MONTHLY ? 'mois' : 'an'}`;

            const newSubscription: Partial<SubscriptionEntity> = {
                amount: this.currentConfiguration.price[selectedSubscriptionType],
                appleInfos: {
                    transactionId: transaction.transactionId ?? null,
                    // signature: null,
                    // receipt: null,
                    productType: transaction.products?.[0]?.id ?? null,
                },
                card: null,
                currency: 'EUR',
                description,
                isApple: true,
                propertiesUID,
                rank,
                renewDate: `${renewDate.getDate()}/${renewDate.getMonth() + 1
                    }/${renewDate.getFullYear()}`,
                subscriptionType: selectedSubscriptionType,
                transactionId: transaction.transactionId ?? null,
                trial: false,
                userUID: this.user.uid,
            };

            // this.logService.create({
            //     level: LogLevel.debug,
            //     message: `Subscription newSubscription`,
            //     context: {
            //         newSubscription: JSON.stringify(newSubscription),
            //         user: JSON.stringify(this.user),
            //         localisation: 'SubscriptionTogglePage',
            //     },
            // })
            try {
                const subscription = await this.subscriptionService.create(newSubscription);
                // this.logService.create({
                //     level: LogLevel.debug,
                //     message: `Subscription added ends`,
                //     context: {
                //         subscription: JSON.stringify(subscription),
                //         user: JSON.stringify(this.user),
                //         localisation: 'SubscriptionTogglePage',
                //     },
                // })
                try {
                    await this.userService.update({
                        uid: this.user.uid,
                        subscriptionUID: subscription.id,
                    });
                } catch (error) {
                    console.error('error while updating user --- Find why this error', error);
                }
            } catch (error) {
                console.error('error while updating subscription --- Find why this error', error);
            }

            const toast = await this.toastController.create({
                position: 'top',
                message: 'Abonnement mis à jour avec succès.',
                duration: 3000,
                color: 'success',
            });

            toast.present();

            await transaction.finish();

            this.pending$.next(false);

            // this.router.navigate(['/tabs/menu/subscription', { state: 'success' }]);
        } catch (error: any) {
            console.error(error.message);
            const toast = await this.toastController.create({
                position: 'top',
                message:
                    'Une erreur est survenue lors du paiement de votre abonnement, veuillez réessayer.',
                duration: 6000,
                color: 'danger',
            });
            toast.present();

            console.error('Error while creating Apple subscription: ', error);
            this.pending$.next(false);
        }
    }

    /**
     * @description Populate the subscription options for the radio button in the form
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 03/02/2025
     * @returns {*}
     * @memberof MenuSubscriptionComponent
     */
    populateSubscriptionOptions() {
        this.billingEntityTypeOptions = [
            {
                label: 'Personne physique',
                id: BillingEntityTypeEnum.individual,
            },
            {
                label: 'Personne morale',
                id: BillingEntityTypeEnum.company,
            },
        ];
        this.subscriptionTypeOptions = [];
        if (!this.currentConfiguration?.price) {
            return;
        }
        Object.keys(this.currentConfiguration.price).forEach((key) => {
            const subscriptionKey = key as SubscriptionType;
            if (subscriptionKey in SubscriptionType && this.currentConfiguration?.price) {
                if (
                    this.currentConfiguration.docName === SubscriptionRank.BLACKFRIDAY2024 ||
                    this.currentConfiguration.docName === SubscriptionRank.NOEL2024
                ) {
                    this.subscriptionTypeOptions.push({
                        id: subscriptionKey,
                        label:
                            subscriptionKey === SubscriptionType.MONTHLY
                                ? `Mensuel\u00A0:
                                ${this.payplugNumberToPricePipe.transform(
                                    this.currentConfiguration.price[SubscriptionType.MONTHLY],
                                )}\u00A0€\u00A0TTC`
                                : `Offre\u00A012\u00A0mois\u00A0:
                                ${this.payplugNumberToPricePipe.transform(
                                    this.currentConfiguration.price[SubscriptionType.ANNUALLY],
                                )}\u00A0€\u00A0TTC (soit
                                ${this.payplugNumberToPricePipe.transform(
                                    (this.currentConfiguration.price[SubscriptionType.ANNUALLY] ??
                                        0) / 12,
                                )}\u00A0€\u00A0TTC /mois)`,
                    });
                } else {
                    this.subscriptionTypeOptions.push({
                        id: subscriptionKey,
                        label:
                            subscriptionKey === SubscriptionType.MONTHLY
                                ? `Mensuel\u00A0:
                                ${this.payplugNumberToPricePipe.transform(
                                    this.currentConfiguration.price[SubscriptionType.MONTHLY],
                                )}\u00A0€\u00A0TTC`
                                : `Annuel\u00A0:
                                ${this.payplugNumberToPricePipe.transform(
                                    this.currentConfiguration.price[SubscriptionType.ANNUALLY],
                                )}\u00A0€\u00A0TTC (soit
                                ${this.payplugNumberToPricePipe.transform(
                                    (this.currentConfiguration.price[SubscriptionType.ANNUALLY] ??
                                        0) / 12,
                                )}\u00A0€\u00A0TTC\u00A0/mois)`,
                    });
                }
            }
        });
        this.subscriptionTypeOptions.sort((a, b) => {
            if (a.id === SubscriptionType.MONTHLY) return -1;
            if (b.id === SubscriptionType.MONTHLY) return 1;
            return 0;
        });
    }

    paymentHistory() {
        this.showPage = ShowPage.history;
    }

    public async cancelSubscription(): Promise<void> {
        let modal: HTMLIonModalElement;
        const renewDate = new Date(this.subscription()?.renewDate ?? 0);
        if (this.subscription()?.isApple && this.now.getTime() < renewDate.getTime()) {
            modal = await this.modalController.create({
                component: SubscriptionRedirectAppleComponent,
                breakpoints: [0, 1],
                initialBreakpoint: 1,
                componentProps: {
                    cancelSubscription: true,
                },
            });
        } else {
            modal = await this.modalController.create({
                component: SubscriptionConfirmCancellationComponent,
                breakpoints: [0, 1],
                initialBreakpoint: 1,
                componentProps: {
                    subscription: this.subscription(),
                    backUrl: '/menu?menu=subscription',
                },
            });
        }
        await modal.present();

        modal.onDidDismiss().then(() => {
            this.showPage = ShowPage.subscription;
        });
    }

    /**
     * @description Get the number of days remaining before the subscription renewal date to display in the template
     * @author ---
     * @date ---
     * @readonly
     * @type {number}
     * @memberof SubscriptionMenuComponent
     */
    public get count(): number {
        const renewDate = new Date(this.subscription()?.renewDate ?? 0);
        const diff = Math.abs(renewDate.getTime() - this.now.getTime());
        return Math.ceil(diff / (1000 * 3600 * 24));
    }

    /**
     * @description Choose the subscription rank to display the right content for the user in the template
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 05/02/2025
     * @param {SubscriptionRank} $event
     * @memberof SubscriptionMenuComponent
     */
    chooseSubscription($event: SubscriptionRank) {
        const renewDate = new Date(this.subscription()?.renewDate ?? 0);
        if (this.subscription()?.isApple && this.now.getTime() < renewDate.getTime()) {
            this.modalController
                .create({
                    component: SubscriptionRedirectAppleComponent,
                    breakpoints: [0, 1],
                    initialBreakpoint: 1,
                })
                .then((modal) => modal.present())
                .then();
        } else {
            this.showPage = ShowPage.changeSubscription;
            this.selectedRank = $event;

            this.currentConfiguration = this.configurations.find(
                (configuration) => configuration.docName === this.selectedRank,
            );
            this.populateSubscriptionOptions();
            this.initApple();
        }
    }

    manageSubscription() {
        this.showPage = ShowPage.manage;
    }

    manageFamily() {
        this.showPage = ShowPage.family;
    }

    leaveFamily() {
        this.isLoading = true;
        const callable = this.functions.httpsCallable('leaveFamily');
        callable(null).subscribe(async () => {
            await this.subscriptionService.updateLocalSubscription();
            this.isLoading = false;
        });
    }

    shouldDisplayButton(configuration: ConfigurationEntity) {
        switch (configuration.docName) {
            case SubscriptionRank.ESSENTIAL:
                if (this.subscription()?.trial) {
                    return false;
                }
                return configuration.docName !== this.subscription()?.rank;
            default:
                return true;
        }
    }

    public async submit(): Promise<void> {
        this.pending$.next(true);
        // If we don't have a subscription or if we have a trial subscription

        if (this.isSubscribing) {
            // If we are on iOS, subscribe to the product
            if (this.isAppleDevice) {
                const selectedSubscriptionType = this.billingForm?.get('subscriptionType')
                    ?.value as SubscriptionType | undefined;
                if (!this.currentConfiguration?.appleIds || !selectedSubscriptionType) {
                    return;
                }
                const appleId = this.currentConfiguration.appleIds[selectedSubscriptionType];

                if (!appleId) {
                    console.error('Apple id does not exist');
                    this.pending$.next(false);
                    return;
                }

                const appleIdProduct = this.store.get(appleId);

                try {
                    if (!appleIdProduct) {
                        console.info('Apple products', this.store.products);
                        throw new Error('Apple product does not exist');
                    }
                    const offer = appleIdProduct.getOffer();

                    if (!offer) {
                        throw new Error('Offer does not exist');
                    }

                    await this.store.order(offer);

                    this.subscriptionService
                        .updateLocalSubscription()
                        .then(async () => {
                            const toast = await this.toastController.create({
                                position: 'top',
                                message: 'Abonnement mis à jour avec succès.',
                                duration: 3000,
                                color: 'success',
                            });
                            toast.present();
                            this.pending$.next(false);
                            this.showPage = ShowPage.subscription;
                        }).catch(async () => {
                            this.pending$.next(false);
                            const toast = await this.toastController.create({
                                position: 'top',
                                message: 'Une erreur est survenue, veuillez réessayer.',
                                duration: 3000,
                                color: 'danger',
                            });
                            toast.present();
                        });
                    return;
                } catch (error) {
                    console.error(error);
                    this.pending$.next(false);
                    const toast = await this.toastController.create({
                        position: 'top',
                        message: 'Une erreur est survenue, veuillez réessayer.',
                        duration: 3000,
                        color: 'danger',
                    });
                    toast.present();
                    return;
                }
            }

            const callable = this.functions.httpsCallable('newSubscription');

            try {
                const billingInformations: Partial<BillingInformation> = {
                    address: this.billingForm?.get('address')?.value ?? undefined,
                    addressLine2: this.billingForm?.get('addressLine2')?.value ?? undefined,
                    city: this.billingForm?.get('city')?.value,
                    postalCode: this.billingForm?.get('postalCode')?.value,
                    billingFirstname: this.individualForm?.get('billingFirstname')?.value,
                    billingName: this.individualForm?.get('billingName')?.value,
                    billingCompanyName:
                        this.companyForm.get('billingCompanyName')?.value ?? undefined,
                    billingEntityType: this.billingForm?.get('billingEntityType')?.value,
                    billingEmail: this.billingForm?.get('billingEmail')?.value,
                };

                // Clean the billing informations
                Object.keys(billingInformations).forEach((key) => {
                    if (
                        billingInformations[key as keyof Partial<BillingInformation>] === undefined
                    ) {
                        delete billingInformations[key as keyof Partial<BillingInformation>];
                    }
                });

                const result = await lastValueFrom(
                    callable({
                        rank: this.currentConfiguration?.docName,
                        billingInformations,
                        subscriptionType: this.billingForm?.get('subscriptionType')?.value,
                    }),
                );

                if (result?.hosted_payment?.payment_url) {
                    window.location.href = result.hosted_payment.payment_url;
                } else {
                    const toast = await this.toastController.create({
                        position: 'top',
                        message: 'Une erreur est survenue, veuillez réessayer.',
                        duration: 3000,
                        color: 'danger',
                    });
                    toast.present();
                }

                this.pending$.next(false);
            } catch (error: any) {
                console.error(error);
                let toastError: HTMLIonToastElement;
                if (error.code) {
                    switch (error.code) {
                        case 'functions/already-exists':
                            toastError = await this.toastController.create({
                                position: 'top',
                                message: 'Vous avez déjà un abonnement en cours.',
                                duration: 3000,
                                color: 'danger',
                            });
                            toastError.present();
                            break;
                        default:
                            toastError = await this.toastController.create({
                                position: 'top',
                                message: 'Une erreur est survenue, veuillez réessayer.',
                                duration: 3000,
                                color: 'danger',
                            });
                            toastError.present();
                            break;
                    }
                } else {
                    toastError = await this.toastController.create({
                        position: 'top',
                        message: 'Une erreur est survenue, veuillez réessayer.',
                        duration: 3000,
                        color: 'danger',
                    });
                    toastError.present();
                }

                this.pending$.next(false);
                return;
            }
        } else {
            const callable = this.functions.httpsCallable('updateSubscriptionV2');

            try {
                const billingInformations: Partial<BillingInformation> = {
                    address: this.billingForm?.get('address')?.value ?? undefined,
                    addressLine2: this.billingForm?.get('addressLine2')?.value ?? undefined,
                    city: this.billingForm?.get('city')?.value,
                    postalCode: this.billingForm?.get('postalCode')?.value,
                    billingFirstname: this.billingForm
                        ?.get('individualForm')
                        ?.get('billingFirstname')?.value,
                    billingName: this.billingForm?.get('individualForm')?.get('billingName')?.value,
                    billingCompanyName:
                        this.billingForm?.get('companyForm')?.get('billingCompanyName')?.value ??
                        undefined,
                    billingEntityType: this.billingForm?.get('billingEntityType')?.value,
                    billingEmail: this.billingForm?.get('billingEmail')?.value,
                };

                // Clean the billing informations
                Object.keys(billingInformations).forEach((key) => {
                    if (
                        billingInformations[key as keyof Partial<BillingInformation>] === undefined
                    ) {
                        delete billingInformations[key as keyof Partial<BillingInformation>];
                    }
                });
                const result = await lastValueFrom(
                    callable({
                        subscriptionRank: this.currentConfiguration?.docName,
                        subscriptionType: this.billingForm?.get('subscriptionType')?.value,
                        subscriptionUID: this.subscription()?.uid,
                        billingInformations: billingInformations as BillingInformation,
                    }),
                );

                if (result?.message) {
                    this.subscriptionService
                        .updateLocalSubscription()
                        .then(async () => {
                            const toast = await this.toastController.create({
                                position: 'top',
                                message: 'Abonnement mis à jour avec succès.',
                                duration: 3000,
                                color: 'success',
                            });
                            toast.present();
                            this.pending$.next(false);
                            this.showPage = ShowPage.subscription;
                        })
                        .catch(async () => {
                            this.pending$.next(false);
                            const toast = await this.toastController.create({
                                position: 'top',
                                message: 'Une erreur est survenue, veuillez réessayer.',
                                duration: 3000,
                                color: 'danger',
                            });
                            toast.present();
                        });
                } else if (result?.hosted_payment?.payment_url) {
                    window.location.href = result.hosted_payment.payment_url;
                }
            } catch (error) {
                console.error(error);

                const toast = await this.toastController.create({
                    position: 'top',
                    message: 'Une erreur est survenue, veuillez réessayer.',
                    duration: 3000,
                    color: 'danger',
                });
                toast.present();

                this.pending$.next(false);
                return;
            }
        }
    }

    protected async showMention(): Promise<void> {
        const modal = await this.modalController.create({
            component: CgvComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                modalController: this.modalController,
                isModal: true,
                cgvCurrentDate: this.cgvCurrentDate,
            },
            cssClass: 'max-width-80',
        });

        await modal.present();
    }

    acceptFamilyInvitation(invitationUID: string) {
        this.isLoading = true;
        this.familyService
            .acceptFamilyInvitation(invitationUID)
            .pipe(switchMap(() => this.familyService.getUserInvitations()))
            .subscribe((userFamilyInvitation) => {
                this.subscriptionService.updateLocalSubscription().then(() => {
                    this.familyInvitations = userFamilyInvitation;
                    this.showPage = ShowPage.subscription;
                    this.isLoading = false;
                });
            });
    }

    refuseFamilyInvitation(invitationUID: string) {
        this.isLoading = true;
        this.familyService
            .refuseFamilyInvitation(invitationUID)
            .pipe(switchMap(() => this.familyService.getUserInvitations()))
            .subscribe((userFamilyInvitation) => {
                this.subscriptionService.updateLocalSubscription().then(() => {
                    this.familyInvitations = userFamilyInvitation;
                    this.showPage = ShowPage.subscription;
                    this.isLoading = false;
                });
            });
    }

    async addShare() {
        this.isAddMemberLoading = true;
        this.analyticsService.logEvent('Share form opened');
        const modal = await this.modalController.create({
            component: ShareFormModalComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                addText: "Ajout d'un membre",
                buttonText: 'Ajouter',
                buttonIcon: 'user',
            },
        });

        modal.onWillDismiss().then((res) => {
            if (res.data) {
                this.isLoading = true;
                const callable = this.functions.httpsCallable('sendFamilyInvitation');
                callable(res.data).subscribe({
                    next: async (result: FamilyDto) => {
                        this.family = result;
                        this.familyService.family$.next(result);
                        this.isLoading = false;
                        const toast = await this.toastController.create({
                            message: 'Membre ajouté',
                            duration: 4000,
                            color: 'primary',
                            position: 'top',
                        });
                        await toast.present();
                    },
                    error: async (err) => {
                        this.isLoading = false;
                        const toast = await this.toastController.create({
                            message: err.message,
                            duration: 4000,
                            color: 'warning',
                            position: 'top',
                        });
                        await toast.present();
                        this.isAddMemberLoading = false;
                    },
                });
            }
        });

        await modal.present();
        this.isAddMemberLoading = false;
    }

    onRemoveShare($event: Sharing) {
        this.isLoading = true;
        const callable = this.functions.httpsCallable('removeFamilyMember');
        callable($event).subscribe((result) => {
            this.family = result;
            this.familyService.family$.next(result);
            this.isLoading = false;
        });
    }

    onRemoveInvitation($event: Sharing) {
        this.isLoading = true;
        const callable = this.functions.httpsCallable('removeFamilyInvitation');
        callable($event).subscribe((result) => {
            this.family = result;
            this.familyService.family$.next(result);
            this.isLoading = false;
        });
    }

    isMac(): boolean {
        return navigator.userAgent.includes('Macintosh') || navigator.userAgent.includes('Mac OS');
    }

    protected get isSubscribing(): boolean {
        const isSubscribing =
            !this.subscription()?.isSubscribed ||
            !this.subscription() ||
            !!this.subscription()?.trial;
        return isSubscribing;
    }

    protected get isChangingSubscription(): boolean {
        this.selectedPeriod = this.billingForm?.get('subscriptionType')?.value as SubscriptionType;
        const isChangingSubscription =
            this.subscription()?.rank !== this.selectedRank ||
            this.subscription()?.subscriptionType !== this.selectedPeriod;
        return isChangingSubscription;
    }

    protected getTotalPrice(taxType: 'TTC' | 'HT'): string {
        const selectedSubscriptionType = this.billingForm?.get('subscriptionType')
            ?.value as SubscriptionType;
        if (!this.currentConfiguration?.price || !selectedSubscriptionType) {
            return '--,--\u00A0€\u00A0' + taxType;
        }

        const priceInCents = this.currentConfiguration.price[selectedSubscriptionType];

        if (!priceInCents) {
            return '--,--\u00A0€\u00A0' + taxType;
        }

        const vat = taxType === 'HT' ? 20 : 0;

        const totalPrice = this.currencyPipe.transform(priceInCents / 100 / (1 + vat / 100), 'EUR') + '\u00A0' + taxType;

        return totalPrice;
    }
}
