import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SocietyEntity, SocietyMember, SocietyStatusMember, UserEntity } from '@omedom/data';
import { UserService } from '@omedom/services';
import { Subscription } from 'rxjs';

import { SocietyMemberFormComponent } from '../../../containers/society/society-member-form/society-member-form.component';

@Component({
    selector: 'omedom-society-info-members',
    templateUrl: './members.component.html',
    styleUrls: ['./members.component.scss'],
})
export class SocietyInfoMembersComponent implements OnInit, OnDestroy, OnChanges {
    /**
     * @description Society Entity data
     * @author Jérémie Lopez
     * @type {SocietyEntity}
     * @memberof SocietyInfoMembersComponent
     */
    @Input()
    public society?: SocietyEntity;

    /**
     * @description If true, the user can edit the member
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 12/08/2024
     * @memberof SocietyInfoMembersComponent
     */
    @Input()
    public isEdition = true;

    /**
     * @description User Entity data
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof SocietyInfoMembersComponent
     */
    public user?: UserEntity;

    /**
     * @description List of subscription to delete after destroy component
     * @author Jérémie Lopez
     * @private
     * @type {Subscription[]}
     * @memberof SocietyInfoMembersComponent
     */
    private readonly subscriptions: Subscription[] = [];

    userStatus: SocietyStatusMember | null = SocietyStatusMember.associated;

    constructor(
        private readonly userService: UserService,
        private readonly modalController: ModalController,
    ) { }

    ngOnInit(): void {
        // Retrieve user data
        const user$ = this.userService.user$.subscribe((user) => {
            this.user = user;
            this.setUserStatus();
        });

        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setUserStatus();
    }

    /**
     * @description Find the status of current user
     * @author ANDRE Felix
     * @memberof SocietyInfoMembersComponent
     */
    setUserStatus() {
        if (this.user) {
            if (this.society?.members) {
                const member = this.society.members.find(
                    (member) => member.email === this.user?.email
                );
                this.userStatus = member ? member.status : null;
            }
        }
    }

    /**
     * @description Check if the user can edit the society
     * @author Jérémie Lopez
     * @readonly
     * @type {boolean}
     * @memberof SocietyInfoMembersComponent
     */
    public get canEditMember(): boolean {
        if (this.isEdition && this.user && this.society) {
            return (
                this.userStatus === SocietyStatusMember.manager ||
                this.society.userUID === this.user.uid
            );
        } else {
            return false;
        }
    }

    /**
     * @description Create a member
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof SocietyInfoMembersComponent
     */
    public async createMember(): Promise<void> {
        const modal = await this.modalController.create({
            component: SocietyMemberFormComponent,
            canDismiss: true,
            initialBreakpoint: 1,
            backdropDismiss: true,
            componentProps: {
                society: this.society,
            },
        });
        await modal.present();
    }

    protected sortFunction(a: SocietyMember, b: SocietyMember): number {
        return Number(b.isCreator) - Number(a.isCreator);
    }
}

