@if (rating) {
    <div class="dpe-container">
        <div class="dpe-scale margin-bottom-2 margin-left-1">
            <div class="sub-title margin-bottom-1">{{ subTitle }}</div>
            @for (level of levels; track level.letter; let i = $index) {
                <div
                    class="dpe-label"
                    [ngClass]="'level-' + (i + 1)"
                    [class.active-score]="level.active"
                    [class.not-active]="!level.active"
                    [style]="{
                        'background-color': level.color,
                        '--color': level.color
                    }"
                >
                    <div class="dpe-range">
                        @if (level.min && level.max && level.max !== infinity) {
                            {{ level.min }} - {{ level.max }}
                        } @else if (level.min) {
                            &gt; {{ level.min }}
                        } @else if (level.max) {
                            &lt; {{ level.max }}
                        }
                    </div>
                    <div class="dpe-letter">{{ level.letter }}</div>
                    @if (rating > level.min && rating <= level.max) {
                        <div class="dpe-rating">
                            {{ ratingText }}
                        </div>
                    }
                </div>
            }
            <div class="dpe-legend">En {{ ratingType }}</div>
        </div>
    </div>
}
