import { Component, Input, OnInit } from '@angular/core';

type levelType = { letter: string; range: string; color: string; active: boolean };

@Component({
    selector: 'omedom-emission-consumption-interface',
    templateUrl: './emission-consumption-interface.component.html',
    styleUrls: ['./emission-consumption-interface.component.scss'],
})
export class EmissionConsumptionInterfaceComponent implements OnInit {
    @Input() rating?: number;

    ratingType = 'kgCO₂/m²/an';

    defaultLeftRatingPosition = 247.5;
    topRatingPadding = 14;
    isScreenWidthLarge = true;
    widthStep = 27.5;
    heightStep = 37;

    /**
     * @description Coefficient pour adapter la position gauche par défaut sur petit écran
     */
    coefLeftRatingProsition = 3.15;

    /**
     * @description Coefficient pour adapter la largeur sur petit écran
     */
    coefWidthStepSmallScreen = 0.045;

    levels: levelType[] = [
        { letter: 'A', range: '< 4', color: '#E4C1F9', active: false },
        { letter: 'B', range: '4 - 10', color: '#C39BD3', active: false },
        { letter: 'C', range: '11 - 20', color: '#9B59B6', active: false },
        { letter: 'D', range: '21 - 35', color: '#8E44AD', active: false },
        { letter: 'E', range: '36 - 55', color: '#6C3483', active: false },
        { letter: 'F', range: '56 - 80', color: '#5B2C6F', active: false },
        { letter: 'G', range: '> 80', color: '#4A235A', active: false },
    ];

    ratingText = '';
    ratingLeftProsition = `${this.defaultLeftRatingPosition}px`;
    ratingPosition = '0';

    constructor() { }

    ngOnInit() {
        this.setSizes();
        this.ratingText = `${this.rating} ${this.ratingType}`;
        this.getRatingPosition();
    }

    getRatingPosition() {
        const index = this.levels.findIndex((level) => {
            const range = level.range.trim();
            if (!this.rating) {
                return false;
            }
            if (range.startsWith('<')) {
                const max = parseInt(range.substring(1).trim(), 10);
                return this.rating < max;
            } else if (range.startsWith('>')) {
                const min = parseInt(range.substring(1).trim(), 10);
                return this.rating > min;
            } else {
                const [min, max] = range.split('-').map((num) => parseInt(num.trim(), 10));
                return this.rating >= min && this.rating <= max;
            }
        });

        if (index !== -1) {
            this.levels[index].active = true;
        }
        this.ratingLeftProsition =
            index !== -1 ? this.defaultLeftRatingPosition + index * this.widthStep + 'px' : '270px';
        this.ratingPosition =
            index !== -1 ? index * this.heightStep - this.topRatingPadding + 'px' : '0';
    }

    setSizes() {
        this.isScreenWidthLarge = window.innerWidth > 550;
        if (!this.isScreenWidthLarge) {
            this.defaultLeftRatingPosition = window.innerWidth / this.coefLeftRatingProsition;
            this.widthStep = window.innerWidth * this.coefWidthStepSmallScreen;
            this.topRatingPadding = 9;
            this.heightStep = 35;
        }
    }
}
