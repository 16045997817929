import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import {
    FamilyDto,
    FamilyEntity,
    FamilyInvitationEntity,
    Sharing,
    UserFamilyInvitation,
} from '@omedom/data';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
    providedIn: 'root',
})
export class FamilyService extends RestService<FamilyEntity> {
    public family$: BehaviorSubject<FamilyDto> = new BehaviorSubject({} as FamilyDto);

    constructor(
        protected override firestore: AngularFirestore,
        protected auth: AngularFireAuth,
        protected functions: AngularFireFunctions
    ) {
        super(firestore, 'family');
    }

    getFamily(forceUpdate = false): Observable<FamilyDto> {
        if (this.family$.value.userUID && !forceUpdate) {
            return this.family$;
        }

        return this.fetchUserFamily().pipe(
            tap((family: FamilyDto) => {
                if (family) {
                    this.family$.next(family);
                }
            })
        );
    }

    getHostInvitation(): Observable<FamilyInvitationEntity> {
        const callable = this.functions.httpsCallable('getHostInvitations');
        return callable(null);
    }

    getUserInvitations(): Observable<UserFamilyInvitation[]> {
        const callable = this.functions.httpsCallable('getUserInvitations');
        return callable(null);
    }

    acceptFamilyInvitation(invitationUID: string): Observable<boolean> {
        const callable = this.functions.httpsCallable('acceptFamilyInvitation');
        return callable(invitationUID);
    }

    refuseFamilyInvitation(invitationUID: string): Observable<boolean> {
        const callable = this.functions.httpsCallable('refuseFamilyInvitation');
        return callable(invitationUID);
    }

    public sendInvitation(sharing: Sharing): Observable<FamilyDto> {
        const callable = this.functions.httpsCallable('sendFamilyInvitation');
        return callable(sharing);
    }

    private fetchUserFamily(): Observable<FamilyDto> {
        const callable = this.functions.httpsCallable('getUserFamily');
        return callable(null);
    }
}
