import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { RestEntity } from '@omedom/data';
import { SettingService } from '@omedom/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'isMaintenance',
})
@Injectable({ providedIn: 'root' })
export class OmedomIsMaintenancePipe implements PipeTransform {
    constructor(private settingService: SettingService<MaintenanceEntity>) {}

    transform(accessValue: string): Observable<MaintenanceEnum> {
        return this.settingService._get('maintenance').pipe(
            map((maintenance) => {
                if (maintenance && maintenance[accessValue]) {
                    return maintenance[accessValue] as MaintenanceEnum;
                }
                return MaintenanceEnum.UNKNOWN;
            }),
        );
    }
}
export enum MaintenanceEnum {
    FAILURE = 'FAILURE',
    SUCCESS = 'SUCCESS',
    PENDING = 'PENDING',
    UNKNOWN = 'UNKNOWN',
    MAINTENANCE = 'MAINTENANCE',
}

class MaintenanceEntity extends RestEntity {
    /**
     * @description UID of the entity
     * @author Jérémie Lopez
     * @type {string}
     * @memberof RestEntity
     */
    public override uid!: string;

    /**
     * @description Created date of the entity
     * @author Jérémie Lopez
     * @type {Date}
     * @memberof RestEntity
     */
    public override created: Date = new Date();

    /**
     * @description Updated date of the entity
     * @author Jérémie Lopez
     * @type {Date}
     * @memberof RestEntity
     */
    public override updated: Date = new Date();

    /**
     * @description Updated date of the entity
     * @author Vidjay Seganti (Keleo)
     * @type {string}
     * @memberof RestEntity
     */
    public override docName?: string;

    [key: string]: MaintenanceEnum | Date | { [key: string]: any } | string | undefined;

    constructor(
        protected override data?: {
            [key: string]: any;
        },
    ) {
        super(data);
    }
}
