import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { ClientEntity, defaultRoleState, DocumentEntity, EntityTypes, PropertyEntity, RoleState } from '@omedom/data';
import { DocumentService, RoleService } from '@omedom/services';
import { lastValueFrom, map, Observable, of, take } from 'rxjs';

import { DocumentViewerComponent } from '../../../document';

@Component({
    selector: 'omedom-property-profil-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class PropertyProfilDocumentsComponent implements OnInit {
    @Input({ required: true })
    public property?: PropertyEntity;

    @Input({ required: true })
    public client?: ClientEntity;

    @Output()
    public addDocument: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public editDocument: EventEmitter<DocumentEntity> = new EventEmitter<DocumentEntity>();

    public documents$: Observable<DocumentEntity[]> = of([]);

    public roleState$: Observable<RoleState> = of(defaultRoleState);

    public defaultRoleState = defaultRoleState;

    constructor(
        private readonly documentService: DocumentService,
        private readonly roleService: RoleService,
        private readonly modalController: ModalController,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {}

    async ngOnInit(): Promise<void> {
        // Check if property is defined
        if (!this.property || !this.client) {
            return;
        }

        this.roleState$ = this.roleService._getRoleState(this.property, EntityTypes.property);

        // Get documents of the property
        this.documents$ = this.documentService._search([
            {
                where: 'userUID',
                operator: '==',
                value: this.client.userUID,
            },
            {
                where: 'clientUID',
                operator: '==',
                value: this.client.uid,
            },
            {
                where: 'propertyUID',
                operator: '==',
                value: this.property.uid,
            },
        ]);
    }

    protected async documentClick(documentUid: string): Promise<void> {
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { documentViewer: 'open' },
            queryParamsHandling: 'merge',
        });

        const document = await lastValueFrom(
            this.documents$.pipe(
                map((documents) => documents.find((doc) => doc.uid === documentUid)),
                take(1),
            ),
        );
        const modal = await this.modalController.create({
            component: DocumentViewerComponent,
            componentProps: {
                document,
                readOnly: true,
            },

            cssClass: 'max-heigth-mode',
        });

        await modal.present();

        await modal.onDidDismiss().then(
            async (
                overlayEventDetail: OverlayEventDetail<{
                    document?: DocumentEntity;
                }>,
            ) => {
                await this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: { documentViewer: null },
                    queryParamsHandling: 'merge',
                });

                if (overlayEventDetail.data?.document) {
                    this.editDocument.emit(overlayEventDetail.data.document);
                }
            },
        );
    }

    public downloadDocument(document: DocumentEntity): void {
        // Check if document is defined
        if (!document) {
            return;
        }

        // Download the document
        const url = document.fileURL;

        // Create a link
        const link = window.document.createElement('a');
        link.href = url;
        link.download = document.name;
        link.target = '_blank';
        link.click();
    }
}
