import { Component, Input, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";

@Component({
    selector: "omedom-dismiss-modal",
    templateUrl: "./dismiss-modal.component.html",
    styleUrls: ["./dismiss-modal.component.scss"],
})
export class DismissModalComponent implements OnInit {
    @Input({ required: true })
    public modalController!: ModalController

    @Input()
    public forceDisplayingComlponent = false;

    protected isDesktop = false

    constructor(
        private readonly platform: Platform,
    ) { }

    ngOnInit() {
        this.isDesktop = !this.platform.is('capacitor')
    }

    protected dismissModal() {
        this.modalController.dismiss();
    }


}
