@if (user$ | async; as user) {
    <div class="progress">
        <div
            class="progress-bar"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            [attr.aria-valuenow]="getPercentage(user)"
            [ngClass]="{
                warn: getTotalUsage(user) >= 60 && getTotalUsage(user) < 80,
                danger: getTotalUsage(user) >= 80
            }"
            [style.borderRadius]="staging ? '10px 0 0 10px' : '10px'"
            [style.width]="getPercentage(user) + '%'"
        ></div>
        <div
            class="staging-bar"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            [attr.aria-valuenow]="getStaging(user)"
            [ngClass]="{
                warn: getTotalUsage(user) >= 60 && getTotalUsage(user) < 80,
                danger: getTotalUsage(user) >= 80
            }"
            [style.width]="getStaging(user) + '%'"
        ></div>
    </div>
    <div class="storage">
        <span
            >{{ (user.storageUsed ?? 0) + staging | omedomByte }} utilisé{{
                (user.storageUsed ?? 0) + staging > 1 ? 's' : ''
            }}
            sur 2 Go</span
        >
    </div>
}
