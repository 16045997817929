import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { ToastController } from '@ionic/angular';
import {
    AllAssets,
    AllAssetTypeName,
    BankAccountEntity,
    BankItemEntity,
    ClientEntity,
    LoanEntity,
    ProEntity,
    PropertyEntity,
    PropertyType,
    SavingEntity,
    SocietyEntity,
    UserEntity,
} from '@omedom/data';
import {
    BankAccountService,
    BankItemService,
    ClientService,
    LoanService,
    PropertyService,
    SavingService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { combineLatest, filter, Subscription, switchMap } from 'rxjs';

import { elementAnimation, listAnimation } from '../../animations';

@Component({
    selector: 'omedom-share-to-pro',
    templateUrl: './share-to-pro.component.html',
    styleUrls: ['./share-to-pro.component.scss'],
    animations: [listAnimation, elementAnimation],
})
export class ShareToProComponent implements OnDestroy, OnChanges {
    /**
     * @description Pro to share assets with
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {ProEntity}
     * @memberof ShareToProComponent
     */
    @Input({ required: true }) pro!: ProEntity;

    /**
     * @description User to share assets with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {UserEntity}
     * @memberof ShareToProComponent
     */
    user?: UserEntity;

    /**
     * @description Client to share assets with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {ClientEntity}
     * @memberof ShareToProComponent
     */
    client?: ClientEntity;

    /**
     * @description User properties to share with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {PropertyEntity[]}
     * @memberof ShareToProComponent
     */
    userProperties: PropertyEntity[] = [];

    /**
     * @description User societies to share with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {SocietyEntity[]}
     * @memberof ShareToProComponent
     */
    userSocieties: SocietyEntity[] = [];

    /**
     * @description User bank accounts UID to share with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {string[]}
     * @memberof ShareToProComponent
     */
    userBankAccountsUID: string[] = [];

    /**
     * @description User bank accounts to share with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {BankAccountEntity[]}
     * @memberof ShareToProComponent
     */
    userBankAccounts: BankAccountEntity[] = [];

    /**
     * @description User bank items to share with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {BankItemEntity[]}
     * @memberof ShareToProComponent
     */
    userBankItems: BankItemEntity[] = [];

    /**
     * @description User bank items UID to share with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {string[]}
     * @memberof ShareToProComponent
     */
    userBankItemsUID: string[] = [];

    /**
     * @description User loans to share with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {SavingEntity[]}
     * @memberof ShareToProComponent
     */
    userSavings: SavingEntity[] = [];

    /**
     * @description User loans to share with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {LoanEntity[]}
     * @memberof ShareToProComponent
     */
    userLoans: LoanEntity[] = [];

    /**
     * @description All asset types name enum to display (property, building, society, loan, saving) in the template
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @memberof ShareToProComponent
     */
    allAssetTypeName = AllAssetTypeName;

    /**
     * @description Subscriptions to unsubscribe on destroy lifecycle hook to avoid memory leaks
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @type {Subscription[]}
     * @memberof ShareToProComponent
     */
    subscriptions: Subscription[] = [];

    /**
     * @description Toast messages to display when sharing or unsharing assets with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @private
     * @memberof ShareToProComponent
     */
    private toastMessages = {
        success: {
            share: (type: AllAssetTypeName) => this.getShareMessage(type),
            unshare: (type: AllAssetTypeName) => this.getUnShareMessage(type),
        },
        error: 'Une erreur est survenue, réessayez plus tard',
    };

    constructor(
        private userService: UserService,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private bankAccountService: BankAccountService,
        private bankItemService: BankItemService,
        private loanService: LoanService,
        private savingService: SavingService,
        private clientService: ClientService,
        private toastController: ToastController,
    ) {}

    ngOnChanges() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        const user$ = this.userService.user$
            .pipe(
                filter((user) => !!user),
                switchMap((user) => {
                    this.user = user;
                    const properties$ = this.propertyService._getUserProperties(this.user.uid);
                    const societies$ = this.societyService._getUserSocieties(this.user.uid);
                    const bankAccounts$ = this.bankAccountService._getUserBankAccounts(user.uid);
                    const bankItems$ = this.bankItemService._getUserBankItems(user.uid);
                    const loans$ = this.loanService._getLoansFromUser(user.uid);
                    const savings$ = this.savingService._getSavingsFromUser(user.uid);
                    const client$ = this.clientService._getClientByUserUIDAndProUID(
                        this.user.uid,
                        this.pro.uid,
                    );

                    return combineLatest([
                        properties$,
                        societies$,
                        bankItems$,
                        loans$,
                        savings$,
                        bankAccounts$,
                        client$,
                    ]);
                }),
            )
            .subscribe(
                ([properties, societies, bankItems, loans, savings, bankAccounts, client]) => {
                    this.userProperties = properties;
                    this.userSocieties = societies;
                    this.userBankAccounts = bankAccounts;
                    this.userBankItems = bankItems;
                    this.userBankAccountsUID = bankAccounts.map((account) => account.uid);
                    this.userBankItemsUID = bankItems.map((item) => item.uid);
                    this.userLoans = loans;
                    this.userSavings = savings;
                    this.client = client;
                },
            );

        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @description Select an asset to share or unshare with the pro and update the client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @param {AllAssets} asset
     * @param {boolean} isSelected
     * @param {AllAssetTypeName} type
     * @memberof ShareToProComponent
     */
    async selectAsset(asset: AllAssets, isSelected: boolean, type: AllAssetTypeName) {
        let isAddingSharing = true;
        switch (type) {
            case AllAssetTypeName.property:
            case AllAssetTypeName.building:
                isAddingSharing = this.changePropertyAsset(asset as PropertyEntity, isSelected);
                break;
            case AllAssetTypeName.society:
                isAddingSharing = this.changeSocietyAsset(asset as SocietyEntity, isSelected);
                break;
            case AllAssetTypeName.loan:
                isAddingSharing = this.changeLoanAsset(asset as LoanEntity, isSelected);
                break;
            case AllAssetTypeName.saving:
                isAddingSharing = this.changeSavingAsset(asset as SavingEntity, isSelected);
                break;
        }
        try {
            if (this.client) {
                await this.clientService.update(this.client);
                this.shareToProToast(type, isAddingSharing);
            } else {
                throw new Error('Client not found');
            }
        } catch (err) {
            this.shareToProErrorToast();
        }
    }

    /**
     * @description Change the property asset in the client propertiesUID array and return the new value of isSelected to update the view accordingly
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @param {PropertyEntity} property
     * @param {boolean} isSelected
     * @returns {*}
     * @memberof ShareToProComponent
     */
    changePropertyAsset(property: PropertyEntity, isSelected: boolean) {
        if (!this.client || !this.client.propertiesUID) {
            return false;
        }
        if (isSelected) {
            this.client.propertiesUID?.push(property.uid);
            return true;
        } else {
            const index = this.client.propertiesUID?.indexOf(property.uid) ?? -1;
            if (index < 0) {
                return true;
            }
            this.client.propertiesUID?.splice(index, 1);
            return false;
        }
    }

    /**
     * @description Change the society asset in the client societiesUID array and return the new value of isSelected to update the view accordingly
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @param {SocietyEntity} society
     * @param {boolean} isSelected
     * @returns {*}
     * @memberof ShareToProComponent
     */
    changeSocietyAsset(society: SocietyEntity, isSelected: boolean) {
        if (!this.client || !this.client.societiesUID) {
            return false;
        }
        if (isSelected) {
            this.client.societiesUID?.push(society.uid);
            return true;
        } else {
            const index = this.client.societiesUID?.indexOf(society.uid) ?? -1;
            if (index < 0) {
                return true;
            }

            this.client.societiesUID?.splice(index, 1);
            return false;
        }
    }

    /**
     * @description Change the saving asset in the client savingsUID array and return the new value of isSelected to update the view accordingly
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @param {SavingEntity} saving
     * @param {boolean} isSelected
     * @returns {*}
     * @memberof ShareToProComponent
     */
    changeSavingAsset(saving: SavingEntity, isSelected: boolean) {
        if (!this.client || !this.client.savingsUID) {
            return false;
        }

        if (isSelected) {
            this.client.savingsUID.push(saving.uid);
            return true;
        } else {
            const index = this.client.savingsUID.indexOf(saving.uid);
            if (index < 0) {
                return true;
            }

            this.client.savingsUID.splice(index, 1);
            return false;
        }
    }

    /**
     * @description Change the loan asset in the client loansUID array and return the new value of isSelected to update the view accordingly
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @param {LoanEntity} loan
     * @param {boolean} isSelected
     * @returns {*}
     * @memberof ShareToProComponent
     */
    changeLoanAsset(loan: LoanEntity, isSelected: boolean) {
        if (!this.client || !this.client.loansUID) {
            return false;
        }

        if (isSelected) {
            this.client.loansUID.push(loan.uid);
            return true;
        } else {
            const index = this.client.loansUID.indexOf(loan.uid);
            if (index < 0) {
                return true;
            }

            this.client.loansUID.splice(index, 1);
            return false;
        }
    }

    /**
     * @description Share the global balance with the pro and update the client bank accounts and bank items UID arrays in the database
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @param {boolean} hasClientAcceptCondition
     * @returns {*}
     * @memberof ShareToProComponent
     */
    async shareGlobalBalanceChanged(hasClientAcceptCondition: boolean) {
        if (!this.client) {
            return;
        }

        const bankAccountsUID = hasClientAcceptCondition ? this.userBankAccountsUID : [];
        const bankItemsUID = hasClientAcceptCondition ? this.userBankItemsUID : [];

        const clientValuesToUpdate = {
            uid: this.client.uid,
            hasAgreedToShareBankTotalBalance: hasClientAcceptCondition,
            bankAccountsUID,
            bankItemsUID,
        };

        try {
            await this.clientService.update(clientValuesToUpdate);
        } catch (err) {
            this.shareToProErrorToast();
        }
    }

    /**
     * @description Display a toast message when sharing or unsharing assets with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @private
     * @param {AllAssetTypeName} type
     * @param {boolean} isAddingSharing
     * @memberof ShareToProComponent
     */
    private async shareToProToast(type: AllAssetTypeName, isAddingSharing: boolean) {
        const message = isAddingSharing
            ? this.toastMessages.success.share(type)
            : this.toastMessages.success.unshare(type);

        const toast = await this.toastController.create({
            message,
            duration: 2500,
            color: 'primary',
            position: 'top',
        });

        await toast.present();
    }

    /**
     * @description Display a toast message when an error occurs while sharing or unsharing assets with the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @private
     * @memberof ShareToProComponent
     */
    private async shareToProErrorToast() {
        const toast = await this.toastController.create({
            message: this.toastMessages.error,
            duration: 3000,
            color: 'danger',
            position: 'top',
        });

        await toast.present();
    }

    /**
     * @description Get the property type (building or property) to display the correct icon in the template
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @param {PropertyEntity} property
     * @returns {*}  {(AllAssetTypeName.building | AllAssetTypeName.property)}
     * @memberof ShareToProComponent
     */
    public getPropertyType(
        property: PropertyEntity,
    ): AllAssetTypeName.building | AllAssetTypeName.property {
        if (property.type === PropertyType.immeuble) {
            return AllAssetTypeName.building;
        }
        return AllAssetTypeName.property;
    }

    /**
     * @description Capitalize the first letter of a text to display it correctly
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @private
     * @param {string} text
     * @returns {*}
     * @memberof ShareToProComponent
     */
    private capitalize(text: string) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    /**
     * @description Get the share message to display in the toast when sharing
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @private
     * @param {AllAssetTypeName} type
     * @returns {*}
     * @memberof ShareToProComponent
     */
    private getShareMessage(type: AllAssetTypeName) {
        if (type === AllAssetTypeName.building || type === AllAssetTypeName.loan) {
            return `${this.capitalize(type)} partagé avec le pro`;
        }
        return `${this.capitalize(type)} partagée avec le pro`;
    }

    /**
     * @description Get the unshare message to display in the toast when unsharing assets
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/11/2024
     * @private
     * @param {AllAssetTypeName} type
     * @returns {*}
     * @memberof ShareToProComponent
     */
    private getUnShareMessage(type: AllAssetTypeName) {
        if (type === AllAssetTypeName.building || type === AllAssetTypeName.loan) {
            return `${this.capitalize(type)} n'est plus partagé avec le pro`;
        }
        return `${this.capitalize(type)} n'est plus partagée avec le pro`;
    }
}
