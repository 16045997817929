import { Injectable } from '@angular/core';
import {
    Action,
    AssetTypes,
    DocumentEntity,
    EntityTypes,
    LoanEntity,
    PropertyEntity,
    RestEntity,
    RoleAbsolute,
    RoleState,
    SavingEntity,
    SocietyEntity,
    SocietyRoleMember,
    Story,
    UserEntity,
} from '@omedom/data';
import { OmedomEnvironment } from '@omedom/environment';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
    ChargeService,
    ClientService,
    IncomeService,
    PropertyService,
    ProService,
    SocietyService,
    UserService,
} from '../core';

@Injectable({
    providedIn: 'root',
})
export class RoleService {
    /**
     * @description Get user data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @private
     * @type {UserEntity}
     * @memberof RoleService
     */
    private user?: UserEntity;

    constructor(
        private userService: UserService,
        private proService: ProService,
        private clientService: ClientService,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private chargeService: ChargeService,
        private incomeService: IncomeService,
    ) {
        this.userService.user$.subscribe((user: UserEntity) => {
            this.user = user;
        });
    }

    /**
     * @description Get the role state of the user for an entity
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @template T Type of the entity
     * * @param {string} type type of entity
     * @param {T} entity Entity to check
     * @return {*}  {Promise<RoleState>}
     * @memberof RoleService
     * @example
     * ...
     * constructor(private roleService: RoleService) {...}
     * ...
     * const roleState = await this.roleService.getRoleState<DocumentEntity>(document, 'DocumentEntity');
     */
    public async getRoleState<T extends RestEntity | Story>(
        entity: T,
        type: EntityTypes,
    ): Promise<RoleState> {
        // The entity is a document
        if (type === EntityTypes.document) {
            return this.getRoleStateDocument(entity as unknown as DocumentEntity);
        }
        if (type === EntityTypes.property) {
            return this.getRoleStateProperty(entity as unknown as PropertyEntity);
        }
        if (type === EntityTypes.society) {
            return this.getRoleStateSociety(entity as unknown as SocietyEntity);
        }
        if (type === EntityTypes.story) {
            return this.getRoleStateStory(entity as unknown as Story);
        }

        // The entity is not defined
        return {
            [Action.create]: false,
            [Action.read]: false,
            [Action.update]: false,
            [Action.delete]: false,
            [Action.import]: false,
            [Action.export]: false,
            [Action.share]: false,
        };
    }

    public async getRoleStateWithAssetUid<T extends SocietyEntity | PropertyEntity | undefined>(
        assetUid: string,
        assetType: AssetTypes,
    ): Promise<RoleState> {
        let entity = {} as T | undefined;
        let type: EntityTypes | undefined;
        switch (assetType) {
            case AssetTypes.building:
            case AssetTypes.property:
                entity = (await this.propertyService.get(assetUid)) as T | undefined;
                type = EntityTypes.property;
                break;
            case AssetTypes.society:
                entity = (await this.societyService.get(assetUid)) as T | undefined;
                type = EntityTypes.society;
                break;
            default:
                break;
        }

        if (entity && type) {
            return this.getRoleState(entity, type);
        }
        return {
            [Action.create]: false,
            [Action.read]: false,
            [Action.update]: false,
            [Action.delete]: false,
            [Action.import]: false,
            [Action.export]: false,
            [Action.share]: false,
        };
    }

    /**
     * @description Get the role state of the user for an entity (observable version)
     * @author Brisset Killian
     * @date 18/06/2024
     * @template T
     * @param {T} entity
     * @param {EntityTypes} type
     * @returns {*}  {Observable<RoleState>}
     * @memberof RoleService
     */
    public _getRoleState<T extends RestEntity | Story>(
        entity: T,
        type: EntityTypes,
    ): Observable<RoleState> {
        // The entity is a document
        return this.userService.user$.pipe(
            switchMap((user) => {
                this.user = user;

                if (type === EntityTypes.property) {
                    return this._getRoleStateProperty(entity as unknown as PropertyEntity);
                }
                if (type === EntityTypes.society) {
                    return this._getRoleStateSociety(entity as unknown as SocietyEntity);
                }
                if (type === EntityTypes.document) {
                    return this._getRoleStateDocument(entity as unknown as DocumentEntity);
                }
                if (type === EntityTypes.loan) {
                    return this._getRoleStateLoan(entity as unknown as LoanEntity);
                }
                if (type === EntityTypes.saving) {
                    return this._getRoleStateSaving(entity as unknown as SavingEntity);
                }

                // The entity is not defined
                return of({
                    [Action.create]: false,
                    [Action.read]: false,
                    [Action.update]: false,
                    [Action.delete]: false,
                    [Action.import]: false,
                    [Action.export]: false,
                    [Action.share]: false,
                });
            }),
        );
    }

    /**
     * @description Get the AbsolutRole depending of the Entity (document, property, ...)
     * @author ANDRE Felix
     * @template T
     * @param {T} entity
     * @param {string} type
     * @returns {*}  {Promise<RoleAbsolute>}
     * @memberof RoleService
     */
    public async getRole<T extends RestEntity>(
        entity: T,
        type: string,
        user: UserEntity,
    ): Promise<RoleAbsolute> {
        // I did it like this to be sure a user is set
        this.user = user;
        // The entity is a document
        if (type === 'DocumentEntity') {
            return this.retrieveRoleDocument(entity as unknown as DocumentEntity);
        }
        if (type === 'PropertyEntity') {
            return this.retrieveRoleProperty(entity as unknown as PropertyEntity);
        }
        if (type === 'SocietyEntity') {
            return this.retrieveRoleSociety(entity as unknown as SocietyEntity);
        }

        // The entity is not defined
        return RoleAbsolute.none;
    }

    /**
     * @description Get the role state of the user for a document
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @private
     * @param {DocumentEntity} document Document to check
     * @return {Promise<RoleState>} Role state of the user for the document
     * @memberof RoleService
     */
    private async getRoleStateDocument(document: DocumentEntity): Promise<RoleState> {
        return {
            [Action.create]: await this.enableActionDocument(document, Action.create),
            [Action.read]: await this.enableActionDocument(document, Action.read),
            [Action.update]: await this.enableActionDocument(document, Action.update),
            [Action.delete]: await this.enableActionDocument(document, Action.delete),
            [Action.import]: await this.enableActionDocument(document, Action.import),
            [Action.export]: await this.enableActionDocument(document, Action.export),
        };
    }

    /**
     * @description Get the role state of the user for a document (observable version)
     * @author Brisset Killian
     * @date 19/06/2024
     * @private
     * @param {DocumentEntity} document
     * @returns {*}  {Observable<RoleState>}
     * @memberof RoleService
     */
    private _getRoleStateDocument(document: DocumentEntity): Observable<RoleState> {
        const create$ = this._enableActionDocument(document, Action.create);
        const read$ = this._enableActionDocument(document, Action.read);
        const update$ = this._enableActionDocument(document, Action.update);
        const delete$ = this._enableActionDocument(document, Action.delete);
        const import$ = this._enableActionDocument(document, Action.import);
        const export$ = this._enableActionDocument(document, Action.export);

        const role$ = combineLatest([create$, read$, update$, delete$, import$, export$]).pipe(
            map(([create, read, update, del, import_, export_]) => {
                return {
                    [Action.create]: create,
                    [Action.read]: read,
                    [Action.update]: update,
                    [Action.delete]: del,
                    [Action.import]: import_,
                    [Action.export]: export_,
                };
            }),
        );
        return role$;
    }

    /**
     * @description Get the role state of the user for a loan (observable version)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/01/2025
     * @private
     * @param {LoanEntity} loan
     * @returns {*}  {Observable<RoleState>}
     * @memberof RoleService
     */
    private _getRoleStateLoan(loan: LoanEntity): Observable<RoleState> {
        const create$ = this._enableActionLoan(loan, Action.create);
        const read$ = this._enableActionLoan(loan, Action.read);
        const update$ = this._enableActionLoan(loan, Action.update);
        const delete$ = this._enableActionLoan(loan, Action.delete);
        const import$ = this._enableActionLoan(loan, Action.import);
        const export$ = this._enableActionLoan(loan, Action.export);

        const role$ = combineLatest([create$, read$, update$, delete$, import$, export$]).pipe(
            map(([create, read, update, del, import_, export_]) => {
                return {
                    [Action.create]: create,
                    [Action.read]: read,
                    [Action.update]: update,
                    [Action.delete]: del,
                    [Action.import]: import_,
                    [Action.export]: export_,
                };
            }),
        );
        return role$;
    }

    /**
     * @description Get the role state of the user for a saving (observable version)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/01/2025
     * @private
     * @param {SavingEntity} saving
     * @returns {*}  {Observable<RoleState>}
     * @memberof RoleService
     */
    private _getRoleStateSaving(saving: SavingEntity): Observable<RoleState> {
        const create$ = this._enableActionSaving(saving, Action.create);
        const read$ = this._enableActionSaving(saving, Action.read);
        const update$ = this._enableActionSaving(saving, Action.update);
        const delete$ = this._enableActionSaving(saving, Action.delete);
        const import$ = this._enableActionSaving(saving, Action.import);
        const export$ = this._enableActionSaving(saving, Action.export);

        const role$ = combineLatest([create$, read$, update$, delete$, import$, export$]).pipe(
            map(([create, read, update, del, import_, export_]) => {
                return {
                    [Action.create]: create,
                    [Action.read]: read,
                    [Action.update]: update,
                    [Action.delete]: del,
                    [Action.import]: import_,
                    [Action.export]: export_,
                };
            }),
        );
        return role$;
    }

    /**
     * @description Get the role state of the user for a property
     * @author ANDRE Felix
     * @private
     * @param {PropertyEntity} property
     * @returns {Promise<RoleState>} Role state of the user for the document
     * @memberof RoleService
     */
    private async getRoleStateProperty(property: PropertyEntity): Promise<RoleState> {
        return {
            [Action.create]: await this.enableActionProperty(property, Action.create),
            [Action.read]: await this.enableActionProperty(property, Action.read),
            [Action.update]: await this.enableActionProperty(property, Action.update),
            [Action.delete]: await this.enableActionProperty(property, Action.delete),
            [Action.import]: await this.enableActionProperty(property, Action.import),
            [Action.export]: await this.enableActionProperty(property, Action.export),
        };
    }

    /**
     * @description Get the role state of the user for a property (observable version)
     * @author Brisset Killian
     * @date 18/06/2024
     * @private
     * @param {PropertyEntity} property
     * @returns {*}  {Observable<RoleState>}
     * @memberof RoleService
     */
    private _getRoleStateProperty(property: PropertyEntity): Observable<RoleState> {
        const create$ = this._enableActionProperty(property, Action.create);
        const read$ = this._enableActionProperty(property, Action.read);
        const update$ = this._enableActionProperty(property, Action.update);
        const delete$ = this._enableActionProperty(property, Action.delete);
        const import$ = this._enableActionProperty(property, Action.import);
        const export$ = this._enableActionProperty(property, Action.export);

        const role$ = combineLatest([create$, read$, update$, delete$, import$, export$]).pipe(
            map(([create, read, update, del, import_, export_]) => {
                return {
                    [Action.create]: create,
                    [Action.read]: read,
                    [Action.update]: update,
                    [Action.delete]: del,
                    [Action.import]: import_,
                    [Action.export]: export_,
                };
            }),
        );
        return role$;
    }

    /**
     * @description Get the role state of the user for a society
     * @author ANDRE Felix
     * @private
     * @param {SocietyEntity} society
     * @returns {Promise<RoleState>} Role state of the user for the document
     * @memberof RoleService
     */
    private async getRoleStateSociety(society: SocietyEntity): Promise<RoleState> {
        return {
            [Action.create]: await this.enableActionSociety(society, Action.create),
            [Action.read]: await this.enableActionSociety(society, Action.read),
            [Action.update]: await this.enableActionSociety(society, Action.update),
            [Action.delete]: await this.enableActionSociety(society, Action.delete),
            [Action.import]: await this.enableActionSociety(society, Action.import),
            [Action.export]: await this.enableActionSociety(society, Action.export),
        };
    }

    /**
     * @description Get the role state of the user for a society (observable version)
     * @author Brisset Killian
     * @date 18/06/2024
     * @private
     * @param {SocietyEntity} society
     * @returns {*}  {Observable<RoleState>}
     * @memberof RoleService
     */
    private _getRoleStateSociety(society: SocietyEntity): Observable<RoleState> {
        const create$ = this._enableActionSociety(society, Action.create);
        const read$ = this._enableActionSociety(society, Action.read);
        const update$ = this._enableActionSociety(society, Action.update);
        const delete$ = this._enableActionSociety(society, Action.delete);
        const import$ = this._enableActionSociety(society, Action.import);
        const export$ = this._enableActionSociety(society, Action.export);

        const role$ = combineLatest([create$, read$, update$, delete$, import$, export$]).pipe(
            map(([create, read, update, del, import_, export_]) => {
                return {
                    [Action.create]: create,
                    [Action.read]: read,
                    [Action.update]: update,
                    [Action.delete]: del,
                    [Action.import]: import_,
                    [Action.export]: export_,
                };
            }),
        );
        return role$;
    }

    /**
     * @description Get the role state of the user for a story
     * @author ANDRE Felix
     * @private
     * @param {Story} story
     * @returns {*}  {Promise<RoleState>}
     * @memberof RoleService
     */
    private async getRoleStateStory(story: Story): Promise<RoleState> {
        const treasuryService = story.isCharge ? this.chargeService : this.incomeService;
        const treasury = await treasuryService.get(story.uid);
        let asset: PropertyEntity | SocietyEntity | undefined;
        if (treasury?.propertyUID) {
            asset = await this.propertyService.get(treasury.propertyUID);
        }
        if (treasury?.societyUID) {
            asset = await this.societyService.get(treasury.societyUID);
        }
        return {
            [Action.create]: await this.enableActionProperty(asset, Action.create),
            [Action.read]: await this.enableActionProperty(asset, Action.read),
            [Action.update]: await this.enableActionProperty(asset, Action.update),
            [Action.delete]: await this.enableActionProperty(asset, Action.delete),
            [Action.import]: await this.enableActionProperty(asset, Action.import),
            [Action.export]: await this.enableActionProperty(asset, Action.export),
        };
    }

    /**
     * @description Check if the user has the right to do an action on a document
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @private
     * @param {DocumentEntity} document Document to check
     * @param {Action} action Action to check
     * @return {Promise<boolean>} True if the user can do the action, false otherwise
     * @memberof RoleService
     */
    private async enableActionDocument(document: DocumentEntity, action: Action): Promise<boolean> {
        // Check if there is a user
        if (!this.user) {
            return false;
        }
        const role = await this.retrieveRoleDocument(document);
        return this.defineRoleByAction(action, role);
    }

    /**
     * @description Check if the user has the right to do an action on a document (observable version)
     * @author Brisset Killian
     * @date 18/06/2024
     * @private
     * @param {DocumentEntity} document
     * @param {Action} action
     * @returns {*}  {Observable<boolean>}
     * @memberof RoleService
     */
    private _enableActionDocument(document: DocumentEntity, action: Action): Observable<boolean> {
        // Check if there is a user
        if (!this.user) {
            return of(false);
        }
        const role$ = this._retrieveRoleDocument(document);
        const result$ = role$.pipe(
            map((role) => {
                return this.defineRoleByAction(action, role);
            }),
        );
        return result$;
    }

    /**
     * @description Check if the user has the right to do an action on a loan (observable version)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/01/2025
     * @private
     * @param {LoanEntity} loan
     * @param {Action} action
     * @returns {*}  {Observable<boolean>}
     * @memberof RoleService
     */
    private _enableActionLoan(loan: LoanEntity, action: Action): Observable<boolean> {
        // Check if there is a user
        if (!this.user) {
            return of(false);
        }
        const role$ = this._retrieveRoleLoan(loan);
        const result$ = role$.pipe(
            map((role) => {
                return this.defineRoleByAction(action, role);
            }),
        );
        return result$;
    }

    /**
     * @description Check if the user has the right to do an action on a saving (observable version)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/01/2025
     * @private
     * @param {SavingEntity} saving
     * @param {Action} action
     * @returns {*}  {Observable<boolean>}
     * @memberof RoleService
     */
    private _enableActionSaving(saving: SavingEntity, action: Action): Observable<boolean> {
        // Check if there is a user
        if (!this.user) {
            return of(false);
        }
        const role$ = this._retrieveRoleSaving(saving);
        const result$ = role$.pipe(
            map((role) => {
                return this.defineRoleByAction(action, role);
            }),
        );
        return result$;
    }

    /**
     * @description Check if the user has the right to do an action on a property
     * @author ANDRE Felix
     * @private
     * @param {PropertyEntity} property Property to check
     * @param {Action} action Action to check
     * @returns {Promise<boolean>} True if the user can do the action, false otherwise
     * @memberof RoleService
     */
    private async enableActionProperty(
        asset: PropertyEntity | SocietyEntity | undefined,
        action: Action,
    ): Promise<boolean> {
        // Check if there is a user
        if (!this.user || !asset) {
            return false;
        }
        const role = await this.retrieveRoleProperty(asset);
        const result = this.defineRoleByAction(action, role);
        return result;
    }

    /**
     * @description Check if the user has the right to do an action on a property (observable version)
     * @author Brisset Killian
     * @date 18/06/2024
     * @private
     * @param {(PropertyEntity | SocietyEntity | undefined)} asset
     * @param {Action} action
     * @returns {*}  {Observable<boolean>}
     * @memberof RoleService
     */
    private _enableActionProperty(
        asset: PropertyEntity | SocietyEntity | undefined,
        action: Action,
    ): Observable<boolean> {
        // Check if there is a user
        if (!this.user || !asset) {
            return of(false);
        }
        const role$ = this._retrieveRoleProperty(asset);
        const result$ = role$.pipe(
            map((role) => {
                return this.defineRoleByAction(action, role);
            }),
        );
        return result$;
    }

    /**
     * @description Check if the user has the right to do an action on a property
     * @author ANDRE Felix
     * @private
     * @param {PropertyEntity} property Property to check
     * @param {Action} action Action to check
     * @returns {Promise<boolean>} True if the user can do the action, false otherwise
     * @memberof RoleService
     */
    private async enableActionSociety(society: SocietyEntity, action: Action): Promise<boolean> {
        // Check if there is a user
        if (!this.user) {
            return false;
        }
        const role = await this.retrieveRoleSociety(society);
        const result = this.defineRoleByAction(action, role);
        return result;
    }

    /**
     * @description Check if the user has the right to do an action on a society (observable version)
     * @author Brisset Killian
     * @date 18/06/2024
     * @private
     * @param {SocietyEntity} society
     * @param {Action} action
     * @returns {*}  {Observable<boolean>}
     * @memberof RoleService
     */
    private _enableActionSociety(society: SocietyEntity, action: Action): Observable<boolean> {
        // Check if there is a user
        if (!this.user) {
            return of(false);
        }
        const role$ = this._retrieveRoleSociety(society);
        const result$ = role$.pipe(
            map((role) => {
                return this.defineRoleByAction(action, role);
            }),
        );
        return result$;
    }

    /**
     * @description Assign a role to a user for a document
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @private
     * @param {DocumentEntity} document Document to assign the role
     * @return {Promise<RoleAbsolute>} Role of the user for the document
     * @memberof RoleService
     */
    private async retrieveRoleDocument(document: DocumentEntity): Promise<RoleAbsolute> {
        // Check if there is a user
        if (!this.user) {
            throw new Error('There is no user');
        }
        // This document was created by the user
        if (document.userUID === this.user.uid) {
            return RoleAbsolute.owner;
        }

        // This document depends on a society
        if (document.societyUID) {
            this.getSocietyRoleByID(document.societyUID);
        }

        // This document depends on a property
        if (document.propertyUID) {
            let property: PropertyEntity | undefined;

            try {
                property = await this.propertyService.get(document.propertyUID);
            } catch (error) {
                if (OmedomEnvironment.currentEnvionment === 'development') {
                    console.error('Impossible to retrieve the property', error);
                }

                throw new Error('Impossible to retrieve the property');
            }

            // Check if the property exists
            if (!property) {
                throw new Error('The property does not exist');
            }

            // This property was created by the user
            if (property.userUID === this.user.uid) {
                return RoleAbsolute.owner;
            }

            const sharing = property.sharing.find((element) => element.email === this.user?.email);
            // This property is shared with the user
            if (sharing) {
                switch (sharing.role) {
                    //     case SocietyRoleMember.admin:
                    //         return RoleAbsolute.admin;

                    case SocietyRoleMember.editor:
                        return RoleAbsolute.editor;

                    case SocietyRoleMember.reader:
                        return RoleAbsolute.reader;

                    default:
                        return RoleAbsolute.reader;
                }
            }
        }

        // Default role
        return RoleAbsolute.none;
    }

    /**
     * @description Assign a role to a user for a document (observable version)
     * @author Brisset Killian
     * @date 18/06/2024
     * @private
     * @param {DocumentEntity} document
     * @returns {*}  {Observable<RoleAbsolute>}
     * @memberof RoleService
     */
    private _retrieveRoleDocument(document: DocumentEntity): Observable<RoleAbsolute> {
        // Check if there is a user
        if (!this.user) {
            throw new Error('There is no user');
        }
        // This document was created by the user
        if (document.userUID === this.user.uid) {
            return of(RoleAbsolute.owner);
        }

        if (this.user.proUID && document.userUID && document.userUID !== this.user.uid) {
            return this._getProRoleByID(this.user.proUID, document.userUID);
        }

        // This document depends on a society
        if (document.societyUID) {
            return this._getSocietyRoleByID(document.societyUID);
        }

        // This document depends on a property
        if (document.propertyUID) {
            let property$: Observable<PropertyEntity | undefined>;

            try {
                property$ = this.propertyService._get(document.propertyUID);
            } catch (error) {
                if (OmedomEnvironment.currentEnvionment === 'development') {
                    console.error('Impossible to retrieve the property', error);
                }

                throw new Error('Impossible to retrieve the property');
            }

            const role$ = property$.pipe(
                map((property) => {
                    // Check if the property exists
                    if (!property) {
                        throw new Error('The property does not exist');
                    }

                    // This property was created by the user
                    if (property.userUID === this.user?.uid) {
                        return RoleAbsolute.owner;
                    }

                    const sharing = property.sharing.find(
                        (element) => element.email === this.user?.email,
                    );
                    // This property is shared with the user
                    if (sharing) {
                        switch (sharing.role) {
                            //     case SocietyRoleMember.admin:
                            //         return RoleAbsolute.admin;

                            case SocietyRoleMember.editor:
                                return RoleAbsolute.editor;

                            case SocietyRoleMember.reader:
                                return RoleAbsolute.reader;

                            default:
                                return RoleAbsolute.reader;
                        }
                    }
                    return RoleAbsolute.none;
                }),
            );

            return role$;
        }

        // Default role
        return of(RoleAbsolute.none);
    }

    /**
     * @description Assign a role to a user for a loan (observable version)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/01/2025
     * @private
     * @param {LoanEntity} loan
     * @returns {*}  {Observable<RoleAbsolute>}
     * @memberof RoleService
     */
    private _retrieveRoleLoan(loan: LoanEntity): Observable<RoleAbsolute> {
        // Check if there is a user
        if (!this.user) {
            throw new Error('There is no user');
        }
        // This document was created by the user
        if (loan.userUID === this.user.uid) {
            return of(RoleAbsolute.owner);
        }

        if (this.user.proUID && loan.userUID && loan.userUID !== this.user.uid) {
            return this._getProRoleByID(this.user.proUID, loan.userUID);
        }

        // Default role
        return of(RoleAbsolute.none);
    }

    /**
     * @description Assign a role to a user for a saving (observable version)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/01/2025
     * @private
     * @param {SavingEntity} saving
     * @returns {*}  {Observable<RoleAbsolute>}
     * @memberof RoleService
     */
    private _retrieveRoleSaving(saving: SavingEntity): Observable<RoleAbsolute> {
        // Check if there is a user
        if (!this.user) {
            throw new Error('There is no user');
        }
        // This document was created by the user
        if (saving.userUID === this.user.uid) {
            return of(RoleAbsolute.owner);
        }

        if (this.user.proUID && saving.userUID && saving.userUID !== this.user.uid) {
            return this._getProRoleByID(this.user.proUID, saving.userUID);
        }

        // Default role
        return of(RoleAbsolute.none);
    }

    /**
     * @description Assign a role to a user for a property
     * @author ANDRE Felix
     * @private
     * @param {PropertyEntity} property Property to assign the role
     * @returns {Promise<RoleAbsolute>} Role of the user for the document
     * @memberof RoleService
     */
    private async retrieveRoleProperty(
        asset: PropertyEntity | SocietyEntity,
    ): Promise<RoleAbsolute> {
        // Check if there is a user
        if (!this.user) {
            throw new Error('There is no user');
        }
        if (asset.userUID === this.user.uid) {
            return RoleAbsolute.owner;
        }

        // if (property.societyUID) {
        //     return await this.getSocietyRoleByID(property.societyUID);
        // }

        if (asset.uid) {
            const sharing = asset.sharing.find((element) => element.email === this.user?.email);

            // This property is shared with the user
            if (sharing) {
                // return RoleAbsolute[sharing.role]
                switch (sharing.role) {
                    // case SocietyRoleMember.admin:
                    //     return RoleAbsolute.admin;

                    case SocietyRoleMember.editor:
                        return RoleAbsolute.editor;

                    case SocietyRoleMember.reader:
                        return RoleAbsolute.reader;

                    default:
                        return RoleAbsolute.reader;
                }
            }
        }
        if ('societyUID' in asset && asset.societyUID) {
            return await this.getSocietyRoleByID(asset.societyUID);
        }

        return RoleAbsolute.none;
    }

    /**
     * @description Assign a role to a user for a property of a user (observable version)
     * @author Brisset Killian
     * @date 18/06/2024
     * @private
     * @param {(PropertyEntity | SocietyEntity)} asset
     * @returns {*}  {Observable<RoleAbsolute>}
     * @memberof RoleService
     */
    private _retrieveRoleProperty(asset: PropertyEntity | SocietyEntity): Observable<RoleAbsolute> {
        // Check if there is a user
        if (!this.user) {
            throw new Error('There is no user');
        }
        if (asset.userUID === this.user.uid) {
            return of(RoleAbsolute.owner);
        }

        if (this.user.proUID && asset.userUID && asset.userUID !== this.user.uid) {
            return this._getProRoleByID(this.user.proUID, asset.userUID);
        }

        // if (property.societyUID) {
        //     return await this.getSocietyRoleByID(property.societyUID);
        // }

        if (asset.uid) {
            const sharing = asset.sharing.find((element) => element.email === this.user?.email);

            // This property is shared with the user
            if (sharing) {
                // return RoleAbsolute[sharing.role]
                switch (sharing.role) {
                    // case SocietyRoleMember.admin:
                    //     return RoleAbsolute.admin;

                    case SocietyRoleMember.editor:
                        return of(RoleAbsolute.editor);

                    case SocietyRoleMember.reader:
                        return of(RoleAbsolute.reader);

                    default:
                        return of(RoleAbsolute.reader);
                }
            }
        }
        if ('societyUID' in asset && asset.societyUID) {
            return this._getSocietyRoleByID(asset.societyUID);
        }

        return of(RoleAbsolute.none);
    }

    /**
     * @description Assign a role to a user for a society
     * @author Brisset Killian
     * @date 18/06/2024
     * @private
     * @param {SocietyEntity} society
     * @returns {*}  {Promise<RoleAbsolute>}
     * @memberof RoleService
     */
    private async retrieveRoleSociety(society: SocietyEntity): Promise<RoleAbsolute> {
        return await this.getSocietyRoleByID(society.uid);
    }

    /**
     * @description Assign a role to a user for a society (observable version)
     * @author Brisset Killian
     * @date 18/06/2024
     * @private
     * @param {SocietyEntity} society
     * @returns {*}  {Observable<RoleAbsolute>}
     * @memberof RoleService
     */
    private _retrieveRoleSociety(society: SocietyEntity): Observable<RoleAbsolute> {
        if (!this.user) {
            throw new Error('There is no user');
        }
        if (this.user.proUID && society.userUID && society.userUID !== this.user.uid) {
            return this._getProRoleByID(this.user.proUID, society.userUID);
        }
        return this._getSocietyRoleByID(society.uid);
    }

    /**
     * @description get role of a society of a user
     * @author ANDRE Felix
     * @private
     * @param {string} societyUID
     * @param {string} userUID
     * @returns {*}
     * @memberof RoleService
     */
    private async getSocietyRoleByID(societyUID: string) {
        if (!societyUID) {
            return RoleAbsolute.none;
        }

        let society: SocietyEntity | undefined;

        try {
            society = await this.societyService.get(societyUID);
        } catch (error) {
            if (OmedomEnvironment.currentEnvionment === 'development') {
                console.error('Impossible to retrieve the society', error);
            }

            throw new Error('Impossible to retrieve the society');
        }

        if (!society) {
            throw new Error('The society does not exist');
        }

        // This society was created by the user
        if (society.userUID === this.user?.uid) {
            return RoleAbsolute.owner;
        }

        const sharing = society.sharing?.find((element) => element.email === this.user?.email);

        // This society is shared with the user
        if (sharing) {
            switch (sharing.role) {
                //     case SocietyRoleMember.admin:
                //         return RoleAbsolute.admin;

                case SocietyRoleMember.editor:
                    return RoleAbsolute.editor;

                case SocietyRoleMember.reader:
                    return RoleAbsolute.reader;

                default:
                    return RoleAbsolute.reader;
            }
        }
        return RoleAbsolute.none;
    }

    private _getProRoleByID(proUID: string, userUID: string): Observable<RoleAbsolute> {
        return this.clientService
            ._search([
                {
                    where: 'proUID',
                    operator: '==',
                    value: proUID,
                },
                {
                    where: 'userUID',
                    operator: '==',
                    value: userUID,
                },
                {
                    limit: 1,
                    limitToLast: false,
                },
            ])
            .pipe(
                map((clients) => {
                    if (clients.length > 0 && clients[0].hasAgreedToEditByPro) {
                        return RoleAbsolute.owner;
                    }
                    return RoleAbsolute.none;
                }),
            );
    }

    /**
     * @description get role of a society of a user (observable version)
     * @author Brisset Killian
     * @date 18/06/2024
     * @private
     * @param {string} societyUID
     * @returns {*}  {Observable<RoleAbsolute>}
     * @memberof RoleService
     */
    private _getSocietyRoleByID(societyUID: string): Observable<RoleAbsolute> {
        if (!societyUID) {
            return of(RoleAbsolute.none);
        }

        let society$: Observable<SocietyEntity | undefined>;

        try {
            society$ = this.societyService._get(societyUID);
        } catch (error) {
            if (OmedomEnvironment.currentEnvionment === 'development') {
                console.error('Impossible to retrieve the society', error);
            }

            throw new Error('Impossible to retrieve the society');
        }

        const role$ = society$.pipe(
            map((society) => {
                if (!society) {
                    throw new Error('The society does not exist');
                }

                if (society.userUID === this.user?.uid) {
                    return RoleAbsolute.owner;
                }

                const sharing = society.sharing?.find(
                    (element) => element.email === this.user?.email,
                );

                if (sharing) {
                    switch (sharing.role) {
                        case SocietyRoleMember.editor:
                            return RoleAbsolute.editor;

                        case SocietyRoleMember.reader:
                            return RoleAbsolute.reader;

                        default:
                            return RoleAbsolute.reader;
                    }
                }
                return RoleAbsolute.none;
            }),
        );

        return role$;
    }

    /**
     * @description Check the right of a use on a property
     * @author ANDRE Felix
     * @private
     * @param {Action} action action to check
     * @param {RoleAbsolute} role role to check
     * @returns {*}  {boolean}
     * @memberof RoleService
     */
    private defineRoleByAction(action: Action, role: RoleAbsolute): boolean {
        switch (role) {
            // The user is the owner of the document or admin of the property or society
            // The user can do everything
            case RoleAbsolute.owner:
                // case RoleAbsolute.admin:
                return true;

            // The user is editor of the property or society
            // The user can do everything except delete and replace (only the owner can do that)
            case RoleAbsolute.editor:
                switch (action) {
                    case Action.read:
                    case Action.update:
                    case Action.export:
                    case Action.import:
                        return true;

                    case Action.create:
                    case Action.delete:
                        return false;

                    default:
                        return false;
                }

            // The user is reader of the property or society
            // The user can only read and download
            case RoleAbsolute.reader:
                switch (action) {
                    case Action.read:
                    case Action.export:
                        return true;

                    case Action.create:
                    case Action.delete:
                    case Action.import:
                    case Action.update:
                        return false;

                    default:
                        return false;
                }

            // The user does not have any role
            // The user can't do anything
            case RoleAbsolute.none:
                return false;

            default:
                return false;
        }
    }
}
