<div class="omedom-modal">
    <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
            <i
                class="uil uil-calendar-alt color-light-black background-color-light-orange"
            ></i>
            <h3>Résiliation d'abonnement</h3>
        </div>
        <i class="uil uil-times-circle" (click)="dismiss()"></i>
    </div>
    <div class="omedom-modal-content">
        <p>Êtes-vous sûr⋅e de vouloir résilier votre abonnement ?</p>
        <omedom-info
            information="En résiliant votre abonnement, vous n’aurez plus accès aux fonctionnalités disponibles.
              La résiliation sera effective à la fin de la période, mensuelle ou annuelle, en cours."
        >
        </omedom-info>
    </div>
    <div class="omedom-modal-buttons">
        <button class="omedom-button-secondary" (click)="dismiss()">
            Annuler
        </button>
        <button
            class="omedom-button-warn"
            [disabled]="pending$.asObservable() | async"
            (click)="cancelSubscription()"
        >
            <i class="uil uil-calendar-alt"></i>
            Oui, je résilie
            @if (pending$.asObservable() | async) {
                <ion-spinner
                    color="warn"
                    class="omedom-upload-spinner"
                ></ion-spinner>
            }
        </button>
    </div>
</div>
