import { Injectable } from '@angular/core';
import { PropertyEntity, Sharing, SocietyEntity, SocietyMember } from '@omedom/data';
import { PropertyService, SocietyService, UserService } from '../core';
import { OmedomShare } from '@omedom/utils';

@Injectable({
    providedIn: 'root',
})
export class ShareService {
    constructor(private societyService: SocietyService, private propertyService: PropertyService) { }

    public async addSocietySharing(
        share: Sharing,
        society: SocietyEntity,
        sharingIndex: number = -1
    ) {
        const updatedSociety = this.updateAssetSharing(share, society, sharingIndex);
        await this.societyService.update({
            uid: society.uid,
            sharing: updatedSociety.sharing,
        });
    }

    public async addPropertySharing(
        share: Sharing,
        property: PropertyEntity,
        sharingIndex: number = -1
    ) {
        const updatedProperty = this.updateAssetSharing(share, property, sharingIndex);
        await this.propertyService.update({
            uid: property.uid,
            sharing: updatedProperty.sharing,
        });
    }

    public async addSocietyMember(
        society: SocietyEntity,
        member: SocietyMember,
        sharingIndex: number | undefined
    ) {
        console.log('#addSocietyMember', society, member, sharingIndex);
        if (member && sharingIndex !== undefined) {
            society.members[sharingIndex] = member;
        } else {
            society.members.push(member);
        }

        const share = OmedomShare.transformSocietyMemberToShare(member);

        society.sharing.push(share);
        console.log('#addSocietyMember society.sharing', society.sharing);
        console.log('#addSocietyMember society.members', society.members);
        console.log('#addSocietyMember member.email', member.email);
        await this.societyService.update(society);
    }

    public async updateSocietyMember(
        society: SocietyEntity,
        member: SocietyMember,
        sharingIndex: number | undefined
    ): Promise<void> {
        if (member && sharingIndex !== undefined) {
            society.members[sharingIndex] = member;
        } else {
            society.members.push(member);
        }
        await this.societyService.update(society);
    }

    private updateAssetSharing(
        share: Sharing,
        entity: PropertyEntity | SocietyEntity,
        sharingIndex = -1
    ) {
        if (sharingIndex >= 0) {
            // we update an already existing sharing
            entity.sharing[sharingIndex] = share;
        } else {
            if (entity.sharing) {
                entity.sharing.push(share);
            } else {
                entity.sharing = [share];
            }
        }
        return entity;
    }

    public isEmailAlreadyInMembersOrSharing(
        shareEmail: string,
        societySharing: Sharing[],
        societyMembers: SocietyMember[]
    ) {
        // Search existing user in sharing
        const existingSharing = societySharing.findIndex((share) => {
            return shareEmail === share.email;
        });

        if (existingSharing > -1) {
            return true;
        }

        // Search existing user in member
        const existingMember = societyMembers.findIndex((member) => {
            return shareEmail === member.email;
        });

        if (existingMember > -1) {
            return true;
        }
        return false;
    }
}
