<div class="omedom-modal">
    <div class="omedom-modal-header w-100 margin-bottom-2 margin-top-2">
        <h3 class="flex-1">Validation à deux facteurs</h3>
        <span>
            <i class="uil uil-times-circle" (click)="close()"> </i>
        </span>
    </div>
    <hr class="omedom-divider w-100" />
    @if (!selectedMethod) {
        <omedom-info
            class="w-100 background-color-light-grey"
            information="Veuillez choisir une méthode de validation à deux facteurs."
        ></omedom-info>
        <div class="list">
            @if (emailVerification) {
                <div class="card" (click)="selectMethod('email')">
                    <i class="uil uil-envelope"></i>
                    <div class="card-content">
                        <h3>Vérification par e-mail</h3>
                        <p>
                            Un code de vérification vous sera envoyé par e-mail
                            à l'adresse suivante
                        </p>
                        <b>{{ multiFactorDetails.email.value }}</b>
                    </div>
                </div>
            }
            @if (false && phoneVerification) {
                <div class="card" (click)="selectMethod('phone')">
                    <i class="uil uil-mobile-android"></i>
                    <div class="card-content">
                        <h3>Vérification par sms</h3>
                        <p>
                            Un code de vérification vous sera envoyé par sms au
                            numéro
                        </p>
                        <b>06 ** ** ** 00</b>
                    </div>
                </div>
            }
        </div>
    }
    @if (selectedMethod) {
        <omedom-info
            class="w-100 background-color-light-grey"
            [information]="
                selectedMethod === 'email'
                    ? 'Un code de vérification vous a été envoyé par e-mail à l\'adresse suivante <b>' +
                      multiFactorDetails.email.value +
                      '</b>'
                    : 'Un code de vérification vous a été envoyé par sms au numéro suivant : <b>06 ** ** ** 00</b>'
            "
        ></omedom-info>
        <!-- 6 digits input -->
        <form [formGroup]="verificationForm" class="digit-form">
            <omedom-input
                formControlName="digit1"
                [max]="1"
                [min]="1"
                [pattern]="'[0-9]'"
                (keyup)="onKeyUp($event, 1)"
            ></omedom-input>
            <omedom-input
                formControlName="digit2"
                [max]="1"
                [min]="1"
                [pattern]="'[0-9]'"
                (keyup)="onKeyUp($event, 2)"
            ></omedom-input>
            <omedom-input
                formControlName="digit3"
                [max]="1"
                [min]="1"
                [pattern]="'[0-9]'"
                (keyup)="onKeyUp($event, 3)"
            ></omedom-input>
            <omedom-input
                formControlName="digit4"
                [max]="1"
                [min]="1"
                [pattern]="'[0-9]'"
                (keyup)="onKeyUp($event, 4)"
            ></omedom-input>
            <omedom-input
                formControlName="digit5"
                [max]="1"
                [min]="1"
                [pattern]="'[0-9]'"
                (keyup)="onKeyUp($event, 5)"
            ></omedom-input>
            <omedom-input
                formControlName="digit6"
                [max]="1"
                [min]="1"
                [pattern]="'[0-9]'"
                (keyup)="onKeyUp($event, 6)"
            ></omedom-input>
        </form>
        <div class="loading">
            @if (!verificationLoading) {
                <button
                    type="button"
                    class="omedom-button-primary"
                    [disabled]="waitBeforeResend > 0"
                    (click)="selectMethod('email')"
                >
                    Renvoyer le code
                    {{
                        waitBeforeResend > 0 ? '(' + waitBeforeResend + ')' : ''
                    }}
                </button>
                <button
                    type="button"
                    class="omedom-button-primary"
                    (click)="verify()"
                    [disabled]="!verificationForm.valid"
                >
                    Valider
                </button>
            }
            @if (verificationLoading) {
                <ion-spinner name="crescent" class="spinner"></ion-spinner>
                <p class="text-center">Vérification en cours</p>
            }
        </div>
    }
</div>
