<table class="w-100">
    <tr>
        <th class="sub-title" colspan="2">Eléments à rénover</th>
        <th class="sub-title">Coûts de rénovation</th>
    </tr>

    @for (renovation of transformationRenovations; track $index) {
        <tr class="text-grey">
            <td colspan="2">
                {{ renovation.type }}
                <i class="uil-arrow-right"></i> {{ renovation.value }}
            </td>
            <td>{{ renovation.costs.value | currency: 'EUR' }}</td>
        </tr>
    }
</table>
