import { BrevosTemplatesIds } from '../enums';
import { AutomationTemplateSendinblue } from '../interfaces';

export const templates: AutomationTemplateSendinblue[] = [
    // {
    //     templateId: BrevosTemplatesIds.subscriptionConfirmedWithReceipt,
    //     description: `🎨 #AV - ACHAT VALIDÉ - SMART - Microservice Firebase`,
    //     to: [{ email: 'user.email' }],
    //     params: {
    //         user_firstname: 'user.firstname',
    //         user_name: 'user.name',
    //         purchase_amount: 'user.subscription.amount',
    //         purchase_description: 'user.subscription.description'
    //     },
    // },
    // {
    //     templateId: BrevosTemplatesIds.accountDeletionAsked,
    //     description: `🎨 #SC - SUPPRESSION COMPTE - (COMPTE ACTIF) - Microservice Firebase`,
    //     to: [{ email: 'user.email' }],
    //     params: {
    //         activate_link: 'user.activateLink',
    //         delete_link: 'user.deleteLink'
    //     },
    // },
    // {
    //     templateId: BrevosTemplatesIds.emailVerification,
    //     description: `🎨 #DCE - DEMANDE CONFIRMATION EMAIL - Microservice Firebase`,
    //     to: [{ email: 'user.email' }],
    //     params: {
    //         firstname: 'user.firstname',
    //         name: 'user.name',
    //         verificationLink: 'user.verificationLink'
    //     },
    // },
    {
        templateId: BrevosTemplatesIds.subscriptionAborted,
        description: `🎨 #PA - PANIER ABANDONNÉ - (COMPTE ACTIF) - Microservice Firebase`,
        to: [{ email: 'user.email' }],
        params: {
            user_firstname: 'user.firstname',
            user_name: 'user.name',
            user_email: 'user.email',
        },
    },
    // {
    //     templateId: BrevosTemplatesIds.endSharingPropertyOrSociety,
    //     description: `🎨 #APBS - ARRÊT DE PARTAGE D'UN BIEN - TIERS - Microservice Firebase`,
    //     to: [{ email: 'user.email' }],
    //     params: {
    //         shared_firstname: 'property.sharing.old.firstname',
    //         shared_name: 'property.sharing.old.name',
    //         shared_email: 'property.sharing.old.email',
    //         user_firstname: 'user.firstname',
    //         user_name: 'user.name',
    //         type_description: 'un bien',
    //         description: 'le bien property.name'
    //     },
    // },
    {
        templateId: BrevosTemplatesIds.trialEndsIn3Days,
        description: `🎨 #FPDJ - 7 - FIN PÉRIODE DÉCOUVERTE 7 JOURS AVANT LA FIN - COMPTES ACTIFS - Sendinblue`,
        to: [{ email: 'user.email' }],
        params: {
            nom: 'user.name',
            prenom: 'user.firstname',
            case: 'case',
        },
    },
    // {
    //     templateId: BrevosTemplatesIds.sharedProrpertyOrSocietyReceived,
    //     description: `🎨 #PDB2 - PARTAGE D'UN BIEN QUI RECOIT - TIERS - Microservice Firebase`,
    //     to: [{ email: 'user.email' }],
    //     params: {
    //         nom: 'user.name',
    //         prenom: 'user.firstname',
    //     },
    // },
    // {
    //     templateId: BrevosTemplatesIds.endSharingPropertyOrSociety,
    //     description: `🎨 #APBS - ARRÊT DE PARTAGE D'UNE SOCIÉTÉ - TIERS - Microservice Firebase`,
    //     to: [{ email: 'user.email' }],
    //     params: {
    //         shared_firstname: 'society.sharing.old.firstname',
    //         shared_name: 'society.sharing.old.name',
    //         shared_email: 'society.sharing.old.email',
    //         user_firstname: 'user.firstname',
    //         user_name: 'user.name',
    //         type_description: 'une société',
    //         description: 'la société society.name'
    //     },
    // },
    // {
    //     templateId: BrevosTemplatesIds.sharingASocietyToMember,
    //     description: `🎨 #PUS - PARTAGE D'UNE SOCIÉTÉ - MEMBRE - Microservice Firebase`,
    //     to: [{ email: 'user.email' }],
    //     params: {
    //         shared_firstname: 'society.members.new.firstname',
    //         shared_name: 'society.members.new.name',
    //         shared_email: 'society.members.new.email',
    //         user_firstname: 'user.firstname',
    //         user_name: 'user.name',
    //         society_name: 'society.name'
    //     },
    // },
    {
        templateId: BrevosTemplatesIds.pricingChangesInToday,
        description: `🎨 #FPDM - 1 - FIN PÉRIODE DÉCOUVERTE 1 MOIS AVANT LA FIN - COMPTES ACTIFS - Sendinblue`,
        to: [{ email: 'user.email' }],
        params: {
            prenom: 'user.firstname',
            nom: 'user.name',
            case: 'case',
        },
    },
    {
        templateId: BrevosTemplatesIds.trialEnd15daysAgo,
        description: `🎨 #AFPDJ + 15 - APRÈS FIN PÉRIODE DÉCOUVERTE 15 JOURS APRÈS LA FIN - COMPTES ACTIFS - Sendinblue`,
        to: [{ email: 'user.email' }],
        params: {
            prenom: 'user.firstname',
            nom: 'user.name',
        },
    },
    {
        templateId: BrevosTemplatesIds.trialEnd1MonthAgo,
        description: `🎨 #AFPDM + 1 - APRÈS FIN PÉRIODE DÉCOUVERTE 1 MOIS APRÈS LA FIN - COMPTES ACTIFS - Sendinblue`,
        to: [{ email: 'user.email' }],
        params: {
            prenom: 'user.firstname',
            nom: 'user.name',
        },
    },
    {
        templateId: BrevosTemplatesIds.trialEnd3daysAgo,
        description: `🎨 #AFPDJ + 3 - APRÈS FIN PÉRIODE DÉCOUVERTE 3 JOURS APRÈS LA FIN - COMPTES ACTIFS - Sendinblue`,
        to: [{ email: 'user.email' }],
        params: {
            prenom: 'user.firstname',
            nom: 'user.name',
            case: 'case',
        },
    },
    {
        templateId: BrevosTemplatesIds.trialEndsIn1Day,
        description: `🎨 #FPDJ - 1 - FIN PÉRIODE DÉCOUVERTE 1 JOUR AVANT LA FIN - COMPTES ACTIFS - Sendinblue`,
        to: [{ email: 'user.email' }],
        params: {
            prenom: 'user.firstname',
            nom: 'user.name',
            case: 'case',
        },
    },
    {
        templateId: BrevosTemplatesIds.specialOfferActivated,
        description: `🎨 #OEA - OFFRE EXCEPTIONNELLE ACTIVÉE - (COMPTE FINALISÉ) - Microservice Firebase`,
        to: [{ email: 'user.email' }],
        params: {
            user_firstname: 'user.firstname',
            offer_end_date: 'user.subscription.end',
        },
    },
    // 118 Beta 1 mois
    // 117 Beta 1 an
];
