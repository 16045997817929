// DEVELOPMENT
const dev = {
    adjust: {
        appToken: 'j7cqnhk5an0g',
        app: 'OMEDOM-DEV',
    },
    firebase: {
        apiKey: 'AIzaSyDM4wBCcjPArgRUV-ZieJM37aj0tBjJ71o',
        authDomain: 'omedom-77fe5.firebaseapp.com',
        projectId: 'omedom-77fe5',
        locationId: 'europe-west',
        storageBucket: 'omedom-77fe5.appspot.com',
        messagingSenderId: '873146377704',
        appId: '1:873146377704:web:9f0571d8b344304ca4705d',
        vapidKey:
            'BNBNaZburttyrL37_dbqoRueTf6wqJ1IMEXA5aR4GzlHP9cAcE-B6nPF5q_OX1uiizZurk_ludBe9_XcnoYjXMo',
    },
    payplug: 'pk_test_RVfbtQe4k8vauXsTA7T3S',
    production: false,
    cgvCurrentDate: '2025-02-18',
};

// PRODUCTION
export const prod = {
    adjust: {
        appToken: 'wp648vyuqigw',
        app: 'OMEDOM',
    },
    firebase: {
        projectId: 'omedom-prod',
        appId: '1:479352102126:web:fa4ce709b34131298f27ff',
        storageBucket: 'omedom-prod.appspot.com',
        locationId: 'europe-west',
        apiKey: 'AIzaSyCdok-xpIWkmepasdmJUfThgIdaOb7zpUM',
        authDomain: 'omedom-prod.firebaseapp.com',
        messagingSenderId: '479352102126',
        vapidKey:
            'BCL0ceHiwmiCORYItZwpLHeTrhf-RUJMV7ygjAKcGSodfQ1mkKoHJJsfjwPUC8I1r7EvDHUaHltROyR-fCEYaWw',
    },
    payplug: 'pk_live_IqoMYsIW63rzvoRvafBoR',
    production: true,
    cgvCurrentDate: '2025-02-18',
};

/**
 * @description This class is used to get the environment variables
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 09/08/2023
 * @export
 * @class OmedomEnvironment
 */
export class OmedomEnvironment {
    /**
     * @description This method is used to get the development environment variables
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @readonly
     * @static
     * @type {typeof dev}
     * @memberof OmedomEnvironment
     */
    public static get development(): typeof dev {
        this.currentEnvionment = 'development';

        return dev;
    }

    /**
     * @description This method is used to get the production environment variables
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @readonly
     * @static
     * @type {typeof prod}
     * @memberof OmedomEnvironment
     */
    public static get production(): typeof prod {
        this.currentEnvionment = 'production';

        return prod;
    }

    /**
     * @description This method is used to get the current environment variables
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @type {('development' | 'production')}
     * @memberof OmedomEnvironment
     */
    public static currentEnvionment: 'development' | 'production' = 'development';
}
