<!-- Card -->

<div
    class="card card-{{ mode }} layout-{{ layout }}"
    [ngClass]="{
        'list-view': listView,
        'card-view': !listView,
        'hide-outside-shadow':
            showParentAsset &&
            parentAsset &&
            !listView &&
            mode === allMode.desktop
    }"
    [style]="gridTemplateColumns"
    (click)="
        isAccessible ? onPropertyClick() : openModal(smartModal);
        $event.stopPropagation()
    "
>
    @if (!isAccessible) {
        <div
            class="locked"
            [ngClass]="{ 'list-view': listView, 'card-view': !listView }"
        >
            <button class="omedom-icon-button-primary ng-star-inserted">
                <i class="omedom-icon-crown smart-option-icon"></i>
            </button>
        </div>
    }
    <!-- Header -->
    <div
        class="header"
        [class.flex-1]="mode !== allMode.desktop"
        [ngClass]="{ 'list-view': listView, 'card-view': !listView }"
    >
        <!-- Property avatar-->
        @if (property?.photo) {
            <img
                [src]="property?.photo"
                class="header-avatar"
                [ngClass]="{ 'list-view': listView, 'card-view': !listView }"
                alt="Avatar du bien"
            />
        } @else {
            <div
                class="header-icon"
                [ngClass]="{ 'list-view': listView, 'card-view': !listView }"
            >
                <i class="uil uil-{{ isBuilding ? 'building' : 'home' }}"></i>
            </div>
        }
        @if (listView) {
            <!-- Title in List view-->
            <div
                class="header-title-container list-view"
                [class.flex-1]="mode !== allMode.desktop"
            >
                <h3 class="header-title">{{ property?.name }}</h3>
                <span class="header-subtitle" *ngIf="society">
                    {{ society.name }}
                </span>
            </div>
        } @else {
            @if (showParentAsset && parentAsset) {
                <!-- Absoulte Container with parentAsset avatar -->
                @if (parentAsset.photo) {
                    <div class="parent-asset-container">
                        <img
                            [src]="parentAsset.photo"
                            class="parent-asset-avatar"
                            alt="Avatar du bien parent"
                        />
                    </div>
                } @else if (parentAsset.toSelectOption!().icon) {
                    <div class="parent-asset-container">
                        <i
                            [class]="parentAsset.toSelectOption!().icon"
                            class="parent-asset-icon"
                        ></i>
                    </div>
                }
            }
            <!-- Title in Card view-->
            <div class="header-title-container card-view">
                <h4 class="header-title">
                    {{ property?.name }}
                </h4>
                <span
                    *ngIf="!listView && mode === allMode.desktop"
                    class="header-subtitle-address"
                >
                    {{ getStringAddress(property) }}
                </span>
            </div>
        }

        <!-- Icons-->
        <ng-container
            *ngTemplateOutlet="
                mode === allMode.desktop || layout === assetCardLayout.CardMini
                    ? null
                    : iconContainer
            "
        ></ng-container>
    </div>
    <!-- Last and next movments in List view only-->
    @if (listView && showPastAndFutureMovements) {
        <div class="widgets">
            <div class="next-move">
                @if (mode !== allMode.desktop) {
                    <div class="next-move-header">
                        <span class="next-move-title">Dernier mouvement</span>
                    </div>
                }
                <omedom-movement-card
                    [story]="lastMouvement"
                    [isPropertyCard]="true"
                    [mode]="mode"
                ></omedom-movement-card>
            </div>
            <div class="next-move">
                @if (mode !== allMode.desktop) {
                    <div class="next-move-header">
                        <span class="next-move-title">Prochain mouvement</span>
                    </div>
                }
                <omedom-movement-card
                    [story]="nextMouvement"
                    [isPropertyCard]="true"
                    [futureCard]="true"
                    [mode]="mode"
                ></omedom-movement-card>
            </div>
        </div>
    }
    @if (showRemoveSociety && mode !== allMode.desktop) {
        <!-- Remove the property from the society in the deskton society view -->
        <div class="footer padding-top-1">
            <button
                class="omedom-button-secondary w-100"
                (click)="openModal(modal); $event.stopPropagation()"
            >
                <i class="uil uil-minus-circle"></i>
                Retirer le bien de la société
            </button>
        </div>
        <!-- } @if (canRemoveFromBuilding && mode !== allMode.desktop) { -->
    }
    @if (isLinkToBuilding && layout !== assetCardLayout.CardMini) {
        <!-- Remove the property from building in the desktop building view -->
        <div class="footer padding-top-1">
            <button
                class="omedom-button-secondary w-100"
                (click)="
                    openModal(modalUnlinkBuilding); $event.stopPropagation()
                "
            >
                <i class="uil uil-minus-circle"></i>
                Retirer le bien de l'immeuble
            </button>
        </div>
    }
    @if (
        !listView &&
        showValuation &&
        mode === allMode.desktop &&
        property?.valuation?.valuationSale?.value
    ) {
        <div class="valuation-container">
            <span
                >{{
                    property?.valuation?.valuationSale?.value ?? 0 | number
                }}
                €</span
            >
        </div>
    }

    <!-- Remove society in List view only -->
    @if (canRemoveFromSociety) {
        <i
            class="uil uil-minus-circle remove-icon"
            (click)="openModal(modal); $event.stopPropagation()"
        ></i>
    }
    <!-- Remove property in building, in property List view only -->
    @if (canRemoveFromBuilding) {
        <i
            class="uil uil-minus-circle remove-icon"
            (click)="openModal(modalUnlinkBuilding); $event.stopPropagation()"
        ></i>
    }

    <ion-modal
        #modal
        [breakpoints]="[0, 1]"
        initialBreakpoint="1"
        [canDismiss]="true"
    >
        <ng-template>
            <div class="omedom-modal">
                <div class="omedom-modal-header">
                    <div class="omedom-modal-header-title">
                        <i
                            class="uil uil-minus-circle color-light-black background-color-light-orange"
                        ></i>
                        <h3>Retirer le bien de la société</h3>
                    </div>
                    <i
                        class="uil uil-times-circle"
                        (click)="modal.dismiss()"
                    ></i>
                </div>
                <div class="omedom-modal-content">
                    <p>
                        Êtes-vous sûr.e de vouloir retirer ce bien de la société
                        ?
                    </p>
                    <omedom-info
                        information="Veuillez choisir un autre type de détention pour ce bien."
                    >
                    </omedom-info>
                    <omedom-select
                        [(ngModel)]="newOwningTypeId"
                        label="Détention"
                        class="margin-top-1"
                        [placeholder]="owningPlaceholder"
                        [options]="(owningOptions$ | async) || []"
                    ></omedom-select>
                </div>
                <div class="omedom-modal-buttons">
                    <button
                        class="omedom-button-secondary"
                        (click)="modal.dismiss()"
                    >
                        Annuler
                    </button>
                    <button
                        class="omedom-button-warn"
                        (click)="removeSociety(modal)"
                        [disabled]="
                            newOwningTypeId === owningTypes.society ||
                            !newOwningTypeId
                        "
                    >
                        <i class="uil uil-minus-circle"></i>
                        Oui retirer
                        @if (pending$.asObservable() | async) {
                            <ion-spinner
                                color="warn"
                                class="omedom-upload-spinner"
                            ></ion-spinner>
                        }
                    </button>
                </div>
            </div>
        </ng-template>
    </ion-modal>

    <ion-modal
        #modalUnlinkBuilding
        [breakpoints]="[0, 1]"
        initialBreakpoint="1"
        [canDismiss]="true"
    >
        <ng-template>
            <div class="omedom-modal">
                <div class="omedom-modal-header">
                    <div class="omedom-modal-header-title">
                        <i
                            class="uil uil-minus-circle color-light-black background-color-light-orange"
                        ></i>
                        <h3>Retirer le bien de cet immeuble</h3>
                    </div>
                    <i
                        class="uil uil-times-circle"
                        (click)="modalUnlinkBuilding.dismiss()"
                    ></i>
                </div>
                <div class="omedom-modal-content">
                    <p>
                        Êtes-vous sûr.e de vouloir retirer ce bien de cet
                        immeuble ?
                    </p>
                </div>
                <div class="omedom-modal-buttons">
                    <button
                        class="omedom-button-secondary"
                        (click)="modalUnlinkBuilding.dismiss()"
                    >
                        Annuler
                    </button>
                    <button
                        class="omedom-button-warn"
                        (click)="unlinkProperty(modalUnlinkBuilding)"
                    >
                        <i class="uil uil-minus-circle"></i>
                        Oui retirer
                        @if (pending$.asObservable() | async) {
                            <ion-spinner
                                color="warn"
                                class="omedom-upload-spinner"
                            ></ion-spinner>
                        }
                    </button>
                </div>
            </div>
        </ng-template>
    </ion-modal>

    <ion-modal
        #smartModal
        [breakpoints]="[0, 1]"
        initialBreakpoint="1"
        [canDismiss]="true"
    >
        <ng-template>
            <div class="omedom-modal">
                <div class="omedom-modal-header">
                    <i
                        class="uil uil-times-circle"
                        (click)="smartModal.dismiss()"
                    ></i>
                </div>

                <div class="omedom-modal-content" style="margin-bottom: 0">
                    @if (!isSharedProperty) {
                        <div>
                            <div class="smart-option-container">
                                <omedom-action
                                    class="margin-top-2 w-100"
                                    text="Fonctionnalité Premium"
                                    actionIcon="omedom-icon-crown"
                                    (click)="smartModal.dismiss()"
                                >
                                </omedom-action>
                                <button
                                    class="omedom-button-primary w-100"
                                    routerLink="/tabs/menu/subscription"
                                    [queryParams]="{ tab: 'manage' }"
                                    (click)="smartModal.dismiss()"
                                >
                                    Changer mon abonnement
                                </button>
                            </div>
                        </div>
                    }
                    @if (isSharedProperty) {
                        <div>
                            <p>
                                Le propriétaire de
                                <b>{{ property?.name }}</b> est en version
                                Essential et n'a plus les droits de partage pour
                                ce bien.
                            </p>
                        </div>
                    }
                </div>
            </div>
        </ng-template>
    </ion-modal>

    <ng-template #iconContainer>
        <div
            class="icon-container"
            [ngClass]="{ 'list-view': listView, 'card-view': !listView }"
        >
            <!-- Society Icon-->
            @if (
                society?.photo &&
                property?.societyUID &&
                !listView &&
                layout !== assetCardLayout.CardMini
            ) {
                <img
                    class="society-avatar"
                    [ngClass]="{
                        'list-view': listView,
                        'card-view': !listView
                    }"
                    [src]="society?.photo"
                    alt="Avatar de la société"
                />
            } @else if (
                !listView &&
                property?.societyUID &&
                !society?.photo &&
                layout !== assetCardLayout.CardMini
            ) {
                <i
                    class="uil uil-suitcase society-icon"
                    [ngClass]="{ 'disabled-icon': !property?.societyUID }"
                ></i>
            }
            @if (!listView && !property?.societyUID) {
                <div></div>
            }

            <!-- Shared Icon -->
            @if (isSharedProperty) {
                <i class="uil uil-share-alt"></i>
            }
        </div>

        @if (listView) {
            <i class="uil uil-angle-right-b header-next"></i>
        }
    </ng-template>
</div>
