<div class="omedom-page-container layout-{{ layout }}">
    <div class="omedom-page-container-content layout-{{ layout }}">
        <!-- Step 1 -->
        @if (activeStepForm === 0) {
            <div class="treasury-container w-100" id="treasuryTypeTuto">
                <div class="treasury-list">
                    @if (isCharge) {
                        @for (
                            category of chargeCategories;
                            track category.label
                        ) {
                            <div
                                class="omedom-button-toggle"
                                [class.selected]="
                                    treasury.category === category.category
                                "
                                (click)="onCategorySelected(category)"
                            >
                                <i
                                    class="omedom-button-toggle-icon"
                                    [class]="category.icon"
                                ></i>
                                <span class="omedom-button-toggle-title">{{
                                    category.label
                                }}</span>
                            </div>
                        }
                    }
                    @if (!isCharge) {
                        @for (
                            category of incomeCategories;
                            track category.label
                        ) {
                            <div
                                class="omedom-button-toggle"
                                [class.selected]="
                                    treasury.category === category.category
                                "
                                (click)="onCategorySelected(category)"
                            >
                                <i
                                    class="omedom-button-toggle-icon"
                                    [class]="category.icon"
                                ></i>
                                <span class="omedom-button-toggle-title">{{
                                    category.label
                                }}</span>
                            </div>
                        }
                    }
                </div>
                <div class="omedom-sticky-button-container">
                    <button
                        class="omedom-button-secondary flex-1 margin-right-1"
                        (click)="back()"
                    >
                        Annuler
                    </button>
                    <button
                        class="omedom-button-primary flex-1"
                        [disabled]="!treasury.category"
                        (click)="activeStepForm = activeStepForm + 1"
                    >
                        Suivant
                    </button>
                </div>
            </div>
        }

        <!-- Step 2 -->
        @if (
            activeStepForm === 1 &&
            (selectedChargeCategory || selectedIncomeCategory) &&
            treasury
        ) {
            <form #form="ngForm" class="w-100">
                <div class="treasury-header">
                    <i
                        [class]="
                            isCharge
                                ? selectedChargeCategory?.icon
                                : selectedIncomeCategory?.icon
                        "
                        class="treasury-icon"
                    ></i>
                    <span class="treasury-title">{{
                        isCharge
                            ? selectedChargeCategory?.label
                            : selectedIncomeCategory?.label
                    }}</span>
                </div>
                <div id="fieldsTuto">
                    <omedom-input
                        inputmode="decimal"
                        class="margin-top-2"
                        id="amount"
                        name="amount"
                        label="Montant"
                        icon="uil uil-euro"
                        [(ngModel)]="treasury.amount"
                        [debounce]="125"
                        (ngModelChange)="formatAmountNumber($event)"
                        [required]="true"
                        appOmedomNumber
                        placeholder="ex : {{ '70,00' | omedomNumber }}"
                    ></omedom-input>

                    @if (selectedIncomeCategory?.label === 'Loyer') {
                        <omedom-input
                            class="margin-top-2"
                            id="rentWithCharges"
                            name="rentWithCharges"
                            label="Loyer hors charges"
                            icon="uil uil-euro"
                            [(ngModel)]="rentWithCharges"
                            [debounce]="125"
                            (ngModelChange)="
                                formatAmountNumber($event, 'rentWithCharges')
                            "
                            [required]="false"
                            appOmedomNumber
                            placeholder="ex : {{ '70,00' | omedomNumber }}"
                        ></omedom-input>
                    }
                    <omedom-select
                        [disabled]="
                            ((propertyOptions$ | async) || []).length === 1 ||
                            (!(
                                property &&
                                property.lotsUID &&
                                property.lotsUID.length > 0
                            ) &&
                                (!!propertyUid || !isOptionPropertyReady))
                        "
                        class="margin-y-2"
                        name="property"
                        label="Associer au bien"
                        [required]="true"
                        [(ngModel)]="selectedProperty"
                        [placeholder]="propertyPlaceholder"
                        [options]="(propertyOptions$ | async) || []"
                        (selectExited)="handleAssociateChargeTo()"
                    ></omedom-select>
                </div>
                @if (isSelectedPropertyBuilding || isSelectedPropertySociety) {
                    @for (
                        propertyInfo of lotToAssociate;
                        track propertyInfo.propertyUid
                    ) {
                        <omedom-form-associate-to
                            [propertyInfo]="propertyInfo"
                            [totalAmount]="treasury.amount ?? 0"
                            (percentageHasChanged)="onPercentageChange($event)"
                            [disabled]="false"
                        ></omedom-form-associate-to>
                    }
                }
                <hr class="omedom-divider" />
                <omedom-select
                    [required]="true"
                    id="periodicityStepTuto"
                    label="Périodicité"
                    name="periodicity"
                    [(ngModel)]="selectedPeriodicity"
                    [placeholder]="periodicityPlaceholder"
                    [options]="(periodicityOptions$ | async) || []"
                ></omedom-select>
                @if (selectedPeriodicity) {
                    @if (selectedPeriodicity !== chargePeriodicity.punctual) {
                        <omedom-input
                            class="margin-top-2 w-50 padding-right-1"
                            type="date"
                            name="startDate"
                            label="Date de début"
                            placeholder="Date de début"
                            [(ngModel)]="startDate"
                            [required]="true"
                            min="1200-01-01"
                            max="2080-01-01"
                            warning="La “date de début“ est obligatoire et non modifiable car elle est liée à votre historique et au calcul de votre trésorerie."
                        >
                        </omedom-input>
                        <omedom-input
                            class="margin-top-2 w-50 padding-left-1"
                            type="date"
                            name="endDate"
                            label="Date de fin"
                            placeholder="Date de fin"
                            [(ngModel)]="endDate"
                            min="1200-01-01"
                            max="2080-01-01"
                        ></omedom-input>
                        <omedom-input
                            class="margin-top-2 padding-right-1"
                            type="number"
                            name="debitDate"
                            label="Jour de prélèvement"
                            placeholder="01"
                            [(ngModel)]="displayDebitDate"
                            [min]="01"
                            [max]="31"
                            [required]="true"
                            pattern="(0*[1-9]|[12]\d|3[01])"
                        ></omedom-input>
                    }
                    @if (selectedPeriodicity === chargePeriodicity.punctual) {
                        <omedom-input
                            class="margin-top-2 padding-right-1"
                            type="date"
                            name="debitDate"
                            label="Date de prélèvement"
                            placeholder="01"
                            [(ngModel)]="debitDate"
                            [required]="true"
                            min="1200-01-01"
                            max="2080-01-01"
                        ></omedom-input>
                    }
                }
                <hr class="omedom-divider" />
                <omedom-input
                    class="margin-bottom-2"
                    name="notes"
                    label="Complément d'information"
                    icon="uil uil-notes"
                    placeholder="Complément d'information"
                    [(ngModel)]="treasury.notes"
                ></omedom-input>
                <div class="omedom-sticky-button-container">
                    @if (!isCategorySelectedByRoute) {
                        <button
                            class="omedom-button-secondary flex-1 margin-right-1"
                            (click)="activeStepForm = activeStepForm - 1"
                        >
                            Précédent
                        </button>
                    }
                    <button
                        class="omedom-button-green flex-1"
                        [class.margin-left-1]="!isCategorySelectedByRoute"
                        (click)="submit()"
                        [disabled]="
                            form.invalid ||
                            !isTotalPercentageInRange ||
                            (pending$.asObservable() | async)
                        "
                    >
                        <i class="uil uil-save"></i>
                        Enregistrer
                        @if (pending$.asObservable() | async) {
                            <ion-spinner
                                color="primary"
                                class="form-upload-spinner"
                            ></ion-spinner>
                        }
                    </button>
                </div>
            </form>
        }
    </div>
</div>
