import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import {
    DPELevelType,
    DPEScore,
    energyConsumptionLevels,
    ghgEmissionLevels,
    PropertyEntity,
    RenovationDPE,
} from '@omedom/data';
import { PricehubbleService } from '@omedom/services';
import { OmedomPricehubble, OmedomPricehubbleMissingData } from '@omedom/utils';

import { showLoading } from '../../functions';
import { PricehubbleMissingDataModalComponent } from '../property';

@Component({
    selector: 'omedom-energy',
    templateUrl: './energy.component.html',
    styleUrls: ['./energy.component.scss'],
})
export class EnergyComponent implements OnChanges {
    /**
     * @description The property to display the energy information
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {PropertyEntity}
     * @memberof EnergyComponent
     */
    @Input({ required: true }) property!: PropertyEntity;

    /**
     * @description If the user can access the valuation of the property
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @memberof EnergyComponent
     */
    @Input({ required: true }) canAccessValuation = false;

    /**
     * @description If the user can start the valuation of the property
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @memberof EnergyComponent
     */
    @Input() canStartValuation = true;

    /**
     * @description If missingDataInView is true, the user will be able to see the missing data in the view, not in the modal
     * @author ANDRE Felix
     * @type {boolean}
     * @memberof EnergyComponent
     */
    @Input() missingDataInView: boolean = false;

    /**
     * @description Event when the user click on the button to go to the missing data view
     * @author ANDRE Felix
     * @memberof EnergyComponent
     */
    @Output() goToMissingDataView = new EventEmitter<void>();

    /**
     * @description Energy consumption rating of the property (kWh/m²/an)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {number}
     * @memberof EnergyComponent
     */
    public ecRating?: number;

    /**
     * @description Greenhouse gas emission rating of the property (kgCO₂/m²/an)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {number}
     * @memberof EnergyComponent
     */
    ghgRating?: number;

    /**
     * @description Energy consumption levels to display on the interface
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @memberof EnergyComponent
     */
    public get energyConsumptionLevels(): DPELevelType[] {
        if (this.property.energyPerformance?.energyLabelRanges) {
            return [
                ...this.property.energyPerformance.energyLabelRanges.ranges.map((energyRange) => {
                    const level = energyConsumptionLevels.find(
                        (level) => level.letter === energyRange.label,
                    );

                    return {
                        ...level,
                        max: energyRange.range.max ?? Math.min(),
                        min: energyRange.range.min ?? 0,
                        active: this.ecRating
                            ? (energyRange.range.min ?? 0) <= this.ecRating &&
                              this.ecRating <= (energyRange.range.max ?? Math.min())
                            : false,
                    } as DPELevelType;
                }),
            ];
        }

        return energyConsumptionLevels.map((level) => {
            return {
                ...level,

                active: this.ecRating
                    ? level.min <= this.ecRating && this.ecRating <= level.max
                    : false,
            } as DPELevelType;
        });
    }

    /**
     * @description Greenhouse gas emission levels to display on the interface
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @memberof EnergyComponent
     */
    public get ghgEmissionLevels(): DPELevelType[] {
        if (this.property.energyPerformance?.ghgEmissionRanges) {
            return [
                ...this.property.energyPerformance.ghgEmissionRanges.ranges.map((ghgRange) => {
                    const level = ghgEmissionLevels.find(
                        (level) => level.letter === ghgRange.label,
                    );

                    return {
                        ...level,
                        max: ghgRange.range.max ?? Math.min(),
                        min: ghgRange.range.min ?? 0,
                        active: this.ghgRating
                            ? (ghgRange.range.min ?? 0) <= this.ghgRating &&
                              this.ghgRating <= (ghgRange.range.max ?? Math.min())
                            : false,
                    } as DPELevelType;
                }),
            ];
        }

        return ghgEmissionLevels.map((level) => {
            return {
                ...level,
                active: this.ghgRating
                    ? level.min <= this.ghgRating && this.ghgRating <= level.max
                    : false,
            } as DPELevelType;
        });
    }

    /**
     * @description Current energy label of the property (A, B, C, D, E, F, G) (General DPE)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {DPEScore}
     * @memberof EnergyComponent
     */
    currentEnergyLabel?: DPEScore;

    /**
     * @description Estimated energy label of the property (A, B, C, D, E, F, G) (Estimated General DPE)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {DPEScore}
     * @memberof EnergyComponent
     */
    energyLabelEstimated?: DPEScore;

    /**
     * @description Renovation calculation of the property (renovations, cost, savings, etc.) (DPE)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {RenovationDPE[]}
     * @memberof EnergyComponent
     */
    propertyRenovations: RenovationDPE[] = [];

    /**
     * @description If the property has a valuation (Pricehubble data) or not (false by default) (Pricehubble)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @memberof EnergyComponent
     */
    hasValorisation = false;

    /**
     * @description Last valuation update of the property
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {string}
     * @memberof EnergyComponent
     */
    lastValuationUpdate?: string;

    /**
     * @description If the property is an appartement assimilate or not
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @memberof EnergyComponent
     */
    isAssimilateAppartement = false;

    /**
     * @description If the property is a house assimilate or not
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @memberof EnergyComponent
     */
    isAssimilateHouse = false;

    /**
     * @description If the property type is valid for valuation or not
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @memberof EnergyComponent
     */
    isPropertyTypeValidForValuation = false;

    constructor(
        private pricehubbleService: PricehubbleService,
        private modalController: ModalController,
        private loadingController: LoadingController,
    ) {}

    ngOnChanges() {
        this.refreshPricehubbleData();
    }

    /**
     * @description Refresh the Pricehubble data to display
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @private
     * @memberof EnergyComponent
     */
    private refreshPricehubbleData() {
        // if (this.isFakeData) {
        //     this.setFakeData();
        //     return;
        // }
        this.isPropertyTypeValidForValuation = OmedomPricehubble.isPropertyTypeValidForValuation(
            this.property,
        );
        this.isAssimilateAppartement = OmedomPricehubble.isPropertyAssimilateAppartement(
            this.property?.type,
        );
        this.isAssimilateHouse = OmedomPricehubble.isPropertyAssimilateHouse(this.property?.type);

        this.ecRating = this.getECRatingFromEstimation(this.property);
        this.ghgRating = this.getGhgRatingFromEstimation(this.property);
        this.currentEnergyLabel = this.property?.energyReport?.currentEnergyLabel?.value;
        this.energyLabelEstimated = this.property?.dpeDetails?.estimationResult?.energyLabel?.value;
        this.propertyRenovations = this.property?.renovationCalculation?.renovations || [];

        this.hasValorisation = this.property?.valuation ? true : false;
    }

    /**
     * @description Get the energy consumption rating from the estimation of the property (DPE or Pricehubble) (kWh/m²/an)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @private
     * @param {PropertyEntity} property
     * @returns {*}
     * @memberof EnergyComponent
     */
    private getECRatingFromEstimation(property: PropertyEntity) {
        if (!property?.energyReport) {
            return;
        }

        if ('currentEnergyConsumption' in property?.energyReport) {
            return property?.energyReport?.currentEnergyConsumption.measure.value;
        }

        return property?.dpeDetails?.estimationResult?.energyConsumption?.[0]?.measure?.value;
    }

    /**
     * @description Get the greenhouse gas emission rating from the estimation of the property (DPE or Pricehubble) (kgCO₂/m²/an)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @private
     * @param {PropertyEntity} property
     * @returns {*}
     * @memberof EnergyComponent
     */
    private getGhgRatingFromEstimation(property: PropertyEntity) {
        if (!property?.energyReport) {
            return;
        }

        if ('currentGhgEmission' in property?.energyReport) {
            return property?.energyReport?.currentGhgEmission.measure.value;
        }

        return property?.dpeDetails?.estimationResult?.ghgEmission?.measure?.value;
    }

    /**
     * @description Refresh the Pricehubble data of the property (energy, valuation, etc.) in database
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @returns {*}
     * @memberof EnergyComponent
     */
    async refreshPricehubble() {
        this.isPropertyTypeValidForValuation = OmedomPricehubble.isPropertyTypeValidForValuation(
            this.property,
        );
        if (!this.isPropertyTypeValidForValuation) {
            return;
        }
        const hasValidateRefresh = await this.checkMissingData(this.property);

        if (hasValidateRefresh) {
            this.doValorisation();
        }
    }

    /**
     * @description Launch the Pricehubble data refresh of the property
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @private
     * @memberof EnergyComponent
     */
    private async doValorisation() {
        const loadingModal = await showLoading(
            "Valorisation en cours d'estimation...",
            this.loadingController,
        );
        try {
            await this.pricehubbleService.refreshAllData(this.property);
        } catch (err) {
            console.error('error', err);
        }
        loadingModal.dismiss();
    }

    /**
     * @description Check if the property has missing data to refresh Pricehubble data
     *  and display a modal to complete the missing data if needed
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @private
     * @param {PropertyEntity} property
     * @returns {*}
     * @memberof EnergyComponent
     */
    private async checkMissingData(property: PropertyEntity) {
        // If user don't validate the modal to refresh pricehubble
        let hasValidateRefresh = false;

        if (!this.property?.uid) {
            return hasValidateRefresh;
        }

        if (!(this.isAssimilateAppartement || this.isAssimilateHouse)) {
            return hasValidateRefresh;
        }

        const missingData = this.getAllMissingData(property);

        hasValidateRefresh = await this.displayModalMissingData(
            missingData.missingRequiredData,
            missingData.missingOptionalData,
        );

        return hasValidateRefresh;
    }

    /**
     * @description Get all missing data of the property
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @private
     * @param {PropertyEntity} property
     * @returns {*}
     * @memberof EnergyComponent
     */
    private getAllMissingData(property: PropertyEntity) {
        if (this.isAssimilateAppartement) {
            return this.getMissingDataApartement(property);
        } else {
            return this.getMissingDataHouseGeneral(property);
        }
    }

    /**
     * @description Get missing data of the property if it's an appartement assimilate
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @private
     * @param {PropertyEntity} property
     * @returns {*}
     * @memberof EnergyComponent
     */
    private getMissingDataApartement(property: PropertyEntity) {
        const missingRequiredData =
            OmedomPricehubbleMissingData.missingRequiredFieldAppartment(property);

        const missingOptionalData =
            OmedomPricehubbleMissingData.missingDataApartementOptional(property);

        return {
            missingRequiredData: missingRequiredData,
            missingOptionalData: missingOptionalData,
        };
    }

    /**
     * @description Get missing data of the property if it's a house assimilate
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @private
     * @param {PropertyEntity} property
     * @returns {*}
     * @memberof EnergyComponent
     */
    private getMissingDataHouseGeneral(property: PropertyEntity) {
        const missingRequiredData =
            OmedomPricehubbleMissingData.missingRequiredFieldHouse(property);

        const missingOptionalData =
            OmedomPricehubbleMissingData.missingOptionalFieldHouse(property);
        return {
            missingRequiredData: missingRequiredData,
            missingOptionalData: missingOptionalData,
        };
    }

    /**
     * @description Display a modal to complete the missing data of the property to refresh Pricehubble data
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @param {object} missingRequiredData
     * @param {object} [missingOptionalData={}]
     * @returns {*}  {Promise<boolean>}
     * @memberof EnergyComponent
     */
    async displayModalMissingData(
        missingRequiredData: object,
        missingOptionalData: object = {},
    ): Promise<boolean> {
        const allMissingData = {
            ...missingRequiredData,
            ...missingOptionalData,
        };

        if (!OmedomPricehubbleMissingData.hasMissingProperty(allMissingData)) {
            return true;
        }

        // Check if missing data is in the view
        if (this.missingDataInView) {
            this.goToMissingDataView.emit();
            return false;
        }

        const modal = await this.modalController.create({
            component: PricehubbleMissingDataModalComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            componentProps: {
                property: this.property,
                missingRequiredData: missingRequiredData,
                missingOptionalData: missingOptionalData,
            },
        });
        await modal.present();

        return new Promise((resolve) => {
            modal.onDidDismiss().then((res) => {
                const confirmation = res.data;
                resolve(!!confirmation);
            });
        });
    }
}
