import { Timestamp } from 'firebase/firestore';

import { RestEntity } from './rest.entity';

export class ReferralEntity extends RestEntity {
    /**
     * @description Firstname of the referral user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @type {string}
     * @memberof ReferralEntity
     */
    firstname!: string;

    /**
     * @description Name of the referral user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @type {string}
     * @memberof ReferralEntity
     */
    name!: string;

    /**
     * @description Email of the referral user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @type {string}
     * @memberof ReferralEntity
     */
    email!: string;

    /**
     * @description Date of the account creation of the referral user (format: dd/mm/yyyy) (optional
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @type {boolean}
     * @memberof ReferralEntity
     */
    hasSubscription?: boolean;

    /**
     * @description UID of the referral user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @type {string}
     * @memberof ReferralEntity
     */
    referralUserUID!: string;

    /**
     * @description UID of the referral user (optional)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @type {string}
     * @memberof ReferralEntity
     */
    referralCode!: string;

    /**
     * @description
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @type {Date}
     * @memberof ReferralEntity
     */
    subscriptionCreatedDate?: Date;

    /**
     * @description Date of the account creation of the referral user (format: dd/mm/yyyy) (optional)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 05/02/2025
     * @type {Date}
     * @memberof ReferralEntity
     */
    userCreatedDate?: Date;

    /**
     * Creates an instance of ReferralEntity.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/01/2025
     * @param {Partial<ReferralEntity>} data
     * @memberof ReferralEntity
     */
    constructor(data: Partial<ReferralEntity>) {
        if (data?.subscriptionCreatedDate) {
            // Check if the subscriptionCreatedDate is a timestamp
            if (data.subscriptionCreatedDate instanceof Timestamp) {
                data.subscriptionCreatedDate = new Date(
                    data.subscriptionCreatedDate.seconds * 1000,
                );
            }
        }

        if (data?.userCreatedDate) {
            // Check if the userCreatedDate is a timestamp
            if (data.userCreatedDate instanceof Timestamp) {
                data.userCreatedDate = new Date(data.userCreatedDate.seconds * 1000);
            }
        }

        super(data);
    }
}

export interface UserReferralBalance {
    amount: number;
    date: Date;
    status: 'pending' | 'unlocked' | 'expired' | 'withdrawn' | 'confirmed';
    link?: string;
}

export class ReferralBalance {
    [userUID: string]: UserReferralBalance;

    constructor(data: Partial<ReferralBalance>) {
        Object.entries(data).forEach(([key, value]) => {
            if (!value) {
                return;
            }
            const date = value.date;

            if (date instanceof Timestamp) {
                value.date = new Date(date.seconds * 1000);
            }
        });

        Object.assign(this, data);
    }
}
