<div class="content">
    <!-- <span class="text">Pour consulter la politique « Sécurité utilisateurs » c'est par ici</span> -->
    <h3 class="titles">Mentions légales</h3>
    <span class="text">
        En vertu de l’Article 6 de la Loi n°2004-575 du 21 juin 2004 pour la
        confiance dans l’économie numérique, il est précisé dans cet article
        l’identité des différents intervenants dans le cadre de la mise en œuvre
        du site internet.
    </span>
    <span class="text">
        <b>Le site est édité par :</b><br />
        OMEDOM SAS au capital social de 29015,15 € immatriculée au registre du
        commerce et des sociétés d'Albi sous le numéro 895 124 949, et dont le
        siège social est situé 72 bis, Rue du Commandant Blanché 81000 ALBI<br />
        RCS d'Albi n°895 124 949<br />
        Siège social : 72 bis, Rue du Commandant Blanché 81000 ALBI<br />
        Tél. : + 33 (0) 6.71.917.857 <br />
        E-mail : contact&#64;omedom.com <br />
        Numéro de TVA intracommunautaire : FR87895124949 <br />
    </span>
    <h3 class="titles">DIRECTEUR DE LA PUBLICATION</h3>
    <span class="text">Coline SINQUIN</span>
    <h3 class="titles">HÉBERGEUR Application</h3>
    <span class="text" style="margin-bottom: 5px"
        >Google Cloud Platform (GCP)
    </span>
    <h3 class="titles">Site</h3>
    <span class="text" style="margin-bottom: 5px"
        >OVH - 2 rue Kellermann BP 80157 59053 ROUBAIX CEDEX 1 – Téléphone :
        1007
    </span>
</div>
