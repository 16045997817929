@if (loan) {
    <omedom-modal-header>{{ loan.name }}</omedom-modal-header>
    <omedom-modal-tab [tabs]="tabs"></omedom-modal-tab>
    <div class="container">
        <ng-container *ngTemplateOutlet="getTemplate()"></ng-container>
    </div>
} @else {
    <omedom-modal-header>Chargement...</omedom-modal-header>
    <div class="container">
        <div class="loading-container">
            <ion-spinner></ion-spinner>
        </div>
    </div>
}

<ng-template #loading>
    <omedom-modal-header>Chargement...</omedom-modal-header>

    <div class="container">
        <div class="loading-container">
            <ion-spinner></ion-spinner>
        </div>
    </div>
</ng-template>

<!-- Informations -->
<ng-template #info>
    <omedom-loan-details [loan]="loan" [layout]="layout"></omedom-loan-details>
</ng-template>

<ng-template #documents>
    @if (loan) {
        <omedom-document-list
            [documents]="(documents$ | async) || []"
            [addButton]="!!client?.hasAgreedToEditByPro"
            [displayProDocument]="true"
            [roleState]="(roleState$ | async) || defaultRoleState"
            (addDocument)="addDocument()"
            (documentClick)="documentClick($event)"
        >
        </omedom-document-list>
    }
</ng-template>
