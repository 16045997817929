@if (story) {
    <div class="property-image" [class.is-icon]="!story.propertyImg">
        <ng-container
            *ngTemplateOutlet="
                story.propertyImg ? propertyImg : defautPropertyImg
            "
        ></ng-container>
        <ng-template #propertyImg>
            <img [src]="story.propertyImg" alt="Photo du bien" />
        </ng-template>
        <ng-template #defautPropertyImg>
            @if (!isSocietyStory && !isBuildingStory) {
                <i class="uil uil-home"></i>
            }
            @if (isSocietyStory && !isBuildingStory) {
                <i class="uil uil-suitcase"></i>
            }
            @if (!isSocietyStory && isBuildingStory) {
                <i class="uil uil-building"></i>
            }
        </ng-template>
    </div>
}

<ion-content #ionContent>
    <div class="story-detail-container padding-2">
        <ng-container *ngTemplateOutlet="property"></ng-container>

        <ng-template #property>
            <div class="property">
                <div class="property-category-icon">
                    <i [class]="story.categoryInfo.icon"></i>
                </div>
                <div
                    class="property-info omedom-center-flex"
                    style="flex-direction: column"
                >
                    <div class="property-header-info">
                        <ng-container
                            *ngTemplateOutlet="
                                roleRight?.update ? notEditButtonTemplate : null
                            "
                        ></ng-container>

                        <ng-template #notEditButtonTemplate>
                            <i
                                class="uil uil-ellipsis-v"
                                style="color: #04151c"
                                (click)="editOrRemove.present()"
                            ></i>
                        </ng-template>
                    </div>
                    <div class="omedom-center-flex property-info-name">
                        <h2 class="margin-x-1">
                            {{ story.propertyName }}
                        </h2>
                        @if (story?.isTransaction || story?.designation) {
                            <i class="uil uil-link-alt"></i>
                        }
                    </div>
                    <div class="property-category-info w-100 padding-x-1">
                        <div class="omedom-center-flex w-100">
                            <h1 class="property-info-title margin-right-1">
                                {{ story.categoryInfo.label }}
                            </h1>
                            <div class="property-info-amount">
                                <ng-container
                                    *ngTemplateOutlet="
                                        isEdit
                                            ? editAmountTemplate
                                            : noEditAmountTemplate
                                    "
                                ></ng-container>

                                <ng-template #noEditAmountTemplate>
                                    <h1 [class.charge]="story.isCharge">
                                        {{ story.isCharge ? ' -' : '    +'
                                        }}{{
                                            (story.amount | number: '0.2-2') ??
                                                0 | omedomNumber
                                        }}&nbsp;{{ story.currency ?? '€' }}
                                    </h1>
                                </ng-template>
                                <ng-template #editAmountTemplate>
                                    <omedom-input
                                        name="amount"
                                        id="storyAmount"
                                        placeholder="Montant"
                                        label="Montant pour ce mois"
                                        [(ngModel)]="amount"
                                        (ngModelChange)="
                                            formatAmountNumber($event)
                                        "
                                        [debounce]="125"
                                        icon="uil-euro"
                                        appOmedomNumber
                                    ></omedom-input>
                                </ng-template>
                            </div>
                        </div>

                        @if (story.date && story.periodicityInfo) {
                            <div>
                                <span class="italic" style="color: #606060">
                                    {{
                                        formatPeriodicity(
                                            story.date,
                                            story.periodicityInfo.periodicity
                                        )
                                    }}
                                </span>
                            </div>
                        }
                    </div>
                    <div style="width: 100%">
                        <div class="property-info-date">
                            <i class="uil uil-calendar-alt"></i>
                            <span>
                                {{ intervalDate }}
                            </span>
                        </div>
                        @if (user && story.ownerUID) {
                            <ng-container
                                *ngTemplateOutlet="
                                    user.uid !== story.ownerUID
                                        ? sharedBy
                                        : sharing && sharing.length > 0
                                          ? sharedAt
                                          : null
                                "
                            ></ng-container>
                        }

                        <ng-template #sharedBy>
                            <div class="property-sharing">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_804_21650)">
                                        <path
                                            d="M17.7924 5.60458L10.7681 3.52743C10.7681 3.52743 10.515 3.45124 10.3916 3.53485C10.2758 3.6133 10.2869 3.82376 10.2869 3.82376L11.074 6.01564C11.074 6.01564 9.0904 6.8576 8.00091 7.62183C6.95247 8.35727 6.40802 8.84858 5.64741 9.78156C5.06553 10.4953 4.78769 10.9291 4.42209 11.7102C3.94145 12.737 3.80403 13.3529 3.72173 14.3975C3.63422 15.5083 4.05682 17.1577 4.05682 17.1577C4.05682 17.1577 4.81048 15.6693 5.53511 14.8096C6.27468 13.9322 6.79654 13.4771 7.77912 12.7665C8.63118 12.1504 9.1574 11.8359 10.1328 11.3805C11.0851 10.936 12.673 10.4683 12.673 10.4683L13.457 12.6517C13.457 12.6517 13.5877 12.7695 13.7095 12.7743C13.8423 12.7795 14.0454 12.6537 14.0454 12.6537L18.1185 6.51259C18.1185 6.51259 18.3282 6.1582 18.2635 5.96113C18.196 5.75588 17.7924 5.60458 17.7924 5.60458Z"
                                            stroke="black"
                                            stroke-width="1.8"
                                        />
                                        <path
                                            d="M20.2548 17.6426C19.8301 17.644 19.4168 17.7806 19.0748 18.0326L17.3248 17.2326L19.2348 16.3526C19.5367 16.5358 19.8817 16.6359 20.2348 16.6426C20.6304 16.6426 21.0171 16.5253 21.346 16.3055C21.6749 16.0858 21.9312 15.7734 22.0826 15.4079C22.234 15.0425 22.2736 14.6404 22.1964 14.2524C22.1192 13.8644 21.9287 13.5081 21.649 13.2284C21.3693 12.9487 21.013 12.7582 20.625 12.681C20.237 12.6038 19.8349 12.6434 19.4695 12.7948C19.104 12.9462 18.7916 13.2025 18.5719 13.5314C18.3521 13.8603 18.2348 14.247 18.2348 14.6426L16.3448 15.5126C16.0859 15.3288 15.7869 15.2094 15.4726 15.1641C15.1583 15.1189 14.8378 15.1491 14.5375 15.2523C14.2372 15.3555 13.9658 15.5287 13.7458 15.7576C13.5257 15.9864 13.3633 16.2644 13.272 16.5685C13.1807 16.8726 13.1631 17.1941 13.2207 17.5064C13.2782 17.8186 13.4093 18.1127 13.6031 18.3642C13.7969 18.6158 14.0477 18.8175 14.3349 18.9529C14.6222 19.0882 14.9375 19.1533 15.2548 19.1426C15.577 19.1427 15.8938 19.0601 16.1748 18.9026L18.2748 19.9026C18.3191 20.2852 18.473 20.6469 18.7179 20.9442C18.9628 21.2415 19.2884 21.4617 19.6554 21.5785C20.0225 21.6952 20.4155 21.7035 20.7871 21.6022C21.1588 21.501 21.4933 21.2946 21.7505 21.0079C22.0076 20.7211 22.1765 20.3662 22.2369 19.9858C22.2972 19.6053 22.2464 19.2156 22.0905 18.8633C21.9347 18.5111 21.6804 18.2113 21.3583 18.0001C21.0362 17.7889 20.66 17.6751 20.2748 17.6726L20.2548 17.6426Z"
                                            fill="#04151C"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_804_21650">
                                            <rect
                                                width="24"
                                                height="24"
                                                fill="white"
                                                transform="translate(0.867188 0.234375)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <span> Partagé par {{ ownerFullName }}</span>
                            </div>
                        </ng-template>
                        <ng-template #sharedAt>
                            <div class="property-sharing property-shared-at">
                                <div class="property-shared-at-header">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clip-path="url(#clip0_804_21691)">
                                            <path
                                                d="M18.3759 18.0449C17.9512 18.0463 17.5379 18.1829 17.1959 18.4349L15.4459 17.6349L17.3559 16.7549C17.6578 16.9382 18.0028 17.0382 18.3559 17.0449C18.7515 17.0449 19.1382 16.9276 19.4671 16.7079C19.796 16.4881 20.0523 16.1757 20.2037 15.8103C20.3551 15.4448 20.3947 15.0427 20.3175 14.6547C20.2403 14.2668 20.0498 13.9104 19.7701 13.6307C19.4904 13.351 19.1341 13.1605 18.7461 13.0834C18.3581 13.0062 17.956 13.0458 17.5906 13.1972C17.2251 13.3485 16.9127 13.6049 16.693 13.9338C16.4732 14.2627 16.3559 14.6494 16.3559 15.0449L14.4659 15.9149C14.207 15.7312 13.908 15.6117 13.5937 15.5665C13.2794 15.5212 12.9589 15.5514 12.6586 15.6546C12.3583 15.7578 12.0869 15.931 11.8669 16.1599C11.6468 16.3888 11.4844 16.6668 11.3931 16.9709C11.3018 17.275 11.2842 17.5965 11.3417 17.9087C11.3993 18.221 11.5304 18.515 11.7242 18.7666C11.9179 19.0181 12.1688 19.2199 12.456 19.3552C12.7433 19.4906 13.0586 19.5556 13.3759 19.5449C13.6981 19.5451 14.0149 19.4624 14.2959 19.3049L16.3959 20.3049C16.4402 20.6876 16.5941 21.0492 16.839 21.3465C17.0839 21.6438 17.4095 21.8641 17.7765 21.9808C18.1436 22.0976 18.5366 22.1058 18.9082 22.0046C19.2799 21.9034 19.6144 21.697 19.8716 21.4102C20.1287 21.1235 20.2976 20.7686 20.358 20.3881C20.4183 20.0077 20.3675 19.6179 20.2116 19.2657C20.0558 18.9134 19.8015 18.6137 19.4794 18.4024C19.1573 18.1912 18.7811 18.0775 18.3959 18.0749L18.3759 18.0449Z"
                                                fill="#04151C"
                                            />
                                            <path
                                                d="M2.93587 12.2056L5.42999 5.31839C5.42999 5.31839 5.5188 5.0694 5.65932 5.01964C5.79115 4.97295 5.95401 5.10673 5.95401 5.10673L7.2518 7.04052C7.2518 7.04052 9.10624 5.94293 10.3678 5.51909C11.5817 5.11122 12.3002 4.96431 13.5025 4.90535C14.4223 4.86024 14.9363 4.89385 15.782 5.06279C16.8938 5.28489 17.4712 5.53958 18.361 6.09294C19.3072 6.68136 20.3844 7.9999 20.3844 7.9999C20.3844 7.9999 18.7391 7.72381 17.6172 7.7973C16.4721 7.87231 15.7961 8.02248 14.6413 8.39209C13.6398 8.7126 13.0745 8.94974 12.1293 9.46493C11.2066 9.96789 9.88822 10.969 9.88822 10.969L11.181 12.8952C11.181 12.8952 11.1983 13.0702 11.1299 13.1712C11.0554 13.2813 10.8336 13.3701 10.8336 13.3701L3.47349 13.0067C3.47349 13.0067 3.06376 12.9654 2.94351 12.7964C2.81828 12.6203 2.93587 12.2056 2.93587 12.2056Z"
                                                stroke="black"
                                                stroke-width="1.8"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_804_21691">
                                                <rect
                                                    width="24"
                                                    height="24"
                                                    fill="white"
                                                    transform="translate(0.183594 0.765625)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span class="italic"> Partagé à </span>
                                </div>
                                @for (
                                    share of sharing?.slice(
                                        0,
                                        seeAllSharing ? sharing?.length : 1
                                    );
                                    track share.email
                                ) {
                                    <div class="property-shared-at-item">
                                        <span class="italic">
                                            {{ share.firstname }}
                                            {{ share.name }}
                                        </span>
                                        <span
                                            class="italic"
                                            style="color: #a8adaf"
                                        >
                                            {{ share.role }}
                                        </span>
                                    </div>
                                }
                                @if (sharing && sharing.length > 1) {
                                    <div
                                        (click)="seeAllSharing = !seeAllSharing"
                                        class="property-shared-at-see-more"
                                    >
                                        <span>
                                            {{
                                                seeAllSharing
                                                    ? 'Voir moins'
                                                    : 'Voir plus'
                                            }}</span
                                        >
                                        <i
                                            class="uil"
                                            [class.uil-angle-down]="
                                                !seeAllSharing
                                            "
                                            [class.uil-angle-up]="seeAllSharing"
                                        ></i>
                                    </div>
                                }
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #nonAssigned>
            <div class="property">
                <div
                    class="property-image"
                    [class.is-icon]="!story.propertyImg"
                >
                    <i class="uil uil-clipboard-notes"></i>
                </div>
                <h1 class="margin-y-2">Non assigné</h1>
            </div>
        </ng-template>
        @if (story.notes) {
            <div class="property-info" style="padding: 10px 20px">
                <!-- Just note on three line max -->
                <span
                    class="property-info-notes italic"
                    [class.show-all]="showAllNote"
                    (click)="showAllNote = !showAllNote"
                    [style.font-weight]="story.isTransaction ? '600' : '400'"
                >
                    {{ story.notes }}</span
                >
            </div>
        }

        <div class="property-info" style="padding: 10px 20px">
            <omedom-story-footer
                [isDateBeforeToday]="isStoryBeforeToday()"
                [isBankingMovment]="!!story.isTransaction"
                [canUpdateStory]="!!roleRight?.update"
                [isCharge]="story.isCharge"
                [isWaitingData]="isWaitingData"
                (storyValidateClick)="storyValidateClick()"
                (storyNotValidateClick)="storyNotValidateClick()"
            ></omedom-story-footer>
        </div>
        <!-- <div #test class="story w-100 flex-1">
        <div
          class="story-info w-100 padding-2 margin-y-1 omedom-border-radius"
          [class.is-edit]="isEdit"
          >
          <div
            class="story-info-actions"
            *ngIf="!story.isTransaction && !story.validateAssigning"
            >
            <ng-template #editButtonTemplate>
              <button class="omedom-button-secondary margin-right-1"
                (click)="toggleEdit()">
                Annuler
              </button>

              <button class="omedom-button-green flex-1 margin-left-1"
                (click)="updateTreasury()">
                <i class="uil uil-save"></i>
                Enregistrer
              </button>
            </ng-template>
          </div>
        </div>

        <div *ngIf="story.isTransaction">
          <strong> le {{ story.date | date : 'dd/MM/y' }}</strong>
        </div>
      </div> -->

        <!-- This section should be uncommened for future version:  -->
        <!-- <app-action
      text="Fonctionnalité Premium, changez d'abonnement."
      actionIcon="omedom-icon-crown"
      >
    </app-action> -->
    </div>
</ion-content>

<ion-modal
    [breakpoints]="[0, 1]"
    initialBreakpoint="1"
    [canDismiss]="true"
    #editOrRemove
>
    <ng-template>
        <div class="omedom-modal">
            <div class="omedom-modal-header">
                <i
                    class="uil uil-times-circle"
                    (click)="editOrRemove.dismiss()"
                ></i>
            </div>
            <div
                (click)="editClicked(story); editOrRemove.dismiss()"
                class="modal-item"
            >
                <i class="uil uil-edit-alt"></i>
                <span> Modifier</span>
            </div>
            <div
                (click)="deleteClicked(story); editOrRemove.dismiss()"
                class="modal-item"
            >
                <!-- Delete this notification -->
                <i class="uil uil-trash"></i>
                <span>Supprimer</span>
            </div>
        </div>
    </ng-template>
</ion-modal>
