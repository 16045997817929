import { Timestamp } from 'firebase/firestore';

import { PostalAddress } from '../interfaces';
import { RestEntity } from './rest.entity';

export enum LeaseType {
    empty = 'Vide',
    furnished = 'Meublé',
    coleasing = 'Co-location',
    student = 'Etudiant',
    mobility = 'Mobilité',
}

export class LeaseEntity extends RestEntity {
    /**
     * @description Date of the beginning of the lease
     * @author Martin Bastié
     * @type {string[]}
     * @memberof TenantEntity
     */
    leaseStart!: Date;

    /**
     * @description Date of the end of the lease
     * @author Martin Bastié
     * @type {string[]}
     * @memberof TenantEntity
     */
    leaseEnd!: Date;

    /**
     * @description Type of the lease
     * @author Jérémie Lopez
     * @type {LeaseType}
     * @memberof LeaseEntity
     */
    leaseType!: {
        id: LeaseType;
        isSelected: boolean;
        label: LeaseType;
    };

    /**
     * @description Amount of the deposit of the guaranty
     * @author Martin Bastié
     * @type {string[]}
     * @memberof TenantEntity
     */
    guarantyDeposit!: number;

    /**
     * @description Property of the lease
     * @author Jérémie Lopez
     * @type {string}
     * @memberof LeaseEntity
     */
    propertyUID!: string;

    /**
     * @description Rent of the lease
     * @author Jérémie Lopez
     * @type {string}
     * @memberof LeaseEntity
     */
    rentUID!: string;

    /**
     * @description Fiduciary of the lease
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 04/03/2025
     * @type {Fiduciary}
     * @memberof LeaseEntity
     */
    fiduciary?: Fiduciary;

    constructor(data: Partial<LeaseEntity>) {
        if (data?.leaseStart instanceof Timestamp) {
            data.leaseStart = new Date(data.leaseStart.seconds * 1000);
        }

        if (data?.leaseEnd instanceof Timestamp) {
            data.leaseEnd = new Date(data.leaseEnd.seconds * 1000);
        }

        super(data);
    }
}

export class Fiduciary {
    /**
     * @description Name of the fiduciary
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 04/03/2025
     * @type {string}
     * @memberof Fiduciary
     */
    name?: string;

    /**
     * @description E-mail of the fiduciary
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 04/03/2025
     * @type {string}
     * @memberof Fiduciary
     */
    email?: string;

    /**
     * @description Phone number of the fiduciary
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 04/03/2025
     * @type {string}
     * @memberof Fiduciary
     */
    tel?: string;

    /**
     * @description Address of the fiduciary
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 05/03/2025
     * @type {PostalAddress}
     * @memberof Fiduciary
     */
    address?: PostalAddress;
}
