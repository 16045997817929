<div class="omedom-modal">
    <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
            <i
                class="uil uil-times-circle color-light-black background-color-light-orange"
            ></i>
            <h3>Echec du paiement</h3>
        </div>
        <i class="uil uil-times-circle" (click)="dismiss()"></i>
    </div>
    <div class="omedom-modal-content">
        <p>Mince ! Votre paiement a échoué.</p>
        <p>Veuillez réessayer ou essayer avec une autre carte</p>
    </div>
    <div class="omedom-modal-buttons-wide">
        <button class="omedom-button-secondary w-100" (click)="dismiss()">
            Retourner à la gestion d’abonnement
        </button>
        <button class="omedom-button-primary w-100" (click)="retry()">
            Réessayer
        </button>
    </div>
</div>
