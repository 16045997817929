@if (society) {
    <omedom-document-list
        [documents]="(documents$ | async) || []"
        [addButton]="!!client?.hasAgreedToEditByPro"
        [societies]="[society]"
        [roleState]="(roleState$ | async) || defaultRoleState"
        [displayProDocument]="true"
        (addDocument)="addDocument.emit($event)"
        (documentClick)="documentClick($event)"
    >
    </omedom-document-list>
}
