import { Component, Input, OnInit } from '@angular/core';
import { DPELevelType } from '@omedom/data';

@Component({
    selector: 'omedom-dpe-interface',
    templateUrl: './dpe-interface.component.html',
    styleUrls: ['./dpe-interface.component.scss'],
})
export class DPEInterfaceComponent implements OnInit {
    /**
     * @description The rating to display
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @memberof DPEInterfaceComponent
     */
    @Input({ required: true }) public rating? = 0;

    /**
     * @description The rating type to display (kWh/m²/an, kgCO₂/m²/an, etc.)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @memberof DPEInterfaceComponent
     */
    @Input({ required: true }) public ratingType = '';

    /**
     * @description The levels to display on the interface
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {DPELevelType[]}
     * @memberof DPEInterfaceComponent
     */
    @Input({
        required: true,
    })
    public levels: DPELevelType[] = [];

    /**
     * @description The infinity value to display on the interface (optional)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/02/2025
     * @memberof DPEInterfaceComponent
     */
    public infinity = Math.min();

    /**
     * @description The title to display on the interface (optional)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @type {string}
     * @memberof DPEInterfaceComponent
     */
    @Input() public subTitle: string = '';

    /**
     * @description The text to display on the interface (rating + ratingType) (optional)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/02/2025
     * @memberof DPEInterfaceComponent
     */
    ratingText = '';

    constructor() {}

    ngOnInit() {
        this.ratingText = `${this.rating} ${this.ratingType}`;
    }
}
