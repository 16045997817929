@if (property) {
    <omedom-modal-header>{{ property.name }}</omedom-modal-header>
    <omedom-modal-tab [tabs]="tabs"></omedom-modal-tab>
    <div
        class="container"
        [style.padding-top.px]="this.activeTab.name === 'valuation' ? 20 : 40"
    >
        <ng-container *ngTemplateOutlet="getTemplate()"></ng-container>
    </div>
} @else {
    <omedom-modal-header>Chargement...</omedom-modal-header>
    <div class="container">
        <div class="loading-container">
            <ion-spinner></ion-spinner>
        </div>
    </div>
}

<ng-template #loading>
    <omedom-modal-header>Chargement...</omedom-modal-header>

    <div class="container">
        <div class="loading-container">
            <ion-spinner></ion-spinner>
        </div>
    </div>
</ng-template>

<!-- Informations -->
<ng-template #info>
    <omedom-property-profil-info
        [property]="property"
    ></omedom-property-profil-info>
</ng-template>

<!-- Valorisation -->
<ng-template #valuation>
    @if (property) {
        <omedom-valuation
            [canStartValuation]="canStartValuation"
            [property]="property"
            [canAccessValuation]="true"
        ></omedom-valuation>
    }
</ng-template>

<!-- Tresorerie -->
<ng-template #treasury>
    <omedom-property-profil-treasury
        [property]="property"
    ></omedom-property-profil-treasury>
</ng-template>

<!-- Documents -->
<ng-template #documents>
    <omedom-property-profil-documents
        [property]="property"
        [client]="client"
        (addDocument)="addDocument()"
        (editDocument)="editDocument($event)"
    ></omedom-property-profil-documents>
</ng-template>
