import {
    DocumentTypeClient,
    DocumentTypeProImportable,
    DocumentTypeProNotImportable,
} from '../entities';
import { SelectOption } from '../models';

export const documentTypeOptionsClient: SelectOption[] = [
    {
        id: DocumentTypeClient.title,
        label: DocumentTypeClient.title,
    },
    {
        id: DocumentTypeClient.receipt,
        label: DocumentTypeClient.receipt,
    },
    {
        id: DocumentTypeClient.quotation,
        label: DocumentTypeClient.quotation,
    },
    {
        id: DocumentTypeClient.charge,
        label: DocumentTypeClient.charge,
    },
    {
        id: DocumentTypeClient.condominium,
        label: DocumentTypeClient.condominium,
    },
    {
        id: DocumentTypeClient.tax,
        label: DocumentTypeClient.tax,
    },
    {
        id: DocumentTypeClient.paiementSchedule,
        label: DocumentTypeClient.paiementSchedule,
    },
    {
        id: DocumentTypeClient.insurance,
        label: DocumentTypeClient.insurance,
    },
    {
        id: DocumentTypeClient.energyDiagnosis,
        label: DocumentTypeClient.energyDiagnosis,
    },
    {
        id: DocumentTypeClient.managementReports,
        label: DocumentTypeClient.managementReports,
    },
    {
        id: DocumentTypeClient.bankAccountStatements,
        label: DocumentTypeClient.bankAccountStatements,
    },
    {
        id: DocumentTypeClient.lease,
        label: DocumentTypeClient.lease,
    },
    {
        id: DocumentTypeClient.stateOfPlay,
        label: DocumentTypeClient.stateOfPlay,
    },
    {
        id: DocumentTypeClient.quittance,
        label: DocumentTypeClient.quittance,
    },
    {
        id: DocumentTypeClient.photo,
        label: DocumentTypeClient.photo,
    },
    {
        id: DocumentTypeClient.other,
        label: DocumentTypeClient.other,
    },
    {
        id: DocumentTypeClient.information,
        label: DocumentTypeClient.information,
    },
    {
        id: DocumentTypeClient.mandate,
        label: DocumentTypeClient.mandate,
    },
].sort((a, b) => (a.id as string).localeCompare(b.id as string));

export const documentTypeOptionsProImportable: SelectOption[] = [
    {
        id: DocumentTypeProImportable.rcs,
        label: DocumentTypeProImportable.rcs,
    },
].sort((a, b) => (a.id as string).localeCompare(b.id as string));

export const documentTypeOptionsProNotImportable: SelectOption[] = [
    {
        id: DocumentTypeProNotImportable.charter,
        label: DocumentTypeProNotImportable.charter,
    },
    {
        id: DocumentTypeProNotImportable.contract,
        label: DocumentTypeProNotImportable.contract,
    },
    {
        id: DocumentTypeProNotImportable.invoice,
        label: DocumentTypeProNotImportable.invoice,
    },
    {
        id: DocumentTypeProNotImportable.ORIASNumber,
        label: DocumentTypeProNotImportable.ORIASNumber,
    },
    {
        id: DocumentTypeProNotImportable.RCInsurance,
        label: DocumentTypeProNotImportable.RCInsurance,
    },
    {
        id: DocumentTypeProNotImportable.Tcard,
        label: DocumentTypeProNotImportable.Tcard,
    },
    {
        id: DocumentTypeProNotImportable.inscriptionNumberAMF,
        label: DocumentTypeProNotImportable.inscriptionNumberAMF,
    },
].sort((a, b) => (a.id as string).localeCompare(b.id as string));
