import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ClientEntity, DocumentEntity, ModalTab, PropertyEntity } from '@omedom/data';

@Component({
    selector: 'omedom-property-profil',
    templateUrl: './property-profil.component.html',
    styleUrls: ['./property-profil.component.scss'],
})
export class PropertyProfilComponent implements OnInit {
    /**
     * @description Property to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/09/2023
     * @type {PropertyEntity}
     * @memberof PropertyProfilComponent
     */
    @Input()
    public property?: PropertyEntity;

    /**
     * @description Client to display (required) (default: undefined)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/01/2025
     * @type {ClientEntity}
     * @memberof PropertyProfilComponent
     */
    @Input({ required: true })
    public client!: ClientEntity;

    /**
     * @description Can start valuation or not (default: false)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 22/01/2025
     * @memberof PropertyProfilComponent
     */
    @Input()
    public canStartValuation = false;

    /**
     * @description Tabs to display in the modal header
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {ModalTab[]}
     * @memberof PropertyProfilComponent
     */
    public tabs: ModalTab[] = [
        {
            label: 'Informations',
            name: 'info',
            index: 0,
            active: true,
        },
        {
            label: 'Valorisation',
            name: 'valuation',
            index: 1,
        },
        {
            label: 'Trésorerie',
            name: 'treasury',
            index: 2,
        },
        {
            label: 'Documents',
            name: 'documents',
            index: 3,
        },
    ];

    /**
     * @description Info template to display in the modal body according to the active tab (optional) (default: undefined)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @type {TemplateRef<HTMLElement>}
     * @memberof PropertyProfilComponent
     */
    @ViewChild('info', { static: true })
    public infoTemplate?: TemplateRef<HTMLElement>;

    /**
     * @description Valuation template to display in the modal body according to the active tab (optional) (default: undefined) (default: undefined)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 01/08/2024
     * @type {TemplateRef<HTMLElement>}
     * @memberof PropertyProfilComponent
     */
    @ViewChild('valuation', { static: true })
    public valuationTemplate?: TemplateRef<HTMLElement>;

    /**
     * @description Treasury template to display in the modal body according to the active tab (optional) (default: undefined)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @type {TemplateRef<HTMLElement>}
     * @memberof PropertyProfilComponent
     */
    @ViewChild('treasury', { static: true })
    public treasuryTemplate?: TemplateRef<HTMLElement>;

    /**
     * @description Documents template to display in the modal body according to the active tab (optional) (default: undefined)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @type {TemplateRef<HTMLElement>}
     * @memberof PropertyProfilComponent
     */
    @ViewChild('documents', { static: true })
    public documentsTemplate?: TemplateRef<HTMLElement>;

    

    constructor(private readonly modalController: ModalController) {}

    ngOnInit(): void {}

    addDocument(): void {
        this.modalController.dismiss({
            action: 'addDocument',
            property: this.property,
        });
    }

    editDocument(document: DocumentEntity): void {
        this.modalController.dismiss({
            action: 'editDocument',
            document,
        });
    }

    /**
     * @description Get active tab to select the right template to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @readonly
     * @type {string}
     * @memberof PropertyProfilComponent
     */
    public get activeTab(): ModalTab {
        // Get active tab
        return this.tabs.find((x) => x.active) ?? this.tabs[0];
    }

    /**
     * @description Get the right template to display according to the active tab
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @returns {*}  {(TemplateRef<HTMLElement> | null)}
     * @memberof PropertyProfilComponent
     */
    public getTemplate(): TemplateRef<HTMLElement> | null {
        // Get active tab
        const tab = this.activeTab;

        switch (tab.name) {
            case 'info':
                return this.infoTemplate ?? null;
            case 'valuation':
                return this.valuationTemplate ?? null;
            case 'treasury':
                return this.treasuryTemplate ?? null;
            case 'documents':
                return this.documentsTemplate ?? null;
            default:
                return null;
        }
    }
}
