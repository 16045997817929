import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
    AssetTypes,
    EntityTypes,
    PropertyEntity,
    RoleAbsolute,
    RoleFr,
    RoleRight,
    RoleRightFrEnum,
    RolesInterface,
    Sharing,
    SocietyEntity,
    SocietyRoleMember,
    UserEntity,
} from '@omedom/data';
import { AnalyticsService, RoleService, UserService } from '@omedom/services';
import { RoleRightAdapter } from '@omedom/utils';

import { elementAnimation, listAnimation } from '../../../animations';
import { ShareFormComponent } from '../share-form/share-form.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'omedom-share-list',
    templateUrl: './share-list.component.html',
    styleUrls: ['./share-list.component.scss'],
    animations: [listAnimation, elementAnimation],
})
export class ShareListComponent implements OnInit, OnDestroy {
    /**
     * @description Entity of share list
     * @author Brisset Killian
     * @date 20/06/2024
     * @type {(PropertyEntity | SocietyEntity)}
     * @memberof ShareListComponent
     */
    @Input()
    public entity?: PropertyEntity | SocietyEntity;

    /**
     * @description Type of the entity shared list
     * @author Brisset Killian
     * @date 20/06/2024
     * @type {AssetTypes}
     * @memberof ShareListComponent
     */
    @Input()
    public type?: AssetTypes;

    /**
     * @description True if the user is the owner of the entity
     * @author Brisset Killian
     * @date 20/06/2024
     * @type {boolean}
     * @memberof ShareListComponent
     */
    @Input()
    public isOwner?: boolean;

    /**
     * @description Role of the user in the entity shared list
     * @author Brisset Killian
     * @date 20/06/2024
     * @type {RoleAbsolute}
     * @memberof ShareListComponent
     */
    public role?: RoleAbsolute;

    /**
     * @description Right of a user depending of his role
     * @author ANDRE Felix
     * @type {string[]}
     * @memberof ShareListComponent
     */
    public roleRight?: string[];
    /**
     * @description User role translate in langage wanted
     * @author ANDRE Felix
     * @type {string}
     * @memberof ShareListComponent
     */
    public roleTranslate?: string;
    /**
     * @description Infos of the user who shared the entity
     * @author Jérémie Lopez
     * @type {Sharing}
     * @memberof ShareListComponent
     */
    public sharedBy?: Sharing;

    /**
     * @description True when user is owner or has admin role
     * @author ANDRE Felix
     * @memberof ShareListComponent
     */
    public editable = false;

    public readOnlyDescription = RoleRight.fr.reader;
    public editorDescription = RoleRight.fr.editor;
    public adminDescription = RoleRight.fr.admin;

    /**
     * @description determine if the entity is a Society
     * @author ANDRE Felix
     * @memberof ShareListComponent
     */
    isSociety = false;

    /**
     * @description Get rights description depending on role
     * @author ANDRE Felix
     * @memberof ShareListComponent
     */
    public roleRightAdapter = new RoleRightAdapter(RoleRightFrEnum);

    /**
     * @description User entity of the current user
     * @author Brisset Killian
     * @date 20/06/2024
     * @type {UserEntity}
     * @memberof ShareListComponent
     */
    user?: UserEntity;

    /**
     * @description Text to inform user about sharing a property in array
     * I did an array to use it in the omedom-popover
     * @author ANDRE Felix
     * @memberof ShareListComponent
     */
    advertissementRules = [
        `Le partage permet de rendre visible en lecture ou en écriture les informations relatives à un bien immobilier,
un immeuble ou une société immobilière.`,
        `A noter : Le partage concerne uniquement les informations liées au bien, immeuble ou Société immobilière que vous aurez sélectionné.`,
        `Le partage ne permettra jamais l’accès aux informations de votre compte OMEDOM, votre abonnement, ni vos informations bancaires.`,
    ];

    /**
     * @description Text to inform user about sharing a property
     * As I had bold for 'A noter', I didn't use advertissementRules for the second part
     * @author ANDRE Felix
     * @memberof ShareListComponent
     */
    advertissement = `${this.advertissementRules[0]}<br /><br />
    <b>A noter :</b> Le partage concerne uniquement les informations liées au bien, immeuble ou Société immobilière que vous aurez sélectionné.
    <br /><br /><b>${this.advertissementRules[2]}</b>`;

    /**
     * @description Subscription to user observable to get user data and update role and editable property of the component
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 18/03/2025
     * @private
     * @type {Subscription}
     * @memberof ShareListComponent
     */
    private subscription?: Subscription;

    constructor(
        private modalController: ModalController,
        private userService: UserService,
        private roleService: RoleService,
        private analyticsService: AnalyticsService
    ) { }

    async ngOnInit(): Promise<void> {
        this.isSociety = this.type === AssetTypes.society;
        this.subscription = this.userService.user$.subscribe(async (user) => {
            this.user = user;
            await this.updateRole(this.user);
            this.editable = this.isOwner || this.role === 'admin';
            if (!this.role) {
                return;
            }
            this.roleTranslate = this.translateRole(this.role, RoleFr);
            this.roleRight = RoleRight.fr[this.role];
        });

        if (!this.isOwner && this.entity) {
            const owner = await this.userService.get(this.entity.userUID);

            this.sharedBy = {
                userUID: owner?.uid,
                name: owner?.name ?? '',
                email: owner?.email ?? '',
                firstname: owner?.firstname ?? '',
                role: SocietyRoleMember.admin,
            };
        }
    }

    ionViewDidEnter(): void {
        this.analyticsService.setCurrentScreen('Share property tab');
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    private async updateRole(user: UserEntity) {
        if (!this.entity) {
            return;
        }
        if (this.type === AssetTypes.property || this.type === AssetTypes.building) {
            this.role = await this.roleService.getRole(this.entity, EntityTypes.property, user);
        }
        if (this.type === AssetTypes.society) {
            this.role = await this.roleService.getRole(this.entity, EntityTypes.society, user);
        }
    }

    public async addShare(): Promise<void> {
        this.analyticsService.logEvent('Share form opened');
        const modal = await this.modalController.create({
            component: ShareFormComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                entity: this.entity,
                type: this.type,
                currentUser: this.user,
            },
        });

        await modal.present();
    }
    /**
     * @description Translate the role
     * @author ANDRE Felix
     * @private
     * @param {RoleAbsolute} role
     * @param {RolesInterface} roleEnumTranslate Enum with role in langage we want to translate
     * @returns {*}  {string}
     * @memberof ShareListComponent
     */
    private translateRole(role: RoleAbsolute, roleEnumTranslate: RolesInterface): string {
        return roleEnumTranslate[role];
    }
}
