import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { RestEntity } from '@omedom/data';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * @description Interface "where" for query
 * @author Jérémie Lopez
 * @export
 * @interface WhereSearchParameter
 */
export interface WhereSearchParameter {
    where: string;
    operator: '>=' | '==' | '<=' | '!=' | 'array-contains' | 'array-contains-any' | 'in' | 'not-in';
    value: string | Date | number | boolean | string[];
}

/**
 * @description Interface "sort" for query
 * @author Jérémie Lopez
 * @export
 * @interface SortSearchParameter
 */
export interface SortSearchParameter {
    sortBy: string;
    direction: 'asc' | 'desc';
}

/**
 * @description Interface "limit" for query
 * @author Jérémie Lopez
 * @export
 * @interface LimitSearchParameter
 */
export interface LimitSearchParameter {
    limit: number;
    limitToLast: boolean;
}

/**
 * @description Abstract Rest Service
 * @author Jérémie Lopez
 * @export
 * @abstract
 * @class RestService
 * @template T
 */
export abstract class RestService<T extends RestEntity> {
    /**
     * @description Builder of the entity
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/10/2023
     * @protected
     * @abstract
     * @memberof RestService
     */
    protected builder?: new (data?: any) => T;

    constructor(
        protected firestore: AngularFirestore,
        protected collectionName: string,
    ) { }

    /**
     * @description Get value changes of an entity
     * @author Jérémie Lopez
     * @param {string} uid
     * @return {*}  {Observable<T>}
     * @memberof RestService
     */
    public _get(uid: string): Observable<T | undefined> {
        return this.firestore
            .doc<T>(`${this.collectionName}/${uid}`)
            .valueChanges()
            .pipe(
                map((data) => {
                    // Check if data is undefined
                    if (!data) {
                        return undefined;
                    }

                    if (this.builder) {
                        const build = new this.builder(data as T);
                        return build;
                    } else {
                        return data as T;
                    }
                }),
            );
    }

    /**
     * @description Get a single time an entity
     * @author Jérémie Lopez
     * @param {string} uid
     * @return {*}  {Promise<T>}
     * @memberof RestService
     */
    public get(uid: string): Promise<T | undefined> {
        return lastValueFrom(
            this.firestore
                .doc<T>(`${this.collectionName}/${uid}`)
                .get()
                .pipe(
                    map((snap) => {
                        if (!snap.exists) {
                            return undefined;
                        }

                        if (this.builder) {
                            return new this.builder(snap.data() as T);
                        } else {
                            return snap.data() as T;
                        }
                    }),
                ),
        );
    }

    /**
     * @description Return the collection of the entity
     * @author Jérémie Lopez
     * @return {*}  {Promise<T[]>}
     * @memberof RestService
     */
    public list(): Promise<T[]> {
        return lastValueFrom(
            this.firestore
                .collection<T>(`${this.collectionName}`)
                .get()
                .pipe(
                    map(
                        (snap) =>
                            snap.docs.map((doc) => {
                                return this.builder
                                    ? new this.builder(doc.data() as T)
                                    : (doc.data() as T);
                            }) as T[],
                    ),
                ),
        );
    }

    /**
     * @description Return the changes value on collection of the entity
     * @author Jérémie Lopez
     * @return {*}  {Observable<T[]>}
     * @memberof RestService
     */
    public _list(): Observable<T[]> {
        return this.firestore
            .collection<T>(`${this.collectionName}`)
            .valueChanges()
            .pipe(
                map((snap) =>
                    snap.map((data) => {
                        return this.builder ? new this.builder(data as T) : (data as T);
                    }),
                ),
            );
    }

    /**
     * @description Return a search query one time
     * @author Jérémie Lopez
     * @param {([WhereSearchParameter | SortSearchParameter | LimitSearchParameter])} queries
     * @return {*}  {Promise<T[]>}
     * @memberof RestService
     */
    public search(
        queries: (WhereSearchParameter | SortSearchParameter | LimitSearchParameter)[],
    ): Promise<T[]> {
        return lastValueFrom(
            this.firestore
                .collection<T>(`${this.collectionName}`, (ref) => {
                    let queryRef = ref as any;

                    queries.forEach((query) => {
                        if ((query as WhereSearchParameter).where) {
                            queryRef = queryRef.where(
                                (query as WhereSearchParameter).where,
                                (query as WhereSearchParameter).operator,
                                (query as WhereSearchParameter).value,
                            );
                        }

                        if ((query as SortSearchParameter).sortBy) {
                            queryRef = queryRef.orderBy(
                                (query as SortSearchParameter).sortBy,
                                (query as SortSearchParameter).direction,
                            );
                        }

                        if ((query as LimitSearchParameter).limit) {
                            if ((query as LimitSearchParameter).limitToLast) {
                                queryRef = queryRef.limitToLast(
                                    (query as LimitSearchParameter).limit,
                                );
                            } else {
                                queryRef = queryRef.limit((query as LimitSearchParameter).limit);
                            }
                        }
                    });

                    return queryRef;
                })
                .get()
                .pipe(
                    map(
                        (snap) =>
                            snap.docs.map((doc) => {
                                return this.builder
                                    ? new this.builder(doc.data() as T)
                                    : (doc.data() as T);
                            }) as T[],
                    ),
                ),
        );
    }

    /**
     * @description Return a search query with value changes
     * @author Jérémie Lopez
     * @param {([WhereSearchParameter | SortSearchParameter | LimitSearchParameter])} queries
     * @return {*}  {Observable<T[]>}
     * @memberof RestService
     */
    public _search(
        queries: (WhereSearchParameter | SortSearchParameter | LimitSearchParameter)[],
    ): Observable<T[]> {
        return this.firestore
            .collection<T>(`${this.collectionName}`, (ref) => {
                let queryRef = ref as any;

                queries.forEach((query) => {
                    if ((query as WhereSearchParameter).where) {
                        queryRef = queryRef.where(
                            (query as WhereSearchParameter).where,
                            (query as WhereSearchParameter).operator,
                            (query as WhereSearchParameter).value,
                        );
                    }

                    if ((query as SortSearchParameter).sortBy) {
                        queryRef = queryRef.orderBy(
                            (query as SortSearchParameter).sortBy,
                            (query as SortSearchParameter).direction,
                        );
                    }

                    if ((query as LimitSearchParameter).limit) {
                        if ((query as LimitSearchParameter).limitToLast) {
                            queryRef = queryRef.limitToLast((query as LimitSearchParameter).limit);
                        } else {
                            queryRef = queryRef.limit((query as LimitSearchParameter).limit);
                        }
                    }
                });

                return queryRef;
            })
            .valueChanges()
            .pipe(
                map((snap) =>
                    snap.map((data) => {
                        return this.builder ? new this.builder(data as T) : (data as T);
                    }),
                ),
            );
    }

    /**
     * @description Update an entity
     * @author Jérémie Lopez
     * @param {Partial<T>} entity
     * @return {*}  {Promise<void>}
     * @memberof RestService
     */
    public update(entity: Partial<T>): Promise<void> {
        entity.updated = new Date();
        const objectEntitty = Object.assign({}, entity);
        return this.firestore.doc(`${this.collectionName}/${entity.uid}`).update(objectEntitty);
    }

    /**
     * @description Create an entity and return it reference
     * @author Jérémie Lopez
     * @param {Partial<T>} entity
     * @return {*}  {Promise<DocumentReference>}
     * @memberof RestService
     */
    public async create(entity: Partial<T>): Promise<DocumentReference<T>> {
        const uid = this.firestore.createId();

        if (!entity.uid) {
            entity.uid = uid;
        }

        entity.created = new Date();

        await this.firestore.doc(`${this.collectionName}/${uid}`).set(entity, {
            merge: false,
        });

        return this.firestore.doc<T>(`${this.collectionName}/${uid}`).ref;
    }

    /**
     * @description Delete an entity
     * @author Jérémie Lopez
     * @param {string} uid
     * @return {*}  {Promise<void>}
     * @memberof RestService
     */
    public delete(uid: string): Promise<void> {
        return this.firestore.doc(`${this.collectionName}/${uid}`).delete();
    }
}
