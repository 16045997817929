import { Component, Input, OnInit } from '@angular/core';
import { InvoiceDetails } from '@omedom/data';
import { Timestamp } from '@firebase/firestore';

@Component({
    selector: 'omedom-document-viewer-invoice-details',
    templateUrl: './document-viewer-invoice-details.component.html',
    styleUrls: ['./document-viewer-invoice-details.component.scss']
})
export class DocumentViewerInvoiceDetailsComponent implements OnInit {
    /**
     * @description Invoice details to display in the component
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 27/01/2025
     * @type {InvoiceDetails}
     * @memberof DocumentViewerInvoiceDetailsComponent
     */
    @Input({ required: true })
    public invoiceDetails!: InvoiceDetails;

    /**
     * @description Flag to show or hide the address section of the invoice details component
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 27/01/2025
     * @memberof DocumentViewerInvoiceDetailsComponent
     */
    @Input()
    public hasSupplierAddress = false;

    /**
     * @description Flag to show or hide the supplier section of the invoice details component
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 27/01/2025
     * @memberof DocumentViewerInvoiceDetailsComponent
     */
    @Input()
    public hasSupplier = false;

    /**
     * @description Due date of the invoice
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 27/01/2025
     * @protected
     * @type {(Date | undefined)}
     * @memberof DocumentViewerInvoiceDetailsComponent
     */
    protected dueDate: Date | undefined;

    constructor() { }

    ngOnInit(): void {
        this.setDueDate();

    }
    setDueDate() {
        this.dueDate = (this.invoiceDetails.dueDate as unknown as Timestamp).toDate();
    }
}

