import { Component, Input } from '@angular/core';
import { DPEScore, RenovationDPE } from '@omedom/data';

@Component({
    selector: 'omedom-old-renovation',
    templateUrl: './old-renovation.component.html',
    styleUrls: ['./old-renovation.component.scss'],
})
export class OldRenovationComponent {
    @Input() currentEnergyLabel?: DPEScore;
    @Input({ required: true }) propertyRenovations: RenovationDPE[] = [];

    @Input() displayUpdateMessage = true;

    constructor() {}
}
