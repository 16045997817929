import { Timestamp } from 'firebase/firestore';

import {
    documentTypeOptionsClient,
    documentTypeOptionsProImportable,
    documentTypeOptionsProNotImportable,
} from '../constants';
import { SelectOption } from '../models';
import { RestEntity } from './rest.entity';
import { PostalAddress } from '../interfaces/mail.interface';

export enum DocumentTypeClient {
    title = 'Titre de propriété',
    receipt = 'Facture',
    quotation = 'Devis',
    charge = 'Charge',
    condominium = 'Copropriété',
    tax = 'Impôt',
    insurance = 'Assurance',
    energyDiagnosis = 'Diagnostic énergétique',
    managementReports = 'Compte rendu de gestion',
    bankAccountStatements = 'Relevé de compte bancaire',
    paiementSchedule = 'Echéancier',
    lease = 'Bail',
    stateOfPlay = 'Etat des lieux',
    quittance = 'Quittance',
    relaunch = 'Relance',
    photo = 'Photo',
    other = 'Autre',
    information = 'Information',
    client = 'Client',
}

export enum DocumentTypeProImportable {
    rcs = 'RCS',
    CIF = 'CIF',
}

export enum DocumentTypeProNotImportable {
    invoice = 'Facture',
    contract = 'Contrat',
    charter = 'Charte',
    RCInsurance = 'Assurance RC',
    Tcard = 'Carte T',
    ORIASNumber = 'Numéro ORIAS',
    inscriptionNumberAMF = " N° d'inscription AMF",
}

export type DocumentProType = DocumentTypeProImportable | DocumentTypeProNotImportable;

export type DocumentTypeAll = DocumentProType | DocumentTypeClient;

export enum DocumentTypeUsed {
    client = 'client',
    proImportable = 'proImportable',
    proNotImportable = 'proNotImportable',
}

export enum Color {
    orange = 'orange',
    grey = 'grey',
    green = 'green',
    blue = 'blue',
    yellow = 'yellow',
}

export enum Category {
    receipt = 'receipt',
    file = 'file',
    state = 'state',
    contract = 'contract',
    picture = 'picture',
    electricity = 'electricity',
    quittance = 'quittance',
    relaunch = 'relaunch',
    information = 'information',
    paiementSchedule = 'paiementSchedule',
}

export enum ImageBlobType {
    png = 'image/png',
    jpeg = 'image/jpeg',
    jpg = 'image/jpg',
    gif = 'image/gif',
    webp = 'image/webp',
    tiff = 'image/tiff',
    bmp = 'image/bmp',
    svg = 'image/svg+xml',
    ico = 'image/x-icon',
}

export enum DocBlobType {
    pdf = 'application/pdf',
    // doc = 'application/msword',
    // docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // xls = 'application/vnd.ms-excel',
    // xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // ppt = 'application/vnd.ms-powerpoint',
    // pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    // odt = 'application/vnd.oasis.opendocument.text',
    // ods = 'application/vnd.oasis.opendocument.spreadsheet',
    // odp = 'application/vnd.oasis.opendocument.presentation',
    // rtf = 'application/rtf',
    // txt = 'text/plain',
    // csv = 'text/csv',
}

export enum VideoBlobType {
    mp4 = 'video/mp4',
    webm = 'video/webm',
    ogg = 'video/ogg',
    avi = 'video/x-msvideo',
    mov = 'video/quicktime',
    wmv = 'video/x-ms-wmv',
    flv = 'video/x-flv',
    mpeg = 'video/mpeg',
    mpg = 'video/mpg',
    mpe = 'video/mpe',
    m4v = 'video/m4v',
    mkv = 'video/x-matroska',
}

export enum AudioBlobType {
    mp3 = 'audio/mpeg',
    wav = 'audio/wav',
    wma = 'audio/x-ms-wma',
    aac = 'audio/aac',
    m4a = 'audio/x-m4a',
    ogg = 'audio/ogg',
    mid = 'audio/mid',
    midi = 'audio/midi',
    rmi = 'audio/mid',
    weba = 'audio/webm',
}

/**
 * @description Detail for a document with type "receipt" only
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 21/01/2025
 * @export
 * @interface InvoiceDetails
 */
export interface InvoiceDetails {
    invoiceNumber?: string;
    invoiceDate?: Date;
    amount?: number;
    averageVAT?: number; // in percent --> 20 = 20%
    totalAmount?: number;
    invoiceType: InvoiceType;
    supplier?: Supplier;
    dueDate?: Date;
    constructionWorkDetail?: string;
}

/**
 * @description Supplier details for a document with type "receipt" and only
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 21/01/2025
 * @export
 * @interface Supplier
 */
export interface Supplier {
    supplierName?: string;
    address?: PostalAddress;
}

/**
 * @description Type of invoice for a document with type "receipt" only
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 21/01/2025
 * @export
 * @enum {number}
 */
export enum InvoiceType {
    operatingExpenses = 'Charge gestion',
    managementFee = 'Charge courante',
    constructionWork = 'Travaux',
    other = 'Autre',
}

export class DocumentEntity extends RestEntity {
    /**
     * @description Link to property
     * @author Jérémie Lopez
     * @type {string}
     * @memberof DocumentEntity
     */
    propertyUID?: string;

    /**
     * @description Link to user
     * @author Jérémie Lopez
     * @type {string}
     * @memberof DocumentEntity
     */
    userUID?: string;

    /**
     * @description Link to SOCIETY
     * @author Jérémie Lopez
     * @type {string}
     * @memberof DocumentEntity
     */
    societyUID?: string;

    /**
     * @description Link to client (if the document is created by a pro);
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/01/2025
     * @type {string}
     * @memberof DocumentEntity
     */
    clientUID?: string;

    /**
     * @description Link to loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 24/06/2024
     * @type {string}
     * @memberof DocumentEntity
     */
    loanUID?: string;

    /**
     * @description Link to saving
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {string}
     * @memberof DocumentEntity
     */
    savingUID?: string;

    /**
     * @description Link to pro
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @type {string}
     * @memberof DocumentEntity
     */
    proUID?: string;

    /**
     * @description Name of the document
     * @author Jérémie Lopez
     * @type {string}
     * @memberof DocumentEntity
     */
    name!: string;

    /**
     * @description Type of the document
     * @author Jérémie Lopez
     * @type {string}
     * @memberof DocumentEntity
     */
    type!: string;
    // Pro can have a type which is a string
    // type!: DocumentTypeAll ;

    /**
     * @description Extension of the file
     * @author Jérémie Lopez
     * @type {string}
     * @memberof DocumentEntity
     */
    extension!: string;

    /**
     * @description Extension name of the file
     * @author Jérémie Lopez
     * @type {string}
     * @memberof DocumentEntity
     */
    extensionName!: string;

    /**
     * @description Date of the document
     * @author Jérémie Lopez
     * @type {Date}
     * @memberof DocumentEntity
     */
    date!: Date;

    /**
     * @description URL File in cloud storage
     * @author Jérémie Lopez
     * @type {string}
     * @memberof DocumentEntity
     */
    fileURL!: string;

    /**
     * @description prefered filename (e.g "file.ext"), used for snedinblue
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {string}
     * @memberof DocumentEntity
     */
    fileName!: string;

    /**
     * @description Weight of the file
     * @author Jérémie Lopez
     * @type {number}
     * @memberof DocumentEntity
     */
    weight!: number;

    /**
     * @description Amount of the document (if it's a receipt or a quotation for example) in euros (€) (e.g 100.00)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @type {number}
     * @memberof DocumentEntity
     */
    amount?: number;

    /**
     * @description is Document for a pro user
     * @author ANDRE Felix
     * @type {boolean}
     * @memberof DocumentEntity
     */
    isForPro?: boolean;


    /**
     * @description Details for a documetn type receipt
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 21/01/2025
     * @type {InvoiceDetails}
     * @memberof DocumentEntity
     */
    invoiceDetails?: InvoiceDetails;

    constructor(data?: Partial<DocumentEntity>) {
        super(data);

        if (this.date instanceof Timestamp) {
            this.date = new Date(this.date.seconds * 1000).toUTC();
        }
    }

    /**
     * @description True if the color match with the type, false otherwise (e.g true for a receipt with a receipt type, false for a receipt with a title type)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @static
     * @param {Color} color
     * @param {DocumentTypeAll} type
     * @returns {*}  {boolean}
     * @memberof DocumentEntity
     * @example
     * DocumentEntity.isColor(Color.orange, DocumentTypeAll.receipt) // true
     */
    public static isColor(color: Color, type: string): boolean {
        switch (color) {
            case Color.orange:
                const oranges = [
                    DocumentTypeClient.quotation,
                    DocumentTypeClient.receipt,
                    DocumentTypeClient.relaunch,
                    DocumentTypeClient.information,
                ];
                return !!oranges.find((element) => element === type);

            case Color.blue:
                const blues = [
                    DocumentTypeClient.stateOfPlay,
                    DocumentTypeClient.energyDiagnosis,
                    DocumentTypeClient.managementReports,
                    DocumentTypeClient.bankAccountStatements,
                ];
                return !!blues.find((element) => element === type);

            case Color.green:
                const greens = [
                    DocumentTypeClient.lease,
                    DocumentTypeClient.quittance,
                    DocumentTypeClient.title,
                    DocumentTypeClient.condominium,
                ];
                return !!greens.find((element) => element === type);

            case Color.yellow:
                const yellows = [
                    DocumentTypeClient.charge,
                    DocumentTypeClient.insurance,
                    DocumentTypeClient.tax,
                    DocumentTypeClient.paiementSchedule,
                ];
                return !!yellows.find((element) => element === type);

            case Color.grey:
                const greys = [DocumentTypeClient.photo, DocumentTypeClient.other];
                return !!greys.find((element) => element === type);

            default:
                return false;
        }
    }

    /**
     * @description Return true if the category match with the type, false otherwise (e.g true for a receipt with a receipt type, false for a receipt with a title type)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @static
     * @param {Category} category
     * @param {DocumentTypeAll} type
     * @returns {*}  {boolean}
     * @memberof DocumentEntity
     * @example
     * DocumentEntity.isCategory(Category.receipt, DocumentTypeClient.receipt) // true
     */
    public static isCategory(category: Category, type: string): boolean {
        switch (category) {
            case Category.contract:
                const contracts = [
                    DocumentTypeClient.insurance,
                    DocumentTypeClient.lease,
                    DocumentTypeClient.title,
                ];
                return !!contracts.find((element) => element === type);

            case Category.receipt:
                const receipts = [
                    DocumentTypeClient.charge,
                    DocumentTypeClient.quotation,
                    DocumentTypeClient.receipt,
                ];
                return !!receipts.find((element) => element === type);

            case Category.file:
                const files = [
                    DocumentTypeClient.condominium,
                    DocumentTypeClient.bankAccountStatements,
                    DocumentTypeClient.tax,
                ];
                return !!files.find((element) => element === type);

            case Category.picture:
                const pictures = [DocumentTypeClient.photo];
                return !!pictures.find((element) => element === type);

            case Category.paiementSchedule:
                const paiementSchedule = [DocumentTypeClient.paiementSchedule,];
                return !!paiementSchedule.find((element) => element === type);

            case Category.electricity:
                const elecs = [DocumentTypeClient.energyDiagnosis];
                return !!elecs.find((element) => element === type);

            case Category.state:
                const states = [
                    DocumentTypeClient.stateOfPlay,
                    DocumentTypeClient.managementReports,
                ];
                return !!states.find((element) => element === type);

            case Category.relaunch:
                const relaunchs = [DocumentTypeClient.relaunch];
                return !!relaunchs.find((element) => element === type);

            case Category.quittance:
                const quittances = [DocumentTypeClient.quittance];
                return !!quittances.find((element) => element === type);

            case Category.information:
                const informations = [DocumentTypeClient.information];
                return !!informations.find((element) => element === type);

            default:
                return false;
        }
    }

    /**
     * @description Return the color for a type
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @static
     * @param {DocumentTypeAll} type
     * @returns {*}  {Color}
     * @memberof DocumentEntity
     */
    public static getColor(type: string): Color {
        if (this.isColor(Color.orange, type)) {
            return Color.orange;
        } else if (this.isColor(Color.blue, type)) {
            return Color.blue;
        } else if (this.isColor(Color.green, type)) {
            return Color.green;
        } else if (this.isColor(Color.yellow, type)) {
            return Color.yellow;
        } else if (this.isColor(Color.grey, type)) {
            return Color.grey;
        } else {
            return Color.grey;
        }
    }

    /**
     * @description Return an icon for a type
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @static
     * @param {DocumentTypeAll} type
     * @returns {*}  {string}
     * @memberof DocumentEntity
     */
    public static getIcon(type: string): string {
        if (this.isCategory(Category.contract, type)) {
            return 'uil-clipboard-notes';
        } else if (this.isCategory(Category.receipt, type)) {
            return 'uil-receipt';
        } else if (this.isCategory(Category.file, type)) {
            return 'uil-file-alt';
        } else if (this.isCategory(Category.picture, type)) {
            return 'uil-scenery';
        } else if (this.isCategory(Category.electricity, type)) {
            return 'uil-lightbulb';
        } else if (this.isCategory(Category.state, type)) {
            return 'uil-file-edit-alt';
        } else if (this.isCategory(Category.quittance, type)) {
            return 'quittance';
        } else if (this.isCategory(Category.relaunch, type)) {
            return 'uil-document-info';
        } else if (this.isCategory(Category.information, type)) {
            return 'uil-document-info';
        } else if (this.isCategory(Category.paiementSchedule, type)) {
            return 'uil uil-schedule';
        } else {
            return 'uil-file';
        }
    }

    /**
     * @description Return the options for the document type select
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @static
     * @returns {*}  {SelectOption[]}
     * @memberof DocumentEntity
     */
    public static getDocumentTypeOptions(): SelectOption[] {
        // Only work for Client document Type !!
        const options: SelectOption[] = [];

        for (const keyType in DocumentTypeClient) {
            const type = DocumentTypeClient[keyType as keyof typeof DocumentTypeClient];

            if (typeof type === 'string') {
                const option: SelectOption = {
                    id: type,
                    label: type,
                };

                options.push(option);
            }
        }

        return options;
    }

    /**
     * @description Return SelectOptions for document type, depending on type used (client, pro importable, pro not importable)
     * @author ANDRE Felix
     * @static
     * @param {DocumentTypeUsed} typeUsed
     * @returns {*}
     * @memberof DocumentEntity
     */
    public static getDocumentTypeOptionsByTypeUsed(typeUsed: DocumentTypeUsed) {
        switch (typeUsed) {
            case DocumentTypeUsed.client:
                return documentTypeOptionsClient;
            case DocumentTypeUsed.proImportable:
                return documentTypeOptionsProImportable;
            case DocumentTypeUsed.proNotImportable:
                return documentTypeOptionsProNotImportable;
            default:
                return documentTypeOptionsClient;
        }
    }

    public static isReceipt(type: DocumentTypeAll): boolean {
        return type === DocumentTypeClient.receipt;
    }

    public static isConstructionWorkReceipt(invoiceType: InvoiceType): boolean {
        return invoiceType === InvoiceType.constructionWork;
    }
}
